//import { Pagination } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import '../scss/jobs.scss';
import DashboardWrapper from '../shared/DashboardWrapper';
import ProfileCard from '../shared/UI/ProfileCard';
import SelectDropdown from '../shared/UI/SelectDropdown';
import { jobsService } from '../services/jobs.service';
import SearchDropdown from '../shared/UI/SearchDropdown';
import { externalServices } from '../services/external.services';


function VendorList() {
    const [showFilter, setShowFilter] = useState(true)
    const [searchBy, setSearchBy] = useState('')
    const [categories, setCategories] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const [selectedType, setSelectedType] = useState(null)
    const [vendorList, setVendorList] = useState('')
    useEffect(() => {
        setSearchBy(['Category'])
        externalServices.getCategories().then((response) => {
            setCategories(response.data)
        })
    }, [])
    const toggleFilter = () => {
        setShowFilter(!showFilter)
    }
    const findVendor = () => {
        if(searchInput && selectedType){
            jobsService.getVendorSearchList(selectedType.toLowerCase(), searchInput).then((response) => {
                setVendorList(response.data)
            })
        }
    }
  return (
    <DashboardWrapper>
      <div className='page-content vendor-page'>
        <div className='page-header ps-0 pe-0'>
          <h2>Find Vendor</h2>
          <div className='right-header filter-header'>
            <div className="search-form">
                <SearchDropdown name="category" id="categoryControl" placeholder="Search Vendors"
                onSelectItem={(option) => setSearchInput(option)} data={categories} />
            </div>
            <div className='search-filter'>
                <div className='filter-toggle' onClick={toggleFilter}><span className='icon icon-filter'></span></div>
                {showFilter && <div className='filters'>
                    {searchBy && <div className='filter-item'>
                        <SelectDropdown
                            className="select-small"
                            options={searchBy}
                            labelText='Select Type'
                            onChange={(option) => setSelectedType(option)}
                        />
                    </div>}
                    <button type='button' className='btn btn-primary btn-auto ms-2' onClick={findVendor}>Find Vendor</button>
                </div>}
            </div>
          </div>
            {selectedType && <div className='filter-chips'>
                {selectedType && <span className='categories-chip chip'>{selectedType}</span>}
                {searchInput && <span className='categories-chip chip'>{searchInput}</span>}
            </div>}
        </div>
        <div className='vendor-list'>
            {vendorList && vendorList.map((item, index) => <ProfileCard vendorDetail={item} key={index} />)}
        </div>
        {/*<div className='vendor-pagination'>
            <Pagination>
                <Pagination.Item key={1} active>1</Pagination.Item>
                <Pagination.Item key={2}>2</Pagination.Item>
                <Pagination.Item key={3}>3</Pagination.Item>
            </Pagination>
        </div>*/}
      </div>
    </DashboardWrapper>
  )
}

export default VendorList