import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adminServices } from '../../services/admin.service';
//import SelectDropdown from '../../shared/UI/SelectDropdown';
import MToast from '../../shared/UI/MToast';
import { Link, useLocation } from 'react-router-dom';
import vendorIcon from '../../images/icons/vendors.svg'
import customerIcon from '../../images/icons/customers.svg'
import orderValIcon from '../../images/icons/order-value.png'
import jobAddIcon from '../../images/icons/jobs-added.svg'
import jobCloseIcon from '../../images/icons/jobs-closed.svg'
import jobConfIcon from '../../images/icons/jobs-confirmed.png'

function AdminDashboard() {
    const location = useLocation()
    const initialStartDate = useMemo(() => new Date('2023-10-01'), []);
    const initialEndDate = useMemo(() => new Date(), []);
    const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
    const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);
    const [fromParam, setFromParam] = useState(null);
    const [toParam, setToParam] = useState(null);
    const [pickerOpen, setPickerOpen] = useState(null);
    const [initialRender, setInitialRender] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSticky, setSticky] = useState(false);
    //const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
    const [reportData, setReportData] = useState(null)
    const [isVisible, setIsVisible] = useState(true)
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
            (window.innerWidth < 768) ? setIsVisible(false) : setIsVisible(true)
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuToggle = () => {
        setIsVisible(!isVisible)
    }
    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const formatDate = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    //const formatedSD = useMemo(() => selectedStartDate ? formatDate(selectedStartDate) : '', [selectedStartDate]);
    //const formatedED = useMemo(() => selectedEndDate ? formatDate(selectedEndDate) : '', [selectedEndDate]);
    /*const queryParamsMemo = useMemo(() => {
        const params = new URLSearchParams();
        params.set('from', formatedSD);
        params.set('to', formatedED);
        return params;
    }, [formatedSD, formatedED]);*/

    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    }
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    }
    /*const getReportsSummary = useCallback((loader, clicked, popstate) => {
        if(loader) setIsLoading(true)
        const params = new URLSearchParams(location.search);
        if(clicked){
            const formatedSD = popstate ? params.get('from') : selectedStartDate ? formatDate(selectedStartDate) : '';
            const formatedED = popstate ? params.get('to') : selectedEndDate ? formatDate(selectedEndDate) : '';
            adminServices.getReportSummary(formatedSD, formatedED).then((response) => {
                if(response.success){
                    setReportData(response.data)
                    if(!popstate){
                        params.set('from', formatedSD);
                        params.set('to', formatedED);
                        setFromParam(formatedSD)
                        setToParam(formatedED)
                        window.history.pushState({}, '', `${location.pathname}?${params.toString()}`)
                    }
                    setSelectedEndDate('')
                    setSelectedStartDate('')
                } else if(!response.success){
                setErrorMessage(response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage('Bad Request')
                } else if(response.code === "ERR_NETWORK") {
                    setErrorMessage(response.message)
                }
                setIsLoading(false)
            setTimeout(() => {
                setErrorMessage('')
            }, 3500);
            })
        }
    }, [selectedStartDate, selectedEndDate, location.pathname, location.search])

    const handlePopstate = useCallback(() => {
        getReportsSummary(false, true, 'popstate');
    }, [getReportsSummary])

    useEffect(() => {
    
        window.addEventListener('popstate', handlePopstate);
    
    }, [handlePopstate]);

    useEffect(() => {
        if(!initialRender) {
            getReportsSummary(false, true)
            setInitialRender(true)
        }

    }, [getReportsSummary, initialRender])*/

    const getReportsSummary = () => {
        setIsLoading(true)
        const params = new URLSearchParams(location.search);
        const formatedSD = selectedStartDate ? formatDate(selectedStartDate) : '';
        const formatedED = selectedEndDate ? formatDate(selectedEndDate) : '';
        params.set('from', formatedSD);
        params.set('to', formatedED);
        window.history.pushState({}, '', `${location.pathname}?${params.toString()}`)
        adminServices.getReportSummary(params.get('from'), params.get('to')).then((response) => {
            if (response.success) {
                setReportData(response.data)
                setFromParam(params.get('from'))
                setToParam(params.get('to'))
                setSelectedEndDate('')
                setSelectedStartDate('')
            } else if (!response.success) {
                setErrorMessage(response.error)
            }
            if (response.code === 'ERR_BAD_REQUEST') {
                setErrorMessage('Bad Request')
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
            setIsLoading(false)
            setTimeout(() => {
                setErrorMessage('')
            }, 3500);
        })
    }

    const initialReportSummary = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        let fromParam = params.get('from')
        let toParam = params.get('to')
        if (!fromParam) {
            params.set('from', formatDate(initialStartDate))
            fromParam = formatDate(initialStartDate)
            window.history.pushState({}, '', `${location.pathname}?${params.toString()}`)
        }
        if (!toParam) {
            params.set('to', formatDate(initialEndDate))
            toParam = formatDate(initialEndDate)
            window.history.pushState({}, '', `${location.pathname}?${params.toString()}`)
        }
        const formatedSD = fromParam ? fromParam : '';
        const formatedED = toParam ? toParam : '';
        adminServices.getReportSummary(formatedSD, formatedED).then((response) => {
            if (response.success) {
                setReportData(response.data)
                setFromParam(params.get('from'))
                setToParam(params.get('to'))
                setSelectedEndDate('')
                setSelectedStartDate('')
            } else if (!response.success) {
                setErrorMessage(response.error)
            }
            if (response.code === 'ERR_BAD_REQUEST') {
                setErrorMessage('Bad Request')
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
            setIsLoading(false)
            setTimeout(() => {
                setErrorMessage('')
            }, 3500);
        })
    }, [initialEndDate, initialStartDate, location.pathname])

    useEffect(() => {
        if (!initialRender) {
            initialReportSummary()
            setInitialRender(true)
        }
    }, [initialReportSummary, initialRender])

    const handlePopstate = useCallback(() => {
        initialReportSummary();
    }, [initialReportSummary])

    useEffect(() => {

        window.addEventListener('popstate', handlePopstate);

    }, [handlePopstate]);

    return (
        <>
            <DashboardWrapper>
                <div className='page-content'>
                    <h2>Dashboard</h2>
                    <div className={`distinct-row ${isSticky ? 'sticked-row' : ''}`}>
                        {isVisible && <div className='form'>
                            <div className={`input-wrapper mat-input icon-input ${selectedStartDate ? 'date-selected' : ''}`}>
                                <DatePicker selected={selectedStartDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} name="fromDate" id='FromDateControl' autoComplete='off'
                                    onChange={handleStartDateChange}
                                    maxDate={new Date()}
                                    dateFormat="yyyy-MM-dd"
                                    value={selectedStartDate ? selectedStartDate : ''}
                                    onCalendarOpen={onPickerOpen}
                                    onCalendarClose={onPickerClose} />
                                <span className='icon icon-calendar'></span>
                                <label htmlFor="FromDateControl" className="form-label">From</label>
                            </div>
                            <div className={`input-wrapper mat-input icon-input ${selectedEndDate ? 'date-selected' : ''}`}>
                                <DatePicker selected={selectedEndDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} name="toDate" id='ToDateControl' autoComplete='off'
                                    onChange={handleEndDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    minDate={selectedStartDate}
                                    maxDate={new Date()}
                                    value={selectedEndDate ? selectedEndDate : ''}
                                    disabled={selectedStartDate === ''}
                                    onCalendarOpen={onPickerOpen}
                                    onCalendarClose={onPickerClose} />
                                <span className='icon icon-calendar'></span>
                                <label htmlFor="ToDateControl" className="form-label">To</label>
                            </div>
                            <button type="submit" className={`btn btn-primary btn-medium ${selectedEndDate && selectedStartDate && !isLoading ? '' : 'disabled'}`}
                                onClick={() => getReportsSummary(true, true)}
                            >Search {isLoading && <span className='loader loader-small ms-1'></span>}</button>
                        </div>}
                        <div className='filter-text d-flex justify-content-between align-items-center flex-wrap'>
                            <h5 className='mb-0'>Showing data for {new Date(fromParam).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })} to {new Date(toParam).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</h5>
                            {isSmallScreen && <div className='form-filter-toggle d-flex align-items-center' onClick={handleMenuToggle}>{isVisible ? 'Hide' : 'Show'} Filter <i className='icon icon-filter-green'></i></div>}
                        </div>
                    </div>
                    {reportData && <div className='admin-widgets'>
                        <div className='widget'>
                            <div className='icon'>
                                <img src={vendorIcon} alt='Vendors Onboarded' />
                            </div>
                            <div className='content'>
                                <h3>{reportData.venOnboarded}</h3>
                                <p><Link to={`/admin/onboard-report?from=${fromParam ? formatDate(fromParam) : ''}&to=${toParam ? formatDate(toParam) : ''}&type=Vendors`}>Vendors Onboarded</Link></p>
                            </div>
                        </div>
                        <div className='widget'>
                            <div className='icon'>
                                <img src={customerIcon} alt='Customers Onboarded' />
                            </div>
                            <div className='content'>
                                <h3>{reportData.cusOnboarded}</h3>
                                <p><Link to={`/admin/onboard-report?from=${fromParam ? formatDate(fromParam) : ''}&to=${toParam ? formatDate(toParam) : ''}&type=Customers`}>Customers Onboarded</Link></p>
                            </div>
                        </div>
                        <div className='widget'>
                            <div className='icon'>
                                <img src={jobAddIcon} alt='Jobs Created' />
                            </div>
                            <div className='content'>
                                <h3>{reportData.jobCreated}</h3>
                                <p><Link to={`/admin/job-report?from=${fromParam ? formatDate(fromParam) : ''}&to=${toParam ? formatDate(toParam) : ''}&type=All`}>Jobs Created</Link></p>
                            </div>
                        </div>
                        <div className='widget'>
                            <div className='icon'>
                                <img src={jobConfIcon} alt='Jobs Confirmed' />
                            </div>
                            <div className='content'>
                                <h3>{reportData.jobAssigned}</h3>
                                <p><Link to={`/admin/job-report?from=${fromParam ? formatDate(fromParam) : ''}&to=${toParam ? formatDate(toParam) : ''}&type=Confirmed`}>Total Jobs Confirmed</Link></p>
                            </div>
                        </div>
                        <div className='widget'>
                            <div className='icon'>
                                <img src={jobCloseIcon} alt='Total Jobs Completed' />
                            </div>
                            <div className='content'>
                                <h3>{reportData.jobCompleted}</h3>
                                <p><Link to={`/admin/job-report?from=${fromParam ? formatDate(fromParam) : ''}&to=${toParam ? formatDate(toParam) : ''}&type=Completed`}>Total Jobs Completed</Link></p>
                            </div>
                        </div>
                        <div className='widget'>
                            <div className='icon'>
                                <img src={orderValIcon} alt='Order Value' />
                            </div>
                            <div className='content'>
                                <h3>0</h3>
                                <p><Link to="/admin/invoice-status">Total Invoice Value</Link></p>
                            </div>
                        </div>
                    </div>}
                </div>
            </DashboardWrapper>
            {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
        </>
    )
}

export default AdminDashboard