import React, { useState } from 'react'
import DashboardWrapper from '../shared/DashboardWrapper'
import '../scss/form.scss';
//import MToast from '../shared/UI/MToast';

function AddDistinctItemsPage() {
    //const [successMessage, setSuccessMessage] = useState(null)
    //const [errorMessage, setErrorMessage] = useState(null)
    const [category, setCategory] = useState('')
    const [brand, setBrand] = useState('')
    const [equipmentType, setEquipmentType] = useState('')
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <h2>Distinct Items</h2>
            <div className='distinct-row'>
                <h3>Add Category</h3>
                <div className='form'>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="category" className="form-control" id="categoryControl" placeholder=' ' autoComplete='off' value={category} onChange={(e) => setCategory(e.target.value)} />
                        <label htmlFor="categoryControl" className="form-label">Enter Category Name</label>
                    </div>
                    <button type="submit" className={`btn btn-primary btn-medium`}>Add</button>
                </div>
            </div>
            <div className='distinct-row'>
                <h3>Add Brand</h3>
                <div className='form'>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="brand" className="form-control" id="brandControl" placeholder=' ' autoComplete='off' value={brand} onChange={(e) => setBrand(e.target.value)} />
                        <label htmlFor="brandControl" className="form-label">Enter Brand Name</label>
                    </div>
                    <button type="submit" className={`btn btn-primary btn-medium`}>Add</button>
                </div>
            </div>
            <div className='distinct-row'>
                <h3>Add Equipment Type</h3>
                <div className='form'>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="category" className="form-control" id="equipmentTypeControl" placeholder=' ' autoComplete='off' value={equipmentType} onChange={(e) => setEquipmentType(e.target.value)} />
                        <label htmlFor="equipmentTypeControl" className="form-label">Enter Equipment Type Name</label>
                    </div>
                    <button type="submit" className={`btn btn-primary btn-medium`}>Add</button>
                </div>
            </div>
        </div>
    </DashboardWrapper>
  )
}

export default AddDistinctItemsPage