import React, { memo, useEffect, useState } from 'react'
import { authServices } from '../../../services/auth.services'

const JobCard = memo(({ jobdata, handleShow, lostBid }) => {
    const [jobData, setJobData] = useState('')
    const [user, setUser] = useState(null)
    useEffect(() => {
        setJobData(jobdata)
    }, [jobdata])
    useEffect(() => {
        authServices.getUser().then((response) => {
          setUser(response)
        })
    }, [])
    const showModal = () => {
        if(handleShow && jobData) handleShow(true, jobData.jobId)
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDateString = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return formattedDateString
    }
  return (
    <div className={`${jobData.multiActivity ? 'multi-activity' : ''} job-card`} onClick={showModal}>
        {jobData.multiActivity && <div className='tag'><span className='icon icon-calendar'></span>Scheduled Job</div>}
        {user && user.roles.includes('ROLE_VENDOR') && jobData && jobData.approval && <p className="mt-0 error" style={{fontSize: '14px', fontWeight: '600'}}>You have a request to extend Job End Date. Please click job to Accept/Reject.</p>}
        {user && user.roles.includes('ROLE_CUSTOMER') && jobData && jobData.approval && <p className="mt-0 error" style={{fontSize: '14px', fontWeight: '600'}}>Your request to extend Job End Date is sent to vendor. Please wait till request get accept.</p>}
        {user && user.roles.includes("ROLE_CUSTOMER") && jobData && (jobData.status === 'ASSIGNED' || jobData.status === 'START_OTP_GENERATED' || jobData.status === 'STARTED' || jobData.status === 'END_OTP_GENERATED') && <p className='error'>Please ensure you provide a Start and End OTP to vendor</p>}
        {user && user.roles.includes("ROLE_VENDOR") && jobData && (jobData.status === 'ASSIGNED' || jobData.status === 'START_OTP_GENERATED' || jobData.status === 'STARTED' || jobData.status === 'END_OTP_GENERATED') && !lostBid && <p className='error'>Please ensure you ask the customer for a Start and End OTP. It is mandatory for your payment</p>}
        <div className="card-header">
            <h4>{jobData.jobTitle}{new Date(jobData.createdAt).toDateString() === new Date().toDateString() && <span className='badge badge-primary'>New</span>}</h4>
            <div className='posted-on-wrapper'>
                <div className='posted-on'>Posted on {formatDate(jobData.createdAt)}</div>
                <span className='icon icon-more-hz'></span>
            </div>
        </div>
        <div className='card-body split-half-md'>
            <div className='card-left'>
                {jobData.rating && <div className='meta-tags'>This Job was rated: <div className="rating small-inline">{jobdata.rating} <span className="icon icon-star"></span></div></div>}
                <div className='meta-tags'>
                    <span>{jobData.bidType === "REQUEST_QUOTE" ? "Request a Quote" : "Fixed Budget"} - </span>
                    <span>{jobData.category}</span>
                </div>
                <p>{jobData && jobData.description.substr(0, 100)} <span className='link'>Read More</span></p>
                <ul className='card-hlist w-half'>
                    {user && user.roles.includes('ROLE_CUSTOMER') && jobData.bidCount ?
                        <li>Vendors Participated: <span className='badge badge-secondary badge-small badge-rounded'>{jobData.bidCount}</span></li>
                    : <>{user && user.roles.includes('ROLE_VENDOR') ? 
                        <>
                            {jobData.bidType === "REQUEST_QUOTE" ?
                            <li>Price: <strong>On Request</strong></li>
                            : <li>Fixed Price: <strong>{jobData.bidAmount}</strong></li>}
                            <li>Last date to bid : <strong>{formatDate(jobData.lastBidDate)}</strong></li>
                        </> : null
                    }</>}
                    {user && user.roles.includes('ROLE_VENDOR') && jobData.yourBidAmount ? <li>Current Bid Value <strong>{jobData.yourBidAmount}</strong></li> : null}
                   {/*jobData.lowestBidAmount && <li>Lowest Bid <strong>{jobData.lowestBidAmount}</strong></li>*/}
                </ul>
                <div className="text-sm mt-10">Job Id: <span className="blue-text">{jobData.jobId}</span></div>
            </div>
            <div className='card-right'>
                <ul className='card-hlist w-half text-tb no-bullets'>
                    <li>Job Start Date & time<strong>{formatDate(jobData.startDate)}</strong></li>
                    <li>Job End Date & time<strong>{formatDate(jobData.endDate)}</strong></li>
                    <li>Job Award Date<strong>{formatDate(jobData.bidCloserDate)}</strong></li>
                </ul>
            </div>
        </div>
    </div>
  )
})

export default JobCard