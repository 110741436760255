import React, {useState, useEffect, useCallback} from 'react'
import MToast from '../../shared/UI/MToast';
import { adminServices } from '../../services/admin.service';
import RowDropdown from '../../shared/UI/RowDropdown';
import SelectDropdown from '../../shared/UI/SelectDropdown';
import RejectModal from './rejectModal';

function ItemsTable({status, showUpdateItemModal, updateTable}) {
    const [viewRejectModal, SetViewRejectModal] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [items, setItems] = useState([]);
    const [itemId, setItemId] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(15)
    let itemsCountList = [15, 20, 50, 100]
    const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
    const getMasterItems = useCallback((status) => {
        adminServices.getMasterItemsByStatus(status, itemsPerPage, currentPage).then((response) => {
            if(response.data){
                setItems(response.data)
                setTotalItemsCount(response.total)
            }
        })
    }, [currentPage, itemsPerPage])
    useEffect(() => {
        getMasterItems(status);
        if(updateTable) getMasterItems(status)
      }, [status, currentPage, itemsPerPage, getMasterItems, updateTable]);
    const approveItem = (itemId) => {
        adminServices.approveMasterItem(itemId).then((response) => {
            if(response.success){
                setSuccessMessage(response.data)
                getMasterItems(status)
            } else if(!response.success){
                setErrorMessage(response.error)
              }
              if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage(response.data.message)
                  return false
              } else if(response.code === "ERR_NETWORK") {
                  setErrorMessage(response.message)
              }
        })
    }
    const showRejectModal = (show, itemId) => {
        setItemId(itemId)
        SetViewRejectModal(show)
    }
    const updateItemModal = (item) => {
        showUpdateItemModal(item)
    }
    const goToPage = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
          setCurrentPage(pageNumber);
        }
    };
    const getPageRange = () => {
        const range = [];
        const maxDisplayedPages = 10;
        const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);
        let startPage = currentPage - halfDisplayedPages;
        let endPage = currentPage + halfDisplayedPages;
    
        if (startPage <= 0) {
          startPage = 1;
          endPage = Math.min(maxDisplayedPages, totalPages);
        }
    
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(1, endPage - maxDisplayedPages + 1);
        }
    
        for (let i = startPage; i <= endPage; i++) {
          range.push(i);
        }
    
        return range;
    }
    const itemRejected = (value) => {
        if(value){
            getMasterItems(status)
        }
    }
  return (
    <>
        {items && items.length > 0 && <div className='table hover-table master-item-table mobile-responsive'>
            <div className='table-body'>
                <div className='table-header'>
                    <div className='equipmentname'>Equipment name</div>
                    <div>Equipment Type</div>
                    <div className='brand'>Brand</div>
                    <div>Brand Submodel</div>
                    <div className='range'>Equipment Range</div>
                    <div className='action'>Action</div>
                </div>
                {items.map((item, index) => (<div className='table-row' key={index}>
                    <div className='equipmentname'>{item.equipment}</div>
                    <div>{item.itemType}</div>
                    <div className='brand'>{item.brand}</div>
                    <div>{item.brandSubModel}</div>
                    <div className='range'>{item.itemRange}</div>
                    <div className='d-flex action'>
                        <RowDropdown>
                            {status === "PENDING" && <>
                            <li onClick={() => approveItem(item.masterItemId)}>Approve</li>
                            <li onClick={() => showRejectModal(true, item.masterItemId)}>Reject</li>
                            </>}
                            <li onClick={() => updateItemModal(item)}>Modify</li>
                        </RowDropdown>
                    </div>
                </div>))}
            </div>
            {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
            {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
        </div>}
        {totalPages > 1 ? <div className='table-pagination'>
            <ul className="pagination mb-0">
                <li className="page-item"><button className="page-link prev" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button></li>
                {getPageRange().map((pageNumber) => (
                <li key={pageNumber} className={currentPage === pageNumber ? 'page-item active' : 'page-item'}>
                    <button onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber} className="page-link">
                    {pageNumber}
                    </button>
                </li>
                ))}
                <li className="page-item"><button className="page-link next" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button></li>
            </ul>
            <div className="input-wrapper w-110 ms-auto mb-0">
                <label htmlFor="ItemsPerPage" className="form-label">Items Per Page</label>
                <SelectDropdown
                    options={itemsCountList}
                    className='select-small open-top'
                    disableMat={true}
                    labelText='Items per page'
                    value={itemsPerPage}
                    onChange={(option) => setItemsPerPage(option)}
                />
            </div>
        </div> : null}
        {viewRejectModal && itemId && <RejectModal showRejectModal={showRejectModal} viewRejectModal={viewRejectModal} itemId={itemId} itemRejected={itemRejected} />}
    </>
  )
}

export default ItemsTable