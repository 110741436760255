import React, { useEffect, useRef, useState } from 'react'
import { externalServices } from '../../services/external.services';
import MToast from './MToast';

function SearchDropdown({ data, onChange, onSelectItem, label, searcherror, autoPopulate, columns, defaultValue, multiselect, icon, sacSelect, ...rest }) {
    //const [searchQuery, setSearchQuery] = useState(defaultValue || '');
    const [filteredOptions, setFilteredOptions] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const dropDown = useRef(null)

    useEffect(() => {
      const onClickOutside = (event) => {
        if(dropDown.current && !dropDown.current.contains(event.target)){
          setIsOpen(false)
        }
      }
      document.addEventListener("mousedown", onClickOutside)
      return () => {
        document.removeEventListener("mousedown", onClickOutside)
      }
    }, [dropDown, rest]);

    
    const getSACCode = async (value) => {
      if(value.length >= 4){
        let sacData
        let headingId = value.substring(0, 4)
        await externalServices.getSACCode(headingId).then((response) => {
            if(response.data){
                sacData = response.data
            } else if(response.error){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg('Bad Request')
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
        })
        return sacData
      }
  }

    const handleChange = async (event) => {
      const value = event.target.value;
      //setSearchQuery(value);
      onSelectItem(value)
      let sacData = []
      let filtered = []
      if(sacSelect){
        sacData = await getSACCode(value)
      }
      if(sacSelect){
        filtered = sacData && sacData !== undefined && sacData.length > 0 && sacData.filter((option) => {
          return option.sacId.includes(value)
        });
      } else{
        filtered = data && data !== undefined && data.length > 0 && data.filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase())
        });
      }
      if(filtered && filtered.length>0) {
        setFilteredOptions(filtered)
      }
      if (value !== '' || autoPopulate) setIsOpen(true);
    };
    const handleOptionClick = (item) => {
        //setSearchQuery(item);
        setIsOpen(false)
        onSelectItem(item);
    };
  return (
    <>
      <div className="input-wrapper mat-input w-420 icon-input" ref={dropDown}>
          <input type="text" className="form-control" {...rest} placeholder={rest.placeholder || ' '} value={defaultValue || rest.value} onChange={handleChange} onKeyUp={handleChange} onFocus={(e) => autoPopulate && handleChange(e)} autoComplete='off' />
          <label htmlFor={rest.id} className="form-label">{label}</label>
          {searcherror !== '' ? <div className='error'>{searcherror}</div> : null}
          <span className={`icon ${icon ? icon : 'icon-search'}`}></span>
          {isOpen ? <ul className={`search-options column-${columns}`}>
            {filteredOptions.length > 0 && filteredOptions.map((item, index) => (
              sacSelect ? <li key={index} onClick={() => handleOptionClick(item.sacId)}>{item.sacId} -<small className='light-text'> {item.serviceDecsription} </small></li> : <li key={index} onClick={() => handleOptionClick(item)}>{item}</li>
            ))}
          </ul> : null}
      </div>
      {errorMsg && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMsg}</MToast>}
    </>
  )
}

export default SearchDropdown