import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import { adminServices } from '../../services/admin.service'
import MToast from '../../shared/UI/MToast'

function TierCommissionPage() {
    //const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [tierList, setTierList] = useState([])
    useEffect(() => {
        getTierList()
    }, [])
    const getTierList = () => {
        adminServices.getTierList().then((response) => {
            if(response.success){
                setTierList(response.data)
            } else if(!response.success){
                setErrorMessage(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage(response.message)
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
        })
    }
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <h2>Tier Commission</h2>
            <div className='table hover-table tier-table mobile-responsive'>
                <div className='table-body'>
                    <div className='table-header'>
                        <div>Level</div>
                        <div>Days</div>
                        <div>Message</div>
                    </div>
                    {tierList.length>0 && tierList.map((item, index) => <div className='table-row' key={index}>
                        <div>{item.level}</div>
                        <div>{item.days}</div>
                        <div>{item.message}</div>
                    </div>)}
                </div>
                {/*successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>*/}
                {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
            </div>
        </div>
    </DashboardWrapper>
  )
}

export default TierCommissionPage