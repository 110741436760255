import React, { useState } from 'react'
import '../../scss/jobs.scss';
import DashboardWrapper from '../../shared/DashboardWrapper';
import SubscriptionConfirmModal from './SubscriptionConfirmModal';


function MySubscription() {
    const [selectedSubscription, setSelectedSubscription] = useState('annual')
    const [viewConfirmModal, setViewConfirmModal] = useState(false)
    const handleSubscriptionSelect = (e) => {
        e.preventDefault()
        let ele = e.currentTarget
        setSelectedSubscription(ele.children[0].value)
    }
    const showConfirmModal = (show) => {
        setViewConfirmModal(show)
  }
  return (
    <DashboardWrapper>
      <div className='page-content myjobs'>
        <div>
          <h2>Subscription & Payment</h2>
        </div>
        <div className='subscription-wrapper'>
            <div className="input-wrapper mb-0">
                <div className="user-types subscription-types">
                    <div className={selectedSubscription === 'annual' ? 'user-type active': 'user-type'} onClick={handleSubscriptionSelect}>
                        <input type="radio" name="plan_type" value="annual" checked={selectedSubscription === 'annual'} />
                        <div className='plan-details'>
                            <div className='plan-name'>Annual</div>
                            <div className='plan-price'><span className='amount'>&#8377;180000</span>/Year</div>
                        </div>
                        <div className='plan-features'>
                            <h4>Features includes:</h4>
                            <ul className='list-unstyled mb-0'>
                                <li>Feature 1</li>
                                <li>Feature 2</li>
                            </ul>
                            <button className={selectedSubscription === 'annual' ? 'btn btn-secondary': 'btn btn-primary'}>Choose Plan</button>
                        </div>
                    </div>
                    <div className={selectedSubscription === 'half_year' ? 'user-type active': 'user-type'} onClick={handleSubscriptionSelect}>
                        <input type="radio" name="plan_type" value="half_year" checked={selectedSubscription === 'half_year'} />
                        <div className='plan-details'>
                            <div className='plan-name'>Half Yearly </div>
                            <div className='plan-price'><span className='amount'>&#8377;95000</span>/6 Months</div>
                        </div>
                        <div className='plan-features'>
                            <h4>Features includes:</h4>
                            <ul className='list-unstyled mb-0'>
                                <li>Feature 1</li>
                                <li>Feature 2</li>
                            </ul>
                            <button className={selectedSubscription === 'annual' ? 'btn btn-primary': 'btn btn-secondary'}>Choose Plan</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='subscription-details'>
                <p><strong>Your Current subscription:</strong> [Current subscription]/[expired]/[No Subscription Yet]</p>
                <p><strong>Current Subscription Start Date:</strong> 29 Jun, 2023</p>
                <p><strong>Expiration date of subscription:</strong> 28 Jun, 2024</p>
                <button className='btn btn-primary btn-medium' onClick={() => showConfirmModal(true)}>Pay Now</button>
            </div>
        </div>
      </div>
      {viewConfirmModal && <SubscriptionConfirmModal showModal={showConfirmModal} viewModal={viewConfirmModal} />}
    </DashboardWrapper>
  )
}

export default MySubscription