import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import { adminServices } from '../../services/admin.service'
import CompanyTable from './CompanyTable'
import MToast from '../../shared/UI/MToast'

function ManageUsersTierPage() {
    const [gstNo, setGstNo] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [UsersData, setUsersData] = useState([])
    const [filteredUsersData, setFilteredUsersData] = useState([])
    const [errorMsg, setErrorMsg] = useState(null)
    useEffect(() => {
        getCompanyByUserType(true, 'CUSTOMER')
    }, [])
    const getCompanyByUserType = (status, userType) => {
        setIsLoading(true)
        setIsFetching(true)
        adminServices.listCompanyByUserType(status, userType).then((response) => {
            if(response.success){
                setUsersData(response.data.customers)
                setFilteredUsersData(response.data.customers)
                setIsLoading(false)
                setIsFetching(false)
            } else if(response.error){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg('Bad Request')
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
        })
    }
    const handleUserSearch = (e) => {
        e.preventDefault()
        if(gstNo.length>0 && UsersData){
            setFilteredUsersData(
                UsersData.filter((item) => item.companyName.toLowerCase().includes(gstNo.toLowerCase()) || item.companyGstNo.toLowerCase().includes(gstNo.toLowerCase()))
            )
        } else{
            setFilteredUsersData(UsersData)
        }
    }
    const tableUpdated = (value) => {
        if(value === true){
            getCompanyByUserType(true, 'CUSTOMER')
        }
    }
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <h2>Users</h2>
            <h3>Find Users</h3>
            <div className='form inline-form'>
                <div className="input-wrapper mat-input w-420">
                    <input type="text" name="companygst" className="form-control" id="companygstControl" placeholder=' ' autoComplete='off' onChange={(e) => setGstNo(e.target.value)} />
                    <label htmlFor="userControl" className="form-label">Enter GST Number/Company Name</label>
                </div>
                <button type="button" className={`btn btn-primary btn-medium ${gstNo && isLoading ? 'disabled' : ''}`} onClick={(e) => handleUserSearch(e)}>Search{gstNo && isLoading && <span className='loader loader-small ms-1'></span>}</button>
            </div>
            {(filteredUsersData.length>0) ? <CompanyTable usersData={filteredUsersData} tableUpdated={tableUpdated} /> : isFetching ? <div>Loading Data...</div> : <div>No users available</div>}
        </div>
        {errorMsg && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMsg}</MToast>}
    </DashboardWrapper>
  )
}

export default ManageUsersTierPage