import React, { useEffect, useState } from 'react'
import '../../../scss/modal.scss';
import { useFormik } from 'formik';
import { FleetsService } from '../../../services/fleets.service';
//import { externalServices } from '../../../services/external.services';
import SearchDropdown from '../SearchDropdown';

function AddMasterItem({itemAdded, hidemasterItemForm, brandsList, equipmentsList, equipmentTypeList, itemData, mode, adminModify}) {
    //const [categories, setCategories] = useState([])
    //const [equipmentsType, setEquipmentsType] = useState([])
    //const [brands, setBrands] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    //const [equipmentNames, setEquipmentNames] = useState([])
    useEffect(() => {
        /*externalServices.getCategories().then((response) => {
            setCategories(response.data)
        })
        FleetsService.getPreloadItems().then((response) => {
            if(response){
                setEquipmentNames(response.data.equipment)
            }
        })
        externalServices.getEquimentsType().then((response) => {
            setEquipmentsType(response.data)
        })
        externalServices.getBrands().then((response) => {
            setBrands(response.data)
        })*/
    }, [])
    const addMasterItem = (values) => {
        setIsLoading(true)
        FleetsService.createMasterItem(values).then((response) => {
            if(response.success){
                formik.resetForm()
                if(itemAdded) itemAdded(true, response.data)
                if(hidemasterItemForm) hidemasterItemForm(true)
            } else if(!response.success){
                if(itemAdded) itemAdded(false, response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                if(itemAdded) itemAdded(false, response.message)
            } else if(response.code === "ERR_NETWORK") {
                if(itemAdded) itemAdded(false, response.message)
            }
            setIsLoading(false)
        })
    }
    const updateMasterItem = (values) => {
        setIsLoading(true)
        values.masterItemId = itemData.masterItemId
        FleetsService.updateMasterItem(values).then((response) => {
            if(response.success){
                formik.resetForm()
                if(itemAdded) itemAdded(true, response.data)
                if(hidemasterItemForm) hidemasterItemForm(true)
            } else if(!response.success){
                if(itemAdded) itemAdded(false, response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                if(itemAdded) itemAdded(false, response.message)
            } else if(response.code === "ERR_NETWORK") {
                if(itemAdded) itemAdded(false, response.message)
            }
            setIsLoading(false)
        })
    }
    const validate = values => {
        const errors = {};
        /*if (values.category === '') {
            errors.category = 'Category is required';
        }
        if (values.industrySegment === '') {
            errors.industrySegment = 'Industry Segment is required';
        }*/
        if (values.equipment === '') {
            errors.equipment = 'Equipment is required';
        }
        if (values.brand === '') {
            errors.brand = 'Brand is required';
        }
        if (values.brandSubModel === '') {
            errors.brandSubModel = 'Brand SubModel is required';
        }
        if (values.itemDescription === '') {
            errors.itemDescription = 'Item Description is required';
        }
        if(values.itemDescription.length > 2000){
            errors.itemDescription = 'Description can not be longer than 2000 characters.'
        }
        if (values.itemType === '') {
            errors.itemType = 'Item Type is required';
        }
        if (values.itemRange === '') {
            errors.itemRange = 'Item Range is required';
        }
        if (adminModify && mode === "EDIT" && values.remarks === '') {
            errors.remarks = 'Please provide a reason to modify item';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: { equipment: '', brand:  '', brandSubModel: '', itemDescription: '', itemType: '', itemRange: '', remarks: "" },
        validate,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            if(mode === 'EDIT'){
                updateMasterItem(values)
            } else if(mode === 'ADD' || mode === '' || mode === undefined){
                addMasterItem(values)
            }
        },
    });
    useEffect(() => {
        if(itemData){
            if(formik.values.equipment === '') formik.values.equipment = itemData.equipment
            if(formik.values.brand === '') formik.values.brand = itemData.brand
            if(formik.values.brandSubModel === '') formik.values.brandSubModel = itemData.brandSubModel
            if(formik.values.itemDescription === '') formik.values.itemDescription = itemData.itemDescription
            if(formik.values.itemType === '') formik.values.itemType = itemData.itemType
            if(formik.values.itemRange === '') formik.values.itemRange = itemData.itemRange
            if(formik.values.remarks === '') formik.values.remarks = itemData.remarks
        }
    }, [itemData, formik])
  return (
        <div className='form'>
            <p>You can add value manually, if not present in dropdown.</p>
            <form onSubmit={formik.handleSubmit}>
                {/*<SearchDropdown name="category" id="CategoryControl"
                    onBlur={formik.handleBlur}
                    icon="noicon"
                    autoPopulate={true}
                    data={categories ? categories : []}
                    onSelectItem={(option) => formik.setFieldValue("category", option)}
                    onChange={(value) => formik.setFieldValue("category", value)}
                    label="Category" searcherror={(formik.touched.categories && formik.errors.categories) ? formik.errors.categories : null} />
                <div className="input-wrapper mat-input">
                    <input type="text" name="industrySegment" className="form-control" id="industrySegmentControl" placeholder=' '
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.industrySegment}
                    />
                    <label htmlFor="industrySegmentControl" className="form-label">Industry Segment</label>
                    {(formik.touched.industrySegment && formik.errors.industrySegment) ? <div className='error'>{formik.errors.industrySegment}</div> : null}
                </div>*/}
                <SearchDropdown name="equipment" id="equipmentControl"
                    onBlur={formik.handleBlur}
                    icon="noicon"
                    autoPopulate={true}
                    value={formik.values.equipment}
                    data={equipmentsList ? equipmentsList : []}
                    onSelectItem={(option) => formik.setFieldValue("equipment", option)}
                    onChange={(value) => formik.setFieldValue("equipment", value)}
                    label="Equipment Name" searcherror={(formik.touched.equipment && formik.errors.equipment) ? formik.errors.equipment : null} />
                <SearchDropdown name="brand" id="brandControl"
                    onBlur={formik.handleBlur}
                    icon="noicon"
                    autoPopulate={true}
                    value={formik.values.brand}
                    data={brandsList ? brandsList : []}
                    onSelectItem={(option) => formik.setFieldValue("brand", option)}
                    onChange={(value) => formik.setFieldValue("brand", value)}
                    label="Brand" searcherror={(formik.touched.brand && formik.errors.brand) ? formik.errors.brand : null} />
                <div className="input-wrapper mat-input">
                    <input type="text" name="brandSubModel" className="form-control" id="brandSubModelControl" placeholder=' '
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brandSubModel}
                    />
                    <label htmlFor="brandSubModelControl" className="form-label">Brand SubModel</label>
                    {(formik.touched.brandSubModel && formik.errors.brandSubModel) ? <div className='error'>{formik.errors.brandSubModel}</div> : null}
                </div>
                <div className="input-wrapper mat-input">
                    <textarea name="itemDescription" className={`form-control ${formik.values.itemDescription !== '' ? 'textarea-filled' : ''}`} id="itemDescriptionControl" rows="3" style={{'height': '140px'}}
                        onChange={formik.handleChange} maxLength={2000}
                        onBlur={formik.handleBlur} value={formik.values.itemDescription}></textarea>
                    <label htmlFor="itemDescriptionControl" className="form-label">Item Description</label>
                    {(formik.touched.itemDescription && formik.errors.itemDescription) ? <div className='error'>{formik.errors.itemDescription}</div> : null}
                </div>
                <SearchDropdown name="itemType" id="itemTypeControl"
                    onBlur={formik.handleBlur}
                    icon="noicon"
                    autoPopulate={true}
                    value={formik.values.itemType}
                    data={equipmentTypeList ? equipmentTypeList : []}
                    onSelectItem={(option) => formik.setFieldValue("itemType", option)}
                    onChange={(value) => formik.setFieldValue("itemType", value)}
                    label="Equipment Type" searcherror={(formik.touched.itemType && formik.errors.itemType) ? formik.errors.itemType : null} />
                <div className="input-wrapper mat-input">
                    <input type="text" name="itemRange" className="form-control" id="itemRangeControl" placeholder=' '
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.itemRange}
                    />
                    <label htmlFor="itemRangeControl" className="form-label">Item Range</label>
                    {(formik.touched.itemRange && formik.errors.itemRange) ? <div className='error'>{formik.errors.itemRange}</div> : null}
                </div>
                {adminModify && mode === "EDIT" && <div className="input-wrapper mat-input">
                    <textarea name="remarks" className={`form-control ${formik.values.remarks !== '' ? 'textarea-filled' : ''}`} id="remarksControl" rows="3" style={{'height': '140px'}}
                        onChange={formik.handleChange} maxLength={2000}
                        onBlur={formik.handleBlur} value={formik.values.remarks}></textarea>
                    <label htmlFor="remarksControl" className="form-label">Reason to modify</label>
                    {(formik.touched.remarks && formik.errors.remarks) ? <div className='error'>{formik.errors.remarks}</div> : null}
                </div>}
                <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`}>{mode && mode === 'EDIT' ? 'Update' : 'Add'} Item {isLoading && <div className='loader loader-medium'></div>}</button>
            </form>
        </div>
  )
}

export default AddMasterItem