import React, { useEffect, useMemo, useState } from 'react'
import DashboardWrapper from '../shared/DashboardWrapper'
/*import availCat from '../images/widgeticon1.svg'
import equipItem from '../images/widgeticon2.svg'
import AvailSw from '../images/widgeticon3.svg'
import brandsImg from '../images/brands.jpg'*/
/*import vendorIcon from '../images/icons/vendors.svg'
import customerIcon from '../images/icons/customers.svg'
import orderValIcon from '../images/icons/order-value.png'
import jobAddIcon from '../images/icons/jobs-added.svg'
import jobCloseIcon from '../images/icons/jobs-closed.svg'
import jobConfIcon from '../images/icons/jobs-confirmed.png'
import { Link } from 'react-router-dom'*/
import { BarElement, ArcElement, CategoryScale, Chart as ChartJs, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import '../scss/form.scss';
import { UserService } from '../services/user.service';
import FullScreenBox from '../shared/UI/FullScreenBox';
import barIcon from '../images/bar-chart-icon.svg'
import pieIcon from '../images/pie-chart-icon.svg'
//import { authServices } from '../services/auth.services';

ChartJs.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderRadius: {
        topLeft: 5,
        topRight: 5
    },
    borderSkipped: false,
    barThickness: 15,
    scales: {
        y: {
            ticks: {
                beginAtZero:true,
                stepSize: 5,
                maxTicksLimit: 200,
                padding: 0,
                /*callback: function(value, index, ticks) {
                    value = Math.floor(value/1000) + 'K'
                    return value;
                },*/
                color: '#9c9d9f',
                font: {
                    size: 12,
                    weight: 600,
                    lineHeight: '24px'
                }
            },
            grid: {
                color: '#f4f4f5'
            },
            border: {
                width: 0
            }           
        },
        x: {
            ticks: {
                //autoSkip: false,
                //maxRotation: 90,
                //minRotation: 90
              },
            grid: {
                display: false
            },
            border: {
                width: 0
            },
            padding: 0,
            color: '#9c9d9f',
            font: {
                size: 12,
                weight: 600,
                lineHeight: '24px'
            }
        }
    },
    plugins: {
        legend: false
      /*legend: {
        position: 'top',
      },
      title: {
        display: false,
        //text: 'Chart.js Bar Chart',
      }*/
    },
};

//export const bidoptions = {...options}

export const pieOptions = {
    //cutout: 75,
    responsive: true,
    aspectRatio: 1,
    plugins: {
    legend: {
        position: 'bottom',
        align: 'start',
        labels: {
            boxWidth: 10,
            usePointStyle: true,
            padding: 20
        }
    }
    }
}

function VendorDashboard() {
    //const currentUser = authServices.getCurrentUser();
    const [stats, setStats] = useState(null)
    const [graphType, setGraphType] = useState('pie')
    const [jobData, setJobData] = useState({
        labels: [],
        datasets: []
    });
    const [bidData, setBidData] = useState({
        labels: [],
        datasets: []
    });
    const [assignedCategoriesData, setAssignedCategoriesData] = useState({
        labels: [],
        datasets: []
    })
    const [notAssignedCategoriesData, setNotAssignedCategoriesData] = useState({
        labels: [],
        datasets: []
    })
    const labels = useMemo(() => ['Done', 'Expired', 'Rejected'], []);
    const bidlabels = useMemo(() => ['Total Bids', 'Bids Won'], []);
    const bgColor = useMemo(() => ['#FF5767', '#FF9D00', '#21C17A'], []);
    const bgColor2 = useMemo(() => ['#FF5767', '#FF9D00'], []);
    
    const rerenderChart = () => {
        if (stats) {
          setJobData({
            ...jobData,
            datasets: [
              {
                data: [stats.jobDoneCount, stats.jobExpiredCount, stats.bidRejectCount],
                backgroundColor: bgColor,
              }
            ]
          });
        }
    }
    function hasBidsGreaterThanZero(data) {
        for (const category in data) {
          if (data.hasOwnProperty(category)) {
            if (data[category].bids > 0) {
              return true;
            }
          }
        }
        return false;
    }
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await UserService.getVendorStats();
            const vendorCatNotArray = Object.entries(response.categoryState.vendorCatNot).map(([key, value]) => ({ name: key, count: value }));
            vendorCatNotArray.sort((a, b) => b.count - a.count);
            response.categoryState.vendorCatNot = vendorCatNotArray
            setStats(response);
          } catch (error) {
            console.error("Error fetching vendor stats: ", error);
          }
        };
        fetchData();
    }, []);
    
    useEffect(() => {
        if (stats) {
            setJobData({
                labels,
                datasets: [{
                    data: [stats.jobDoneCount, stats.jobExpiredCount, stats.bidRejectCount],
                    backgroundColor: bgColor,
                }],
            })
        }
    }, [stats, labels, bgColor]);

    useEffect(() => {
        if (stats) {
            setBidData({
                labels: bidlabels,
                datasets: [{
                    data: [stats.bidCount, stats.bidWinCount],
                    backgroundColor: bgColor2,
                }],
            })
        }
    }, [stats, bidlabels, bgColor2]);

    useEffect(() => {
        if (stats) {
            setAssignedCategoriesData({
                labels: Object.keys(stats.categoryState.vendorCat),
                datasets: ["jobCount", "bids", "bidApproved"].map((label, index) => ({
                    label: label,
                    backgroundColor: bgColor[index],
                    data: Object.keys(stats.categoryState.vendorCat).map(key => stats.categoryState.vendorCat[key][label])
                })),
            })
        }
    }, [stats, labels, bgColor]);

    useEffect(() => {
        if (stats) {
            setNotAssignedCategoriesData({
                labels: stats.categoryState.vendorCatNot.map((item) => item.name),
                datasets: [{
                    data: stats.categoryState.vendorCatNot.map((item) => item.count),
                    backgroundColor: bgColor,
                }]
            })
        }
    }, [stats, labels, bgColor]);
      
  return (
    <DashboardWrapper disableCard={true}>
        <h2>Dashboard</h2>
        <div className={`analytics-widgets`}>
            <FullScreenBox rerenderChart={rerenderChart}>
                <div className='card-header'>
                    <h4>Overall Jobs Data<br /><small>(From: 1 Oct, 2023 to {new Date().toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric'})})</small></h4>
                </div>
                <div className='total d-flex align-items-start justify-content-between flex-wrap'>
                    <div className='total-left'>
                        <p>Total Job Leads</p>
                        {stats && <h3>{stats.jobsCount}</h3>}
                    </div>
                    {stats && (stats.jobDoneCount || stats.bidRejectCount || stats.jobExpiredCount) ? <select className='form-select' onChange={(e) => setGraphType(e.target.value)}>
                        <option value="pie">Pie</option>
                        <option value="bar">Bar</option>
                    </select> : null}
                </div>
                {stats && (stats.jobDoneCount || stats.bidRejectCount || stats.jobExpiredCount) ? <>
                    {graphType && graphType === 'pie' && <div className='pie-graph'>
                        <Pie data={jobData} options={pieOptions} />
                    </div>}
                    {graphType && graphType === 'bar' && <div className='bar-graph'>
                        <Bar options={options} data={jobData} />
                    </div>}
                </> :
                    <div className='no-data-chart'>
                        <img src={pieIcon} alt='Pie Chart' className='img-fluid' />
                        <p>No Data Available Yet..!</p>
                    </div>}
            </FullScreenBox>
            <FullScreenBox>
                <div className='card-header'>
                    <h4>Job Analysis (Subscribed Categories)</h4>
                </div>
                <div className='total'>
                    <p>Categories you work in</p>
                    {stats && <h3>{stats.categoriesCount}</h3>}
                </div>
                <div className='bar-graph'>
                    {stats && stats.categoryState.vendorCat && hasBidsGreaterThanZero(stats.categoryState.vendorCat) ? <Bar options={options} data={assignedCategoriesData} /> :
                    <div className='no-data-chart'>
                        <img src={barIcon} alt='Bar Chart' className='img-fluid' />
                        <p>No Data Available Yet..!</p>
                    </div>}
                </div>
            </FullScreenBox>
            <FullScreenBox>
                <div className='card-header'>
                    <h4>Job Analysis (Unsubscribe Categories)</h4>
                </div>
                <div className='bar-graph'>
                    <div style={{width: stats && stats.categoryState ? stats.categoryState.vendorCatNot.length * 80 + 'px' : '100%'}}>
                    <Bar options={options} data={notAssignedCategoriesData} />
                    </div>
                </div>
            </FullScreenBox>
            <FullScreenBox>
                <div className='card-header'>
                    <h4>Job Analysis (Bid Success Graph)</h4>
                </div>
                <div className={`${stats && stats.bidCount ? 'bar-graph' : 'bar-graph no-data'}`}>
                    {stats && stats.bidCount ? <Bar options={options} data={bidData} /> :
                    <div className='no-data-chart'>
                        <img src={barIcon} alt='Bar Chart' className='img-fluid' />
                        <p>No Data Available Yet..!</p>
                    </div>}
                </div>
            </FullScreenBox>
        </div>
    </DashboardWrapper>
  )
}

export default VendorDashboard