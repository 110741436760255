import React, { useCallback, useEffect, useState } from 'react'
import SelectDropdown from '../SelectDropdown';

function JobActivityRow({activity, sn, onSerialSelect, copiedFleet, unsetCopiedFleet, updateCatalog, user, serialNumbers, handleToggleGenerateOTP, activeclass, jobData, bidStatus}) {
    const [selectedSerial, setSelectedSerial] = useState('')
    //const [fleetCopied, setFleetCopied] = useState(false)
    /*useEffect(() => {
        if(user && user.roles.includes("ROLE_VENDOR")){
            FleetsService.getSerialNumberList().then((response) => {
                setSerialNumbers(response.data)
                if(jobData.vendor_bid && jobData.vendor_bid.serialNo){
                    const selectedSerialInfo = response.data && response.data.filter((item) => item.serialNo === jobData.vendor_bid.serialNo)
                    setSelectedSerial(selectedSerialInfo[0])
                }
            })
        }
    }, [user])*/

    const handleSerialChange = useCallback((option) => {
        //formik.setFieldValue("serialNo", option.serialNo)
        setSelectedSerial(option)
        if(onSerialSelect) onSerialSelect(option)
        if(updateCatalog && activity) updateCatalog(activity.activityId, option.fleetId, option.serialNo)
    }, [activity, onSerialSelect, updateCatalog])

    useEffect(() => {
        if(copiedFleet) {
            handleSerialChange(copiedFleet)
            onSerialSelect('')
            unsetCopiedFleet()
        }
    }, [copiedFleet, unsetCopiedFleet, onSerialSelect, handleSerialChange])

    
  return (
    <>
    <div className={`table-row ${activeclass ? 'active' : ''}`}>
        <div className='s-no'>{sn+1}</div>
        <div className='date'>{new Date(activity.startDate).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
        <div className='date'>{new Date(activity.endDate).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
        {jobData && jobData.job.budgetType === 'QUANTITY' && <div className='qty'>{activity.quantity}</div>}
        {user && user.roles.includes("ROLE_VENDOR") && bidStatus !== 'REJECTED' && <div className='action'>
        <SelectDropdown
            serialSelect={true}
            disableMat={true}
            options={serialNumbers.length > 0 ? serialNumbers : []}
            labelText='Serial Number'
            className={`select-tiny ${(!selectedSerial && !activity.serialNo) ? 'serial-input-error' : ''} ${activity && activity.status !== 'ACTIVITY_CREATED' && activity.status !== 'START_OTP_GENERATED' ? 'disabled' : ''}`}
            value={selectedSerial ? selectedSerial.serialNo + ' - ' +  selectedSerial.brand + ' - ' + selectedSerial.equipmentName : activity.serialNo ? activity.serialNo + ' - ' + activity.brand + ' - ' + activity.equipment  : '' }
            onChange={(option) => handleSerialChange(option)}
        />
        </div>}
        {user && user.roles.includes("ROLE_CUSTOMER") && activity && jobData && jobData.job.status === 'ASSIGNED' ? (activity.status === 'ACTIVITY_CREATED' || activity.status === 'STARTED' || activity.status === 'START_OTP_GENERATED' || activity.status === 'END_OTP_GENERATED' ? 
            <div className='action-btn'>
                <button className='btn btn-primary' onClick={handleToggleGenerateOTP}>
                    {activity.status === 'ACTIVITY_CREATED' ? 'Generate Start OTP' : activity.status === 'START_OTP_GENERATED' ? 'Re-Generate Start OTP' : activity.status === 'STARTED' ? 'Generate END OTP' : activity.status === 'END_OTP_GENERATED' ? 'Re-Generate End OTP' : null}
                </button>
            </div>
            : user && user.roles.includes("ROLE_CUSTOMER") && activity && activity.status === 'DONE' ? <div className='action-btn success mt-0'>Completed</div> : <div className='action-btn'>-</div>) : null}

        {user && activity && user.roles.includes("ROLE_VENDOR") && jobData && jobData.job.status === 'ASSIGNED' && bidStatus !== 'REJECTED' ? (activity.status === 'ACTIVITY_CREATED' || activity.status === 'STARTED' || activity.status === 'START_OTP_GENERATED' || activity.status === 'END_OTP_GENERATED' ?<div className='action-btn'>{activity.status === 'STARTED' ? <div>Started</div> : <button className={`btn btn-primary ${activity.status !== 'START_OTP_GENERATED' && activity.status !== 'END_OTP_GENERATED' ? 'disabled' : ''}`} onClick={handleToggleGenerateOTP}>
            {activity.status === 'ACTIVITY_CREATED' ? 'Enter Start OTP' : activity.status === 'START_OTP_GENERATED' ? 'Enter Start OTP' : activity.status === 'STARTED' ? 'Enter END OTP' : activity.status === 'END_OTP_GENERATED' ? 'Enter End OTP' : null}
            </button>}</div> : user && user.roles.includes("ROLE_VENDOR") && activity && activity.status === 'DONE' ? <div className='action-btn success mt-0'>Completed</div> : <div className='action-btn'>-</div>) : null}
    </div>
    </>
  )
}

export default JobActivityRow