import { apiData } from "./api";
import { authServices } from "./auth.services";

const initJobPayment = async (jobData) => {
    const token = await authServices.getToken();
    return await apiData.api_payment.post(`/job/init`, jobData, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

const confirmJobPayment = async (jobId, transactionId) => {
    const token = await authServices.getToken();
    return await apiData.api_payment.get(`/get-status?jobId=${jobId}&transactionId=${transactionId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

export const paymentService = {
    initJobPayment, confirmJobPayment
}