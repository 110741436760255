import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

function FleetRemoveModal({viewRemoveFleetModal, showRemoveFleetModal, fleetId, deleteFleet}) {
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(viewRemoveFleetModal) setShow(viewRemoveFleetModal)
    }, [viewRemoveFleetModal])
    const handleHide = () => {
        setShow(false)
        if(showRemoveFleetModal) showRemoveFleetModal(false)
    }
    const removeFleet = () => {
        setIsLoading(true)
        if(deleteFleet) deleteFleet(fleetId)
        handleHide()
        setIsLoading(false)
    }
  return (
    <Modal show={show} onHide={handleHide} className='mpass-modal center-modal modal-small'>
        <Modal.Header>
            <Modal.Title>Remove Fleet</Modal.Title>
            <span className='close-modal icon icon-cross' style={{width: '25px', height: '25px'}} onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <div className='modal-section'>
                <p>Are you sure you want to delete the fleet permanently?</p>
                <div className='d-flex justify-content-between'>
                <button className={`btn btn-secondary btn-auto`} onClick={handleHide} type='button'>No</button>
                <button className={`btn btn-primary btn-auto ${isLoading ? 'disabled' : ''}`} onClick={() => removeFleet()} type='button'>Yes{isLoading && <div className='loader loader-medium'></div>}</button></div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default FleetRemoveModal