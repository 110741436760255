import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import { externalServices } from '../../../services/external.services';
import { UserService } from '../../../services/user.service';
import MToast from '../MToast';

function CategoryModal({showModal, viewModal, userCategories, categoryUpdateSuccess, viewMode}) {
    const [show, setShow] = useState(false)
    const [categories, setCategories] = useState(null)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [categoriesUpdatedMsg, setCategoriesUpdatedMsg] = useState('')
    const [categoriesFailedMsg, setCategoriesFailedMsg] = useState('')
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        externalServices.getCategories().then((response) => {
            setCategories(response.data)
        })
        userCategories && setSelectedCategories([...userCategories])
    }, [viewModal, userCategories])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const handleSelectCategories = (e) => {
        let value = e.target.value
        if (selectedCategories.indexOf(value) === -1) {
            setSelectedCategories([...selectedCategories, value]);
        } else {
            const updatedOptions = [...selectedCategories];
            updatedOptions.splice(selectedCategories.indexOf(value), 1);
            if(updatedOptions.length<=0){
                setCategoriesFailedMsg('Atleast 1 category need to be added.')
                setTimeout(() => {
                    setCategoriesFailedMsg('')
                }, 3000);
            }
            setSelectedCategories(updatedOptions);
        }
    }
    const saveCategories = () => {
        setCategoriesUpdatedMsg('')
        setCategoriesFailedMsg('')
        if(selectedCategories.length>0){
            UserService.updateVendor({"category": selectedCategories}).then((response) => {
                if(response && response.success){
                    setCategoriesUpdatedMsg('Categories updated successfully')
                    if(categoryUpdateSuccess) categoryUpdateSuccess(true)
                } else {
                    setCategoriesFailedMsg('Failed to update categories. Please try again later')
                }
            })
        } else{
            setCategoriesFailedMsg('Atleast 1 category need to be added.')
        }
    }
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal fixed-footer'>
        <Modal.Header>
            <Modal.Title>Categories</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        {categories && 
        <><Modal.Body>
            {!viewMode && <p>Your categories determine which jobs you will be notified for. Deselecting a category may result in missed opportunities. Existing jobs will not be impacted by category selection.</p>}
            <ul className="categories-list">
                {categories.map((item, index) =>
                    <li key={index} className={`${selectedCategories && selectedCategories.includes(item) && 'bold-text'}`}>
                        {!viewMode && <input type='checkbox' className='form-check-input' value={item} id={item.split(" ").join('')} name="categories"
                            checked={selectedCategories && selectedCategories.includes(item)}
                            onChange={handleSelectCategories}
                        />}
                        <label htmlFor={item.split(" ").join('')}>{item}</label>
                    </li>
                )}
            </ul>
        </Modal.Body>
        <Modal.Footer>{!viewMode && <button type='button' className={`${selectedCategories && selectedCategories.length===0 ? 'disabled' : ''} btn btn-primary btn-medium`} onClick={saveCategories}>Update Categories</button>}</Modal.Footer></>}
    </Modal>
    {categoriesUpdatedMsg ? <MToast color="green" duration={2000} icon="icon-check-green-rev">{categoriesUpdatedMsg}</MToast> : null}
    {categoriesFailedMsg ? <MToast color="red" duration={2000} icon="icon-cross-red-rev">{categoriesFailedMsg}</MToast> : null}
    </>
  )
}

export default CategoryModal