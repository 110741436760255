import React, { useEffect, useState } from "react";
import ExternalSide from "./ExternalSide";
import "../scss/signup.scss";

function ExternalWrapper({children, title, bgImg}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
        <div className="signup-container">
            <div className="left-banner">
                <ExternalSide
                    title={title}
                    bgImg={bgImg}
                />
            </div>
            <div className="right-container">
                {children}
                {windowWidth < 767 && <p className='copyright-info'>&copy;2023 Ctrl Alt Del Pvt Ltd. All rights reserved</p>}
            </div>
        </div>
    );
}

export default ExternalWrapper;
