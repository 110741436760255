//import { Pagination } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import '../../scss/jobs.scss';
import { jobsService } from '../../services/jobs.service';
import DashboardWrapper from '../../shared/DashboardWrapper';
import ProfileCard from '../../shared/UI/ProfileCard';
import { useParams } from 'react-router-dom';


function VendorListByCategory() {
    const [vendorList, setVendorList] = useState('')
    const {category} = useParams()
    useEffect(() => {
        const findVendor = () => {
            jobsService.getVendorSearchList('Category', category).then((response) => {
                setVendorList(response.data)
            })
        }
        if(category !== null || '' || undefined) findVendor()
    }, [category])
  return (
    <DashboardWrapper>
      <div className='page-content vendor-page'>
        <div className='page-header ps-0 pe-0'>
          <h2>{vendorList ? vendorList.length : '0'} Vendors in {category}</h2>
        </div>
        <div className='vendor-list'>
            {vendorList && vendorList.map((item, index) => <ProfileCard vendorDetail={item} key={index} />)}
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default VendorListByCategory