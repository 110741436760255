import React, { useEffect, useRef, useState } from "react";
import BannerBg from "../../images/side-banner.jpg";
import "../../scss/signup.scss";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { authServices } from "../../services/auth.services";
import MToast from "../../shared/UI/MToast";
import ExternalWrapper from "../../shared/ExternalWrapper";

function Login() {
    const navigate = useNavigate();
    const routeFragment = window.location.hash.replace("#", "");
    const [loginError, setLoginError] = useState(null);
    const [loginSuccess, setLoginsuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [isRegistered, setIsRegistered] = useState(null);
    const pwdToggle = useRef(null);
    useEffect(() => {
        if (routeFragment === "verified") {
            setLoginsuccess(
                "Congratulations! you are verified. Please login again."
            );
        }
        if (routeFragment === "resetsuccess") {
            setLoginsuccess(
                "Congratulations! your password is reset successfully. Please login again."
            );
        }
        if (routeFragment === "registered") {
            setLoginsuccess(
                "You have been successfully registered. Please login to continue."
            );
            setIsRegistered(true)
        }
        if (routeFragment === "session-expired") {
            setLoginError("Your session is expired. Please login again.");
        }
    }, [routeFragment]);
    const signIn = (credentials) => {
        setLoginsuccess("");
        setLoginError("");
        setIsLoading(true);
        authServices
            .signIn(credentials)
            .then((response) => {
                setIsLoading(false);
                if (response.code === "ERR_BAD_REQUEST")
                    setLoginError("Invalid Credentials");
                if (response.message === "Bad credentials")
                    setLoginError("Either username or password is incorrect. Please try again.");
                if (response.error)
                    setLoginError(response.error);
                else if (response.code && response.code !== "ERR_BAD_REQUEST")
                    setLoginError(response.message);
                else {
                    if (response.username && !response.success) {
                        setLoginError(
                            "Your account is deactivated. Please connect to admin."
                        );
                    }
                    if (
                        response.username &&
                        response.success &&
                        response.status === "INITIALIZE"
                    ) {
                        navigate("/verify-otp");
                    } else if (
                        response.username &&
                        response.success &&
                        response.status === "ONBOARDED"
                    ) {
                        if (response.roles[0] === "ROLE_CUSTOMER") {
                            navigate("/customer/dashboard");
                        }
                        if (response.roles[0] === "ROLE_VENDOR") {
                            navigate("/vendor/my-jobs");
                        }
                        if (response.roles[0] === "ROLE_ADMIN") {
                            navigate("/admin/dashboard");
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };
    let togglePassword = () => {
        const icon = pwdToggle.current.childNodes[1];
        const input = pwdToggle.current.childNodes[0];
        const attrType = input.getAttribute("type");
        if (attrType === "text") {
            input.setAttribute("type", "password");
        } else {
            input.setAttribute("type", "text");
        }
        icon.classList.toggle("icon-eye");
        icon.classList.toggle("icon-eye-close");
    };
    return (
        <ExternalWrapper
            title="Discover India's Top Measurement, Design and 3D Printing service providers"
            bgImg={BannerBg}>
            <Fade delay={1500}>
                <div className="login-menu text-end">
                    <span className="login-menu-text">
                        Don't have an Account?{" "}
                    </span>
                    <Link to="/" className="btn btn-secondary">
                        Create New Account
                    </Link>
                </div>
            </Fade>
            <Fade delay={1800}>
                <div className="form-wrapper">
                    <div className="heading-wrapper">
                        <h2>Login to Continue</h2>
                    </div>
                    <div className="form width-540">
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email =
                                        "Email Address is required";
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                        values.email
                                    )
                                ) {
                                    errors.email =
                                        "Please enter valid Email Address";
                                }
                                if (!values.password) {
                                    errors.password =
                                        "Please enter password";
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                signIn(values);
                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="input-wrapper">
                                        <label
                                            htmlFor="usernameControl"
                                            className="form-label"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            id="usernameControl"
                                            placeholder="Please enter your registered email address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        <div className="error">
                                            {errors.email &&
                                                touched.email &&
                                                errors.email}
                                        </div>
                                    </div>
                                    <div className="input-wrapper">
                                        <div className="d-flex justify-content-between">
                                            <label
                                                htmlFor="passwordControl"
                                                className="form-label"
                                            >
                                                Password
                                            </label>
                                            <Link
                                                to="/reset-password"
                                                style={{
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Forgot Password
                                            </Link>
                                        </div>
                                        <div
                                            className="show-hide-password"
                                            ref={pwdToggle}
                                        >
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                id="passwordControl"
                                                placeholder="*********"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                            <span
                                                className="icon icon-eye"
                                                onClick={togglePassword}
                                            ></span>
                                        </div>
                                        <div className="error">
                                            {errors.password &&
                                                touched.password &&
                                                errors.password}
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-full ${
                                            isLoading ? "disabled" : ""
                                        }`}
                                    >
                                        Login
                                        {isLoading && (
                                            <span className="loader loader-small ms-1"></span>
                                        )}
                                    </button>
                                </form>
                            )}
                        </Formik>
                    </div>
                    {isRegistered && <p className="success">You are registered successfully. Please login to verify your account.</p>}
                    {loginSuccess && (
                        <MToast
                            color="green"
                            duration={3500}
                            icon="icon-check-green-rev"
                        >
                            {loginSuccess}
                        </MToast>
                    )}
                    {loginError && (
                        <MToast
                            color="red"
                            duration={3500}
                            icon="icon-cross-red-rev"
                        >
                            {loginError}
                        </MToast>
                    )}
                </div>
            </Fade>
        </ExternalWrapper>
    );
}

export default Login;
