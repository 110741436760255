import React, { useEffect, useMemo, useState } from 'react'
import DashboardWrapper from '../shared/DashboardWrapper'
/*import availCat from '../images/widgeticon1.svg'
import equipItem from '../images/widgeticon2.svg'
import AvailSw from '../images/widgeticon3.svg'
import brandsImg from '../images/brands.jpg'
import vendorIcon from '../images/icons/vendors.svg'
import customerIcon from '../images/icons/customers.svg'
import orderValIcon from '../images/icons/order-value.png'
import jobAddIcon from '../images/icons/jobs-added.svg'
import jobCloseIcon from '../images/icons/jobs-closed.svg'
import jobConfIcon from '../images/icons/jobs-confirmed.png'
import { Link } from 'react-router-dom'*/
import { BarElement, ArcElement, CategoryScale, Chart as ChartJs, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import '../scss/form.scss';
import { UserService } from '../services/user.service';
import FullScreenBox from '../shared/UI/FullScreenBox';
import pieIcon from '../images/pie-chart-icon.svg'
//import { authServices } from '../services/auth.services';

ChartJs.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderRadius: {
        topLeft: 5,
        topRight: 5
    },
    borderSkipped: false,
    barThickness: 15,
    scales: {
        y: {
            ticks: {
                beginAtZero:true,
                stepSize: 5,
                maxTicksLimit: 200,
                padding: 20,
                /*callback: function(value, index, ticks) {
                    value = Math.floor(value/1000) + 'K'
                    return value;
                },*/
                color: '#9c9d9f',
                font: {
                    size: 12,
                    weight: 600,
                    lineHeight: '24px'
                }
            },
            grid: {
                color: '#f4f4f5'
            },
            border: {
                width: 0
            }           
        },
        x: {
            grid: {
                display: false
            },
            border: {
                width: 0
            },
            padding: 15,
            color: '#9c9d9f',
            font: {
                size: 12,
                weight: 600,
                lineHeight: '24px'
            }
        }
    },
    plugins: {
        legend: false
      /*legend: {
        position: 'top',
      },
      title: {
        display: false,
        //text: 'Chart.js Bar Chart',
      }*/
    },
};

export const pieOptions = {
    //cutout: 75,
    responsive: true,
    aspectRatio: 1,
    plugins: {
    legend: {
        position: 'bottom',
        align: 'start',
        labels: {
            boxWidth: 10,
            usePointStyle: true,
            padding: 20
        }
    }
    }
}

function CustomerDashboard() {
    //const currentUser = authServices.getCurrentUser();
    const [stats, setStats] = useState(null)
    const [graphType, setGraphType] = useState('pie')
    const [jobCatVendorCountData, setJobCatVendorCountData] = useState({
        labels: [],
        datasets: []
    })
    const bgColor = useMemo(() => ['#FF5767', '#FF9D00', '#21C17A', '#a83ab5'], []);
    const jobData = {
        labels: ['Total Jobs Posted', 'Total Jobs Assigned', 'Total Jobs Completed', 'Total Jobs Expired'],
        datasets: [
          {
            label: '# of Jobs',
            data: stats ? [stats.totalJobPosted, stats.totalJobInAssigned, stats.totalJobCompleted, stats.totalJobExpired] : ['', '', '', ''],
            backgroundColor: [
                '#FF5767', '#FF9D00', '#21C17A', '#a83ab5'
            ],
            borderWidth: 0,
          },
        ]
    }
    useEffect(() => {
        UserService.getCustomerStats().then((response) => {
            const jobCatVendorCount = Object.entries(response.jobCatVendorCount).map(([key, value]) => ({ name: key, count: value }));
            jobCatVendorCount.sort((a, b) => b.count - a.count);
            response.jobCatVendorCount = jobCatVendorCount
            setStats(response)
        })
    }, [])
    useEffect(() => {
        if (stats) {
            setJobCatVendorCountData({
                labels: stats.jobCatVendorCount.map((item) => item.name),
                datasets: [{
                    data: stats.jobCatVendorCount.map((item) => item.count),
                    backgroundColor: bgColor,
                }]
            })
        }
    }, [stats, bgColor]);
  return (
    <DashboardWrapper disableCard={true}>
        <h2>Dashboard</h2>
        <div className={`analytics-widgets ${stats && stats.jobCatVendorCount && Object.keys(stats.jobCatVendorCount).length < 6 ? 'half-box-2nd' : ''}`}>
            <FullScreenBox>
                <div className='card-header d-flex align-items-start justify-content-between flex-wrap' style={{paddingTop: '15px'}}>
                    <h4>Overall Jobs Data<br /><small>(From: 1 Oct, 2023 to {new Date().toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric'})})</small></h4>
                    {stats && (stats.totalJobInAssigned || stats.totalJobCompleted || stats.totalJobInAssigned || stats.totalJobExpired) ? <select className='form-select' onChange={(e) => setGraphType(e.target.value)}>
                        <option value="pie">Pie</option>
                        <option value="bar">Bar</option>
                    </select> : null}
                </div>
                {stats && (stats.totalJobInAssigned || stats.totalJobCompleted || stats.totalJobInAssigned || stats.totalJobExpired) ? <>
                    {graphType && graphType === 'pie' && <div className='pie-graph'>
                        <Pie data={jobData} options={pieOptions} />
                    </div>}
                    {graphType && graphType === 'bar' && <div className='bar-graph'>
                        <Bar options={options} data={jobData} />
                    </div>}
                </> :
                    <div className='no-data-chart'>
                        <img src={pieIcon} alt='Pie Chart' className='img-fluid' />
                        <p>No Data Available Yet..!</p>
                    </div>}
            </FullScreenBox>
            <FullScreenBox>
                <div className='card-header'>
                    <h4>Number of Vendors per Category</h4>
                </div>
                <div className='total d-flex align-items-center'>
                    <p className='me-2'>Categories Count for Posted Jobs: </p>
                    {stats && <h3>{stats.category.categoryCount}</h3>}
                </div>
                {/*<div className='data-table'>
                    {stats ? <ul>
                        {Object.entries(stats.jobCatVendorCount).map(([key, value]) => <li key={key}><span>{key}</span><span>{value}</span></li>)}
                    </ul>: null}
                </div>*/}
                <div className='bar-graph'>
                    <Bar options={options} data={jobCatVendorCountData} />
                </div>
            </FullScreenBox>
        </div>
    </DashboardWrapper>
  )
}

export default CustomerDashboard