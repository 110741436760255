import React, { useEffect, useState } from 'react'
import LogoRevert from '../images/logo-revert.svg';
import { Fade, Reveal } from "react-awesome-reveal";
import { keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

function ExternalSide({bgImg, title}) {
  const customAnimation = keyframes`
  0% {
    left: 0;
    width: 0;
  }
  5% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
  }
  51% {
    left: auto;
    right: 0;
  }
  100% {
    left: auto;
    right: 0;
    width: 0;
  }
  `;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <aside className="sidecontent">
      <Fade className='slide-image' duration={800} delay={600} triggerOnce>
        <img src={bgImg} alt='' className='revealImg' />
      </Fade>
      <Reveal className="slide-overlay" keyframes={customAnimation}>&nbsp;</Reveal>
      <Fade className="logo" cascade delay={1000} triggerOnce>
        <Link to="/"><img src={LogoRevert} alt="MPAAS&reg;" /></Link>
        <h2>{title}</h2>
      </Fade>
      {windowWidth > 768 && <p className='copyright-info'>&copy;2023 Ctrl Alt Del Pvt Ltd. All rights reserved</p>}
    </aside>
  )
}

export default ExternalSide