import React, { useEffect, useRef, useState } from "react";

const SelectDropdown = ({ options, value, onChange, labelText, multiselect, disableMat, className, serialSelect, sacSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [noneEnabled, setNoneEnabled] = useState(false);
  const [multiValues, setMultiValues] = useState([])
  const dropDown = useRef(null)

  useEffect(() => {
    if(value !== '' || value.length>0){
      setHasValue(true)
    } else{
      setHasValue(false)
    }
    const onClickOutside = (event) => {
      if(dropDown.current && !dropDown.current.contains(event.target)){
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", onClickOutside);
        return () => {
          document.removeEventListener("mousedown", onClickOutside);
        };
  }, [dropDown, value]);

  const handleOptionClick = (option) => {
    if(multiselect){
      const optionIndex = multiValues.indexOf(option)
      if (optionIndex === -1) {
        if (option === 'None') {
          setNoneEnabled(true)
          setMultiValues([option])
          onChange([option])
        } else{
          setMultiValues([...multiValues, option]);
          onChange([...multiValues, option]);
        }
      } else {
        const updatedOptions = [...multiValues];
        updatedOptions.splice(optionIndex, 1);
        setMultiValues(updatedOptions);
        onChange(updatedOptions);
        if (option === 'None') setNoneEnabled(false)
      }
    } else{
      onChange(option);
      setIsOpen(false);
    }
    setHasValue(true)
  };

  return (
    <div className={`custom-select ${className ? className : ''} ${isOpen ? 'open-select' : ''} ${hasValue ? 'selected' : ''}`} ref={dropDown}>
      <div className='select-header form-control ' onClick={() => setIsOpen(!isOpen)}>
        {disableMat && hasValue ? '' : <label className="form-label">{labelText}</label>}
        <div className="value">{value}</div>
      </div>
      {isOpen && (
        <ul className="select-options">
          {options.map((option, key) => (
            <li key={key} onClick={() => handleOptionClick(option)} className={`${multiValues.includes(option)? 'bold-text': ''} ${noneEnabled && option !== 'None' ? 'disabled' : ''}`}>
              {multiselect && <input type='checkbox' className='form-check-input' checked={multiValues.includes(option)} readOnly />}
              {serialSelect ? `${option.serialNo} - ${option.brand} - ${option.equipmentName}` : sacSelect ? <>{option.sacCode} {option.sacCode !== '' ? '-' : ''} <small>{option.sacInfo}</small></> : option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectDropdown;