import React, { useCallback, useEffect, useState } from 'react'
import DashboardWrapper from './DashboardWrapper';
import { paymentService } from '../services/payment.service';
import MToast from './UI/MToast';
import { Link } from 'react-router-dom';
import { jobsServicev2 } from '../services/job.service-v2';


function PaymentSuccessFailed() {
  const [paymentStatus, setPaymentStatus] = useState('PAYMENT_PENDING')
  const [apiDone, setApiDone] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const jobPaymentInfo = localStorage.getItem('jobPaymentInfo') ? JSON.parse(localStorage.getItem('jobPaymentInfo')) : ''
  const approveBid = useCallback((bidId) => {
    jobsServicev2.approveJobBid(bidId).then((response) => {
      if(response){
        if(response.success){
        } else if(!response.success){
          setErrorMsg(response.error)
        }
        if(response.code === 'ERR_BAD_REQUEST'){
          setErrorMsg(response.data.message)
        } else if(response.code === "ERR_NETWORK") {
            setErrorMsg(response.message)
        }
      }
      //buttonRef.current.classList.remove('disabled')
    })
  }, [])
  useEffect(() => {
    if(jobPaymentInfo && !apiDone){
      paymentService.confirmJobPayment(jobPaymentInfo.jobId, jobPaymentInfo.transactionId).then((response) => {
        if(response){
          if(response.success){
            localStorage.setItem('PaymentStatus', response.data)
            setPaymentStatus(response.data)
            setApiDone(true)
            if(response.data === 'PAYMENT_SUCCESS') approveBid(jobPaymentInfo.bidId)
          } else if(!response.success){
            setErrorMsg(response.error)
          }
          if(response.code === 'ERR_BAD_REQUEST'){
            setErrorMsg('Bad Request')
          } else if(response.code === "ERR_NETWORK") {
            setErrorMsg(response.message)
          }
        }
      })
    }
  }, [jobPaymentInfo, approveBid, apiDone])
  return (
    <DashboardWrapper disableCard={true}>
      <div className='page-content ps-0 pe-0 myjobs'>
        {localStorage.getItem('PaymentStatus') === 'PAYMENT_SUCCESS' ? <div className='payment-done payment-success'>
          <div className='icon icon-check-green'></div>
          <h3>Payment Success</h3>
          <p>Your payment for JobId: {jobPaymentInfo.jobId} is done successfully</p>
          <Link className='btn btn-primary' to="/customer/my-jobs">Go to Jobs Page</Link>
        </div> : localStorage.getItem('PaymentStatus') === 'PAYMENT_ERROR' ? <div className='payment-done payment-failed'>
          <div className='icon icon-cross-red'></div>
          <h3>Payment Failed</h3>
          <p>Your payment for {jobPaymentInfo.jobId} is failed</p>
          <Link className='btn btn-primary' to="/customer/my-jobs">Try Again</Link>
        </div> : (paymentStatus === 'PAYMENT_PENDING' || !paymentStatus) && <div className='payment-done payment-pending'>
          <div className='icon icon-exclaim-yellow'></div>
          <h3>Payment Pending</h3>
          <p>Your payment for JobId: {jobPaymentInfo.jobId} is pending</p>
          {/*<button className='btn btn-primary'>Check Status</button>*/}
        </div>}
        {/*<ul className='payment-info bid-info'>
          <li>
            <span>Info 1</span>
            <div>First information</div>
          </li>
          <li>
            <span>Info 2</span>
            <div>Second information</div>
          </li>
          <li>
            <span>Info 3</span>
            <div>Third information</div>
          </li>
          <li>
            <span>Info 4</span>
            <div>Fourth information</div>
          </li>
        </ul>*/}
      </div>
      {errorMsg && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMsg}</MToast>}
    </DashboardWrapper>
  )
}

export default PaymentSuccessFailed