import React from 'react'
import { Link } from 'react-router-dom'

function JobDetailsReportsRow({item, sno, passJobId}) {
  return (
    <div className='table-row'>
      <div className='serial'>{sno+1}</div>
      <div className='link' onClick={() => passJobId(item.JobId)}>{item.JobId}</div>
      <div>{item.JobName}</div>
      <div>{item.cusCompany}</div>
      <div><Link to={`/admin/vendors/${item.JobCategory}`}>{item.JobCategory}</Link></div>
      <div>{item.VendorCount}</div>
    </div>
  )
}

export default JobDetailsReportsRow