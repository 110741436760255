import React, { useEffect, useState } from 'react'
import BannerBg from '../../images/side-banner.jpg'
import { Fade } from 'react-awesome-reveal'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { authServices } from '../../services/auth.services'
import MToast from '../../shared/UI/MToast'
import ExternalWrapper from '../../shared/ExternalWrapper'

function OtpVerify() {
  const [otpError, setOtpError] = useState(null)
  const [otpSuccess, setOtpSuccess] = useState(null)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  useEffect(() => {
      authServices.getUser().then((response) => {
        setUser(response)
      })
  }, [])
  let otpSent = localStorage.getItem('otpSent')
  const navigate = useNavigate()
  useEffect(() => {
    if(user && user.status === 'INITIALIZE' && !otpSent){
      sendOtp()
    } else if(user && user.status === 'ONBOARDED'){
      if(user.roles[0] === 'ROLE_CUSTOMER'){
        navigate('/customer/dashboard')
      }
      if(user.roles[0] === 'ROLE_VENDOR'){
          navigate('/vendor/my-jobs')
      }
    }
  }, [user, otpSent, navigate])
  const verifyOTP = (otp) => {
    setIsLoading(true)
    let otpstr = '';
    for(let key in otp) {
      otpstr += otp[key];
    }
    authServices.verifyOtp(otpstr).then((response) => {
      if(response){
        if(response.data.success){
          authServices.getUser().then((response) => {
            const updatedUser = response
            updatedUser.status = 'ONBOARDED'
            localStorage.setItem("userData", JSON.stringify(updatedUser));
            if(updatedUser.roles[0] === 'ROLE_CUSTOMER'){
              navigate('/customer/dashboard')
            }
            if(updatedUser.roles[0] === 'ROLE_VENDOR'){
                navigate('/vendor/my-jobs')
            }
            if(updatedUser.roles[0] === 'ROLE_ADMIN'){
                navigate('/admin/dashboard')
            }
          })
          //navigate('/login#verified')
        } else if(response.data.error){
          setOtpError(response.data.error)
        }
        if(response.code === 'ERR_BAD_REQUEST'){
          setOtpError('Bad Request')
        } else if(response.code === "ERR_NETWORK") {
          setOtpError(response.message)
        }
      }
      setTimeout(() => {
        setOtpError('')
        setOtpSuccess('')
      }, 3500);
      setIsLoading(false)
    })
  }
  const sendOtp = () => {
    authServices.getOtp().then((response) => {
      if(response){
        if(response.success){
          localStorage.setItem('otpSent', true)
          setOtpSuccess('OTP Sent, please check your phone')
        } else if(response.error){
          setOtpError(response.error)
        } else if(response.code === "ERR_BAD_REQUEST"){
          setOtpError('Bad Request. Please try again later')
        } else if(response && response.code === "ERR_NETWORK"){
          setOtpError(response.message)
        }  else if(response && response.code){
          setOtpError(response.message)
        }
      }
      setTimeout(() => {
        setOtpError('')
        setOtpSuccess('')
      }, 3500);
    })
  }
  let isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : evt.keyCode
    if(charCode === 8){ 
      if(evt.target.value !== '') formik.setFieldValue(evt.target.name, '')
      if(evt.target.value === '' && evt.target.previousElementSibling) {
        evt.target.previousElementSibling.focus()
      }
    }
    if (!(charCode >= 48 && charCode <= 57)){
      formik.setFieldValue(evt.target.name, '')
      evt.preventDefault()
    }
  }
  let focusChange = (e) => {
    let charCode = (e.which) ? e.which : e.keyCode
    if(charCode !== 8){
      if ((e.target.value.length).toString() === e.target.getAttribute("maxlength")) {
        if(e.target.nextElementSibling) e.target.nextElementSibling.focus()
      }
    }
  }
  const formik = useFormik({
    initialValues: { otp1:'', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' },
    //validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      verifyOTP(values)
      setSubmitting(false);
    },
  });
  const logout = () => {
    authServices.logout().then((response) => {
      navigate('/login')
    })
  }
  return (
    <ExternalWrapper
      title="Discover India's Top Measurement, Design and 3D Printing service providers"
      bgImg={BannerBg}>
      <Fade delay={1500} triggerOnce>
          <div className="login-menu text-end">
              Already have an Account? <button onClick={logout} className='btn btn-secondary'>Logout</button>
          </div>
      </Fade>
      <Fade delay={1800} triggerOnce>
          <div className='heading-wrapper'>
              <h2>Verify the Number</h2>
          </div>
          <div className='otp-form'>
            <p>Enter the OTP sent on {user && user.mobile}</p>
            <form onSubmit={formik.handleSubmit}>
                <div className="input-wrapper">
                    <input type="text" name="otp1" className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp1} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                    />
                    <input type="text" name="otp2" className="form-control"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    value={formik.values.otp2} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                    />
                    <input type="text" name="otp3" className="form-control"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    value={formik.values.otp3} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                    />
                    <input type="text" name="otp4" className="form-control"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    value={formik.values.otp4} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                    />
                    <input type="text" name="otp5" className="form-control"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    value={formik.values.otp5} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                    />
                    <input type="text" name="otp6" className="form-control"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    value={formik.values.otp6} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                    />
                </div>
                {otpSuccess && <MToast color="green" duration={3500} icon="icon-check-green-rev">{otpSuccess}</MToast>}
                {otpError && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{otpError}</MToast>}
                <div className="link d-table" onClick={sendOtp}>Resend OTP</div>
                <div>
                <button type="submit" className={`btn btn-primary btn-full ${isLoading ? 'disabled' : ''}`}>
                    Verify{isLoading && <span className='loader loader-small ms-1'></span>}
                </button>
                </div>
            </form>
          </div>
      </Fade>
    </ExternalWrapper>
  )
}

export default OtpVerify