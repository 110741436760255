import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import MToast from '../MToast';
import { jobsServicev2 } from '../../../services/job.service-v2';
import Thumb66 from '../../../images/thumb-66.png'

function ReviewDetailsModal({showModal, viewModal, reviewDetail}) {
    const [show, setShow] = useState(false)
    const [data, setData] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const getJobById = useCallback((jobId) => {
        setErrorMessage('')
        jobsServicev2.getJobById(jobId).then((response) => {
            if(response.success){
                if(response.success){
                    setData(response.data)
                } else{
                    setErrorMessage('Data not available')
                }
            } else{
                if(response.error){
                    setErrorMessage(response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                    setErrorMessage('Bad Request. Try again later.')
                } else if(response.code && response.code !== 'ERR_BAD_REQUEST'){
                    setErrorMessage(response.message)
                }
            }
        })
    }, []);

    useEffect(() => {
        if(viewModal) setShow(viewModal)
        if(reviewDetail && reviewDetail.review && reviewDetail.review.jobId) {
            getJobById(reviewDetail.review.jobId)
        }
    }, [viewModal, reviewDetail, getJobById])

    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDateString = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return formattedDateString
    }

  return (
    <>
        <Modal show={show} onHide={handleHide} className='mpass-modal modal-medium'>
            <Modal.Header>
                <Modal.Title>Review Details</Modal.Title>
                <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
            </Modal.Header>
            <Modal.Body>
                {data ? <>
                    <div className='modal-section'>
                        <h3>{data.job.jobTitle}</h3>
                        <div className='job-rating-info'>
                            <div className='content'>
                                <div className='request-no'>Job Id #{data.job.jobId}</div>
                                <span className='status'>Completed on {formatDate(data.job.endDate)}</span>
                                {data.rating && <div className='location mb-0 mt-2'>This Job was rated: <div className="rating small-inline">{data.rating} <span className="icon icon-star"></span></div></div>}
                                {data.customer && <><h4 className='company'>{data.customer.companyName}</h4>
                                <span className='location'>{data.customer.city}, {data.customer.state}</span></>}
                                <h4 className='company'>Person Info</h4>
                                <span className='location mb-1'>Name: {data.job.siteContactName}</span>
                                <span className='location mb-1'>Mobile: {data.job.siteContactMobile}</span>
                                <span className='location mb-1'>Email: {data.job.siteContactEmail}</span>
                            </div>
                            {data.customer && <div className='image'>
                                <img src={data.customer.profileImage ? data.customer.profileImage : Thumb66} alt="Thumb" />
                            </div>}
                        </div>
                        <ul className='bid-info'>
                            {data.job.bidType === "REQUEST_QUOTE" ?
                            <li>
                                <span>Request a Quote</span>
                                <div>RFQ</div>
                            </li> :
                            <li>
                                <span>Fixed Price</span>
                                <div>{data.job.bidAmount}</div>
                            </li>}
                            {data.job.budgetType && <li>
                                <span>Budget Type</span>
                                <div>
                                    {data.job.budgetType === "PERSHIFT" || data.job.budgetType === "PERDAY" ? "Per Shift" : data.job.budgetType === "PERPROJECT" ? "Per Project" : data.job.budgetType === "QUANTITY" ? "Per Unit Quantity" : data.job.budgetType === "HOURLY" ? "Per Hour" : ''}
                                </div>
                            </li>}
                            {data.bid_assigned && data.bid_assigned.quotation !== '' ?
                            <li>
                                <span>Job Award Amount</span>
                                <div>{data.bid_assigned.quotation ? data.bid_assigned.quotation : 'Not Closed Yet'}</div>
                            </li> : null}
                            <li>
                                <span>Category</span>
                                <div>{data.job.category}</div>
                            </li>
                            {data.job.software && <li>
                                <span>Software</span>
                                <div>{data.job.software}</div>
                            </li>}
                            <li>
                                <span>Equipment Type</span>
                                <div>{data.job.equipmentType}</div>
                            </li>
                            {data.job.brand && JSON.parse(data.job.brand).length>0 && <li>
                                <span>Brand Name</span>
                                <div>{JSON.parse(data.job.brand).toString().split(',').join('/')}</div>
                            </li>}
                            <li>
                                <span>Job Start Date</span>
                                <div>{formatDate(data.job.startDate)}</div>
                            </li>
                            <li>
                                <span>Job End Date</span>
                                <div>{formatDate(data.job.endDate)}</div>
                            </li>
                        </ul>
                    </div>
                </> : null}
            </Modal.Body>
        </Modal>
        {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </>
  )
}

export default ReviewDetailsModal