import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import BidTable from '../JobDetails/BidTable';
import VendorTerms from '../JobDetails/VendorTerms';
//import StartEndOtp from '../JobDetails/StartEndOtp';
//import EnterStartEndOtp from '../JobDetails/EnterStartEndOtp';
//import JobTerms from '../JobDetails/JobTerms';
import JobStatus from '../JobDetails/JobStatus';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { authServices } from '../../../services/auth.services';
//import { jobsService } from '../../../services/jobs.service';
//import { JobActionService } from '../../../services/job-actions.service';
import MToast from '../MToast';
import { jobsServicev2 } from '../../../services/job.service-v2';
import JobActivityTable from '../JobDetails/JobActivityTable';
import { bidServicev2 } from '../../../services/bid.service-v2';
import RatingForm from '../JobDetails/RatingForm';
//import RatingModal from './RatingModal';

function JobDetailsModal({ showModal, viewModal, jobId, isBidApproved, updateJobs }) {
    const [show, setShow] = useState(false)
    //const [toggleSwitch, setToggleSwitch] = useState(false)
    const [data, setData] = useState(null)
    const [createdBids, setCreateBids] = useState(null)
    //const [rejectedBids, setRejectedBids] = useState(null)
    //const [bidStatus, setBidStatus] = useState('CREATE')
    //const [statusChange, setStatusChanged] = useState()
    const [user, setUser] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [tierMessage, setTierMessage] = useState(null)
    const [isBlur, setIsBlur] = useState(true)
    const [pickerOpen, setPickerOpen] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [toggleEndDate, setToggleEndDate] = useState(false)
    const [endDateLoader, setEndDateLoader] = useState(false)
    const [vendorBid, setVendorBid] = useState(false)
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const getJobById = useCallback((jobId) => {
        setErrorMessage('')
        jobsServicev2.getJobById(jobId).then((response) => {
            if (response.success) {
                if (response.success) {
                    setData(response.data)
                    if (response.data.job.endDate) setEndDate(new Date(response.data.job.endDate))
                } else {
                    setErrorMessage('Data not available')
                }
            } else {
                if (response.error) {
                    setErrorMessage(response.error)
                }
                if (response.code === 'ERR_BAD_REQUEST') {
                    setErrorMessage('Bad Request. Try again later.')
                } else if (response.code && response.code !== 'ERR_BAD_REQUEST') {
                    setErrorMessage(response.message)
                }
            }
        })
    }, []);

    const getAssignedTiers = async (tierName) => {
        if (tierName === 'SILVER') {
            setTierMessage('100% Advance payment before start of the job.')
        } else if (tierName === 'GOLD') {
            setTierMessage('100% Payment on successful completion of the job')
        } else if (tierName === 'PLATINUM') {
            setTierMessage('Payment within 45 days of end of the job')
        }
    }
    useEffect(() => {
        if (viewModal) setShow(viewModal)
        //if(statusChange) setBidStatus(statusChange)
        //toggleSwitch ? setBidStatus('REJECTED') : setBidStatus('CREATE')
        if (jobId) {
            if (data === null) {
                getJobById(jobId)
            }
            if (user && user.roles.includes("ROLE_CUSTOMER")) {
                /*JobActionService.getJobBids(jobId, 'CREATE').then((response) => {
                    setCreateBids(response)
                })
                JobActionService.getJobBids(jobId, 'REJECTED').then((response) => {
                    setRejectedBids(response)
                })*/
                bidServicev2.getJobBids(jobId, 'CREATE').then((response) => {
                    if (response.n > 0) {
                        setCreateBids(response)
                    }
                })
            }
            data && getAssignedTiers(data.job.paymentOption)
        }
        if (user === null) {
            authServices.getUser().then((response) => {
                setUser(response)
            })
        }
        if (data) {
            const lastbiddate = new Date(data.job.lastBidDate)
            if (lastbiddate >= new Date()) {
                setIsBlur(true)
            } else {
                setIsBlur(false)
            }
        }
    }, [viewModal, jobId, user, data, getJobById])
    setTimeout(() => {
        setIsBlur(false)
    }, new Date(data && data.job.lastBidDate) - new Date());
    /*const onToggle = () => {
        setToggleSwitch(!toggleSwitch)
    }*/
    /*const statusChanged = (status) => {
        setStatusChanged(status)
    }*/
    const handleHide = () => {
        setShow(false)
        if (showModal) showModal(false)
    }
    /*const bidCreated = (created) => {
        if(created && jobId){
            //getJobById(jobId)
        }
    }*/
    const updateJob = (created, jobId) => {
        if (created && jobId) {
            getJobById(jobId)
        }
    }
    const activityUpdated = (jobId) => {
        if (jobId) {
            getJobById(jobId)
        }
    }
    const reviewUpdated = (jobId) => {
        if (jobId) {
            getJobById(jobId)
        }
    }
    /*const handleReviewModal = (ratingData) => {
        openreviewmodal(true, ratingData)
    }*/
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDateString = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return formattedDateString
    }
    const bidsUpdated = () => {
        if (user && user.roles.includes("ROLE_CUSTOMER")) {
            updateJob(true, jobId)
            /*JobActionService.getJobBids(jobId, 'CREATE').then((response) => {
                setCreateBids(response)
            })
            JobActionService.getJobBids(jobId, 'REJECTED').then((response) => {
                setRejectedBids(response)
            })*/
            bidServicev2.getJobBids(jobId, 'CREATE').then((response) => {
                if (response.n > 0) {
                    setCreateBids(response)
                }
            })
        }
    }
    const requestEndDateUpdate = (date, jobId) => {
        if (data && (new Date(date) > new Date(data.job.endDate))) {
            setEndDateLoader(true)
            jobsServicev2.editJobEndDate(date, jobId).then((response) => {
                if (response.success) {
                    setSuccessMessage('Request to approve end date is sent to vendor.')
                    setToggleEndDate(false)
                    if (data && data.job.jobId) getJobById(data.job.jobId)
                    if (data && updateJobs) updateJobs(data.job.status)
                } else {
                    if (response.error) {
                        setErrorMessage(response.error)
                    }
                    if (response.code === 'ERR_BAD_REQUEST') {
                        setErrorMessage(response.message)
                    } else if (response.code === "ERR_NETWORK") setErrorMessage(response.message);
                }
                setTimeout(() => {
                    setEndDateLoader(false)
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 2800);
            })
        }
    }
    const handleEndDateActions = (action) => {
        if (data && data.pendingApproval.length > 0 && data.pendingApproval[0].workflowId) {
            jobsServicev2.jobEndDateAction(action, data.pendingApproval[0].workflowId).then((response) => {
                if (response.success) {
                    if (action === 'APPROVE') setSuccessMessage('You have approved the customer request.')
                    if (action === 'REJECT') setSuccessMessage('You have rejected the customer request.')
                    setToggleEndDate(false)
                    if (data && data.job.jobId) getJobById(data.job.jobId)
                    if (data && updateJobs) updateJobs(data.job.status)
                } else {
                    if (response.error) {
                        setErrorMessage(response.error)
                    }
                    if (response.code === 'ERR_BAD_REQUEST') {
                        setErrorMessage(response.message)
                    } else if (response.code === "ERR_NETWORK") setErrorMessage(response.message);
                }
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 2800);
            })
        }
    }
    const scrollSection = (height) => {
        const dialog = document.querySelectorAll('.modal-content')[0]
        dialog.scrollTop = height
    }
    useEffect(() => {
        if (user && user.roles.includes("ROLE_VENDOR") && data) {
            bidServicev2.getJobBids(data.job.jobId).then((response) => {
                if (response.n > 0) {
                    setVendorBid(response.data[0].bid)
                }
            })
        }
    }, [user, data])
    return (
        <>
            <Modal show={show} onHide={handleHide} className='mpass-modal modal-large'>
                <Modal.Header>
                    <Modal.Title>Job Details</Modal.Title>
                    <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
                </Modal.Header>
                {data && data.bid_assigned && user && user.roles.includes("ROLE_VENDOR") && vendorBid && vendorBid.status === 'REJECTED' ? <div className='strip red-strip'>Client has decided to move ahead with other vendor</div> : null}

                {data && data.job.status === 'EXPIRED' && !data.bid_assigned && user && user.roles.includes("ROLE_VENDOR") ? <div className='strip gray-strip'>The customer did not choose a vendor for this job</div> : null}

                {user && user.roles.includes("ROLE_VENDOR") && data && data.pendingApproval && data.pendingApproval.length > 0 ? <div className='strip red-strip alert-strip text-center d-flex justify-content-center flex-wrap'><p className='mb-0'>Customer requested to extend end date from {new Date(data.job.endDate).toDateString()} to {new Date(data.pendingApproval[0].endDate).toDateString()}. Would you like to accept request?</p><div className='action-buttons'><strong onClick={() => handleEndDateActions('APPROVE')} style={{ cursor: 'pointer' }} className='btn btn-light btn-blue mt-1 me-1'>Accept</strong> <strong className='btn btn-light btn-red mt-1' onClick={() => handleEndDateActions('REJECT')} style={{ cursor: 'pointer' }}>Reject</strong></div></div> : null}

                {user && user.roles.includes("ROLE_CUSTOMER") && data && data.job.approval ? <div className='strip red-strip alert-strip text-center d-flex justify-content-center flex-wrap'><p className='mb-0'>Your request to extend Job End Date is awaited from vendor's end. Please wait till vendor accept/Reject request.</p></div> : null}
                <Modal.Body>
                    {data ? <>
                        <div className='modal-section'>
                            {data.job.status === 'DONE' && user && user.roles.includes("ROLE_VENDOR") && ((vendorBid && vendorBid.status !== 'REJECTED') || !vendorBid) ? <span className='badge badge-success badge-medium fs-10'>Completed</span> : null}
                            {vendorBid && vendorBid.status === 'REJECTED' && (user && user.roles.includes("ROLE_VENDOR")) ? <span className='badge badge-red badge-medium fs-10'>Declined</span> : null}
                            {(data && data.job.status === 'EXPIRED' && (user && user.roles.includes("ROLE_VENDOR"))) ? <span className='badge badge-gray badge-medium fs-10'>Expired</span> : null}
                            <h3>{data.job.jobTitle}</h3>
                            {data.rating && <div className='location'>This Job was rated: <div className="rating small-inline">{data.rating} <span className="icon icon-star"></span></div></div>}
                            <div className='posted-on'>Posted on {formatDate(data.job.createdAt)}</div>
                            <div className='location'>{data.job.location}</div>
                            <p>{data.job.description}</p>
                            <div className="text-md mt-10">Job Id: <span className="blue-text">{data.job.jobId}</span></div>
                            {/*user && user.roles.includes("ROLE_CUSTOMER") && data && data.job.status === 'DONE' && !data.job.reviewId ? <div className='btn btn-primary' onClick={() => handleReviewModal(data.job)} style={{marginTop: "10px", maxWidth: "fit-content"}}>Review Vendor</div> : null*/}
                            <ul className='bid-info divide-3'>
                                {data.job.bidType === "REQUEST_QUOTE" ?
                                    <li>
                                        <span>Request a Quote</span>
                                        <div>RFQ</div>
                                    </li> :
                                    <li>
                                        <span>Fixed Price</span>
                                        <div>{data.job.bidAmount}</div>
                                    </li>}
                                {data.job.budgetType && <li>
                                    <span>Budget Type</span>
                                    <div>
                                        {data.job.budgetType === "PERSHIFT" || data.job.budgetType === "PERDAY" ? "Per Shift" : data.job.budgetType === "PERPROJECT" ? "Per Project" : data.job.budgetType === "QUANTITY" ? "Per Unit Quantity" : data.job.budgetType === "HOURLY" ? "Per Hour" : ''}
                                    </div>
                                </li>}
                                {data.bid_assigned && data.bid_assigned.quotation !== '' ?
                                    <li>
                                        <span>Job Award Amount</span>
                                        <div>{data.bid_assigned.quotation ? data.bid_assigned.quotation : 'Not Closed Yet'}</div>
                                    </li> : null}
                                <li>
                                    <span>Job Award Date
                                        <div className='tooltip-wrapper'>
                                            <span className='icon icon-info-dark'></span>
                                            <div className='tooltip'>If no bid is confirmed by this date the job will be moved to Expired Jobs and no further action will be possible.</div>
                                        </div>
                                    </span>
                                    <div>{formatDate(data.job.bidCloserDate)}</div>
                                </li>
                                <li>
                                    <span>Category</span>
                                    <div>{data.job.category}</div>
                                </li>
                                <li>
                                    <span>Last date to bid</span>
                                    <div>{formatDate(data.job.lastBidDate)}</div>
                                </li>
                                {data.job.software && <li>
                                    <span>Software</span>
                                    <div>{data.job.software}</div>
                                </li>}
                                <li>
                                    <span>Equipment Type</span>
                                    <div>{data.job.equipmentType}</div>
                                </li>
                                {data.job.brand && JSON.parse(data.job.brand).length > 0 && <li>
                                    <span>Brand Name</span>
                                    <div>{JSON.parse(data.job.brand).toString().split(',').join('/')}</div>
                                </li>}
                                <li>
                                    <span>Job Start Date</span>
                                    <div>{formatDate(data.job.startDate)}</div>
                                </li>
                                <li>
                                    {!toggleEndDate && <span>Job End Date</span>}
                                    <div className='edit-end-date'>
                                        {!toggleEndDate && <><span>{formatDate(data.job.endDate)}</span>
                                            {user && user.roles.includes('ROLE_CUSTOMER') && data.job.multiActivity && data.job.status === 'ASSIGNED' && <span className='icon icon-edit' onClick={() => setToggleEndDate(true)}></span>}</>}
                                        {toggleEndDate && user && user.roles.includes('ROLE_CUSTOMER') && <div className='enddate-form'>
                                            <div className={`input-wrapper mat-input icon-input timeenabled ${endDate ? 'date-selected' : ''}`}>
                                                <DatePicker selected={endDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}
                                                    id={`jobEndControl`} autoComplete='off'
                                                    minDate={new Date(data.job.endDate)}
                                                    onFocus={(e) => e.target.readOnly = true}
                                                    onChange={(date) => setEndDate(date)}
                                                    showTimeSelect
                                                    timeIntervals={60}
                                                    dateFormat="EEE, dd MMM, yyyy h:mm aa"
                                                    value={endDate}
                                                    onCalendarOpen={onPickerOpen}
                                                    onCalendarClose={onPickerClose}
                                                />
                                                <span className='icon icon-calendar'></span>
                                                <label htmlFor={`jobEndControl`} className="form-label">Job End Date*</label>
                                            </div>
                                            <button className={`btn btn-primary ${endDateLoader ? 'disabled' : ''}`} onClick={() => requestEndDateUpdate(endDate, data.job.jobId)}>Request{endDateLoader && <div className='loader loader-medium'></div>}</button>
                                            <button className='btn btn-secondary' onClick={() => setToggleEndDate(false)}>Cancel</button>
                                        </div>}
                                    </div>
                                </li>
                                <li>
                                    <span>Bid Inclusions
                                        <div className='tooltip-wrapper'>
                                            <span className='icon icon-info-dark'></span>
                                            <div className='tooltip'>Bid inclusions are overhead costs you expect the vendor to include in their quotation.</div>
                                        </div>
                                    </span>
                                    <div>{data.job.bidInclusion && JSON.parse(data.job.bidInclusion).toString().split(',').join('/')}</div>
                                </li>
                                {user && user.roles.includes("ROLE_CUSTOMER") ? <li className='w-100'>
                                    <span>Payment Terms</span>
                                    <div>{tierMessage && tierMessage}</div>
                                    {/*tierMessage && <p className='help-text'>{tierMessage}</p>*/}
                                </li> : null}
                                {data.s3files && data.s3files.length > 0 && <li style={{ width: 'auto' }}>
                                    <div>Attachment</div>
                                    {data.s3files ? data.s3files.map((item, index) =>
                                        <Link className='attachment' to={item} key={index} target='_blank'>
                                            <span className='icon icon-clip'></span>{item.split('/')[item.split('/').length - 1]}
                                        </Link>
                                    ) : null}
                                </li>}
                            </ul>
                        </div>

                        {data.job.jobActivity ? <JobActivityTable vendorBid={vendorBid} jobData={data} user={user} jobUpdated={activityUpdated} scrollSection={scrollSection} /> : null}

                        {user && user.roles.includes("ROLE_CUSTOMER") && data && (data.job.status === 'DONE' || (data.job.jobActivity[data.job.jobActivity.length - 1]).status === 'END_OTP_GENERATED') ? <RatingForm ratingData={data.job} reviewUpdated={reviewUpdated} /> : null}

                        {/*(user && user.roles.includes("ROLE_CUSTOMER") && data && (data.job.status === 'ASSIGNED' || data.job.status === 'START_OTP_GENERATED' || data.job.status === 'STARTED' || data.job.status === 'END_OTP_GENERATED')) ? <StartEndOtp jobData={data} updateJob={updateJob} /> : null*/}

                        {/*(user && user.roles.includes("ROLE_VENDOR") && data && (data.job.status === 'START_OTP_GENERATED' || data.job.status === 'END_OTP_GENERATED')) ? <EnterStartEndOtp status={data.job.status} jobData={data} otpSubmitted={updateJob} /> : null*/}

                        {(data.job.servedHour !== '' && data.job.servedMinute !== '' && data.job.servedHour !== null && data.job.servedMinute !== null && data.job.servedHour !== undefined && data.job.servedMinute !== undefined && data.job.status === "DONE") ? <div className='modal-section'>
                            <h4 className='modal-section-title'>Total time taken for the job</h4>
                            <ul className='bid-info'>
                                {user && user.roles.includes("ROLE_VENDOR") && <li>
                                    <span>Company Name</span>
                                    <div>{data.customer && data.customer.companyName}</div>
                                </li>}
                                <li>
                                    <span>Hours</span>
                                    <div>{data.job.servedHour}</div>
                                </li>
                                <li>
                                    <span>Minutes</span>
                                    <div>{data.job.servedMinute}</div>
                                </li>
                            </ul>
                        </div> : null}
                        {((data.job.status === 'ASSIGNED' && ((vendorBid && vendorBid.status !== 'REJECTED') || !vendorBid)) || (user && user.roles.includes("ROLE_CUSTOMER"))) ?
                            <div className='modal-section'>
                                <h4 className='modal-section-title'>Customer Site Contact Details</h4>
                                <ul className='bid-info divide-3 mt-0'>
                                    {user && user.roles.includes("ROLE_VENDOR") && <li>
                                        <span>Company Name</span>
                                        <div>{data.customer && data.customer.companyName}</div>
                                    </li>}
                                    <li>
                                        <span>Name</span>
                                        <div>{data.job.siteContactName}</div>
                                    </li>
                                    <li>
                                        <span>Mobile</span>
                                        <div>{data.job.siteContactMobile}</div>
                                    </li>
                                    <li>
                                        <span>Email</span>
                                        <div>{data.job.siteContactEmail}</div>
                                    </li>
                                </ul>
                            </div> : null}
                    </> : null}
                    {(user && user.roles.includes("ROLE_CUSTOMER") && data &&
                        (data.job.status === 'ASSIGNED' || data.job.status === 'START_OTP_GENERATED'
                            || data.job.status === 'START' || data.job.status === 'END_OTP_GENERATED')) ?
                        <VendorTerms vendorId={data.job.vendorId} jobValue={data.bidAmount} budgetType={data.job.budgetType} /> : null}
                    {//(user && user.roles.includes("ROLE_CUSTOMER") && ((createdBids && createdBids.n) || (rejectedBids && rejectedBids.n)) && data && data.job.status === 'CREATED')
                        (user && user.roles.includes("ROLE_CUSTOMER") && (createdBids && createdBids.n) && data && (data.job.status === 'CREATED' || data.job.status === 'EXPIRED')) ? <div className='modal-section'>
                            <div className='modal-section-header'>
                                <h4 className='modal-section-title'>Total Received Bids <span className='badge badge-secondary badge-rounded'>{createdBids /*&& rejectedBids*/ && (createdBids.total /*+ rejectedBids.total*/)}</span></h4>
                                {/*<div className='switch-button'>
                            <input type='checkbox' className='switch-toggle' checked={toggleSwitch} onChange={onToggle} />
                            <div className='switch-items'>
                                <span className={`item ${!toggleSwitch && 'active'}`}>All Bids ({createdBids && createdBids.total})</span>
                                <span className={`item ${toggleSwitch && 'active'}`}>Rejected Bids ({rejectedBids && rejectedBids.total})</span>
                            </div>
                                </div>*/}
                            </div>
                            {/*<BidTable isBlured={isBlur} bids={bidStatus === 'CREATE' ? createdBids : rejectedBids} statusChanged={statusChanged} updatedBidStatus={bidStatus} isBidApproved={isBidApproved} />*/}
                            <BidTable isBlured={isBlur} bids={createdBids} isBidApproved={isBidApproved} bidsUpdated={bidsUpdated} jobData={data.job} />
                        </div> : null}
                    {/*((user && user.roles.includes("ROLE_VENDOR") && data &&
                (data.job.status === 'CREATED' || (data.job.status === 'CREATED' &&
                data.vendor_bid && data.vendor_bid.status === 'CREATE')))) || ((user && user.roles.includes("ROLE_VENDOR") && data &&
                (data.job.status === 'ASSIGNED'))) ? <JobTerms jobData={data} bidCreated={bidCreated} /> : null*/}
                    {/*(user && user.roles.includes("ROLE_VENDOR") && data &&
                data.job.status !== 'EXPIRED' && data.job.status !== 'DONE') ||
                (data && data.vendor_bid && data.vendor_bid.status !== 'REJECTED' && data.job.status !== 'EXPIRED' && data.job.status !== 'DONE') ? <JobTerms jobData={data} bidCreated={bidCreated} /> : null*/}

                    {user && data && ((vendorBid && vendorBid.status !== 'REJECTED') || !vendorBid) ? <JobStatus jobData={data.job.jobTrack} /> : null}
                    {successMessage && <MToast color="green" duration={3000} icon="icon-check-green-rev">{successMessage}</MToast>}
                    {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default JobDetailsModal