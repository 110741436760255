import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FleetsService } from '../../../services/fleets.service'
import MToast from '../MToast'
import FleetRemoveModal from '../Modals/FleetRemoveModal'

function FleetCard({fleetItem, isEditFleet, fleetDeleted}) {
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const [fleetDeleteSuccess, setFleetDeleteSuccess] = useState('')
    const [fleetDeleteError, setFleetDeleteError] = useState('')
    const [isLoading, setIsLoading] = useState('')
    const [viewRemoveFleetModal, SetViewRemoveFleetModal] = useState(null)
    const [fleetId, setFleetId] = useState(null)
    const dropDown = useRef(null)
    useEffect(() => {
        const onClickOutside = (event) => {
            if(dropDown.current && !dropDown.current.contains(event.target)){
                setToggleDropdown(false)
            }
        }
        document.addEventListener("mousedown", onClickOutside);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
        };
    }, [])
    const handleToggle = () => {
        setToggleDropdown(!toggleDropdown)
    }
    const showRemoveFleetModal = (show, itemId) => {
      setFleetId(itemId)
      SetViewRemoveFleetModal(show)
    }
    const editFleetItem = () => {
        if(isEditFleet) isEditFleet(fleetItem)
        setToggleDropdown(false)
    }
    const deleteFleetItem = (fleetId) => {
        setIsLoading(true)
        setToggleDropdown(false)
        FleetsService.deleteFleet(fleetId).then((response) => {
            if(response.success){
                setFleetDeleteSuccess(response.data)
                setTimeout(() => {
                    fleetDeleted(true)
                }, 2500);
            } else if(!response.success){
                setFleetDeleteError(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setFleetDeleteError(response.data.message)
            } else if(response.code === "ERR_NETWORK") {
                setFleetDeleteError(response.message)
            }
            setTimeout(() => {
                setFleetDeleteError('')
                setFleetDeleteSuccess('')
            }, 3500);
            setIsLoading(false)
        })
    }
  return (
    <>{fleetItem && <div className='review-card fleet-card'>
        {isLoading ? <div className='loader loader-small' style={{position: 'absolute', right: '15px', top: '15px'}}></div> : null}
        <h4>{fleetItem.masterItem && fleetItem.masterItem.equipment}</h4>
        <div className='dropdown' ref={dropDown}>
            <span className='icon icon-more-vrt' onClick={handleToggle}></span>
            {toggleDropdown && <ul className="select-options">
                <li onClick={() => editFleetItem(fleetItem)}>Edit</li>
                <li onClick={() => showRemoveFleetModal(true, fleetItem.fleet.fleetId)}>Remove</li>
            </ul>}
        </div>
        <ul className='bid-info mt-0 mb-0'>
            {fleetItem.masterItem && <>
            <li>
                <span>Brand</span>
                <div>{fleetItem.masterItem.brand}</div>
            </li>
            {/*<li>
                <span>Category</span>
                <div>{fleetItem.masterItem.category}</div>
            </li>*/}
            <li>
                <span>Type</span>
                <div>{fleetItem.masterItem.itemType}</div>
            </li>
            <li>
                <span>Range</span>
                <div>{fleetItem.masterItem.itemRange}</div>
            </li>
            </>}
            <li>
                <span>Serial no.</span>
                <div>{fleetItem.fleet.serialNo}</div>
            </li>
            <li>
                <span>Certificate</span>
                {fleetItem.fleet.caliberateImages ? <div><Link target='_blank' className='attachment mt-0' to={fleetItem.fleet.caliberateImages}>{fleetItem.fleet.caliberateImages.split('/')[fleetItem.fleet.caliberateImages.split('/').length - 1]}</Link></div> : '-'}
            </li>
        </ul>
        <div className='images-gallery'>
            {fleetItem.fleet.itemImages && <div className='thumb'><img src={fleetItem.fleet.itemImages} alt={fleetItem.fleet.itemImages.split('/')[fleetItem.fleet.itemImages.split('/').length - 1]} /></div>}
        </div>
    </div>}
    {viewRemoveFleetModal && fleetId && <FleetRemoveModal showRemoveFleetModal={showRemoveFleetModal} viewRemoveFleetModal={viewRemoveFleetModal} fleetId={fleetId} deleteFleet={deleteFleetItem} />}
    {fleetDeleteSuccess && <MToast color="green" duration={2000} icon="icon-check-green-rev">{fleetDeleteSuccess}</MToast>}
    {fleetDeleteError && <MToast color="red" duration={2000} icon="icon-cross-red-rev">{fleetDeleteError}</MToast>}
    </>
  )
}

export default FleetCard