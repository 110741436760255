import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import MToast from '../../shared/UI/MToast'
import { UserService } from '../../services/user.service'
import CustomerUpdateModal from '../../shared/UI/Modals/CustomerUpdateModal'
import CustomerAccountInfoUpdateModal from '../../shared/UI/Modals/CustomerAccountInfoUpdateModal'

function CustomerProfile() {
  const [customerData, setcustomerData] = useState('')
  const [viewInfoModal, SetViewInfoModal] = useState(false)
  const [viewAccountModal, SetViewAccountModal] = useState(false)
  const [doesOwnProfile, setDoesOwnProfile] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [ErrorMessage, setErrorMessage] = useState('')
  const customerId = JSON.parse(localStorage.getItem('userData'))['company_id']
  const showInfoModal = (show) => {
    SetViewInfoModal(show)
  }
  const showAccountModal = (show) => {
    SetViewAccountModal(show)
  }
  const profileUpdateSuccess = (updated, message) => {
    if(updated){
      setSuccessMessage(message)
      UserService.getCustomerInfo(customerId).then((response) => {
        setcustomerData(response.data)
        setTimeout(() => {
          setSuccessMessage('')
        }, 3500);
      })
    } else{
      setErrorMessage(message)
    }
    setTimeout(() => {
      setSuccessMessage('')
      setErrorMessage('')
    }, 3500);
  }
  useEffect(() => {
    if(customerId){
      UserService.getCustomerInfo(customerId).then((response) => {
        setcustomerData(response.data)
        if(customerId === response.data.customerId) setDoesOwnProfile(true)
      })
    }
  }, [customerId])
  return (
    <DashboardWrapper>
      <div className='page-content myjobs my-profile'>
        <div className='page-header ps-0 pe-0'>
          <div className='user-info'>
              {customerData && <>{/*<div className='user-thumb'><img src={customerData.profileImage} alt={customerData.companyName} /></div>*/}
              <div className='user-content'>
                  <h2>{customerData.companyName}</h2>
                  <div className='location'>{customerData.location}</div>
                  {/*<div className='rating-review'>
                      <div className='rating'>{customerData.rating} <span className='icon icon-star'></span></div>
                  </div>*/}
              </div></>}
          </div>
          {doesOwnProfile && <div className='action-buttons'>
            <button className='btn btn-light btn-blue' onClick={() => showInfoModal(true)}>Edit</button>
          </div>}
        </div>
        <div className='profile-section about-section'>
            <h3>About us</h3>
            <p>{customerData ? customerData.about : '-'}</p>
            <ul className="bid-info">
                <li>
                    <span>GST Number</span>
                    <div>{customerData && customerData.companyGstNo}</div>
                </li>
                <li>
                    <span>Company Registration Date</span>
                    <div>{customerData && new Date(customerData.registrationDate).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
                </li>
                {/*<li>
                    <span>Aadhar Number (only visible to you)</span>
                    <div><Link>Add</Link></div>
                </li>*/}
                <li>
                    <span>Phone (only visible to you)</span>
                    <div>{customerData.mobile? customerData.mobile : '-'}</div>
                </li>
                <li>
                    <span>Email</span>
                    <div>{customerData.email? customerData.email : '-'}</div>
                </li>
                <li>
                    <span>City</span>
                    <div>{customerData.city? customerData.city : '-'}</div>
                </li>
                <li>
                    <span>State</span>
                    <div>{customerData.state? customerData.state : '-'}</div>
                </li>
                <li>
                    <span>Pin</span>
                    <div>{customerData.pin? customerData.pin : '-'}</div>
                </li>
                <li>
                    <span>Address</span>
                    <div>{customerData.companyAddress? customerData.companyAddress : '-'}</div>
                </li>
                {/*<li>
                    <span>Tier</span>
                    <div>{customerData.tier? customerData.tier : '-'}</div>
              </li>*/}
                <li>
                    <span>Profile Link</span>
                    <div>{window.location.origin}/profile/{customerData.customerId}</div>
                </li>
                {/*<li>
                    <span>Website</span>
                    <div><Link>Add</Link></div>
              </li>*/}
            </ul>
        </div>
        {/*doesOwnProfile && <div className='profile-section account-section'>
            <h3>Bank Account Details</h3>
            <div className='action-buttons'>
              <button className='btn btn-light btn-blue' onClick={() => showAccountModal(true)}>Edit</button>
            </div>
            <ul className="bid-info">
                <li>
                    <span>Account Name</span>
                    <div>{customerData.accountName ? customerData.accountName : '-'}</div>
                </li>
                <li>
                    <span>Account Type</span>
                    <div>{customerData.accountType ? customerData.accountType : '-'}</div>
                </li>
                <li>
                    <span>Account Number</span>
                    <div>{customerData.accountNumber? customerData.accountNumber : '-'}</div>
                </li>
                <li>
                    <span>IFSC Code</span>
                    <div>{customerData.ifsc? customerData.ifsc : '-'}</div>
                </li>
            </ul>
        </div>*/}
        {viewInfoModal && customerData && <CustomerUpdateModal showModal={showInfoModal} viewModal={viewInfoModal} userInfo={customerData} profileUpdateSuccess={profileUpdateSuccess} />}
        {viewAccountModal && customerData && <CustomerAccountInfoUpdateModal showModal={showAccountModal} viewModal={viewAccountModal} userInfo={customerData} profileUpdateSuccess={profileUpdateSuccess} />}
        {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
        {ErrorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{ErrorMessage}</MToast>}
      </div>
    </DashboardWrapper>
  )
}

export default CustomerProfile