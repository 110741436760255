import React from 'react'
import JobDetailsReportsRow from './JobDetailsReportRow';

function JobDetailsReportsTable({items, passJobId, currentPageNo, itemsPerPage}) {
  return (
    <>
        {items && Object.keys(items).length > 0 ? <div className={`table hover-table job-report-table mobile-responsive`}>
            <div className='table-body'>
                <div className='table-header'>
                    <div className='serial'>S. No.</div>
                    <div>Job Id</div>
                    <div>Job Title</div>
                    <div>Company Name</div>
                    <div>Job Category</div>
                    <div>Vendor Count</div>
                </div>
                {items && items.map((item, index) => (
                    <JobDetailsReportsRow item={item} key={index} sno={((currentPageNo - 1) * itemsPerPage) + index} passJobId={passJobId} />
                ))}
            </div>
        </div> : <div className='table-body'>No data available.</div>}
    </>
  )
}

export default JobDetailsReportsTable