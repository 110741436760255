import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { UserService } from '../../../services/user.service'
import { useFormik } from 'formik'

function CustomerUpdateModal({showModal, viewModal, userInfo, profileUpdateSuccess}) {
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(viewModal) setShow(viewModal)
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    
    const updateUserInfo = (values) => {
        setIsLoading(true)
        UserService.updateCustomer(values).then((response) => {
            if(response.success){
                if(profileUpdateSuccess) profileUpdateSuccess(true, 'Profile updated successfully.')
                handleHide()
            } else if(!response.success){
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.data.message)
            } else if(response.code === "ERR_NETWORK") {
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.message)
            }
            setIsLoading(false)
        })
    }
    const validate = values => {
        const errors = {};
        return errors;
    };
    const formik = useFormik({
        initialValues: { customerId: userInfo.customerId, companyAddress: userInfo.companyAddress || '', city: userInfo.city || '', state: userInfo.state || '', pin: userInfo.pin || '', phone_no: userInfo.mobile || '', email_address: userInfo.email || '' },
        validate,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            updateUserInfo(values)
        },
    })
  return (
    <Modal show={show} onHide={handleHide} className='mpass-modal fixed-footer'>
        <Modal.Header>
            <Modal.Title>Update Info</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        {userInfo && 
        <Modal.Body>
            <div className='form'>
                <form onSubmit={formik.handleSubmit}><div className="input-wrapper mat-input">
                        <input type="text" name="companygst_no" className="form-control" id="gstControl" placeholder=" "
                        onBlur={formik.handleBlur}
                        value={userInfo.companyGstNo}
                        //onKeyUp={() => getCompanyName(formik.values.companygst_no)}
                        maxLength={15}
                        readOnly={true}
                        />
                        <label htmlFor="gstControl" className="form-label">Company GST Number</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="companyname" className="form-control" id="companyControl" placeholder=" "
                        value={userInfo.companyName}
                        readOnly
                        />
                        <label htmlFor="companyControl" className="form-label d-flex">Company Name</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="phone_no" className="form-control" id="phoneControl" placeholder=" " maxLength={10}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone_no}
                        />
                        <label htmlFor="phoneControl" className="form-label">Phone Number</label>
                        {(formik.touched.phone_no && formik.errors.phone_no) ? <div className='error'>{formik.errors.phone_no}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="email" name="email_address" className="form-control" id="emailControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email_address}
                        />
                        <label htmlFor="emailControl" className="form-label">Email ID</label>
                        {(formik.touched.email_address && formik.errors.email_address) ? <div className='error'>{formik.errors.email_address}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="companyAddress" className="form-control" id="companyAddressControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyAddress}
                        />
                        <label htmlFor="companyAddressControl" className="form-label">Company Address</label>
                        {(formik.touched.companyAddress && formik.errors.companyAddress) ? <div className='error'>{formik.errors.companyAddress}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="city" className="form-control" id="cityControl"
                        placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                        />
                        <label htmlFor="cityControl" className="form-label">City</label>
                        {(formik.touched.city && formik.errors.city) ? <div className='error'>{formik.errors.city}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="state" className="form-control" id="stateControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                        />
                        <label htmlFor="stateControl" className="form-label">State</label>
                        {(formik.touched.state && formik.errors.state) ? <div className='error'>{formik.errors.state}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="pin" className="form-control" id="pinControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.pin}
                        />
                        <label htmlFor="pinControl" className="form-label">Pin</label>
                        {(formik.touched.pin && formik.errors.pin) ? <div className='error'>{formik.errors.pin}</div> : null}
                    </div>
                    <Modal.Footer>
                        <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`}>Update Info{isLoading && <div className='loader loader-medium'></div>}</button>
                    </Modal.Footer>
                    {/*formError !== '' ? <div className='error'>{formError}</div> : null*/}
                </form>
            </div>
        </Modal.Body>}
    </Modal>
  )
}

export default CustomerUpdateModal