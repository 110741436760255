import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../scss/modal.scss';

function SubscriptionConfirmModal({showModal, viewModal, subscriptionData, isLoading}) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        if(viewModal) setShow(viewModal)
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false, '', '')
    }
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal modal-medium'>
        <Modal.Header>
            <Modal.Title>Confirm Subscription</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <h3>Half Yearly Subscription</h3>
            <div className='form inline-form mt-4'>
                <div className="input-wrapper mat-input w-420">
                    <input type="text" name="coupon" className="form-control" id="couponControl" placeholder=' ' autoComplete='off' />
                    <label htmlFor="couponControl" className="form-label">Enter Coupon</label>
                </div>
                <button type="button" className={`btn btn-secondary btn-small`} style={{minWidth: '130px'}}>Apply Coupon</button>
            </div>
            <p>Please confirm the given details</p>
            <ul className='bid-info w-half'>
                <li>
                    <span>Subscription Amount</span>
                    <div>&#8377;2000</div>
                </li>
                <li>
                    <span>Tax</span>
                    <div>&#8377;200</div>
                </li>
                <li>
                    <span>Total Amount</span>
                    <div>&#8377;2200</div>
                </li>
            </ul>
        </Modal.Body>
        <Modal.Footer><button type='button' className='btn btn-primary btn-medium'>Confirm & Pay</button></Modal.Footer>
    </Modal>
    </>
  )
}

export default SubscriptionConfirmModal