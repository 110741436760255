import { apiData } from "./api";
import { authServices } from "./auth.services";

const increaseDateByOneDay = (date) => {
    const givenDate = new Date(date)
    givenDate.setDate(givenDate.getDate() + 1)
    const increasedDate = givenDate.toISOString().slice(0, 10)
    return increasedDate
}

const getMasterItemsByStatus = async (status, size, page) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.get(`get-masteritem?status=${status}&size=${size}&page=${page}&order=-1`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const approveMasterItem = async (itemId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.get(`/approve?masterItemId=${itemId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data.success){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const rejectMasterItem = async (itemData) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.post(`/reject`, itemData , {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data.success){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getTierList = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_config.get(`/get-tier-list`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data.success){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getPurchasedOrders = async (status) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_po.get(`/get-po-status?status=${status}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data.success){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const updatePurchasedOrders = async (jobId, poNum, status, poAmt, custPONum, customerPoDate) => {
    try {
        let poDate = customerPoDate
        let formattedDate
        if(poDate){
            var inputDate = new Date(poDate)
            var year = inputDate.getFullYear();
            var month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            var day = inputDate.getDate().toString().padStart(2, '0');
            var hours = inputDate.getHours().toString().padStart(2, '0');
            var minutes = inputDate.getMinutes().toString().padStart(2, '0');
            var seconds = inputDate.getSeconds().toString().padStart(2, '0');
            formattedDate = `${year}-${month}-${day}+${hours}:${minutes}:${seconds}`
        }
        const token = await authServices.getToken();
        /*return await apiData.api_po.get(`update?jobId=${jobId}&poNumber=${poNum}&purchaseOrderStatus=${status}&cusPoAmt=${poAmt}&cusPoNum=${custPONum}&cusPoDate=${formattedDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });*/
        return await apiData.api_po.get(`update?jobId=${jobId}&poNumber=${poNum}&purchaseOrderStatus=${status}&cusPoNum=${custPONum}&cusPoDate=${formattedDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const searchPurchasedOrders = async (jobId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_po.get(`search-po?key=jobid&value=${jobId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const searchInvoice = async (jobId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_invoice.get(`search-invoice?key=jobid&value=${jobId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}
const updateInvoice = async (jobId, invoiceNum, values) => {
    try {
        let invoiceDate = values.vendorInvoiceDate
        let formattedDate
        if(invoiceDate){
            var inputDate = new Date(invoiceDate)
            var year = inputDate.getFullYear();
            var month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            var day = inputDate.getDate().toString().padStart(2, '0');
            var hours = inputDate.getHours().toString().padStart(2, '0');
            var minutes = inputDate.getMinutes().toString().padStart(2, '0');
            var seconds = inputDate.getSeconds().toString().padStart(2, '0');
            formattedDate = `${year}-${month}-${day}+${hours}:${minutes}:${seconds}`
        }
        const token = await authServices.getToken();
        /*return await apiData.api_invoice.get(`update?jobId=${jobId}&invoiceNumber=${invoiceNum}&invoiceStatus=${values.status}&vanInvNum=${values.vendorInvoiceNumber}&vanInvAmt=${values.vendorInvoiceAmount}&vanInvDate=${formattedDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });*/
        return await apiData.api_invoice.get(`update?jobId=${jobId}&invoiceNumber=${invoiceNum}&invoiceStatus=${values.status}&vanInvDate=${formattedDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getInvoiceStatus = async (status) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_invoice.get(`/get-invoice-status?status=${status}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data.success){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const listUserByGST = async (userstatus, gstNo, page, size) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_admin.get(`/list-user?active=${userstatus}&gst=${gstNo}&page=${page}&size=${size}&order=-1`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const activateUser = async (userType, userId) => {
    try {
        const token = await authServices.getToken();
        const apiUrl = userType === 'CUSTOMER' ? `activate-customer?customerId=${userId}` : userType === 'VENDOR' ? `/activate-vendor?vendorId=${userId}` : null
        return await apiData.api_admin.get(`${apiUrl}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const suspendUser = async (userType, userId) => {
    try {
        const token = await authServices.getToken();
        const apiUrl = userType === 'CUSTOMER' ? `suspend-customer?customerId=${userId}` : userType === 'VENDOR' ? `/suspend-vendor?vendorId=${userId}` : null
        return await apiData.api_admin.get(`${apiUrl}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const activateSingleUser = async (userName) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_admin.get(`activate-user?userName=${userName}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const suspendSingleUser = async (userName) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_admin.get(`suspend-user?userName=${userName}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const listCompanyByUserType = async (status, userType) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_admin.get(`/list-company?active=${status}&companyType=${userType}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const updateCustomerTier = async (customerId, tier) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_admin.get(`/update-tier?customerId=${customerId}&tier=${tier}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getVendorJobReport = async (fromDate, toDate, pageNo, pageSize, order) => {
    try {
        const increasedToDate = increaseDateByOneDay(toDate)
        const token = await authServices.getToken();
        return await apiData.api_report.get(`/category/job-vendor-report?fromDate=${fromDate}&toDate=${increasedToDate}&page=${pageNo}&size=${pageSize}&order=${order}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getUserOnBoardReport = async (fromDate, toDate, pageNo, pageSize, order, userType, tier) => {
    try {
        const increasedToDate = increaseDateByOneDay(toDate)
        const token = await authServices.getToken();
        const userTyp = userType === 'Vendors' ? 'VENDOR' : userType === 'Customers' ? 'CUSTOMER' : userType === 'Admin' ? 'ADMIN' : ''
        const queryString = tier ? `/user/onboarded-report?fromDate=${fromDate}&toDate=${increasedToDate}&page=${pageNo}&size=${pageSize}&order=${order}&userType=${userTyp}&tier=${tier}` : `/user/onboarded-report?fromDate=${fromDate}&toDate=${increasedToDate}&page=${pageNo}&size=${pageSize}&order=${order}&userType=${userTyp}`
        return await apiData.api_report.get(queryString, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getJobDetailsReport = async (jobId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_report.get(`/job/detail?jobId=${jobId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getJobReportList = async (from, to, page, size, jobType) => {
    try {
        let jobstatus = ''
        const increasedToDate = increaseDateByOneDay(to)
        if(jobType === 'Created')  jobstatus = 'CREATED'
        if(jobType === 'Confirmed')  jobstatus = 'ASSIGNED'
        if(jobType === 'Completed')  jobstatus = 'DONE'
        if(jobType === 'Expired')  jobstatus = 'EXPIRED'
        const token = await authServices.getToken();
        return await apiData.api_report.get(`/job/list?fromDate=${from}&toDate=${increasedToDate}&page=${page}&size=${size}&order=-1&status=${jobstatus}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const allowRCJobs = async (customerId, allowRC) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_admin.get(`/user/access?cid=${customerId}&ma=${allowRC}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getReportSummary = async (fromDate, toDate) => {
    try {
        const increasedToDate = increaseDateByOneDay(toDate)
        const token = await authServices.getToken();
        return await apiData.api_report.get(`/summery?fromDate=${fromDate}&toDate=${increasedToDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

export const adminServices = {
    getMasterItemsByStatus, approveMasterItem, getTierList, rejectMasterItem, getPurchasedOrders, updatePurchasedOrders, searchPurchasedOrders, getInvoiceStatus, searchInvoice, updateInvoice, listUserByGST, activateUser, suspendUser, listCompanyByUserType, updateCustomerTier, getVendorJobReport, getUserOnBoardReport, getJobDetailsReport, getJobReportList, allowRCJobs, getReportSummary, activateSingleUser, suspendSingleUser
}