import { apiData } from "./api";
import { authServices } from "./auth.services";

let getCompanyNameByGST = async (gstin, user) => {
    try{
        let gstData = await apiData.api_ob.get(`/get-gstin?gstin=${gstin}&type=${user}`)
        gstData = gstData.data
        return gstData
    } catch(error){
        return error
    }
}
let getCategories = async () => {
    try{
        let categoryData = await apiData.api_internal.get(`/get-category`)
        categoryData = categoryData.data ? categoryData.data : null
        return categoryData
    } catch(error){
        return error
    }
}
let getEquimentsType = async () => {
    try{
        let equipmentData = await apiData.api_internal.get(`/get-equipment-type`)
        equipmentData = equipmentData.data ? equipmentData.data : null
        return equipmentData
    } catch(error){
        return error
    }
}
let getSoftwares = async () => {
    try{
        let softwareData = await apiData.api_internal.get(`/get-softwares`)
        softwareData = softwareData.data ? softwareData.data : null
        return softwareData
    } catch(error){
        return error
    }
}
let getBrands = async () => {
    try{
        let brandsData = await apiData.api_internal.get(`/get-brands`)
        brandsData = brandsData.data ? brandsData.data : null
        return brandsData
    } catch(error){
        return error
    }
}

const getCities = async (citystring) => {
    try{
        let citiesData = await apiData.api_internal.get(`/get-city?cityStr=${citystring}`)
        citiesData = citiesData.data ? citiesData.data : null
        return citiesData
    } catch(error){
        return error
    }
}

const getSACCode = async (headingId) => {
    try{
        /*let signature;
        if(headingId){
            signature = 'headingId=' + headingId
        }
        if(headingId && groupId){
            signature += '&groupId=' + groupId
        }*/
        const token = await authServices.getToken();
        return await apiData.api_internal.get(`/get-saccode?headingId=${headingId}`, {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch(error){
        return error
    }
}

const ProfilePicUpload = async (uploadFor, formdata) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_internal.post(`/profile/img?profileType=${uploadFor}`, formdata, {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

export const externalServices = {
    getCompanyNameByGST,
    getCategories,
    getEquimentsType,
    getSoftwares,
    getBrands,
    getCities,
    getSACCode,
    ProfilePicUpload
}