import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import { Tab, Tabs } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import ReviewCard from '../../shared/UI/Profile/ReviewCard'
import { reviewService } from '../../services/review.service'
import FleetCard from '../../shared/UI/Profile/FleetCard'
import AddFleetModal from '../../shared/UI/Modals/AddFleetModal'
import AllFleetModal from '../../shared/UI/Modals/AllFleetModal'
import { FleetsService } from '../../services/fleets.service'
import { jobsService } from '../../services/jobs.service'
import CategoryModal from '../../shared/UI/Modals/CategoriesModal'
import UserUpdateModal from '../../shared/UI/Modals/UserUpdateModal'
import MToast from '../../shared/UI/MToast'
import AccountInfoUpdateModal from '../../shared/UI/Modals/AccountInfoUpdateModal'
import AllReviewModal from '../../shared/UI/Modals/AllReviewModal'

function VendorProfile() {
  const [reviews, SetReviews] = useState('')
  const [vendorData, SetVendorData] = useState('')
  const [fleets, SetFleets] = useState('')
  const [fleetItem, setFleetItem] = useState('')
  const [viewModal, SetViewModal] = useState(false)
  const [viewReviewsModal, SetViewReviewsModal] = useState(false)
  const [viewAccountModal, SetViewAccountModal] = useState(false)
  const [viewCategoriesModal, SetViewCategoriesModal] = useState(false)
  const [viewInfoModal, SetViewInfoModal] = useState(false)
  const [viewAllFleetModal, SetViewAllFleetModal] = useState(false)
  //const [profileUpdateMsg, setProfileUpdateMsg] = useState(false)
  const [doesOwnProfile, setDoesOwnProfile] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [ErrorMessage, setErrorMessage] = useState('')
  const [activeTab, setActiveTab] = useState('AboutUs');
  const { vendorId } = useParams();
  const winloc = useLocation()
  const navigate = useNavigate();
  const showModal = (show) => {
    SetViewModal(show)
    setFleetItem('')
  }
  const showReviewsModal = (show) => {
    SetViewReviewsModal(show)
  }
  const showAccountModal = (show) => {
    SetViewAccountModal(show)
  }
  const showCategoryModal = (show) => {
    SetViewCategoriesModal(show)
  }
  const showInfoModal = (show) => {
    SetViewInfoModal(show)
  }
  const showAllFleetModal = (show) => {
    SetViewAllFleetModal(show)
  }
  const isEditFleet = (item) => {
    setFleetItem(item)
    SetViewModal(true)
  }
  const profileUpdateSuccess = (updated, message) => {
    if(updated){
      setSuccessMessage(message)
      jobsService.getVendorInfo().then((response) => {
        SetVendorData(response.data[0])
        setTimeout(() => {
          setSuccessMessage('')
        }, 3500);
      })
    } else{
      setErrorMessage(message)
    }
    setTimeout(() => {
      setSuccessMessage('')
      setErrorMessage('')
    }, 3500);
  }
  const categoryUpdateSuccess = (updated) => {
    if(updated){
      jobsService.getVendorInfo().then((response) => {
        SetVendorData(response.data[0])
      })
    }
  }
  const getFleets = () => {
    setErrorMessage('')
    FleetsService.getFleets().then((response) => {
      if(response.success){
          SetFleets(response.data)
      } else if(!response.success){
        setErrorMessage(response.error)
      }
      if(response.code === 'ERR_BAD_REQUEST'){
        setErrorMessage(response.data.message)
          return false
      } else if(response.code === "ERR_NETWORK") {
        setErrorMessage(response.message)
      }
    })
  }
  const fleetAdded = (added, message) => {
    if(added){
      setSuccessMessage(message)
      getFleets()
    } else{
      setErrorMessage(message)
    }
    setTimeout(() => {
      setSuccessMessage('')
      setErrorMessage('')
    }, 3500);
  }
  const fleetDeleted = (deleted) => {
    if(deleted){
      getFleets()
    }
  }
  const itemAdded = (added, message) => {
    if(added){
      setSuccessMessage(message)
    } else{
      setErrorMessage(message)
    }
    setTimeout(() => {
      setSuccessMessage('')
      setErrorMessage('')
    }, 3500);
  }
  const handleSelect = (pageName) => {
    setActiveTab(pageName)
    if(pageName === 'fleets' || pageName === 'categories' || pageName === 'reviews') {
      if(vendorId){
        navigate(`/profile/${vendorId}/${pageName}`)
      } else{
        navigate(`/vendor/my-profile/${pageName}`)
      }
    } else {
      if(vendorId){
        navigate(`/profile/${vendorId}`)
      } else{
        navigate(`/vendor/my-profile`)
      }
    }
  }
  useEffect(() => {
    const PageUrl = winloc.pathname.split('/').pop();
    if(PageUrl && activeTab !== PageUrl && (PageUrl === 'fleets' || PageUrl === 'categories' || PageUrl === 'reviews')) {
      setActiveTab(PageUrl)
      if(vendorId){
        navigate(`/profile/${vendorId}/${PageUrl}`)
      } else{
        navigate(`/vendor/my-profile/${PageUrl}`)
      }
    }
    if(vendorId){
      jobsService.getVendorById(vendorId).then((response) => {
        SetVendorData(response.data[0])
        setDoesOwnProfile(false)
        reviewService.getReview(vendorId).then((response) => {
          SetReviews(response.data)
        })
      })
    } else{
      getFleets()
      jobsService.getVendorInfo().then((response) => {
        SetVendorData(response.data[0])
        setDoesOwnProfile(true)
        reviewService.getReview(response.data[0].vendorId).then((response) => {
          SetReviews(response.data)
        })
      })
    }
  }, [vendorId, activeTab, winloc, navigate])
  return (
    <>
    <DashboardWrapper>
      <div className='page-content ps-0 pe-0 myjobs my-profile'>
        <div className='page-header'>
          <div className='user-info'>
              {vendorData && <><div className='user-thumb'><img src={vendorData.profileImage} alt={vendorData.companyName} /></div>
              <div className='user-content'>
                  <h2>{vendorData.companyName}</h2>
                  <div className='location'>{vendorData.location}</div>
                  <div className='rating-review'>
                      <div className='rating'>{vendorData.rating} <span className='icon icon-star'></span></div>
                      { vendorId ? <Link to={`/profile/${vendorId}/reviews`}>{vendorData.reviewCount} Reviews</Link> : <Link to="/vendor/my-profile/reviews">{vendorData.reviewCount} Reviews</Link> }
                  </div>
              </div></>}
          </div>
          {doesOwnProfile && <div className='action-buttons'>
            <button className='btn btn-light btn-blue' onClick={() => showInfoModal(true)}>Edit</button>
          </div>}
        </div>
        <Tabs className="jobs-tab profile-tabs" defaultActiveKey='AboutUs' activeKey={activeTab} onSelect={handleSelect}>
          <Tab eventKey="AboutUs" title="About us">
            <div className='profile-section about-section'>
                <h3>About us</h3>
                {doesOwnProfile && <div className='action-buttons'>
                  <button className='btn btn-light btn-blue' onClick={() => showInfoModal(true)}>Edit</button>
                </div>}
                <p>{vendorData ? vendorData.about : '-'}</p>
                <ul className="bid-info mb-0">
                    <li>
                        <span>GST Number</span>
                        <div>{vendorData && vendorData.companyGstNo}</div>
                    </li>
                    <li>
                        <span>Company Registration Date</span>
                        <div>{vendorData && new Date(vendorData.registrationDate).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
                    </li>
                    {/*<li>
                        <span>Aadhar Number (only visible to you)</span>
                        <div><Link>Add</Link></div>
                    </li>*/}
                    <li>
                        <span>Phone (only visible to you)</span>
                        <div>{vendorData.mobile? vendorData.mobile : '-'}</div>
                    </li>
                    <li>
                        <span>Email</span>
                        <div>{vendorData.email? vendorData.email : '-'}</div>
                    </li>
                    <li>
                        <span>Address</span>
                        <div>{vendorData.companyAddress ? vendorData.companyAddress : '-'}</div>
                    </li>
                    <li>
                        <span>City</span>
                        <div>{vendorData.city ? vendorData.city : '-'}</div>
                    </li>
                    <li>
                        <span>State</span>
                        <div>{vendorData.state ? vendorData.state : '-'}</div>
                    </li>
                    <li>
                        <span>Pin Code</span>
                        <div>{vendorData.pin ? vendorData.pin : '-'}</div>
                    </li>
                    <li>
                        <span>No. of employees</span>
                        <div>{vendorData.employeeCountUpto === 0 ? '-' : vendorData.employeeCountUpto === 5 ? '0-5' : vendorData.employeeCountUpto === 10 ? '5-10' : vendorData.employeeCountUpto > 10 ? 'More than 10' : ''}</div>
                    </li>
                    <li>
                        <span>No. of offices</span>
                        <div>{vendorData.multiOffices ? 'Multiple offices' : 'Single Office'}</div>
                    </li>
                    <li>
                        <span>Profile Link</span>
                        <div>{window.location.origin}/profile/{vendorData.vendorId}</div>
                    </li>
                    {/*<li>
                        <span>Website</span>
                        <div><Link>Add</Link></div>
                  </li>*/}
                </ul>
            </div>
            {/*doesOwnProfile && <div className='profile-section account-section'>
                <h3>Bank Account Details</h3>
                <div className='action-buttons'>
                  <button className='btn btn-light btn-blue' onClick={() => showAccountModal(true)}>Edit</button>
                </div>
                <ul className="bid-info">
                <li>
                    <span>Account Name</span>
                    <div>{vendorData.accountName ? vendorData.accountName : '-'}</div>
                </li>
                <li>
                    <span>Account Type</span>
                    <div>{vendorData.accountType ? vendorData.accountType : '-'}</div>
                </li>
                <li>
                    <span>Account Number</span>
                    <div>{vendorData.accountNumber? vendorData.accountNumber : '-'}</div>
                </li>
                <li>
                    <span>IFSC Code</span>
                    <div>{vendorData.ifsc? vendorData.ifsc : '-'}</div>
                </li>
            </ul>
            </div>*/}
            {viewInfoModal && vendorData && <UserUpdateModal showModal={showInfoModal} viewModal={viewInfoModal} userInfo={vendorData} profileUpdateSuccess={profileUpdateSuccess} />}
            {viewAccountModal && vendorData && <AccountInfoUpdateModal showModal={showAccountModal} viewModal={viewAccountModal} userInfo={vendorData} profileUpdateSuccess={profileUpdateSuccess} />}
          </Tab>
          <Tab eventKey="categories" title="Categories">
            <div className='profile-section'>
                <h3>Categories</h3>
                {doesOwnProfile && <div className='action-buttons'>
                  <button className='btn btn-light btn-blue' onClick={() => showCategoryModal(true)}>Edit</button>
                </div>}
                <div className='categories d-flex flex-wrap'>
                  {vendorData && vendorData.category.map((item, index) =>
                    <span className='chip-rounded-gray' key={index}>{item}</span>
                  )}
                </div>
            </div>
            {viewCategoriesModal && vendorData && <CategoryModal showModal={showCategoryModal} viewModal={viewCategoriesModal} userCategories={vendorData.category} categoryUpdateSuccess={categoryUpdateSuccess} />}
          </Tab>
         {doesOwnProfile && <Tab eventKey="fleets" title="My Fleet">
            <div className='profile-section w-1052'>
                <h3>My Fleet ({fleets ? fleets.length : 0})</h3>
                <div className='action-buttons'>
                  <button className='btn btn-light btn-blue' onClick={() => showModal(true)}>Add New</button>
                  <button className='btn btn-light btn-blue' onClick={() => showAllFleetModal(true)}>View All</button>
                </div>
                <div className='reviews-list'>
                  {fleets && fleets.map((item, index) => <FleetCard key={index} fleetItem={item} isEditFleet={isEditFleet} fleetDeleted={fleetDeleted} /> )}
                </div>
            </div>
            {viewModal && <AddFleetModal showModal={showModal} viewModal={viewModal} fleetItem={fleetItem} fleetAdded={fleetAdded} itemAdded={itemAdded} />}
            {viewAllFleetModal && <AllFleetModal showModal={showAllFleetModal} viewModal={viewAllFleetModal} />}
          </Tab>}
          <Tab eventKey="reviews" title="Reviews">
            <div className='profile-section w-1052'>
                <h3>Reviews ({reviews ? reviews.length : 0})</h3>
                <div className='action-buttons'>
                  <button className='btn btn-light btn-blue' onClick={() => showReviewsModal(true)}>View All</button>
                </div>
                <div className='reviews-list'>
                  {reviews && reviews.map((item, index) => <ReviewCard reviewDetail={item} key={index} /> )}
                </div>
            </div>
            {viewReviewsModal && <AllReviewModal showModal={showReviewsModal} viewModal={viewReviewsModal} />}
          </Tab>
        </Tabs>
        {/*profileUpdateMsg && <MToast color="green" duration={3500} icon="icon-check-green-rev">Your profile is updated successfully.</MToast>*/}
      </div>
    </DashboardWrapper>
        {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
        {ErrorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{ErrorMessage}</MToast>}
        </>
  )
}

export default VendorProfile