import './App.scss';
import {Routes, Route, Navigate, Outlet, useNavigate} from 'react-router-dom';
import SignUp from './auth/Register/SignUp';
import Login from './auth/Login/Login';
import CustomerDashboard from './Customers/CustomerDashboard';
import PostJob from './Customers/PostJob';
import OtpVerify from './auth/Register/OtpVerify';
import { authServices } from './services/auth.services';
import MyJobs from './Customers/jobs/MyJobs';
import MyJobsVendor from './Vendors/MyJobs';
import { useEffect, useState } from 'react';
import VendorProfile from './Vendors/MyProfile/VendorProfile';
import VendorList from './Customers/VendorsList';
import ResetPassword from './auth/Login/ResetPassword';
import MasterItemPage from './Admin/MasterItem/MasterItemPage';
import AddDistinctItemsPage from './Admin/AddDistinctItems';
import InvoiceStatusPage from './Admin/Invoice/InvoiceStatusPage';
import POStatusPage from './Admin/PurchaseOrder/POStatusPage';
import TierCommissionPage from './Admin/TierCommission/TierCommissionPage';
import ManageSubscriptionPage from './Admin/ManageSubscription/ManageSubscriptionPage';
import ManageUsersPage from './Admin/ManageUsers/ManageUsersPage';
import ReportsVendor from './Vendors/Reports/Reports';
import ReportsCustomer from './Customers/Reports/Reports';
import ManageUsersTierPage from './Admin/ManageUsers/ManageUsersTierPage';
import MySubscription from './Vendors/Subscription/Subscription';
import PaymentSuccessFailed from './shared/PaymentSuccessFailed';
import AdminReportsOnboard from './Admin/Reports/OnboardReports';
import AdminReportsVendor from './Admin/Reports/VendorReports';
import JobDetailsReport from './Admin/Reports/JobDetailsReport';
import CustomerProfile from './Customers/MyProfile/CustomerProfile';
import AdminDashboard from './Admin/Dashboard/Dashboard';
import VendorDashboard from './Vendors/VendorDashboard';
import VendorListByCategory from './Admin/Reports/VendorsListByCategory';
function decodeToken(token) {
  const tokenParts = token.split('.');
  const payload = tokenParts[1];

  if (payload) {
    const decodedPayload = atob(payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload;
  }

  return null;
}
function PrivateRoute({ requiredRole }) {
  const[auth, setAuth] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    function startLogoutTimer(expirationTime) {
      const timeRemaining = expirationTime - Date.now();
      setTimeout(() => {
        authServices.logout()
        navigate('/login#session-expired')
      }, timeRemaining - 5000);
    }
    authServices.getToken().then((response) => {
      if(response){
        const decodedToken = decodeToken(response);
        const expirationTime = decodedToken.exp * 1000;
        if (expirationTime < Date.now()) {
          authServices.logout()
          navigate('/login')
        } else {
          authServices.getUser().then((response) => {
            if(response && decodedToken && !(expirationTime < Date.now())) {
              startLogoutTimer(expirationTime)
              const userRole = response['roles']
              setAuth({ isAuthenticated: true, userRole: userRole[0], status: response.status });
            }
          }).catch((error) => {
          })
        }
      } else{
        authServices.logout()
        navigate('/login')
      }
    }).catch((error) => {
      authServices.logout()
      navigate('/login')
    })
  }, [navigate])
  if(auth && auth.isAuthenticated){
    if(auth.userRole && requiredRole.includes(auth.userRole)){
      return <Outlet />
    } else if(auth.status === "INITIALIZE"){
      return <Navigate to="/verify-otp" replace />
    } else if(auth.userRole === "ROLE_CUSTOMER"){
      return <Navigate to="/customer/dashboard" replace />
    } else if(auth.userRole === "ROLE_VENDOR"){
      return <Navigate to="/vendor/my-jobs" replace />
    } else if(auth.userRole === "ROLE_ADMIN"){
      return <Navigate to="/admin/dashboard" replace />
    }
  } else{
    //return <Navigate to="/login" replace />
  }
}

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path='/' element={<Navigate to="register" />} />
          <Route path="register" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER", "ROLE_VENDOR", "ROLE_ADMIN"]} />}>
            <Route path='verify-otp' element={<OtpVerify />} />
          </Route>
          <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER", "ROLE_VENDOR"]} />}>
            <Route path='payment-confirmation' element={<PaymentSuccessFailed />} />
          </Route>
          <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER", "ROLE_VENDOR"]} />}>
            <Route path='profile/:vendorId' element={<VendorProfile />}>
              <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER", "ROLE_VENDOR"]} />}>
                <Route path='categories' element={<VendorProfile />} />
              </Route>
              <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER", "ROLE_VENDOR"]} />}>
                <Route path='reviews' element={<VendorProfile />} />
              </Route>
            </Route>
          </Route>
          <Route path="customer">
            <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER"]} />}>
              <Route path='dashboard' element={<CustomerDashboard />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER"]} />}>
              <Route path='post-a-job' element={<PostJob />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER"]} />}>
              <Route path='my-jobs' element={<MyJobs />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER"]} />}>
              <Route path='find-vendor' element={<VendorList />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER"]} />}>
              <Route path='reports' element={<ReportsCustomer />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_CUSTOMER"]} />}>
              <Route path='my-profile' element={<CustomerProfile />} />
            </Route>
          </Route>
          <Route path="vendor">
            <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
              <Route path='dashboard' element={<VendorDashboard />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
              <Route path='my-jobs' element={<MyJobsVendor />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
              <Route path='my-profile' element={<VendorProfile />}>
                <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
                  <Route path='fleets' element={<VendorProfile />} />
                </Route>
                <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
                  <Route path='categories' element={<VendorProfile />} />
                </Route>
                <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
                  <Route path='reviews' element={<VendorProfile />} />
                </Route>
              </Route>
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
              <Route path='find-vendor' element={<VendorList />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
              <Route path='subscription-and-payment' element={<MySubscription />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_VENDOR"]} />}>
              <Route path='reports' element={<ReportsVendor />} />
            </Route>
          </Route>
          <Route path="admin">
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='dashboard' element={<AdminDashboard />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='master-items' element={<MasterItemPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='add-distinct-items' element={<AddDistinctItemsPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='invoice-status' element={<InvoiceStatusPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='po-status' element={<POStatusPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='tier-commission' element={<TierCommissionPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='manage-subscription' element={<ManageSubscriptionPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='manage-users-status' element={<ManageUsersPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='manage-users-tier' element={<ManageUsersTierPage />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='vendor-report' element={<AdminReportsVendor />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='onboard-report' element={<AdminReportsOnboard />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='job-report' element={<JobDetailsReport />} />
            </Route>
            <Route element={<PrivateRoute requiredRole={["ROLE_ADMIN"]} />}>
              <Route path='vendors/:category' element={<VendorListByCategory />} />
            </Route>
          </Route>
      </Routes>
    </div>
  );
}

export default App;
