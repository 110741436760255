import React, { useCallback, useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adminServices } from '../../services/admin.service';
import SelectDropdown from '../../shared/UI/SelectDropdown';
import AdminReportsTable from './AdminReportsTable';
import MToast from '../../shared/UI/MToast';
import { useLocation } from 'react-router';
import MpaasPagination from '../../shared/UI/Pagination';

function AdminReportsOnboard() {
    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [pickerOpen, setPickerOpen] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [isLoading, setIsLoading] = useState('')
    const [isSticky, setSticky] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    let itemsCountList = [10, 25, 50, 100]
    let userTypeList = ['Customers', 'Vendors', 'Admin']
    let tierList = ['SILVER', 'GOLD', 'PLATINUM']
    const [userType, setUserType] = useState(userTypeList[0]);
    const [selectedTier, setSelectedTier] = useState(null);
    //const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');
    const to = queryParams.get('to');
    const utype = queryParams.get('type');
    const [isVisible, setIsVisible] = useState(true)
    const [isSmallScreen, setIsSmallScreen] = useState(false);
  
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
            //(window.innerWidth < 768) ? setIsVisible(false) : setIsVisible(true)
        };
  
        handleResize();
        window.addEventListener('resize', handleResize);
  
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const handleMenuToggle = () => {
        setIsVisible(!isVisible)
    }

    useEffect(() => {
        const handleScroll = () => {
          if(document.documentElement.scrollHeight > window.innerHeight+150) {
            setSticky(window.scrollY > 100)
          }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const pageChangeHandler = (pageNumber) => {
        getOnboardReports(false, selectedStartDate, selectedEndDate, pageNumber, itemsPerPage, selectedTier)
    }
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const formatDate = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    const handleStartDateChange = (date) => {
        if(from) queryParams.set('from', formatDate(date))
        window.history.replaceState({}, '', `${location.pathname}?${queryParams.toString()}`)
        setSelectedStartDate(date);
    }
    const handleEndDateChange = (date) => {
        if(to) queryParams.set('to', formatDate(date))
        window.history.replaceState({}, '', `${location.pathname}?${queryParams.toString()}`)
        setSelectedEndDate(date);
    }
    const getOnboardReports = (loader, startdate, enddate, currentpage, itemsperPage, tier) => {
        if(loader) setIsLoading(true)
        const formatedSD = formatDate(startdate)
        const formatedED = formatDate(enddate)
        setCurrentPage(currentpage)
        adminServices.getUserOnBoardReport(formatedSD, formatedED, currentpage, itemsperPage, '-1', userType, tier).then((response) => {
            if(response.data){
                setTotalPage(Math.ceil(response.total / itemsPerPage))
                setItems(response.data)
                setTotalItemsCount(response.total)
                queryParams.set('from', formatedSD);
                queryParams.set('to', formatedED);
                queryParams.set('type', userType);
                window.history.pushState({}, '', `${location.pathname}?${queryParams.toString()}`)
            } else if(!response.success){
                setErrorMessage(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage('Bad Request')
            } else if(response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
            setIsLoading(false)
            setTimeout(() => {
                setErrorMessage('')
            }, 3500);
        })
    }
    const getInitialOnboardReports = useCallback((startdate, enddate, currentpage, itemsperPage, utype) => {
        const formatedSD = formatDate(startdate)
        const formatedED = formatDate(enddate)
        setCurrentPage(currentpage)
        adminServices.getUserOnBoardReport(formatedSD, formatedED, currentpage, itemsperPage, '-1', utype).then((response) => {
            if(response.data){
                setTotalPage(Math.ceil(response.total / itemsperPage))
                setItems(response.data)
                setTotalItemsCount(response.total)
            } else if(!response.success){
                setErrorMessage(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage('Bad Request')
            } else if(response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
            setIsLoading(false)
            setTimeout(() => {
                setErrorMessage('')
            }, 3500);
        })
    }, [])
    useEffect(() => {
        if(!initialLoad){
            if(utype) setUserType(utype)
            if(from && to) {
                getInitialOnboardReports(from, to, 1, itemsPerPage, utype)
                setSelectedStartDate(new Date(from))
                setSelectedEndDate(new Date(to))
            }
            setInitialLoad(true)
        }
    }, [from, to, itemsPerPage, utype, initialLoad, getInitialOnboardReports])

    const handlePopstate = useCallback(() => {
        getInitialOnboardReports();
    }, [getInitialOnboardReports])

    useEffect(() => {
        window.addEventListener('popstate', handlePopstate);
    }, [handlePopstate]);
    const tableUpdated = (value) => {
        if(value === true){
            getOnboardReports(false, selectedStartDate, selectedEndDate, currentPage, itemsPerPage, selectedTier)
        }
    }
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <div className='page-header ps-0 pe-0 mb-0'>
                <h2 className='mb-0-md'>Onboard Report</h2>
                <div className='items-count'><strong>Total Count: {totalItemsCount}</strong></div>
            </div>
            <div className={`distinct-row ${isSticky ? 'sticked-row' : ''}`}>
                {isSmallScreen && <div className='form-filter-toggle d-flex align-items-center' onClick={handleMenuToggle}>{isVisible ? 'Hide' : 'Show'} Filter <i className='icon icon-filter-green'></i></div>}
                {isVisible && <div className='form'>
                    <div className={`input-wrapper mat-input icon-input ${selectedStartDate ? 'date-selected' : ''}`}>
                        <DatePicker selected={selectedStartDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="bidCloserDate" id='startDateControl' autoComplete='off'
                        onChange={handleStartDateChange}
                        dateFormat="yyyy-MM-dd"
                        value={selectedStartDate}
                        maxDate={new Date()}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="startDateControl" className="form-label">Start Date*</label>
                    </div>
                    <div className={`input-wrapper mat-input icon-input ${selectedEndDate ? 'date-selected' : ''}`}>
                        <DatePicker selected={selectedEndDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="bidCloserDate" id='endDateControl' autoComplete='off'
                        onChange={handleEndDateChange}
                        dateFormat="yyyy-MM-dd"
                        value={selectedEndDate}
                        minDate={selectedStartDate}
                        maxDate={new Date()}
                        disabled={selectedStartDate === ''}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="endDateControl" className="form-label">End Date*</label>
                    </div>
                    <div className="input-wrapper mat-input mb-0" style={{minWidth: '150px'}}>
                        <SelectDropdown
                            options={itemsCountList}
                            labelText='Items per page'
                            value={itemsPerPage}
                            onChange={(option) => setItemsPerPage(option)}
                        />
                    </div>
                    <div className="input-wrapper mat-input mb-0" style={{minWidth: '150px'}}>
                        <SelectDropdown
                            options={userTypeList}
                            labelText='User Type'
                            value={userType}
                            onChange={(option) => {
                                //if(utype) queryParams.set('type', option)
                                //window.history.replaceState({}, '', `${location.pathname}?${queryParams.toString()}`)
                                setUserType(option)
                            }}
                        />
                    </div>
                    {userType && userType === 'Customers' && <div className="input-wrapper mat-input mb-0" style={{minWidth: '150px'}}>
                        <SelectDropdown
                            options={tierList}
                            labelText='Select Tier'
                            value={selectedTier ? selectedTier : ''}
                            onChange={(option) => {
                                setSelectedTier(option)
                            }}
                        />
                    </div>}
                    <button type="submit" className={`btn btn-primary btn-medium ${selectedEndDate && selectedStartDate && !isLoading ? '' : 'disabled'}`} onClick={() => getOnboardReports(true, selectedStartDate, selectedEndDate, 1, itemsPerPage, selectedTier)}>Search {isLoading && <span className='loader loader-small ms-1'></span>}</button>
                </div>}
            </div>
            {totalPage > 1 ? <MpaasPagination totalPage={totalPage} onPageChange={pageChangeHandler} currentPage={currentPage} /> : null}

            <AdminReportsTable items={items} reportType="onboard" userType={userType} currentPageNo={currentPage} itemsPerPage={itemsPerPage} tableUpdated={tableUpdated} />

            {totalPage > 1 ? <MpaasPagination totalPage={totalPage} onPageChange={pageChangeHandler} currentPage={currentPage} /> : null}
        </div>
        {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </DashboardWrapper>
  )
}

export default AdminReportsOnboard