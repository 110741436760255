import React, { useCallback, useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import JobCard from '../../shared/UI/JobDetails/JobCard';
import '../../scss/jobs.scss';
//import { jobsService } from '../../services/jobs.service';
import JobDetailsModal from '../../shared/UI/Modals/JobDetailsModal';
//import RatingModal from '../../shared/UI/Modals/RatingModal';
import MToast from '../../shared/UI/MToast';
//import { authServices } from '../../services/auth.services';
import SelectDropdown from '../../shared/UI/SelectDropdown';
import { jobsServicev2 } from '../../services/job.service-v2';
import MpaasPagination from '../../shared/UI/Pagination';


function MyJobs() {
  const [jobsList, setJobsList] = useState([])
  const [filteredJobsList, setFilteredJobsList] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [jobId, setJobId] = useState('')
  const [viewModal, SetViewModal] = useState(false)
  //const [viewRatingModal, setViewRatingModal] = useState(false)
  //const [ratingData, setRatingData] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searched, setSearched] = useState('')
  const [sortItem, setSortItem] = useState('')
  const [jobStatus, setJobStatus] = useState('CREATED')
  const [jobsCount, setJobsCount] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  let itemsCountList = [10, 20, 50, 100]
  const totalPages = Math.ceil(totalItemsCount / itemsPerPage)
  const sortArray = ['Newest First', 'Oldest First', 'Newest Job Award Date', 'Oldest Job Award Date']
  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  }
  /*const getPageRange = () => {
      const range = [];
      const maxDisplayedPages = 10;
      const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);
      let startPage = currentPage - halfDisplayedPages;
      let endPage = currentPage + halfDisplayedPages;

      if (startPage <= 0) {
        startPage = 1;
        endPage = Math.min(maxDisplayedPages, totalPages);
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxDisplayedPages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        range.push(i);
      }

      return range;
  }*/
  const showModal = (show, jobId) => {
    SetViewModal(show)
    setJobId(jobId)
  }
  /*const showRatingModal = (show) => {
    setViewRatingModal(show)
    if(!show){
      SetViewModal(true)
    }
  }*/
  const handleItemPerPage = (option) => {
    setItemsPerPage(option)
    setCurrentPage(1)
  }
  const getJobsByStatus = useCallback((status) => {
    jobsServicev2.getJobsByStatus(status, 1, currentPage, itemsPerPage).then((response) => {
      if(response.success){
        const jobData = response.data.data
        //const createdData = jobData.filter((item) => {return item.status === 'CREATED'})
        setJobsList(jobData.length>0 ? jobData : [])
        setFilteredJobsList(jobData.length>0 ? jobData : [])
        if(response.data.total) setTotalItemsCount(response.data.total)
      } else if(!response.success){
        setErrorMsg(response.error)
      }
      if(response.code === 'ERR_BAD_REQUEST'){
        setErrorMsg(response.data.message)
      } else if(response.code === "ERR_NETWORK") {
        setErrorMsg(response.message)
      }
    })
  }, [currentPage, itemsPerPage])

  const getJobsCount = useCallback(() => {
    jobsServicev2.getJobsCounts().then((response) => {
      if(response.success){
        setJobsCount(response.data)
      } else if(!response.success){
        setErrorMsg(response.error)
      }
      if(response.code === 'ERR_BAD_REQUEST'){
        setErrorMsg(response.data.message)
      } else if(response.code === "ERR_NETWORK") {
        setErrorMsg(response.message)
      }
    })
  }, [])

  useEffect(() => {
    getJobsCount()
  }, [getJobsCount])

  useEffect(() => {
    getJobsByStatus(jobStatus)
  }, [getJobsByStatus, jobStatus, itemsPerPage, currentPage])
  let handleSelect = (key) => {
    clearFilter()
    if (key === 'ActiveJobs'){
      setJobStatus('CREATED')
    }
    if (key === 'ConfirmedJobs'){
      setJobStatus('ASSIGNED')
    }
    if (key === 'CompletedJobs'){
      setJobStatus('DONE')
    }
    if (key === 'ExpiredJobs'){
      setJobStatus('EXPIRED')
    }
  }
  const isBidApproved = (approved) => {
    if(approved){
      getJobsByStatus('CREATED')
      getJobsCount()
    }
  }
  const updateJobs= (status) => {
    if(status){
      getJobsByStatus(status)
      getJobsCount()
    }
  }
  /*const openreviewmodal = (open, ratingData) => {
    if(open) {
      setViewRatingModal(true)
      SetViewModal(false)
      if(ratingData) setRatingData(ratingData)
    }
  }*/
  const filterJobs = (jobType) => {
    const sqLower = searchQuery.toLowerCase()
    return jobType.filter((item) => item.jobTitle.toLowerCase().includes(sqLower) || item.category.toLowerCase().includes(sqLower) || item.equipmentType.toLowerCase().includes(sqLower))
  }
  const searchJob = (e) => {
    if(searchQuery !== ''){
      setSearched(true)
      setFilteredJobsList(filterJobs(jobsList))
    } else{
      setFilteredJobsList(jobsList)
      setSearched(false)
    }
  }
  const sortJobs = (data, option) => {
    let sortedData;
    if(option === 'Newest First'){
      sortedData = [...data].sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)))
    } else if(option === 'Oldest First'){
      sortedData = [...data].sort((a, b) => (new Date(a.createdAt) - new Date(b.createdAt)))
    } else if(option === 'Oldest Job Award Date'){
      sortedData = [...data].sort((a, b) => (new Date(a.bidCloserDate) - new Date(b.bidCloserDate)))
    } else if(option === 'Newest Job Award Date'){
      sortedData = [...data].sort((a, b) => (new Date(b.bidCloserDate) - new Date(a.bidCloserDate)))
    }
    return sortedData
  }
  const handleSortJobs = (option) => {
    setSortItem(option)
    setFilteredJobsList(sortJobs(filteredJobsList, option))
  }
  const clearFilter = () => {
    setFilteredJobsList(jobsList)
    setSearchQuery('')
    setSearched(false)
    setSortItem('')
  }
  return (
    <>
    <DashboardWrapper>
      <div className='page-content ps-0 pe-0 myjobs'>
        <div className='page-header'>
          <h2>My Jobs</h2>
          <div className='right-header'>
            <div className="search-form">
              <span className="icon icon-search"></span>
              <input className="form-control search mb-0" type="search" placeholder="Search Jobs by Entering Title, Category or Equipment Type" aria-label="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
            </div>
            <button className='btn btn-primary btn-auto' onClick={(e) => searchJob(e)}>Search</button>
            {/*<div className='search-filter'><div className='filter-toggle'><span className='icon icon-filter'></span></div></div>*/}
          {searched && <p className='search-filter-text'>You have searched for <strong>{searchQuery}</strong> <span className='link' onClick={clearFilter} style={{fontSize: '12px', paddingLeft: '10px'}}>Clear Search</span></p>}
          </div>
        </div>
        <div className='input-wrapper mat-input'>
          <SelectDropdown
            className="select-small"
            name="sortby" id="sortbyControl"
            options={sortArray}
            labelText='Sort By'
            value={sortItem}
            onChange={(option) => handleSortJobs(option)}
          />
        </div>
        <Tabs className="mb-3 jobs-tab" defaultActiveKey="ActiveJobs" onSelect={handleSelect}>
          <Tab eventKey="ActiveJobs" title={<span>{`Active jobs (${jobsCount ? jobsCount.CREATED : 0})`}
          <div className='tooltip-wrapper'>
            <span className='icon icon-info-dark'></span>
            <div className='tooltip'>Jobs that are open for bids / awaiting your action</div>
          </div>
          </span>}>
            {filteredJobsList && filteredJobsList.map((job, index) => <JobCard key={index} handleShow={showModal} jobdata={job} /> )}
          </Tab>
          <Tab eventKey="ConfirmedJobs" title={<span>{`Confirmed Jobs (${jobsCount ? jobsCount.ASSIGNED : 0})`}
          <div className='tooltip-wrapper'>
            <span className='icon icon-info-dark'></span>
            <div className='tooltip'>Jobs where you have confirmed a bid</div>
          </div>
          </span>}>
            {filteredJobsList && filteredJobsList.map((job, index) => <JobCard key={index} handleShow={showModal} jobdata={job} /> )}
          </Tab>
          <Tab eventKey="CompletedJobs" title={<span>{`Completed Jobs (${jobsCount ? jobsCount.DONE : 0})`}
          <div className='tooltip-wrapper'>
            <span className='icon icon-info-dark'></span>
            <div className='tooltip'>Jobs that are complete/ ready to be rated</div>
          </div>
          </span>}>
            {filteredJobsList && filteredJobsList.map((job, index) => <JobCard key={index} handleShow={showModal} jobdata={job} /> )}
          </Tab>
          {/*<Tab eventKey="DeclinedJobs" title={`Declined Jobs (${declinedJobs.length})`}>
            {declinedJobs && declinedJobs.map((job, index) => <JobCard key={index} handleShow={showModal} jobdata={job} /> )}
          </Tab>*/}
          <Tab eventKey="ExpiredJobs" title={<span>{`Expired Jobs (${jobsCount ? jobsCount.EXPIRED : 0})`}
          <div className='tooltip-wrapper'>
            <span className='icon icon-info-dark'></span>
            <div className='tooltip'>Jobs where no bids were received and time to bid is over and OR bids were received but no action taken</div>
          </div>
          </span>}>
            {filteredJobsList && filteredJobsList.map((job, index) => <JobCard key={index} handleShow={showModal} jobdata={job} /> )}
          </Tab>
        </Tabs>
        <div className='table-pagination job-pagination'>
          {totalPages > 1 ? <MpaasPagination totalPage={totalPages} onPageChange={goToPage} currentPage={currentPage} /> : null}
            {/*<ul className="pagination mb-0">
                <li className="page-item"><button className="page-link prev" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button></li>
                {getPageRange().map((pageNumber) => (
                <li key={pageNumber} className={currentPage === pageNumber ? 'page-item active' : 'page-item'}>
                    <button onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber} className="page-link">
                    {pageNumber}
                    </button>
                </li>
                ))}
                <li className="page-item"><button className="page-link next" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button></li>
            </ul>*/}
            <div className="input-wrapper ms-auto mb-0">
                <label htmlFor="ItemsPerPage" className="form-label">Items Per Page</label>
                <SelectDropdown
                    options={itemsCountList}
                    className='select-small open-top'
                    disableMat={true}
                    labelText='Items per page'
                    value={itemsPerPage}
                    onChange={(option) => handleItemPerPage(option)}
                />
            </div>
        </div>
      </div>
      {viewModal && <JobDetailsModal showModal={showModal} viewModal={viewModal} jobId={jobId} isBidApproved={isBidApproved} 
      //openreviewmodal={openreviewmodal}
      updateJobs={updateJobs} />}
      {/*viewRatingModal && ratingData && <RatingModal showRatingModal={showRatingModal} viewRatingModal={viewRatingModal} ratingData={ratingData} />*/}
    </DashboardWrapper>
      {errorMsg && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMsg}</MToast>}
      </>
  )
}

export default MyJobs