import axios from "axios";

const api_job = axios.create({
    baseURL: process.env.REACT_APP_API_URL_JOB + '/v2',
    headers: {
      'Content-Type': 'application/json',
    },
});

const api_bid = axios.create({
    baseURL: process.env.REACT_APP_API_URL_BID + '/v2',
    headers: {
      'Content-Type': 'application/json',
    },
});

export const apiv2 = {api_job, api_bid}