import React from 'react'
import { adminServices } from '../../services/admin.service';
import RowDropdown from '../../shared/UI/RowDropdown';

function AdminReportsRow({item, reportType, sno, tableUpdated}) {
  
const activateUser = (userName) => {
    adminServices.activateSingleUser(userName).then((response) => {
        if (response.success) {
          tableUpdated(true, response.data)
        } else if (!response.success) {
          tableUpdated(false, response.error);
        } 
        if (response.code === "ERR_BAD_REQUEST") {
          tableUpdated(false, response.data.message);
            return false;
        } else if (response.code === "ERR_NETWORK") {
          tableUpdated(false, response.message);
        }
    });
};
const suspendUser = (userName) => {
    adminServices.suspendSingleUser(userName).then((response) => {
      if (response.success) {
        tableUpdated(true, response.data)
      } else if (!response.success) {
        tableUpdated(false, response.error);
      }
      if (response.code === "ERR_BAD_REQUEST") {
        tableUpdated(false, 'Bad Request');
          return false;
      } else if (response.code === "ERR_NETWORK") {
        tableUpdated(false, response.message);
      }
    });
};
  return (
    <>
    <div className='table-row'>
        <div className='serial'>{sno+1}</div>
        {
            reportType === 'onboard' ?
            <>
                <div>{item.personName}</div>
                <div>{item.userType}</div>
                <div>{item.createdAt}</div>
                <div>{item.mobile_number}</div>
                <div>{item.email}</div>
                <div>{item.active ? <span className='chip-rounded-green'>Active</span> : <span className='chip-rounded-red'>Suspended</span>}</div>
                <div className="d-flex action">
                  <RowDropdown>
                      {item.active ? (
                          <li
                              onClick={() =>
                                  suspendUser(
                                      item.username
                                  )
                              }
                          >
                              Suspend
                          </li>
                      ) : !item.active ? (
                          <li
                              onClick={() =>
                                  activateUser(
                                      item.username
                                  )
                              }
                          >
                              Activate
                          </li>
                      ) : null}
                  </RowDropdown>
                </div>
            </>
            : null
        }
    </div>
    </>
  )
}

export default AdminReportsRow