import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import MToast from '../MToast';
import ReviewCard from '../Profile/ReviewCard';
import { reviewService } from '../../../services/review.service';
import { authServices } from '../../../services/auth.services';

function AllReviewModal({showModal, viewModal}) {
    const [show, setShow] = useState(false)
    const [reviews, SetReviews] = useState(null)
    const [reviewError, setReviewError] = useState('')
    const [reviewSuccess, setReviewSuccess] = useState('')
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        getReviews()
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const getReviews = async () => {
        setReviewError('')
        setReviewSuccess('')
        const userData = await authServices.getUser()
        if(userData.company_id){
          reviewService.getReview(userData.company_id).then((response) => {
            if(response.n>0){
                SetReviews(response.data)
            } else if(!response.success){
              setReviewError(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
              setReviewError(response.data.message)
                return false
            } else if(response.code === "ERR_NETWORK") {
              setReviewError(response.message)
            }
          })
        }
    }
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal modal-w874'>
        <Modal.Header>
            <Modal.Title>My Reviews ({reviews ? reviews.length : 0})</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <div className='reviews-list'>
              {reviews && reviews.map((item, index) => <ReviewCard reviewDetail={item} key={index} /> )}
            </div>
        </Modal.Body>
    </Modal>
    {reviewSuccess && <MToast color="green" duration={2000} icon="icon-check-green-rev">{reviewSuccess}</MToast>}
    {reviewError && <MToast color="red" duration={2000} icon="icon-cross-red-rev">{reviewError}</MToast>}
    </>
  )
}

export default AllReviewModal