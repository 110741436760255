import React, { useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import POStatusTable from './POStatusTable'
import SelectDropdown from '../../shared/UI/SelectDropdown'
//import MToast from '../shared/UI/MToast';

function POStatusPage() {
    //const [successMessage, setSuccessMessage] = useState(null)
    //const [errorMessage, setErrorMessage] = useState(null)
    const [status, setStatus] = useState('PENDING')
    const statusList = ['PENDING', 'APPROVED', 'CANCELLED']
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <div className='page-header d-flex justify-content-between pe-0 ps-0'>
                <h2>Purchase Orders</h2>
                <div className="input-wrapper w-200 ms-auto mb-0">
                    <label htmlFor="ItemsPerPage" className="form-label">Select Status</label>
                    <SelectDropdown
                        options={statusList}
                        className='select-small'
                        disableMat={true}
                        value={status}
                        onChange={(option) => setStatus(option)}
                    />
                </div>
            </div>
            <POStatusTable status={status} />
        </div>
    </DashboardWrapper>
  )
}

export default POStatusPage