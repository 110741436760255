import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import MToast from '../MToast';
import JobActivityRow from './JobActivityRow';
import { jobsServicev2 } from '../../../services/job.service-v2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddFleetModal from '../Modals/AddFleetModal';
import { bidServicev2 } from '../../../services/bid.service-v2';
import { FleetsService } from '../../../services/fleets.service';
import StartEndOtp from './StartEndOtp';
import EnterStartEndOtp from './EnterStartEndOtp';

function JobActivityTable({ jobData, user, jobUpdated, scrollSection, vendorBid }) {
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [newActivities, setNewActivities] = useState([])
    const [scheduleError, setScheduleError] = useState(null)
    const [pickerOpen, setPickerOpen] = useState(null)
    const [selectedSerial, setSelectedSerial] = useState(null)
    const [copiedFleet, setCopiedFleet] = useState(null)
    const [fleetCopied, setFleetCopied] = useState(false)
    const [bidData, setBidData] = useState(null)
    const [viewFleetModal, setViewFleetModal] = useState(false)
    const [fleetItem, setFleetItem] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [activityLoader, setActivityLoader] = useState(false)
    const [serialNumbers, setSerialNumbers] = useState([])
    const [activityData, setActivityData] = useState([])
    const [updatingCatalog, setUpdatingCatalog] = useState(false)
    const [activityId, setActivityId] = useState('')
    const [toggleGenerateOTP, setToggleGenerateOTP] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState(null)
    const sectionRef = useRef(null)
    useEffect(() => {
        if (jobData) {
            let catalogData
            if (vendorBid) {
                catalogData = jobData.job.jobActivity.map((activity) => {
                    return { jobId: activity.jobId, fleetId: "", serialNo: "", jobActivityId: activity.activityId, bidId: vendorBid.bidId }
                })
            } else {
                catalogData = jobData.job.jobActivity.map((activity) => {
                    return { jobId: activity.jobId, fleetId: "", serialNo: "", jobActivityId: activity.activityId }
                })
            }
            const updatedBidData = {
                jobId: jobData.job.jobId,
                quotation: "",
                bidCatlogs: catalogData
            }
            setBidData(updatedBidData)
            setActivityData(jobData.job.jobActivity)
        }
    }, [jobData, vendorBid])

    useEffect(() => {
        if (user && user.roles.includes("ROLE_VENDOR")) {
            FleetsService.getSerialNumberList().then((response) => {
                setSerialNumbers(response.data)
            })
        }
    }, [user])
    const updateCatalog = (activityId, fleetId, serialNo) => {
        setUpdatingCatalog(true)
        const updatedBidCatalog = bidData.bidCatlogs.map((catalog) => {
            if (catalog.jobActivityId === activityId) {
                if (vendorBid) {
                    return {
                        ...catalog,
                        fleetId: fleetId,
                        serialNo: serialNo,
                        bidId: vendorBid.bidId
                    }
                } else {
                    return {
                        ...catalog,
                        fleetId: fleetId,
                        serialNo: serialNo
                    }
                }
            }
            return catalog;
        });
        setBidData({ ...bidData, bidCatlogs: updatedBidCatalog })
    }

    useEffect(() => {
        if (vendorBid && !updatingCatalog) {
            const updatedBidData = bidData
            const updatedActivityData = activityData
            const catalogData = bidData.bidCatlogs
            const resCatalog = vendorBid.bidCatlogs
            updatedBidData.quotation = vendorBid.quotation
            updatedBidData.bidId = vendorBid.bidId
            for (let i = 0; i < resCatalog.length; i++) {
                for (let j = 0; j < catalogData.length; j++) {
                    if (catalogData[j].jobActivityId === resCatalog[i].jobActivityId) {
                        catalogData[j].fleetId = resCatalog[i].fleet.fleetId
                        catalogData[j].serialNo = resCatalog[i].fleet.serialNo
                        catalogData[j].bidId = vendorBid.bidId
                    }
                }
                for (let k = 0; k < updatedActivityData.length; k++) {
                    if (updatedActivityData[k].activityId === resCatalog[i].jobActivityId) {
                        updatedActivityData[k].serialNo = resCatalog[i].fleet.serialNo
                        updatedActivityData[k].equipment = resCatalog[i].fleet.masterItem.equipment
                        updatedActivityData[k].brand = resCatalog[i].fleet.masterItem.brand
                    }
                }
            }
            updatedBidData.bidCatlogs = catalogData
            setBidData(updatedBidData)
            setActivityData(updatedActivityData)
        }
    }, [vendorBid, bidData, activityData, updatingCatalog])

    /*useEffect(() => {
        debugger
        if(activityId && activityData) {
            const filteredActivity = activityData.filter((activity) => {
                return activity.activityId === activityId
            })
            setSelectedActivity(filteredActivity[0])
        }
    }, [activityId, activityData])*/

    const showFleetModal = (show) => {
        setViewFleetModal(show)
        setFleetItem('')
    }
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const onSerialSelect = (value) => {
        setSelectedSerial(value);
    };
    const handleCopyFleet = () => {
        if (selectedSerial) setCopiedFleet(selectedSerial);
    };
    const unsetCopiedFleet = () => {
        setFleetCopied(true)
    }
    const handleAddField = () => {
        setScheduleError('')
        const last_entry = newActivities[newActivities.length - 1]
        if ((last_entry && last_entry.startDate && last_entry.endDate) || newActivities.length === 0) {
            if (jobData && jobData.job.budgetType === 'QUANTITY') {
                setNewActivities([...newActivities, { startDate: '', endDate: '', quantity: '' }])
            } else {
                setNewActivities([...newActivities, { startDate: '', endDate: '' }])
            }
        } else {
            setScheduleError('Please enter start and end schedule dates.')
        }
    }
    const handleRemoveField = (indexToRemove) => {
        setNewActivities(newActivities.filter((_, index) => index !== indexToRemove))
    }
    const updateActivityField = (type, index, value) => {
        const updatedValues = [...newActivities]
        updatedValues[index][type] = value;
        setNewActivities(updatedValues);
    }
    const updateActivity = (jobId) => {
        setActivityLoader(true)
        const jobActivity = [...newActivities]
        jobsServicev2.addActivity({ jobId, jobActivity }).then((response) => {
            if (response.success) {
                if (jobUpdated) jobUpdated(jobData.job.jobId)
                setSuccessMessage('Activity added successfully')
                setNewActivities([])
            } else {
                if (response.error) {
                    setErrorMessage(response.error)
                }
                if (response.code === 'ERR_BAD_REQUEST') {
                    setErrorMessage(response.message)
                } else if (response.code === "ERR_NETWORK") setErrorMessage(response.message);
            }
            setActivityLoader(false)
            setTimeout(() => {
                setErrorMessage(false)
            }, 2800);
        })
    }
    const validate = values => {
        const errors = {};
        if (values.quotation === '' || values.quotation === null || values.quotation === undefined) {
            errors.quotation = 'Bid Amount is required';
        }
        /*if (values.serialNo === '') {
            errors.serialNo = 'Serial Number is required';
        }
        if (values.quotation < 1) {
            errors.quotation = 'Bid Amount must be more then zero.';
        }*/
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            quotation: ''
        },
        validate,
        validateOnMount: true,
        onSubmit: values => {
            createBid(values)
        },
    });
    useEffect(() => {
        if (vendorBid && vendorBid.quotation && (formik.values.quotation === vendorBid.quotation || formik.values.quotation === '')) formik.values.quotation = vendorBid.quotation
    }, [vendorBid, formik.values])

    const createBid = (formVals) => {
        setSuccessMessage('')
        setErrorMessage('')
        setIsLoading(true)
        if (bidData) {
            const updatedBidData = { ...bidData }
            updatedBidData.quotation = formVals.quotation
            updatedBidData.bidCatlogs = updatedBidData.bidCatlogs.filter((item) => item.serialNo !== '' && item.serialNo !== null && item.serialNo !== undefined)
            if (jobData && vendorBid) {
                bidServicev2.updateBid({ ...updatedBidData, bidId: vendorBid.bidId }).then((response) => {
                    setUpdatingCatalog(false)
                    if (response.success) {
                        setSuccessMessage(response.data)
                        if(jobUpdated) jobUpdated(jobData.job.jobId)
                        /*jobsService.getJobById(jobData.job.jobId).then((response) => {
                            setTermData(response.data)
                        })*/
                    } else if (!response.success) {
                        setErrorMessage(response.error)
                    }
                    if (response.code === 'ERR_BAD_REQUEST') {
                        setErrorMessage(response.data.message)
                        return false
                    } else if (response.code === "ERR_NETWORK") {
                        setErrorMessage(response.message)
                    }
                    setIsLoading(false)
                })
            } else {
                bidServicev2.createBid(updatedBidData).then((response) => {
                    setUpdatingCatalog(false)
                    if (response.success) {
                        setSuccessMessage(response.data)
                        if(jobUpdated) jobUpdated(jobData.job.jobId)
                        /*jobsService.getJobById(jobData.job.jobId).then((response) => {
                            setTermData(response.data)
                        })*/
                    } else if (!response.success) {
                        setErrorMessage(response.error)
                    }
                    if (response.code === 'ERR_BAD_REQUEST') {
                        setErrorMessage(response.data.message)
                        return false
                    } else if (response.code === "ERR_NETWORK") {
                        setErrorMessage(response.message)
                    }
                    setIsLoading(false)
                })
            }
        }
    }

    const fleetAdded = (added, message) => {
        setSuccessMessage('')
        setErrorMessage('')
        if (added) {
            setSuccessMessage(message)
            /*FleetsService.getSerialNumberList().then((response) => {
                setSerialNumbers(response.data)
            })*/
        } else {
            setErrorMessage(message)
        }
    }

    const itemAdded = (added, message) => {
        setSuccessMessage('')
        setErrorMessage('')
        if (added) {
            setSuccessMessage(message)
        } else {
            setErrorMessage(message)
        }
    }

    const handleToggleGenerateOTP = (activityId) => {
        setToggleGenerateOTP(true)
        setActivityId(activityId)
        if(activityId && activityData) {
            const filteredActivity = activityData.filter((activity) => {
                return activity.activityId === activityId
            })
            setSelectedActivity(filteredActivity[0])
        }
        if(scrollSection) scrollSection(sectionRef.current.offsetTop + sectionRef.current.clientHeight)
    }

    return (
        <>
            <div className='modal-section pb-4' ref={sectionRef}>
                <div className={`modal-section-header`}>
                    <h4 className='modal-section-title mb-0'>{user && user.roles.includes('ROLE_VENDOR') &&'Bid & '}Activity Details</h4>
                    {user && user.roles.includes('ROLE_VENDOR') && <ul className='bid-info inline-bid-info mb-0 mt-0'>
                        <li>
                            <span>Your Last Bid</span>
                            <div>₹{(vendorBid && vendorBid.quotation) ? vendorBid.quotation : '0'}</div>
                        </li>
                    </ul>}
                </div>
                <div className='table hover-table activity-table'>
                    <div className='table-body'>
                        <div className='table-header'>
                            <div className='s-no'>S. No.</div>
                            <div className='date'>Start Date</div>
                            <div className='date'>End Date</div>
                            {jobData && jobData.job.budgetType === 'QUANTITY' && <div className='qty'>Qty</div>}
                            {user && user.roles.includes("ROLE_VENDOR") && ((vendorBid && vendorBid.status !== 'REJECTED') || !vendorBid) && <div className='action'>Fleet</div>}
                            {jobData.job.status === 'ASSIGNED' && ((vendorBid && vendorBid.status !== 'REJECTED') || !vendorBid) ? <div className='action-btn'>Action/Status</div> : null}
                        </div>
                        {activityData && activityData.map((schedule, index) =>
                            <JobActivityRow activity={schedule} key={index} sn={index} onSerialSelect={onSerialSelect} copiedFleet={copiedFleet} unsetCopiedFleet={unsetCopiedFleet} updateCatalog={updateCatalog} user={user} serialNumbers={serialNumbers}
                            bidStatus={vendorBid ? vendorBid.status : ''}
                                jobData={jobData} handleToggleGenerateOTP={() => handleToggleGenerateOTP(schedule.activityId)} activeclass={activityId && activityId === schedule.activityId ? 'active' : ''} />
                        )}
                        {selectedSerial && !fleetCopied && user && user.roles.includes("ROLE_VENDOR") && <p className='link ms-auto' onClick={handleCopyFleet}>Copy fleet to all activities</p>}
                    </div>
                </div>
                {user && user.roles.includes("ROLE_CUSTOMER") ? <>{newActivities.map((field, index) => (
                    <div key={index} className='d-flex justify-content-between align-items-center schedule-row flex-wrap w-948'>
                        <div className={`input-wrapper mat-input mb-0 icon-input timeenabled ${field.startDate ? 'date-selected' : ''}`}>
                            <DatePicker selected={field.startDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}
                                id={`jobStartControl-${index}`} autoComplete='off'
                                minDate={new Date()}
                                maxDate={new Date(jobData.job.endDate)}
                                minTime={(jobData.job.startDate && new Date(jobData.job.startDate).toDateString() === new Date(field.startDate).toDateString()) ? new Date().setHours(new Date(jobData.job.startDate).getHours(), 0) : new Date().setHours(0, 0)}
                                maxTime={(jobData.job.endDate && new Date(jobData.job.endDate).toDateString() === new Date(field.startDate).toDateString()) ? new Date().setHours(new Date(jobData.job.endDate).getHours(), 0) : new Date().setHours(23, 59)}
                                onFocus={(e) => e.target.readOnly = true}
                                onChange={(date) => updateActivityField('startDate', index, date)}
                                showTimeSelect
                                timeIntervals={60}
                                dateFormat="EEE, dd MMM, yyyy h:mm aa"
                                value={field.startDate}
                                onCalendarOpen={onPickerOpen}
                                onCalendarClose={onPickerClose}
                            />
                            <span className='icon icon-calendar'></span>
                            <label htmlFor={`jobStartControl-${index}`} className="form-label">Schedule Start Date*</label>
                        </div>
                        <div className={`input-wrapper mat-input mb-0 icon-input timeenabled ${field.endDate ? 'date-selected' : ''}`}>
                            <DatePicker selected={field.endDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}
                                id={`jobEndControl-${index}`} autoComplete='off'
                                minDate={field.startDate}
                                maxDate={new Date(jobData.job.endDate)}
                                minTime={(field.startDate && new Date(field.startDate).toDateString() === new Date(field.endDate).toDateString()) ? new Date().setHours(new Date(field.startDate).getHours(), 0) : new Date().setHours(0, 0)}
                                maxTime={(jobData.job.endDate && new Date(jobData.job.endDate).toDateString() === new Date(field.startDate).toDateString()) ? new Date().setHours(new Date(jobData.job.endDate).getHours(), 0) : new Date().setHours(23, 59)}
                                onFocus={(e) => e.target.readOnly = true}
                                onChange={(date) => updateActivityField('endDate', index, date)}
                                showTimeSelect
                                timeIntervals={60}
                                dateFormat="EEE, dd MMM, yyyy h:mm aa"
                                value={field.endDate}
                                //disabled={formik.values.schedules[index].startDate === ''}
                                onCalendarOpen={onPickerOpen}
                                onCalendarClose={onPickerClose}
                            />
                            <span className='icon icon-calendar'></span>
                            <label htmlFor={`jobEndControl-${index}`} className="form-label">Schedule End Date*</label>
                        </div>
                        {jobData.job.budgetType === 'QUANTITY' && <div className={`input-wrapper mat-input mb-0 w-110`}>
                            <input type="text" name="quantity" className="form-control" id={`quantityControl-${index}`} placeholder=' '
                                onChange={(e) => updateActivityField('quantity', index, e.target.value)}
                                value={field.quantity}
                            />
                            <label htmlFor={`quantityControl-${index}`} className="form-label">Quantity*</label>
                        </div>}
                        <button type="button" className='remove-btn' onClick={() => handleRemoveField(index)}><span className='icon icon-cross-white'></span></button>
                    </div>
                ))}
                    {scheduleError && <div className="error">{scheduleError}</div>}
                    {jobData.job.multiActivity && jobData.job.status === 'ASSIGNED' && <div className='d-flex justify-content-start'><button type='button' className='btn btn-secondary mb-3 me-2' onClick={handleAddField}>Add Activity</button>
                        <button type='button' className={`btn btn-primary mb-3 ${activityLoader ? 'disabled' : ''} ${newActivities.length === 0 ? 'disabled' : ''}`} onClick={() => updateActivity(jobData.job.jobId)}>Save Activities{activityLoader && <span className='loader loader-small ms-1'></span>}</button></div>}</> : null}
                {user && user.roles.includes("ROLE_VENDOR") && jobData && (jobData.job.status === 'ASSIGNED' || jobData.job.status === 'CREATED') && ((vendorBid && vendorBid.status !== 'REJECTED') || !vendorBid) && <div className='form job-term-form'>
                    {jobData && jobData.job.status === 'ASSIGNED' && <p>Bid value can not update once bid is accepted. Serial number can still get update.</p>}
                    <form onSubmit={formik.handleSubmit}>
                        {jobData ? <>
                            <p className='mt-2'>You need at least 1 equipment in your fleet to bid. Add new fleet <span className='link' onClick={() => showFleetModal(true)}>here</span></p>
                            <div className="input-wrapper w-540">
                                <label htmlFor="bidControl" className="form-label">Enter Bid Amount</label>
                                <div className='icon-input'>
                                    <input type="text" name="quotation" className={`form-control ${jobData && jobData.job.status === 'ASSIGNED' ? 'disabled' : ''}`} id="bidControl" placeholder=' '
                                        autoComplete='off'
                                        readOnly={jobData && ((jobData.job.status === 'ASSIGNED') || new Date(jobData.job.lastBidDate) < new Date())}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.quotation && (!isNaN(formik.values.quotation) ? Math.round(formik.values.quotation) : formik.values.quotation.split('').filter((char) => { return !isNaN(char) }).join(''))} />
                                    {(formik.touched.quotation && formik.errors.quotation) ? <div className='error'>{formik.errors.quotation}</div> : null}
                                    {jobData && ((new Date(jobData.job.startDate) - new Date(jobData.job.bidCloserDate)) / (1000 * 60 * 60) <= 12) ? <div className='error'>If your bid is selected, there is a less than a 12 hour window for mobilising to customer site. Bid with care.</div> : null}
                                    <span className='icon'>₹</span>
                                </div>
                            </div></> : null}
                        <button className={`btn btn-primary btn-auto ${isLoading ? 'disabled' : ''}`} type='submit'>{jobData && vendorBid ? 'Update' : 'Submit'} Bid/Fleet{isLoading && <span className='loader loader-small ms-1'></span>}</button>
                    </form>
                </div>}
                {/*toggleGenerateOTP && ((user && user.roles.includes("ROLE_CUSTOMER") && jobData && (jobData.job.status === 'ASSIGNED' || jobData.job.status === 'START_OTP_GENERATED' || jobData.job.status === 'STARTED' || jobData.job.status === 'END_OTP_GENERATED'))) && <StartEndOtp jobData={jobData} updateJob={jobUpdated} activityId={activityId} />*/}
            </div>
            {toggleGenerateOTP && (user && user.roles.includes("ROLE_CUSTOMER") && selectedActivity && (selectedActivity.status === 'ACTIVITY_CREATED' || selectedActivity.status === 'START_OTP_GENERATED' || selectedActivity.status === 'STARTED' || selectedActivity.status === 'END_OTP_GENERATED') && jobData && jobData.job.status === 'ASSIGNED') && <StartEndOtp jobData={jobData} updateJob={jobUpdated} activity={selectedActivity} />}

            {toggleGenerateOTP && (user && user.roles.includes("ROLE_VENDOR") && selectedActivity && (selectedActivity.status === 'ACTIVITY_CREATED' || selectedActivity.status === 'START_OTP_GENERATED' || selectedActivity.status === 'STARTED' || selectedActivity.status === 'END_OTP_GENERATED') && jobData && jobData.job.status === 'ASSIGNED') ? <EnterStartEndOtp jobData={jobData} otpSubmitted={jobUpdated} activity={selectedActivity} otpmode={selectedActivity.status === 'START_OTP_GENERATED' ? "STARTJOB" : selectedActivity.status === 'END_OTP_GENERATED' ? "ENDJOB" : null} /> : null}
            
            {user && user.roles.includes("ROLE_VENDOR") && viewFleetModal && <AddFleetModal showModal={showFleetModal} viewModal={viewFleetModal} fleetItem={fleetItem} fleetAdded={fleetAdded} itemAdded={itemAdded} />}
            {successMessage && <MToast color="green" duration={3000} icon="icon-check-green-rev">{successMessage}</MToast>}
            {errorMessage && <MToast color="red" duration={3000} icon="icon-cross-red-rev">{errorMessage}</MToast>}
        </>
    )
}

export default JobActivityTable