import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import { Link } from 'react-router-dom';
import MToast from '../MToast';
import { jobsServicev2 } from '../../../services/job.service-v2';

function ReviewModal({showModal, viewModal, jobData, jobPosted, jobFiles, tierMessage}) {
    const [show, setShow] = useState(false)
    const [data, setData] = useState(null)
    const [jobError, setJobError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        if(jobData) setData(jobData)
    }, [viewModal, jobData])
    const handleHide = () => {
        setJobError(null)
        setShow(false)
        if(showModal) showModal(false)
    }
    const saveJob = () => {
        setIsLoading(true)
        setJobError('')
        jobsServicev2.createJob(jobData).then((response) => {
            if(response.success){
                setTimeout(() => {
                    setIsLoading(false)
                    handleHide()
                }, 3000);
                if(jobPosted) jobPosted(true, response.data)
            } else{
                if(response.error){
                    setJobError(response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                    setJobError(response.message)
                } else if(response.code === "ERR_NETWORK") setJobError(response.message);
                setIsLoading(false)
            }
        })
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDateString = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return formattedDateString
    }
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal modal-large'>
        <Modal.Header>
            <Modal.Title>Review Job Details</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        {data && 
        <Modal.Body>
            <h3>{data.job_title}</h3>
            <div className='location'>{data.location}</div>
            <p>{data.description}</p>
            <ul className='bid-info divide-3'>
                <li>
                    <span>Bid Type</span>
                    <div>{data.bid_type}</div>
                </li>
                {data.bid_amount && <li>
                    <span>Bid Amount</span>
                    <div>{data.bid_amount}</div>
                </li>}
                {data.budget_type && <li>
                    <span>Budget Type</span>
                    <div>{data.budget_type}</div>
                </li>}
                <li>
                    <span>Category</span>
                    <div>{data.category}</div>
                </li>
                <li>
                    <span>Equipment Type</span>
                    <div>{data.equipment_type}</div>
                </li>
                {/*<li>
                    <span>Equipment Name</span>
                    <div>{data.equipment_name}</div>
                </li>*/}
                <li className='w-100'>
                    <span>Payment Terms</span>
                    <div>{tierMessage && tierMessage}</div>
                </li>
                <li>
                    <span>Last date to bid</span>
                    <div>{formatDate(data.last_bid_date)}</div>
                </li>
                <li>
                    <span>Job Award Date
                        <div className='tooltip-wrapper'>
                            <span className='icon icon-info-dark'></span>
                            <div className='tooltip'>If no bid is confirmed by this date the job will be moved to Expired Jobs and no further action will be possible.</div>
                        </div>
                    </span>
                    <div>{formatDate(data.bidCloserDate)}</div>
                </li>
                <li>
                    <span>Software</span>
                    <div>{data.software}</div>
                </li>
                <li>
                    <span>Job Timeline</span>
                    <div>{formatDate(data.start_date)} - {formatDate(data.end_date)}</div>
                </li>
                <li>
                    <span>Person Required</span>
                    <div>{data.person_required}</div>
                </li>
                <li>
                    <span>Bid Inclusions
                        <div className='tooltip-wrapper'>
                            <span className='icon icon-info-dark'></span>
                            <div className='tooltip'>Bid inclusions are overhead costs you expect the vendor to include in their quotation.</div>
                        </div>
                    </span>
                    <div>{data.bid_inclusion.map((item, index) => <span key={index}>{item} {index !== (data.bid_inclusion.length - 1)? ', ' : ''}</span>)}</div>
                </li>
                <li>
                    <span>Contact Name</span>
                    <div>{data.site_contact_name}</div>
                </li>
                <li>
                    <span>Contact Phone</span>
                    <div>{data.site_contact_mobile}</div>
                </li>
                <li>
                    <span>Contact Email</span>
                    <div>{data.site_contact_email}</div>
                </li>
                {jobFiles && <li className='w-100'>
                    <div>Attachment</div>
                        {jobFiles.map((item, key) => item && <Link className='attachment' to={item.doc.documentPath} target='_blank' key={key}>
                            <span className='icon icon-clip'></span>
                            {item.doc.documentPath.split('/')[item.doc.documentPath.split('/').length - 1]}
                        </Link>
                        )}
                </li>}
            </ul>
            {jobData && jobData.isScheduled && <div className='table hover-table activity-table'>
                <div className='table-body'>
                    <div className='table-header'>
                        <div>S. No.</div>
                        <div>Start Date</div>
                        <div>End Date</div>
                        {jobData.budget_type === 'Per Unit Quantity' && <div>Quantity</div>}
                    </div>
                    {data && data.schedules.map((schedule, index) =>
                        <div className='table-row' key={index}>
                            <div>{index+1}</div>
                            <div>{formatDate(schedule.startDate)}</div>
                            <div>{formatDate(schedule.endDate)}</div>
                            {schedule.quantity && <div>{schedule.quantity}</div>}
                        </div>
                    )}
                </div>
            </div>}
            <button type='button' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`} onClick={saveJob}>Post Job{isLoading && <div className='loader loader-medium'></div>}</button>
        </Modal.Body>}
    </Modal>
    {jobError && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{jobError}</MToast>}
    </>
  )
}

export default ReviewModal