import React, { useEffect, useState } from 'react'

function JobStatus({jobData}) {
    const [jobdata, setJobdata] = useState(null)
    useEffect(() => {
        if(jobData) setJobdata(jobData);
    }, [jobData])
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });
    }
  return (
    <div className='modal-section vendor-terms-section'>
        <h4 className='modal-section-title'>Job Status</h4>
        <ul className='job-status-timeline'>
            {jobdata && jobdata.map((item, index) =>(
                item.track.includes(item.jobId) ? <li className='completed' key={index}>
                    <div className='title'>
                        {item.track.split(' ').map((text) => {
                            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
                        }).join(' ')}
                    </div>
                    <div className='date'>{formatDate(item.createdAt)}</div>
                </li> : <li className='completed' key={index}>
                    <div className='title'>
                        {item.track.split('_').map((text) => {
                            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
                        }).join(' ')}
                    </div>
                    <div className='date'>{formatDate(item.createdAt)}</div>
                </li>
            ))}
            {/*<li>
                <div className='title'>Job Confirmed</div>
                <div className='date'>9 Feb 2023, 1:28 PM</div>
            </li>
            <li>
                <div className='title'>Job Started</div>
            </li>
            <li>
                <div className='title'>Job Completed</div>
            </li>*/}
        </ul>
    </div>
  )
}

export default JobStatus