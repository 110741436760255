import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import MToast from '../MToast';
import { jobsServicev2 } from '../../../services/job.service-v2';

function EnterStartEndOtp({ status, jobData, otpSubmitted, activity, otpmode }) {
    //const [jobStatus, setJobStatus] = useState(null)
    const [jobid, setJobId] = useState(null)
    //const [mode, setmode] = useState(null)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(null)
    const inputRef = useRef(null)
    useEffect(() => {
        //if(status) setJobStatus(status)
        if (jobData && jobData.job.jobId) setJobId(jobData.job.jobId)
        /*if(status && status === "START_OTP_GENERATED"){
            setmode('STARTJOB')
        } else if(status && status === "END_OTP_GENERATED"){
            setmode('ENDJOB')
        }*/
    }, [status, jobData])
    useEffect(() => {
        // Focus on the input element when the component mounts
        inputRef.current.focus();
    }, []);
    const verifyOTP = (otp) => {
        setIsLoading(true)
        let otpstr = '';
        for (let key in otp) {
            otpstr += otp[key];
        }
        if (otpmode === 'STARTJOB') {
            jobsServicev2.verifyJobStartOtp(otpstr, jobid, activity.activityId).then((response) => {
                if (response.success) {
                    setSuccessMessage(response.data)
                    setTimeout(() => {
                        if (otpSubmitted) otpSubmitted(jobid)
                    }, 3000);
                } else if (!response.success) {
                    setErrorMessage(response.error)
                }
                if (response.code === 'ERR_BAD_REQUEST') {
                    setErrorMessage(response.data.message)
                    return false
                } else if (response.code === "ERR_NETWORK") {
                    setErrorMessage(response.message)
                }
                setTimeout(() => {
                    setSuccessMessage('')
                    setErrorMessage('')
                }, 3000);
                setIsLoading(false)
            })
        } else {
            if (otpmode === 'ENDJOB') {
                jobsServicev2.verifyJobEndOtp(otpstr, jobid, activity.activityId).then((response) => {
                    if (response.success) {
                        setSuccessMessage(response.data)
                        setTimeout(() => {
                            if (otpSubmitted) otpSubmitted(jobid)
                        }, 3000);
                    } else if (!response.success) {
                        setErrorMessage(response.error)
                    }
                    if (response.code === 'ERR_BAD_REQUEST') {
                        setErrorMessage(response.data.message)
                        return false
                    } else if (response.code === "ERR_NETWORK") {
                        setErrorMessage(response.message)
                    }
                    setTimeout(() => {
                        setSuccessMessage('')
                        setErrorMessage('')
                    }, 3000);
                    setIsLoading(false)
                })
            }
        }
    }
    let isNumberKey = (evt) => {
        let charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode === 8) {
            if (evt.target.value !== '') formik.setFieldValue(evt.target.name, '')
            if (evt.target.value === '' && evt.target.previousElementSibling) {
                evt.target.previousElementSibling.focus()
            }
        }
        if (!(charCode >= 48 && charCode <= 57)) {
            formik.setFieldValue(evt.target.name, '')
            evt.preventDefault()
        }
    }
    let focusChange = (e) => {
        let charCode = (e.which) ? e.which : e.keyCode
        if (charCode !== 8) {
            if ((e.target.value.length).toString() === e.target.getAttribute("maxlength")) {
                if (e.target.nextElementSibling) e.target.nextElementSibling.focus()
            }
        }
    }
    const formik = useFormik({
        initialValues: { otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' },
        onSubmit: (values, { setSubmitting }) => {
            verifyOTP(values)
            setSubmitting(false);
        },
    });
    return (
        <>
            <div className='modal-section otp-section'>
                <div className='modal-section-header'>
                    <div>
                        <h4 className='modal-section-title'>Enter Job {activity.status === 'ASSIGNED' || activity.status === "START_OTP_GENERATED" ? 'Start' : activity.status === 'STARTED' || activity.status === "END_OTP_GENERATED" ? 'End' : null} OTP</h4>
                        <p className='lead-para mb-0'>Please ask customer for OTP to  {activity.status === 'ASSIGNED' || activity.status === "START_OTP_GENERATED" ? 'initiate' : activity.status === 'STARTED' || activity.status === "END_OTP_GENERATED" ? 'deliver' : null} the work.</p>
                    </div>
                </div>
                <div className='job-start'>
                    <div className='otp-form'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="input-wrapper">
                                <input type="text" name="otp1" className="form-control"
                                    onChange={formik.handleChange}
                                    ref={inputRef}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.otp1} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                />
                                <input type="text" name="otp2" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp2} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                />
                                <input type="text" name="otp3" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp3} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                />
                                <input type="text" name="otp4" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp4} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                />
                                <input type="text" name="otp5" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp5} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                />
                                <input type="text" name="otp6" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp6} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                />
                            </div>
                            {/*otpError && <div className='error'>{otpError}</div>}
                    {otpSuccess && <div className='success'>{otpSuccess}</div>*/}
                            <div className="link">Request for OTP</div>
                            <div>
                                <button type="submit" className={`btn btn-primary btn-auto mb-0 ${isLoading ? 'disabled' : ''}`}>Submit OTP & {activity.status === 'ASSIGNED' || activity.status === "START_OTP_GENERATED" ? 'Start' : activity.status === 'STARTED' || activity.status === "END_OTP_GENERATED" ? 'End' : null} Work{isLoading && <span className='loader loader-small ms-1'></span>}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {successMessage && <MToast color="green" duration={3000} icon="icon-check-green-rev">{successMessage}</MToast>}
            {errorMessage && <MToast color="red" duration={3000} icon="icon-cross-red-rev">{errorMessage}</MToast>}
        </>
    )
}

export default EnterStartEndOtp