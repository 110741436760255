import React, { useEffect, useState } from 'react'
import Thumb66 from '../../../images/thumb-66.png'
import { useFormik } from 'formik';
import { reviewService } from '../../../services/review.service';
import { jobsService } from '../../../services/jobs.service';
import MToast from '../MToast';

function RatingForm({ratingData, reviewUpdated}) {
    const [ratingdata, setRatingData] = useState('');
    const [ratingValue, setRatingValue] = useState(0);
    const [vendorData, setVendorData] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const ratingArr = [1,2,3,4,5]
    useEffect(() => {
        if(ratingData){
            setRatingData(ratingData)
            jobsService.getVendorById(ratingData.vendorId).then((response) => {
                setVendorData(response.data[0])
            })
        }
        //if(viewRatingModal) setShow(viewRatingModal)
    }, [ratingData])

    const rateVendor = (values) => {
        setIsLoading(true)
        reviewService.createReview(values).then((response) => {
            if(response.success){
                setSuccessMsg(response.data)
                if(reviewUpdated && ratingData) reviewUpdated(ratingData.jobId)
                /*setTimeout(() => {
                    setShow(false)
                }, 2800);*/
            } else if(!response.success){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg(response.data.message)
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 2800);
        })
    }
    const validate = values => {
        const errors = {};
        if (values.rating === '') {
            errors.rating = 'Rating is required';
        }
        /*if (values.comment === '') {
            errors.comment = 'Comment is required';
        }*/
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            customerId: ratingData.customerId, vendorId: ratingData.vendorId, jobId: ratingData.jobId, rating: '', comment: ''
        },
        validate,
        validateOnMount: true,
        onSubmit: values => {
            rateVendor(values)
        },
    });
    const handleRatingValue = (value) => {
        setRatingValue(value);
        formik.setFieldValue('rating', value)
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDateString = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return formattedDateString
    }
  return (
        <div className='modal-section pb-4'>
            <h4 className='modal-section-title'>Rate your experience</h4>
            <div className='rating-box'>
                {ratingdata && <div className='job-rating-info'>
                    <div className='content'>
                        <div className='request-no'>Job Id #{ratingdata.jobId}</div>
                        <h4>{ratingdata.jobTitle}</h4>
                        <span className='status'>Completed on {formatDate(ratingdata.endsOn)}</span>
                        {vendorData && <><h4 className='company'>{vendorData.companyName}</h4>
                        <span className='location'>{vendorData.location}</span></>}
                    </div>
                    <div className='image'>
                        {vendorData && <img src={vendorData.profileImage ? vendorData.profileImage : Thumb66} alt="Thumb" />}
                    </div>
                </div>}
                {!ratingData.reviewId ? <form onSubmit={formik.handleSubmit}>
                    <div className='rating-form form'>
                        <div className='ratings'>
                            <div className='rating-stars'>
                            {ratingArr && ratingArr.map((value) => 
                                <span key={value} onClick={() => handleRatingValue(value)} style={{ cursor: 'pointer'}}
                                className={`icon icon-star${ratingValue >= value ? '' : '-gray'}`}>
                                </span>)}
                            </div>
                            <div className='rating-text'>Tap to rate</div>
                            {(formik.touched.rating && formik.errors.rating) ? <div className='error w-100'>{formik.errors.rating}</div> : null}
                        </div>
                        <div className="input-wrapper">
                            <textarea name="comment" className={`form-control ${formik.values.comment !== '' ? 'textarea-filled' : ''}`} rows="3"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} value={formik.values.comment} id="ratingText" style={{'height': '60px'}} placeholder='Tell us what did you like'></textarea>
                            {(formik.touched.comment && formik.errors.comment) ? <div className='error'>{formik.errors.comment}</div> : null}
                        </div>
                        <button className={`btn btn-primary btn-small ${isLoading ? 'disabled' : ''}`} type='submit'>Submit Rating{isLoading && <div className='loader loader-medium'></div>}</button>
                    </div>
                </form> :
                <div className='payment-done payment-success'>
                    <div className='icon icon-check-green'></div>
                    <h3>Rating Done</h3>
                    <p>Thanks for providing rating.</p>
                </div>}
            </div>
            {successMsg && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMsg}</MToast>}
            {errorMsg && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMsg}</MToast>}
        </div>
  )
}

export default RatingForm