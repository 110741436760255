import React from 'react'

function MpaasPagination({totalPage, onPageChange, currentPage}) {
  //const [currentPage, setCurrentPage] = useState(1);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPage) {
      //setCurrentPage(pageNumber);
      if(onPageChange) onPageChange(pageNumber)
    }
  }
  const getPageRange = () => {
    const range = []
    let startPage = 1
    let endPage = totalPage - 2
    let maxStartPage = startPage + 6
    const currentPageNumber = currentPage
    if(totalPage<=10){
      for (let i = startPage; i <= totalPage; i++) {
        range.push(i)
      }
    } else{
      if(currentPageNumber > 6 && currentPageNumber < totalPage - 6){
        maxStartPage = Math.min(currentPage + 6, totalPage - 5)
        startPage = Math.min(currentPage, maxStartPage - 6)
      }
      if(currentPageNumber >= totalPage - 6){
        if(currentPageNumber === totalPage - 6){
          endPage = totalPage - 2
          maxStartPage = currentPage + 1
          startPage = currentPage - 5
        } else{
          endPage = totalPage - 6
          maxStartPage = 3
        }
      }
      for (let i = startPage; i <= maxStartPage; i++) {
        range.push(i)
      }
      range.push('...')
      for (let i = endPage; i <= totalPage; i++) {
        range.push(i)
      }
    }
    return range
  }

  return (
    <>{totalPage > 1 ? <div className='table-pagination' style={{ marginBottom: '20px' }}>
        <ul className="pagination mb-0">
        <li className="page-item"><button className="page-link prev" onClick={() => goToPage(1)} disabled={currentPage === 1}>First</button></li>
        <li className="page-item"><button className="page-link prev" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button></li>
        {getPageRange().map((pageNumber) => (
            <li key={pageNumber} className={currentPage === pageNumber ? 'page-item active' : 'page-item'}>
            <button onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber} className="page-link">
                {pageNumber}
            </button>
            </li>
        ))}
        <li className="page-item"><button className="page-link next" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPage}>Next</button></li>
        <li className="page-item"><button className="page-link next" onClick={() => goToPage(totalPage)} disabled={currentPage === totalPage}>Last</button></li>
        </ul>
    </div> : null}</>
  )
}

export default MpaasPagination