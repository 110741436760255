import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { externalServices } from '../../services/external.services';
import { authServices } from '../../services/auth.services';
import MToast from '../../shared/UI/MToast';
//import SelectDropdown from '../../shared/UI/SelectDropdown';
import termsPdfVendor from '../../images/terms-and-conditions-for-vendors.pdf';
import termsPdfCustomer from '../../images/terms-and-conditions-for-customers.pdf';

function UserRegister(props) {
    //const [tourStep, setTourStep] = useState(1);
    //const [dismissTour, setDismissTour] = useState(false);
    const [step, setStep] = useState(1);
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');
    const [gstError, setgstError] = useState('');
    const [gstChange, setgstChange] = useState('');
    const [companyError, setcompanyError] = useState('');
    const [user, setUser] = useState('customer');
    const [categoriesList, setCategoriesList] = useState('');
    const [loader, setLoader] = useState(false);
    const [toggleDrop, setToggleDrop] = useState(false);
    const [isLoading, setIsLoading] = useState(null)
    const [conditionAccept, setConditionAccept] = useState(false)
    const [agreementAccept, setAgreementAccept] = useState(false)
    const [toggleInputs, setToggleInputs] = useState(false)
    const [userExists, setUserExist] = useState(true)
    const pwdToggle = useRef(null);
    const categoryDropdown = useRef(null)
    const navigate = useNavigate()
    /*const steps = [
        {
          selector: '.first-step',
          content: 'Customer tells requirements for work by posting jobs!',
        },
        {
          selector: '.second-step',
          content: 'Vendor bids on jobs to get work!',
        },
        {
          selector: '.second-step',
          content: 'Company name will add automatically after you enter a valid GST Number!',
        }
    ]*/
    useEffect(() => {
        if(props.getStep) props.getStep(step)
        if(props.userType) props.userType(user)
    }, [props, step, user]);
    useEffect(() => {
        function handleClickOutside(event) {
          if (categoryDropdown.current && !categoryDropdown.current.contains(event.target)) {
            setToggleDrop(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [categoryDropdown]);
    /*const nextourStep = () => {
        if (tourStep < steps.length) {
          setTourStep(tourStep + 1);
        }
        if(tourStep === steps.length){
            handleDismissTour()
        }
    }
    const handleDismissTour = () => {
        setDismissTour(true)
    }*/
    const validate = values => {
        const errors = {};
        const passwordStrengthRegx=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,35}$/;
        if (!values.joining_as) {
            values.joining_as = 'vendor';
        }
        if (values.companygst_no === '') {
            formik.errors.companygst_no = 'Enter your company GST Number';
        }
        if((values.companygst_no).length !== 15){
            errors.companygst_no = 'Please provide valid GST number'
        }
        if (values.companyname === '') {
            errors.companyname = 'Company name as per the GST Number';
        }
        if (values.person_name === '') {
            errors.person_name = 'Please provide contact person name';
        }
        if (values.phone_no === '') {
            errors.phone_no = 'Please provide phone number';
        }
        if ((values.phone_no).length !== 10) {
            errors.phone_no = 'Phone number must be 10 digits';
        }
        if (values.email_address === '') {
            errors.email_address = 'Please provide email address';
        }
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_address)){
            errors.email_address = 'Email address is not valid'
        }
        if (values.password === '') {
            errors.password = 'Please provide password';
        } else if((values.password).length < 8){
            errors.password = 'Password must be at least 8 characters long'
        } else if(!(values.password).match(passwordStrengthRegx)){
            errors.password = 'Password must contain at least one numeric digit, lower uppercase letters and a special character'
        }
        if (values.confirm_password !== values.password) {
            errors.confirm_password = 'Password does not match';
        }
        if(user==='vendor'){
            /*if (values.subscription_type === '') {
                errors.subscription_type = 'Please select subscription type';
            }*/
            if (values.categories === '' || values.categories.length<=0) {
                errors.categories = 'At least one category is need to be selected';
            }
            /*if (values.multiOffices === '') {
                errors.multiOffices = 'Please provide no. of Office locations';
            }
            if (values.employeeCountUpto === '') {
                errors.employeeCountUpto = 'Please provide no. of employees';
            }*/
        }
        if(!userExists){
            if (values.address === '') {
                errors.address = 'Please provide address';
            }
            if (values.city === '') {
                errors.city = 'Please provide city';
            }
            if (values.state === '') {
                errors.state = 'Please provide state';
            }
            if (values.pin === '') {
                errors.pin = 'Please provide pin code';
            }
        }
        return errors;
    };
    let registerUser = (userInfo) => {
        setFormError('')
        setFormSuccess('')
        setIsLoading(true)
        authServices.registerUser(userInfo).then((response) => {
            try {
                setIsLoading(false)
                if(response.code === 'ERR_BAD_REQUEST'){
                    setFormError(response.response.data.message)
                } else if(response.code === "ERR_NETWORK") setFormError(response.message); 
                else if(response.data.error){
                    setFormError((response.data.error).replace('Username', 'Email Address'))
                }
                if(response.data && response.data.success) {
                    setFormSuccess(response.data.message)
                    setTimeout(() => {
                        navigate('/login#registered')
                    }, 400);
                }
            } catch(error) {
                if(error) setFormError(error.message)
                setIsLoading(false)
            }
        })
    }
    const formik = useFormik({
        initialValues: {
            joining_as: 'customer', companygst_no: '', companyname: '', registrationDate: '', multiOffices: '', employeeCountUpto: '',
            person_name: '', phone_no: '', email_address: '',
            password: '', confirm_password: '', categories: [], address: '', city: '', state: '', pin: ''
        },
        validate,
        validateOnMount: true,
        onSubmit: values => {
            registerUser(values);
        },
    });
    let handleNext = () => {
        setUser(formik.values.joining_as)
        if(!formik.errors.companygst_no && !formik.errors.companyname){
            if(!formik.errors.person_name && !formik.errors.phone_no && !formik.errors.email_address
                && !formik.errors.password && !formik.errors.confirm_password && user === "vendor" && step === 2){
                step !== 3 && setStep(3)
                getCategories()
            }
            step !== 2 && setStep(2)
        }
    }
    let handlePrev = () => {
        setFormError('')
        setFormSuccess('')
        let maxSteps = 3
        if(formik.values.joining_as === 'customer'){
            maxSteps = 2
        } else{
            maxSteps = 3
        }
        if(step > 1 && step <= maxSteps){
            setStep(step - 1)
        }
    }
    let getCompanyName = async (gstNo) => {
        setFormError('')
        if(!formik.errors.companygst_no && gstNo !== gstChange && user){
            setLoader(true)
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {action: 'submit'}).then(async function(token) {
                    if(token){
                        await externalServices.getCompanyNameByGST(gstNo, user).then((response) => {
                            if(response.success){
                                formik.setFieldValue('companyname', response.data.companyName)
                                formik.setFieldValue('registrationDate', response.data.companyRegistrationDate)
                                if(!response.compExists){
                                    setUserExist(false)
                                    formik.setFieldValue('address', response.data.adr.st + ', ' + response.data.adr.bno)
                                    formik.setFieldValue('city', response.data.adr.loc)
                                    formik.setFieldValue('state', response.data.adr.stcd)
                                    formik.setFieldValue('pin', response.data.adr.pncd)
                                }
                                setgstError('')
                                setcompanyError('')
                            } else{
                                formik.setFieldValue('companyname', '')
                                setgstError('Please provide correct GST number')
                                setcompanyError('Company name as per the GST Number')
                                if(response.code === "ERR_BAD_REQUEST"){
                                    setFormError(response.message + '- Please try again later')
                                  } else if(response && response.code === "ERR_NETWORK"){
                                    setFormError(response.message + '- Please check internet or try again later')
                                  }  else if(response && response.code){
                                    setFormError(response.response.data.error)
                                  }
                            }
                            setgstChange(gstNo)
                            setLoader(false)
                        }).catch((error) => {
                            setLoader(false)
                        })
                    }
                });
            });
        } else if(gstNo !== gstChange && gstNo.length < 15){
            formik.setFieldValue('companyname', '')
        }
    }
    let getCategories = async () => {
        await externalServices.getCategories().then((response) => {
            if(response.data){
                let categories = response.data
                setCategoriesList(categories)
            }
        })
    }
    let togglePassword = () => {
        const icon = pwdToggle.current.childNodes[1]
        const input = pwdToggle.current.childNodes[0]
        const attrType = input.getAttribute('type')
        if(attrType === 'text'){
            input.setAttribute('type', 'password')
        } else{
            input.setAttribute('type', 'text')
        }
        icon.classList.toggle('icon-eye')
        icon.classList.toggle('icon-eye-close')
    }
    let selectRadio = (e) => {
        e.preventDefault()
        let ele = e.currentTarget
        formik.setFieldValue('joining_as', ele.children[0].value)
        setUser(ele.children[0].value)
    }
    let toggleCategoryDropdown = (e) => {
        setToggleDrop(!toggleDrop)
    }
    const onCategoryChange = event => {
        const { value } = event.target;
        const { categories } = formik.values;
    
        if (categories.includes(value)) {
          formik.setFieldValue('categories', categories.filter(t => t !== value));
        } else {
          formik.setFieldValue('categories', [...categories, value]);
        }
    };
    const handleRegisterCondition = (e) => {
        if(e.target.checked){
            setConditionAccept(true)
        } else{
            setConditionAccept(false)
        }
    }
    const handleAgreement = (e) => {
        if(e.target.checked){
            setAgreementAccept(true)
        } else{
            setAgreementAccept(false)
        }
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search)
        if(searchParams.get('joinas') === 'vendor'){
            setUser('vendor')
        }
    }, [formik])
    return (
    <div className='form-stepper'>
        <div className='form width-540'>
            <form onSubmit={formik.handleSubmit}>
                {step === 1 ?
                    <div className='step'>
                        <div className="input-wrapper">
                            <label className="form-label">You are joining as </label>
                            <div className='user-types'>
                                <div className='user-type-box'>
                                    <div className={`user-type ${user === 'customer' ? 'active': ''}`} onClick={selectRadio}>
                                        <input type='radio' name="joining_as"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.joining_as === 'customer'}
                                        value='customer' />
                                        <span className={user === 'customer' ? 'icon icon-user-blue': 'icon icon-user'}></span>
                                        Customer
                                        {/*!dismissTour && tourStep === 1 && <div className="tourStep">
                                            {steps[tourStep - 1].content}
                                            <div className='tour-buttons'><button className='btn btn-light btn-blue' onClick={handleDismissTour}>Dismiss</button>
                                            <button className='btn btn-light btn-black' onClick={nextourStep}>Next</button></div>
                                        </div>*/}
                                    </div>
                                    <p className='help-text' style={{marginRight: '12px'}}><span className='icon icon-info-dark' style={{width: '12px', height: '12px'}}></span> Customer tells the requirements for work by posting jobs!</p>
                                </div>
                                <div className='user-type-box'>
                                    <div className={`user-type ${user === 'vendor' ? 'active': ''}`} onClick={selectRadio}>
                                        <input type='radio' name="joining_as"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.joining_as === 'vendor'}
                                        value='vendor' />
                                        <span className={user === 'vendor' ? 'icon icon-vendor-blue': 'icon icon-vendor'}></span>
                                        Vendor
                                        {/*!dismissTour && tourStep === 2 && <div className="tourStep">
                                            {steps[tourStep - 1].content}
                                            <div className='tour-buttons'><button className='btn btn-light btn-blue'onClick={handleDismissTour}>Dismiss</button>
                                            <button className='btn btn-light btn-black' onClick={nextourStep}>Next</button></div>
                                        </div>*/}
                                    </div>
                                    <p className='help-text' style={{marginRight: '12px'}}><span className='icon icon-info-dark' style={{width: '12px', height: '12px'}}></span> Vendor is a person who bids on jobs to get work!</p>
                                </div>
                            </div>
                        </div>
                        <div className="input-wrapper icon-input">
                            {/*!dismissTour && tourStep === 3 && <div className="tourStep">
                                {steps[tourStep - 1].content}
                                <div className='tour-buttons'>
                                <button className='btn btn-light btn-black ms-auto' onClick={handleDismissTour}>Finish</button></div>
                            </div>*/}
                            <label htmlFor="gstControl" className="form-label">Company GST Number</label>
                            <input type="text" name="companygst_no" className="form-control" id="gstControl" placeholder="Enter your company GST Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companygst_no}
                            onKeyUp={() => getCompanyName(formik.values.companygst_no)}
                            maxLength={15}
                            />
                            {(formik.touched.companygst_no && formik.errors.companygst_no) ? <div className='error'>{formik.errors.companygst_no}</div> : gstError ? <div className='error'>{gstError}</div> : null}
                            <p className='help-text'><span className='icon icon-info-dark'></span> Company name will automatically update when you will enter GST number.</p>
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="companyControl" className="form-label d-flex">Company Name {loader ? <span className='loader loader-small ms-1'></span> : null}</label>
                            <input type="text" name="companyname" className="form-control" id="companyControl" placeholder="Company name as per the GST Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyname}
                            readOnly
                            />
                            {(formik.touched.companyname && formik.errors.companyname) ? <div className='error'>{formik.errors.companyname}</div> : companyError ? <div className='error'>{companyError}</div> : null}
                        </div>
                        {!userExists && <div className='address-info-wrapper'>
                            {!toggleInputs && <button className='btn btn-light' onClick={() => setToggleInputs(true)}>Edit</button>}
                            {toggleInputs && <button className='btn btn-light' onClick={() => setToggleInputs(false)}>Save</button>}
                            <ul className='address-info bid-info'>
                                <li>
                                    <span>Address</span>
                                    <div>{toggleInputs ? 
                                        <div className="input-wrapper">
                                        <input type="text" name="address" className="form-control" id="addressControl" placeholder="Enter Address"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.address}
                                        />
                                        {(formik.touched.address && formik.errors.address) ? <div className='error'>{formik.errors.address}</div> : null}
                                    </div>
                                    : formik.values.address}</div>
                                </li>
                                <li>
                                    <span>City</span>
                                    <div>{toggleInputs ? 
                                        <div className="input-wrapper">
                                        <input type="text" name="city" className="form-control" id="cityControl" placeholder="Enter City"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.city}
                                        />
                                        {(formik.touched.city && formik.errors.city) ? <div className='error'>{formik.errors.city}</div> : null}
                                    </div>
                                    : formik.values.city}</div>
                                </li>
                                <li>
                                    <span>State</span>
                                    <div>{toggleInputs ? 
                                        <div className="input-wrapper">
                                        <input type="text" name="state" className="form-control" id="stateControl" placeholder="Enter State"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.state}
                                        />
                                        {(formik.touched.state && formik.errors.state) ? <div className='error'>{formik.errors.state}</div> : null}
                                    </div>
                                    : formik.values.state}</div>
                                </li>
                                <li>
                                    <span>Pin Code</span>
                                    <div>{toggleInputs ? 
                                        <div className="input-wrapper">
                                        <input type="text" name="pin" className="form-control" id="pinControl" placeholder="Enter Pin Code"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        maxLength={6}
                                        value={formik.values.pin ? (isNaN(formik.values.pin) ? Math.abs(formik.values.pin.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(formik.values.pin)) : ''}
                                        />
                                        {(formik.touched.pin && formik.errors.pin) ? <div className='error'>{formik.errors.pin}</div> : null}
                                    </div>
                                    : formik.values.pin}</div>
                                </li>
                            </ul>
                        </div>}
                    </div>
                : step === 2 ?
                <div className='step'>
                    <div className="input-wrapper">
                        <label htmlFor="NameControl" className="form-label">Contact Name</label>
                        <input type="text" name="person_name" className="form-control" id="NameControl" placeholder="Enter your full name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.person_name}
                        />
                        {(formik.touched.person_name && formik.errors.person_name) ? <div className='error'>{formik.errors.person_name}</div> : null}
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="phoneControl" className="form-label">Contact Phone Number</label>
                        <input type="text" name="phone_no" className="form-control" id="phoneControl" placeholder="Enter your contact number" maxLength={10}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone_no ? (isNaN(formik.values.phone_no) ? Math.abs(formik.values.phone_no.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(formik.values.phone_no)) : ''}
                        />
                        <div className="form-text">
                            We will send OTP to this Mobile Number to verify this account
                        </div>
                        {(formik.touched.phone_no && formik.errors.phone_no) ? <div className='error'>{formik.errors.phone_no}</div> : null}
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="emailControl" className="form-label">Email ID</label>
                        <input type="email" name="email_address" className="form-control" id="emailControl" placeholder="Enter your Email ID"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email_address}
                        />
                        <div className="form-text">
                            We will send OTP to this Email Address to verify this account
                        </div>
                        {(formik.touched.email_address && formik.errors.email_address) ? <div className='error'>{formik.errors.email_address}</div> : null}
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="passwordControl" className="form-label">Password</label>
                        <div className='show-hide-password' ref={pwdToggle}>
                            <input type="password" name="password" className="form-control" id="passwordControl" placeholder="Enter your password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            />
                            <span className="icon icon-eye" onClick={togglePassword}></span>
                        </div>
                        {(formik.touched.password && formik.errors.password) ? <div className='error'>{formik.errors.password}</div> : null}
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="confirmPasswordControl" className="form-label">Verify Password</label>
                        <input type="password" name="confirm_password" className="form-control" id="confirmPasswordControl" placeholder='Reenter your password'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirm_password}
                        />
                        {(formik.touched.confirm_password && formik.errors.confirm_password) ? <div className='error'>{formik.errors.confirm_password}</div> : null}
                    </div>
                </div>
                : (step === 3 && user === 'vendor') ?
                <div className='step'>
                    {/*<div className="input-wrapper">
                        <label htmlFor="subscriptionControl" className="form-label">Subscription Type</label>
                        <select name="subscription_type" className="form-select" id="subscriptionControl"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subscription_type}>
                            <option value="">Choose your subscription</option>
                            <option value="Yearly @Rs.180000">Yearly @Rs.180000</option>
                            <option value="Half-Yearly @Rs.100000">Half-Yearly @Rs.100000</option>
                        </select>
                        {(formik.touched.subscription_type && formik.errors.subscription_type) ? <div className='error'>{formik.errors.subscription_type}</div> : null}
                    </div>*/}
                    <div className="input-wrapper">
                        <label htmlFor="categoriesControl" className="form-label">Categories I work in</label>
                        <div className='category-dropdown-wrapper' ref={categoryDropdown}>
                            <div className='categorySelect' id='category-toggle' onClick={toggleCategoryDropdown}>Select the categories you work in</div>
                            {toggleDrop ?
                            <ul className='categories_dropdown'>
                                {categoriesList && categoriesList.map((category, index) =>
                                    <li key={index}>
                                        <input type='checkbox' className='form-check-input' value={category} id={category.split(" ").join('')} name="categories"
                                        checked={formik.values.categories.includes(category)}
                                        onChange={onCategoryChange}
                                        />
                                        <label htmlFor={category.split(" ").join('')}>{category}</label>
                                    </li>
                                )}
                            </ul> : null
                            }
                        </div>
                        {(formik.touched.categories && formik.errors.categories) ? <div className='error'>{formik.errors.categories}</div> : null}
                    </div>
                    {formik.values.categories !== '' && formik.values.categories !== undefined ? 
                    <div className='categories d-flex flex-wrap'>
                        {formik.values.categories.map((index, item) =>
                            <span className='chip-rounded-gray' key={index}>{formik.values.categories[item]}</span>
                        )}
                    </div> : null}
                    {/*<div className="input-wrapper">
                        <label htmlFor="officesControl" className="form-label">
                        No. of Office locations
                            <div className='tooltip-wrapper'>
                                <span className='icon icon-info-dark'></span>
                                <div className='tooltip'>No. of Office locations field does affects your rating.</div>
                            </div>
                        </label>
                        <SelectDropdown
                            disableMat={true}
                            options={['Single Location', 'Multiple Locations']}
                            labelText='Select no. of Office locations'
                            value={formik.values.multiOffices}
                            onChange={(option) => formik.setFieldValue('multiOffices', option)}
                        />
                        {(formik.touched.multiOffices && formik.errors.multiOffices) ? <div className='error'>{formik.errors.multiOffices}</div> : null}
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="employeeControl" className="form-label">
                        No. of employees
                            <div className='tooltip-wrapper'>
                                <span className='icon icon-info-dark'></span>
                                <div className='tooltip'>No. of employees field does affects your rating.</div>
                            </div>
                        </label>
                        <SelectDropdown
                            disableMat={true}
                            options={['0-5', '5-10', 'More than 10']}
                            labelText='Select no. of employees'
                            value={formik.values.employeeCountUpto}
                            onChange={(option) => formik.setFieldValue('employeeCountUpto', option)}
                        />
                        {(formik.touched.employeeCountUpto && formik.errors.employeeCountUpto) ? <div className='error'>{formik.errors.employeeCountUpto}</div> : null}
                    </div>*/}
                </div> : null}
                {(step === 3 || (step === 2 && user === 'customer')) ? <>
                <p><input type="checkbox" name="tandc" className="form-check-input" id="termsControl" placeholder=' ' onChange={handleRegisterCondition} checked={conditionAccept} autoComplete='off' /> <label htmlFor='termsControl' style={{maxWidth: 'calc(100% - 45px)'}}>I have read and agree to the <Link to={user === 'vendor' ? termsPdfVendor : termsPdfCustomer} target="_blank">Terms & Conditions</Link></label></p>
                <p><input type="checkbox" name="agreechk" className="form-check-input" id="agreementControl" placeholder=' ' onChange={handleAgreement} checked={agreementAccept} /> <label htmlFor='agreementControl' style={{maxWidth: 'calc(100% - 45px)'}}>I agree to allow MPAAS.ai to store and process my personal data.</label></p>
                <div className='stepper-buttons'>
                    <button type="button" className={`btn btn-secondary btn-full ${step === 1 ? 'disabled' : ''}`} onClick={handlePrev}>Back</button>
                    <button type="submit" className={`btn btn-primary btn-full ${isLoading || !conditionAccept || !agreementAccept ? 'disabled' : ''}`}>Create an Account{isLoading && <span className='loader loader-small ms-1'></span>}</button>
                </div>
                </> : null}
            </form>
            {(step === 1 || (step === 2 && user === 'vendor')) ? <div className='stepper-buttons'>
                <button type="button" className={`btn btn-secondary btn-full ${step === 1 ? 'disabled' : ''}`} onClick={handlePrev}>Back</button>
                <button type="button" className='btn btn-primary btn-full' onClick={handleNext}>Proceed</button>
            </div> : null}
            {formSuccess && <MToast color="green" duration={3500} icon="icon-check-green-rev">{formSuccess}</MToast>}{formError && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{formError}</MToast>}
        </div>
    </div>
    )
}

export default UserRegister