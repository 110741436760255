import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';

function ConfirmModal({showModal, viewModal, confirmMessage, confirmTitle, handleConfirm, isLoading, children}) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        if(viewModal) setShow(viewModal)
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false, '', '')
    }
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal center-modal modal-small'>
        <Modal.Header>
            <Modal.Title>{confirmTitle}</Modal.Title>
            <span className='close-modal icon icon-cross' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <p>{confirmMessage}</p>
            {children}
            <div className='d-flex justify-content-between'>
                <button className={`btn btn-secondary btn-auto`} onClick={handleHide} type='button'>No</button>
                <button className={`btn btn-primary btn-auto ${isLoading ? 'disabled' : ''}`} onClick={handleConfirm} type='button'>Yes{isLoading && <div className='loader loader-medium'></div>}</button>
            </div>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default ConfirmModal