import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AddMasterItem from '../../shared/UI/Modals/AddMasterItem'
import { FleetsService } from '../../services/fleets.service'

function AddItemModal({showModal, viewModal, itemAdded, itemData}) {
    const [show, setShow] = useState(false)
    const [brands, setBrands] = useState(null)
    const [itemtype, setItemType] = useState(null)
    const [itemNames, setItemNames] = useState(null)
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        FleetsService.getDistinctAttribute('ITEMTYPE').then((response) => {
            setItemType(response.data)
        })
        FleetsService.getDistinctAttribute('BRAND').then((response) => {
            setBrands(response.data)
        })
        FleetsService.getDistinctAttribute('EQUIPMENT').then((response) => {
            setItemNames(response.data)
        })
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
  return (
    <Modal show={show} onHide={handleHide} className='mpass-modal fleet-modal'>
        <Modal.Header>
            <Modal.Title>Add New Item</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <AddMasterItem itemAdded={itemAdded} brandsList={brands} equipmentsList={itemNames} equipmentTypeList={itemtype} itemData={itemData} mode={itemData ? 'EDIT' : 'ADD'} adminModify="true" />
        </Modal.Body>
    </Modal>
  )
}

export default AddItemModal