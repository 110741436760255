import React, { useState, useRef } from 'react'
import BannerBg from '../../images/side-banner.jpg'
import '../../scss/signup.scss'
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { authServices } from '../../services/auth.services';
import MToast from '../../shared/UI/MToast';
import ExternalWrapper from '../../shared/ExternalWrapper';

function ResetPassword() {
    const navigate = useNavigate()
    const [step, setStep] = useState(1);
    const [formError, setFormError] = useState(null)
    const [formSuccess, setFormsuccess] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const pwdToggle = useRef(null)
    const validate = values => {
        const errors = {};
        const passwordStrengthRegx=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,35}$/;
        if (values.email_address === '') {
            errors.email_address = 'Please provide email address';
        }
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_address)){
            errors.email_address = 'Email address is not valid'
        }
        if (values.password === '') {
            errors.password = 'Please provide password';
        } else if((values.password).length < 8){
            errors.password = 'Password must be at least 8 characters long'
        } else if(!(values.password).match(passwordStrengthRegx)){
            errors.password = 'Password must contain at least one numeric digit, lower uppercase letters and a special character'
        }
        if (values.confirm_password !== values.password) {
            errors.confirm_password = 'Password does not match';
        }
        if (values.otp1 === '' || values.otp1 === '' || values.otp2 === '' || values.otp3 === '' || values.otp4 === '' || values.otp5 === '' || values.otp6 === '') {
            errors.otp = 'Please provide correct OTP';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            email_address: '', password: '', confirm_password: '', otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: ''
        },
        validate,
        validateOnMount: true,
        onSubmit: values => {
            resetPassword(values);
        },
    });
    const handleNext = () => {
        formik.validateForm()
        setFormError('')
        setFormsuccess('')
        setIsLoading(true)
        if(!formik.errors.email_address){
            authServices.resetPasswordOtp(formik.values.email_address).then((response) => {
                if(response && response.success){
                    step !== 2 && setStep(2)
                    setFormsuccess(response.data)
                }else{
                    if(response && response.error) setFormError(response.error)
                    else if(response.code === "ERR_BAD_REQUEST") setFormError('Bad Request')
                    else if(response.code === "ERR_NETWORK") setFormError(response.message)
                }
                setIsLoading(false)
            })
        }
    }
    let handlePrev = () => {
        let maxSteps = 2
        if(step > 1 && step <= maxSteps){
            setStep(step - 1)
        }
    }
    const resetPassword = (credentials) => {
        setFormError('')
        setFormsuccess('')
        setIsLoading(true)
        authServices.resetPassword(credentials).then((response) => {
            setIsLoading(false)
            if(response.code === "ERR_BAD_REQUEST") setFormError('Invalid credentials')
            else if(response.code && response.code !== 'ERR_BAD_REQUEST') setFormError(response.message)
            else{
                if(response.success){
                    setFormsuccess(response.message)
                    navigate('/login#resetsuccess')
                } else if(response.error){
                    setFormError(response.error)
                }
                /*if(response.username && response.status==='INITIALIZE'){
                    navigate('/verify-otp')
                } else if(response.username && response.status==='ONBOARDED'){
                    if(response.roles[0] === 'ROLE_CUSTOMER'){
                        navigate('/customer/dashboard')
                    }
                    if(response.roles[0] === 'ROLE_VENDOR'){
                        navigate('/vendor/my-jobs')
                    }
                } else{
                    navigate('/login')
                }*/
            }
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
        })
    }
    let isNumberKey = (evt) => {
        let charCode = (evt.which) ? evt.which : evt.keyCode
        if(charCode === 8){
          formik.setFieldValue(evt.target.name, '')
          if(evt.target.previousElementSibling) {
            evt.target.previousElementSibling.focus()
          }
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57)){
          formik.setFieldValue(evt.target.name, '')
          evt.preventDefault()
        }
    }
    let focusChange = (e) => {
        if ((e.target.value.length).toString() === e.target.getAttribute("maxlength")) {
            if(e.target.nextElementSibling) e.target.nextElementSibling.focus()
        }
    }
    let togglePassword = () => {
        const icon = pwdToggle.current.childNodes[1];
        const input = pwdToggle.current.childNodes[0];
        const attrType = input.getAttribute("type");
        if (attrType === "text") {
            input.setAttribute("type", "password");
        } else {
            input.setAttribute("type", "text");
        }
        icon.classList.toggle("icon-eye");
        icon.classList.toggle("icon-eye-close");
    };
  return (
    <ExternalWrapper
        title="Discover India's Top Measurement, Design and 3D Printing service providers"
        bgImg={BannerBg}>
        <Fade delay={1500}>
            <div className='login-menu text-end'>
                <span className='login-menu-text'>Don't have an Account? </span><Link to='/' className='btn btn-secondary'>Create New Account</Link>
            </div>
        </Fade>
        <Fade delay={1800}>
            <div className='form-wrapper'>
                <div className='heading-wrapper'>
                    <h2>Reset Password</h2>
                </div>
                <div className='form width-540'>
                    <form onSubmit={formik.handleSubmit}>
                        {step === 1 ?
                        <>
                        <div className="input-wrapper">
                            <label htmlFor="usernameControl" className="form-label">Email Address</label>
                            <input type="email" name="email_address" className="form-control" id="usernameControl" placeholder="Please enter your registered email address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email_address}
                            />
                            <div className='error'>{formik.errors.email_address && formik.touched.email_address && formik.errors.email_address}</div>
                        </div></> : step === 2 ?
                        <>
                        <div className="input-wrapper">
                            <label htmlFor="passwordControl" className="form-label">Password</label>
                            <div className="show-hide-password" ref={pwdToggle}>
                            <input type="password" name="password" className="form-control" id="passwordControl" placeholder="*********"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            />
                            <span className="icon icon-eye" onClick={togglePassword}></span>
                            </div>
                            <div className='error'>{formik.errors.password && formik.touched.password && formik.errors.password}</div>
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="repasswordControl" className="form-label">Re-Enter Password</label>
                            <input type="password" name="confirm_password" className="form-control" id="repasswordControl" placeholder="*********"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirm_password}
                            />
                            <div className='error'>{formik.errors.confirm_password && formik.touched.confirm_password && formik.errors.confirm_password}</div>
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="otpControl" className="form-label">Enter Received OTP</label>
                            <div className='otp-form'>
                                <div className="input-wrapper">
                                    <input type="text" name="otp1" className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.otp1} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                    />
                                    <input type="text" name="otp2" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp2} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                    />
                                    <input type="text" name="otp3" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp3} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                    />
                                    <input type="text" name="otp4" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp4} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                    />
                                    <input type="text" name="otp5" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp5} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                    />
                                    <input type="text" name="otp6" className="form-control"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.otp6} maxLength={1} minLength={1} onKeyDown={isNumberKey} onKeyUp={focusChange}
                                    />
                                </div>
                            </div>
                        </div>
                        </>
                        : null}
                        <div className='stepper-buttons'>
                        <button type="button" className={`btn btn-secondary btn-full ${step === 1 ? 'disabled' : ''}`} onClick={handlePrev}>Back</button>
                            {step === 2 ? <button type="submit" className={`btn btn-primary btn-full ${isLoading ? 'disabled' : ''}`}>
                                Reset Password{isLoading && <span className='loader loader-small ms-1'></span>}
                            </button> :
                            <button type="button" className={`btn btn-primary btn-full ${isLoading ? 'disabled' : ''}`} onClick={handleNext}>
                                Next{isLoading && <span className='loader loader-small ms-1'></span>}
                            </button>}
                        </div>
                        {formSuccess && <MToast color="green" duration={3500} icon="icon-check-green-rev">{formSuccess}</MToast>}
                        {formError && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{formError}</MToast>}
                    </form>
                </div>
            </div>
        </Fade>
    </ExternalWrapper>
  )
}

export default ResetPassword