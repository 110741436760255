import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import FleetCard from '../Profile/FleetCard';
import { FleetsService } from '../../../services/fleets.service';
import MToast from '../MToast';
//import AddFleetModal from './AddFleetModal';

function AllFleetModal({showModal, viewModal}) {
    const [show, setShow] = useState(false)
    const [fleets, setFleets] = useState('')
    //const [fleetItem, setFleetItem] = useState('')
    const [fleetError, setFleetError] = useState('')
    const [fleetSuccess, setFleetSuccess] = useState('')
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        getFleets()
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const isEditFleet = (item) => {
        //setFleetItem(item)
        //SetViewModal(true)
    }
    const fleetDeleted = (deleted) => {
        if(deleted){
          getFleets()
        }
    }
    const getFleets = () => {
        setFleetError('')
        setFleetSuccess('')
        FleetsService.getFleets().then((response) => {
          if(response.success){
              setFleets(response.data)
          } else if(!response.success){
            setFleetError(response.error)
          }
          if(response.code === 'ERR_BAD_REQUEST'){
            setFleetError(response.data.message)
              return false
          } else if(response.code === "ERR_NETWORK") {
            setFleetError(response.message)
          }
        })
    }
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal modal-w874'>
        <Modal.Header>
            <Modal.Title>My Fleet ({fleets ? fleets.length : 0})</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <div className='reviews-list fleet-list-medium'>
            {fleets && fleets.map((item, index) => <FleetCard key={index} fleetItem={item} isEditFleet={isEditFleet} fleetDeleted={fleetDeleted} /> )}
            </div>
        </Modal.Body>
    </Modal>
    {/*viewFleetModal && <AddFleetModal showModal={showModal} viewModal={viewModal} fleetItem={fleetItem} fleetAdded={fleetAdded} itemAdded={itemAdded} />*/}
    {fleetSuccess && <MToast color="green" duration={2000} icon="icon-check-green-rev">{fleetSuccess}</MToast>}
    {fleetError && <MToast color="red" duration={2000} icon="icon-cross-red-rev">{fleetError}</MToast>}
    </>
  )
}

export default AllFleetModal