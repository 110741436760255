import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import MToast from '../MToast';
import { adminServices } from '../../../services/admin.service';

function JobDetailReportModal({showModal, viewModal, jobId}) {
    const [show, setShow] = useState(false)
    const [jobDetails, setJobDetails] = useState([])
    //const [isLoading, setIsLoading] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        if(viewModal) setShow(viewModal)
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const getJobDetails = useCallback(() => {
        if(jobId){
          //setIsLoading(true)
          adminServices.getJobDetailsReport(jobId).then((response) => {
              if(response.data){
                  setJobDetails(response.data)
              } else if(!response.success){
                setErrorMessage(response.error)
              }
              if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage('Bad Request')
              } else if(response.code === "ERR_NETWORK") {
                  setErrorMessage(response.message)
              }
              //setIsLoading(false)
            setTimeout(() => {
                setErrorMessage('')
            }, 3500);
          })
        }
    }, [jobId])
    useEffect(() => {
        if(jobId) getJobDetails()
    }, [getJobDetails, jobId])
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal modal-large'>
        <Modal.Header>
            <Modal.Title>Job Report</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <h4 style={{fontSize: '18px'}}>Vendors Available: <small>{jobDetails['VendorCount']}</small></h4>
            {jobDetails && Object.keys(jobDetails).length > 0 ? Object.keys(jobDetails).map((item, index) => <>{item !== 'VendorCount' && <div key={index} className={`job-${item} job-item`}>
            <h3>{item}</h3>
            {item === 'Bid' ? <div className={`table hover-table job-details-table mobile-responsive`}>
                <div className='table-body'>
                    <div className='table-header'>
                        <div>Quotation</div>
                        <div>Vendor Name</div>
                        <div>Vendor Email</div>
                        <div>Vendor Mobile</div>
                        <div>Status</div>
                    </div>
                    {jobDetails[item] && jobDetails[item].map((item, index) => (
                        <div className='table-row' key={index}>
                          <div>{item.quotation}</div>
                          <div>{item.vendor && item.vendor.vendorName ? item.vendor.vendorName : null}</div>
                          <div>{item.vendor && item.vendor.VendorContact ? item.vendor.VendorContact[0].vendorEmail : null}</div>
                          <div>{item.vendor && item.vendor.VendorContact ? item.vendor.VendorContact[0].vendorMobile : null}</div>
                          <div>{item.status === 'CREATE' ? 'Bid Recd': item.status}</div>
                        </div>
                    ))}
                </div>
                </div> : item === 'job' ? <ul className={`bid-info divide-3`}>
                    <li><span>Job Title</span><div>{jobDetails[item].jobTitle}</div></li>
                    {jobDetails[item].assuredQuantity ? <li><span>Assured Quantity</span><div>{jobDetails[item].assuredQuantity}</div></li> : null}
                    {jobDetails[item].bidAmount ? <li><span>Amount</span><div>{jobDetails[item].bidAmount}</div></li>: null}
                    <li><span>Bid Inclusion</span><div>{JSON.parse(jobDetails[item].bidInclusion).map((item, index) =>  (index !== 0 ? ', ' : '') + item)}</div></li>
                    <li><span>Bid Type</span><div>{jobDetails[item].bidType}</div></li>
                    {jobDetails[item].brand && <li><span>Brand</span><div>{JSON.parse(jobDetails[item].brand).map((item, index) =>  (index !== 0 ? ', ' : '') + item)}</div></li>}
                    <li><span>Budget Type</span><div>{jobDetails[item].budgetType}</div></li>
                    <li><span>Category</span><div>{jobDetails[item].category}</div></li>
                    <li><span>Created At</span><div>{jobDetails[item].createdAt}</div></li>
                    <li><span>Created By</span><div>{jobDetails[item].createdBy}</div></li>
                    <li><span>Last Bid Date</span><div>{jobDetails[item].lastBidDate}</div></li>
                    <li><span>Bid Award Date</span><div>{jobDetails[item].bidCloserDate}</div></li>
                    <li><span>Job Start Date</span><div>{jobDetails[item].startDate}</div></li>
                    <li><span>Job End Date</span><div>{jobDetails[item].endDate}</div></li>
                    <li><span>Customer Id</span><div>{jobDetails[item].customerId}</div></li>
                    <li><span>Description</span><div>{jobDetails[item].description}</div></li>
                    {jobDetails[item].docUrls && JSON.parse(jobDetails[item].docUrls).length > 0 && <li><span>Docs</span><div>{JSON.parse(jobDetails[item].docUrls).map((item, index) =>  (index !== 0 ? ', ' : '') + item)}</div></li>}
                    <li><span>Equipment Type</span><div>{jobDetails[item].equipmentType}</div></li>
                    <li><span>Location</span><div>{jobDetails[item].location}</div></li>
                    <li><span>Is RC Job</span><div>{jobDetails[item].multiActivity ? 'Yes' : 'No'}</div></li>
                    <li><span>Person Required</span><div>{jobDetails[item].personRequired ? 'Yes' : 'No'}</div></li>
                    <li><span>Site Contact Name</span><div>{jobDetails[item].siteContactName}</div></li>
                    <li><span>Site Contact Mobile</span><div>{jobDetails[item].siteContactMobile}</div></li>
                    <li><span>Site Contact Email</span><div>{jobDetails[item].siteContactEmail}</div></li>
                    {jobDetails[item].software && <li><span>Software</span><div>{jobDetails[item].software}</div></li>}
                    <li><span>Job Status</span><div>{jobDetails[item].status}</div></li>
                </ul> : <div className={`job-details`}>
                {jobDetails[item] && jobDetails[item].map((item, index) => (
                  <ul className='bid-info' key={index}>
                    {Object.keys(item).map((itemDetails, index) =>
                    <React.Fragment key={index}>
                      {itemDetails=== 'CustomerContact' ?
                        <div className={`table hover-table`}>
                          <div className='table-body'>
                              <div className='table-header'>
                                  <div>Email</div>
                                  <div>Mobile</div>
                              </div>
                              {item[itemDetails].map((contactData, index) => (
                                <div className='table-row' key={index}>
                                  <div>{contactData.customerEmail}</div>
                                  <div>{contactData.customerMobile}</div>
                                </div>
                              ))}
                          </div>
                        </div>
                        : itemDetails === 'VendorContact' ?
                          <div className={`table hover-table`}>
                            <div className='table-body'>
                                <div className='table-header'>
                                    <div>Email</div>
                                    <div>Mobile</div>
                                </div>
                                {item[itemDetails].map((contactData, index) => (
                                  <div className='table-row' key={index}>
                                    <div>{contactData.vendorEmail}</div>
                                    <div>{contactData.vendorMobile}</div>
                                  </div>
                                ))}
                            </div>
                          </div>
                      : <li><span>{itemDetails}</span><div>{item[itemDetails]}</div></li>}
                      </React.Fragment>
                    )}
                  </ul>
                ))}
            </div>}
            </div>}</>) : null}
        </Modal.Body>
    </Modal>
    {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </>
  )
}

export default JobDetailReportModal