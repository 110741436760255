import React, { useRef, useState } from 'react'
import MToast from '../MToast';
import { jobsServicev2 } from '../../../services/job.service-v2';

function StartEndOtp({jobData, updateJob, activity}) {
    //const [jobdata, setJobData] = useState(null)
    const [otp, setotp] = useState(null)
    const [serialNo, setSerialNo] = useState('')
    const [jobHours, setJobHours] = useState('')
    const [jobMinutes, setJobMinutes] = useState('')
    const [jobShifts, setJobShifts] = useState('')
    const [jobQuantity, setJobQuantity] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(null)
    const [conditionAccept, setConditionAccept] = useState(false)
    const copyRef = useRef()
    /*useEffect(() => {
        //if(jobData) setJobData(jobData)
        if(jobData && jobData.job.startOtp && jobData.job.status === 'START_OTP_GENERATED') {
            if(jobData && jobData.job.serialNo) setSerialNo(jobData.job.serialNo)
            jobData.job.startOtp.length === 6 ? setotp((jobData.job.startOtp).split('')) : setErrorMessage('Invalid OTP String.')
        } else if(jobData && jobData.job.endOtp){
            jobData.job.endOtp.length === 6 ? setotp((jobData.job.endOtp).split('')) : setErrorMessage('Invalid OTP String.')
            if(jobData && jobData.job.servedHour) setJobHours(jobData.job.servedHour)
            if(jobData && jobData.job.servedMinute) setJobMinutes(jobData.job.servedMinute)
        }
    }, [jobData])*/
    const generateOtp = () => {
        if(activity.serialNo !== null  && activity.serialNo !== undefined && activity.serialNo !== ''){
            if(activity.status === 'ACTIVITY_CREATED' || activity.status === 'START_OTP_GENERATED'){
                if(serialNo){
                    jobsServicev2.generateStartJobOtp(serialNo, jobData.job.jobId, activity.activityId).then((response) => {
                        if(response.success){
                            setSuccessMessage('Start OTP generated successfully')
                            setotp((response.data).split(''))
                            setTimeout(() => {
                                if(updateJob) updateJob(jobData.job.jobId)
                            }, 3000);
                        } else if(!response.success){
                            setErrorMessage(response.error)
                        }
                        if(response.code === 'ERR_BAD_REQUEST'){
                            setErrorMessage('Bad Request. Please try again later.')
                        } else if(response.code === "ERR_NETWORK") {
                            setErrorMessage(response.message)
                        }
                        setIsLoading(false)
                    })
                } else {
                    setErrorMessage('Serial number is required')
                    setIsLoading(false)
                }
            } else if(activity.status === 'STARTED' || activity.status === "END_OTP_GENERATED"){
                //if((jobData.job.bidType === 'FIXED_BUDGET' && jobData.job.budgetType==="PERPROJECT") || (jobHours !== null && jobMinutes !== null && jobHours !== undefined && jobMinutes !== undefined && jobHours !== '' && jobMinutes !== '' && !isNaN(jobHours) && !isNaN(jobMinutes))){
                let jobValue
                if(jobData.job.budgetType==="PERPROJECT" || jobData.job.budgetType==="HOURLY"){
                    if(jobData.job.budgetType==="PERPROJECT" && !jobHours){
                        jobValue = 0
                    } else if((jobData.job.budgetType==="HOURLY" || jobData.job.budgetType==="PERPROJECT") && jobHours){
                        jobValue = jobHours
                    } else{
                        setErrorMessage('Job hours are required')
                        return false
                    }
                }
                if(jobData.job.budgetType==="PERSHIFT" || jobData.job.budgetType==="PERDAY"){
                    if(jobShifts){
                        jobValue = jobShifts
                    } else{
                        setErrorMessage('No. of shifts is required')
                        return false
                    }
                }
                if(jobData.job.budgetType==="QUANTITY" && jobQuantity){
                    if(jobQuantity){
                        jobValue = jobQuantity
                    } else{
                        setErrorMessage('Quantity is required')
                        return false
                    }
                }
                setIsLoading(true)
                if(jobData && jobData.job.budgetType && (jobData.job.budgetType==="PERPROJECT" || jobValue)){
                    jobsServicev2.generateEndJobOtp(jobData.job.budgetType, jobValue, jobData.job.jobId, activity.activityId).then((response) => {
                        if(response.success){
                            setSuccessMessage('End OTP generated successfully.')
                            setotp((response.data).split(''))
                            setTimeout(() => {
                                if(updateJob) updateJob(jobData.job.jobId)
                            }, 3000);
                        } else if(!response.success){
                            setErrorMessage(response.error)
                        }
                        if(response.code === 'ERR_BAD_REQUEST'){
                            setErrorMessage('Bad Request. Please try again later.')
                        } else if(response.code === "ERR_NETWORK") {
                            setErrorMessage(response.message)
                        }
                        setIsLoading(false)
                    })
                } else {
                    setErrorMessage('Job value is required.')
                    setIsLoading(false)
                }
            }
        } else {
            setErrorMessage('There is no fleet assigned with this activity.')
        }
        setTimeout(() => {
            setSuccessMessage('')
            setErrorMessage('')
            setIsLoading(false)
        }, 3500);
    }
    const copyOtp = async () => {
        let otpString = otp ? otp.join('') : null
        await navigator.clipboard.writeText(otpString)
        copyRef.current.innerText = "Copied"
        setTimeout(() => {
            copyRef.current.innerText = "Copy Text"
        }, 1000);
    }
    const handleJobHours = (e) => {
        if(isNaN(e.target.value)){
            setErrorMessage('Job hours must be a number')
            setJobHours('')
        } else{
            setJobHours(Math.abs(e.target.value))
        }
        setTimeout(() => {
            setErrorMessage('')
        }, 3000);
    }
    const handleJobMinutes = (e) => {
        if(isNaN(e.target.value)){
            setErrorMessage('Job minutes must be a number')
            setJobMinutes('')
        } else{
            if(Math.round(e.target.value) > 59){
                setErrorMessage("Minutes can't be more then 59")
                setJobMinutes(59)
            } else{
                setJobMinutes(Math.round(e.target.value))
            }
        }
        setTimeout(() => {
            setErrorMessage('')
        }, 3000);
    }
    const handleOtpCondition = (e) => {
        if(e.target.checked){
            setConditionAccept(true)
        } else{
            setConditionAccept(false)
        }
    }
  return (
    <>
    <div className='modal-section otp-section'>
        <h4 className='modal-section-title mb-0'>Generate Job {(activity && (activity.status === 'ACTIVITY_CREATED' || activity.status === 'START_OTP_GENERATED')) ? 'Start' : activity && (activity.status === "STARTED" || activity.status === 'END_OTP_GENERATED') ?  'End' : ''} OTP</h4>
        <p className='lead-para'>This OTP will be require for vendor to {activity && (activity.status === 'ACTIVITY_CREATED') ? 'start' : 'end'} the job </p>
        {activity && (activity.status === 'ACTIVITY_CREATED' || activity.status === 'START_OTP_GENERATED') ?
            <div className='job-start'>
                <div className="input-wrapper w-540 mat-input">
                    <input type="text" name="serialno" className="form-control" id="serialControl" placeholder=' ' autoComplete='off' value={serialNo} onChange={(e) => setSerialNo(e.target.value)} />
                    <label htmlFor="serialControl" className="form-label">Serial Number</label>
                </div>
                <p>If serial number verification fails, please ask vendor to check serial number available in fleet and update the new serial no in bid, if serial number not available.</p>
            </div> : null
        }

        {activity && (activity.status === 'STARTED' || activity.status === "END_OTP_GENERATED") ?
            <div className='job-end'>
                <label className='form-label'>Enter total time taken for the job</label>
                {jobData && (jobData.job.budgetType==="PERPROJECT" || jobData.job.budgetType==="HOURLY") && <div className='inline-inputs'>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="job_hours" className="form-control" id="hoursControl" placeholder=' ' autoComplete='off' value={jobHours} onChange={(e) => handleJobHours(e)} />
                        <label htmlFor="hoursControl" className="form-label">Enter Hours{jobData.job.bidType === 'FIXED_BUDGET' && jobData.job.budgetType==="PERPROJECT" ? "" : "*"}</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="job_minutes" className="form-control" id="minutesControl" placeholder=' ' autoComplete='off' value={jobMinutes} onChange={(e) => handleJobMinutes(e)} />
                        <label htmlFor="minutesControl" className="form-label">Enter Minutes{jobData.job.bidType === 'FIXED_BUDGET' && jobData.job.budgetType==="PERPROJECT" ? "" : "*"}</label>
                    </div>
                </div>}
                {jobData && jobData.job.budgetType === 'QUANTITY' && <div className='inline-inputs'>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="job_quantity_no" className="form-control" id="quantityControl" placeholder=' ' autoComplete='off' value={jobQuantity} onChange={(e) => setJobQuantity(isNaN(e.target.value) ? Math.abs(e.target.value.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(e.target.value))} />
                        <label htmlFor="quantityControl" className="form-label">Enter Quantity</label>
                    </div>
                </div>}
                {jobData && (jobData.job.budgetType === 'PERSHIFT' || jobData.job.budgetType === 'PERDAY') && <div className='inline-inputs'>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="job_shifts" className="form-control" id="shiftControl" placeholder=' ' autoComplete='off' value={jobShifts} onChange={(e) => setJobShifts(isNaN(e.target.value) ? Math.abs(e.target.value.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(e.target.value))} />
                        <label htmlFor="shiftControl" className="form-label">Enter No. of Shifts</label>
                    </div>
                </div>}
                <p><input type="checkbox" name="job_title" className="form-check-input" id="termsControl" placeholder=' ' onChange={handleOtpCondition} checked={conditionAccept} autoComplete='off' /> <label htmlFor='termsControl' style={{maxWidth: 'calc(100% - 45px)'}}>By entering the OTP and the billing hours you accept and confirm that the job was performed to your satisfaction for the performed job</label></p>
            </div> : null
        }
        {jobData && <button className={`btn btn-primary btn-auto mb-0 ${isLoading || (!conditionAccept && activity && (activity.status === 'STARTED' || activity.status === "END_OTP_GENERATED")) ? 'disabled' : ''}`} type='button' onClick={() => generateOtp(jobData.job.status)}>{((jobData.job.startOtp && jobData.job.status !=='STARTED') || jobData.job.endOtp) ? 'Regenerate' : 'Generate' } OTP{isLoading && <span className='loader loader-small ms-1'></span>}</button>}
        {!otp && <p className='bold mt-3 mb-0'>Generated OTP will be displayed here</p>}
        {otp && <div className='otp-box'>
            {otp.map((item, index) => <span key={index}>{item}</span>)}
        </div>}
        {(jobData && (jobData.job.status === "END_OTP_GENERATED" || jobData.job.status === "START_OTP_GENERATED")) ? <div className='otp-actions'>
            <div className='copy-otp' onClick={copyOtp}>
                <span className='icon icon-copy-blue'></span> <span className="otp-text" ref={copyRef}>Copy OTP</span>
            </div>
            <div className='send-otp'>
                <span className='icon icon-envelope-blue'></span> Send to Vendor on email
            </div>
        </div> : null}
    </div>
    {successMessage && <MToast color="green" duration={3000} icon="icon-check-green-rev">{successMessage}</MToast>}
    {errorMessage && <MToast color="red" duration={3000} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </>
  )
}

export default StartEndOtp