import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { UserService } from '../../../services/user.service'
import { useFormik } from 'formik'

function AccountInfoUpdateModal({showModal, viewModal, userInfo, profileUpdateSuccess}) {
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(viewModal) setShow(viewModal)
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const updateUserInfo = (values) => {
        setIsLoading(true)
        UserService.updateVendor({...userInfo, ...values}).then((response) => {
            if(response.success){
                if(profileUpdateSuccess) profileUpdateSuccess(true, 'Profile updated successfully.')
                handleHide()
            } else if(!response.success){
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.data.message)
            } else if(response.code === "ERR_NETWORK") {
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.message)
            }
            setIsLoading(false)
        })
    }
    const validate = values => {
        const errors = {};
        return errors;
    };
    const formik = useFormik({
        initialValues: { vendorId: userInfo.vendorId, accountNumber: userInfo.accountNumber || '', ifsc: userInfo.ifsc || '', accountName: userInfo.accountName || '', accountType: userInfo.accountType || '' },
        validate,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            updateUserInfo(values)
        },
    })
  return (
    <Modal show={show} onHide={handleHide} className='mpass-modal fixed-footer'>
        <Modal.Header>
            <Modal.Title>Update Info</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        {userInfo && 
        <Modal.Body>
            <div className='form'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="accountNumber" className="form-control" id="accountNumberControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.accountNumber}
                        />
                        <label htmlFor="accountNumberControl" className="form-label d-flex">Account Number</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="ifsc" className="form-control" id="ifscControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ifsc}
                        />
                        <label htmlFor="ifscControl" className="form-label d-flex">IFSC Code</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="accountName" className="form-control" id="accountNameControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.accountName}
                        />
                        <label htmlFor="addressControl" className="form-label d-flex">Account Name</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="accountType" className="form-control" id="accountTypeControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.accountType}
                        />
                        <label htmlFor="pinControl" className="form-label d-flex">Account Type</label>
                    </div>
                    <Modal.Footer>
                        <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`}>Update Info{isLoading && <div className='loader loader-medium'></div>}</button>
                    </Modal.Footer>
                    {/*formError !== '' ? <div className='error'>{formError}</div> : null*/}
                </form>
            </div>
        </Modal.Body>}
    </Modal>
  )
}

export default AccountInfoUpdateModal