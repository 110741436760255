import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../../../scss/modal.scss';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown from '../SelectDropdown';
import SearchDropdown from '../SearchDropdown';
import { FleetsService } from '../../../services/fleets.service';
import AddMasterItem from './AddMasterItem';
import MToast from '../MToast';

function AddFleetModal({showModal, viewModal, fleetItem, fleetAdded, itemAdded}) {
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fleetMasterToggle, setFleetMasterToggle] = useState(false)
    const [fleetMasterAlertToggle, setFleetMasterAlertToggle] = useState(false)
    const [brands, setBrands] = useState(null)
    //const [itemtype, setItemType] = useState(null)
    const [itemNames, setItemNames] = useState(null)
    const [itemSubmodels, setItemSubmodels] = useState(null)
    const [itemRange, setItemRange] = useState(null)
    const [itemMasterId, setItemMasterId] = useState('')
    const [fleetId, setFleetId] = useState('')
    const [serialImgErr, setSerialImgErr] = useState('')
    const [calibrateImgErr, setCalibrateImgErr] = useState('')
    const [serialImages, setSerialImages] = useState([])
    const [calibrateImages, setCalibrateImages] = useState([])
    const [serialFileLoader, setSerialFileLoader] = useState(null)
    const [calFileLoader, setCalFileLoader] = useState(null)
    const [fleetError, setFleetError] = useState('')
    const [fleetSuccess, setFleetSuccess] = useState('')
    const [itemFleetId, setItemFleetId] = useState('')
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [pickerOpen, setPickerOpen] = useState(null);
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 25 }, (_, index) => currentYear - index);
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        // All the categories, brands, equipments from master item
        /*FleetsService.getDistinctAttribute('ITEMTYPE').then((response) => {
            setItemType(response.data)
        })*/
        FleetsService.getDistinctAttribute('BRAND').then((response) => {
            setBrands(response.data)
        })
        FleetsService.getDistinctAttribute('EQUIPMENT').then((response) => {
            setItemNames(response.data)
        })
        if(!fleetId) {
            FleetsService.getFleetId().then((response) => {
                setFleetId(response)
            })
        }
    }, [viewModal, fleetId])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
        formik.setFieldValue('caliberatedOn', date)
    };
    const openFleetMasterForm = () => {
        setFleetMasterToggle(true)
        setFleetMasterAlertToggle(false)
    }
    const closeFleetMasterForm = () => {
        setFleetMasterToggle(false)
        setFleetMasterAlertToggle(false)
    }
    const hidemasterItemForm = (hide) => {
        if(hide) setFleetMasterToggle(false)
    }
    const addUpdateFleet = (masterId, values) => {
        setIsLoading(true)
        if(fleetItem){
            FleetsService.updateFleet(itemFleetId, masterId, values).then((response) => {
                if(response.success){
                    handleHide()
                    if(fleetAdded) fleetAdded(true, response.data)
                } else if(!response.success){
                    if(fleetAdded) fleetAdded(false, response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                    if(fleetAdded) fleetAdded(false, response.data.message)
                    return false
                } else if(response.code === "ERR_NETWORK") {
                    if(fleetAdded) fleetAdded(false, response.message)
                }
                setIsLoading(false)
            })
        } else{
            FleetsService.createFleet(masterId, values).then((response) => {
                if(response.success){
                    handleHide()
                    if(fleetAdded) fleetAdded(true, response.data)
                } else if(!response.success){
                    if(fleetAdded) fleetAdded(false, response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                    if(fleetAdded) fleetAdded(false, response.data.message)
                    return false
                } else if(response.code === "ERR_NETWORK") {
                    if(fleetAdded) fleetAdded(false, response.message)
                }
                setIsLoading(false)
            })
        }
    }
    const validate = values => {
        const errors = {};
        if (values.item_name === '') {
            errors.item_name = 'Equipment name is required';
        }
        /*if (values.itemType === '') {
            errors.itemType = 'Equipment type is required';
        }*/
        if (values.brand === '') {
            errors.brand = 'Brand is required';
        }
        if (values.item_submodel === '') {
            errors.item_submodel = 'Item submodel is required';
        }
        if (values.range === '') {
            errors.range = 'Range is required';
        }
        if((itemSubmodels && itemSubmodels.includes(values.item_submodel))
        && (itemRange && itemRange.includes(values.range))){
            setFleetMasterAlertToggle(false)
        }
        if (itemSubmodels && formik.touched.item_submodel && (itemSubmodels.length === 0 || (!itemSubmodels.includes(values.item_submodel) && values.item_submodel !== ''))) {
            setFleetMasterAlertToggle(true)
            errors.item_submodel = 'Submodel not found in database';
        }
        if (itemRange && itemRange.length === 0) {
            setFleetMasterAlertToggle(true)
            errors.range = 'Range not found in database';
        }
        if (values.serial_no === '') {
            errors.serial_no = 'Serial number is required';
        }
        /*if (values.fleet_description === '') {
            errors.fleet_description = 'Fleet description is required';
        }*/
        if (values.caliberatedOn !== '' && values.manufacturingYear !== '') {
            if(new Date(values.caliberatedOn).getFullYear() < values.manufacturingYear){
                errors.caliberatedOn = 'Calibration year is lower than manufacturing year';
                errors.manufacturingYear = "Manufacturing year can't be higher than calibration year" 
            }
        }
        if(values.fleet_description.length > 2000){
            errors.fleet_description = 'Description can not be longer than 2000 characters.'
        }
        /*if(values.manufacturingYear === ''){
            errors.manufacturingYear = 'Manufacturing Year must be 4 digits number.'
        }*/
        return errors;
    };
    const formik = useFormik({
        initialValues: { item_name: '', brand: '', item_submodel:  '', range: '', serial_no: '', item_image: [], is_item_calibrated: '', calibration_image: [], fleet_description: '', caliberatedOn: '', manufacturingYear: '', is_owned: '' },
        validate,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            addUpdateFleet(itemMasterId, values)
        },
    });
    useEffect(() => {
        let formikVals = formik.values
        if(formikVals.item_name && formikVals.brand){
            if(formikVals.item_submodel){
                if(formikVals.range){
                    FleetsService.getMasterId(formikVals.item_name, formikVals.brand, formikVals.item_submodel, formikVals.range).then((response) => {
                        if(response.success){
                            setItemMasterId(response.data)
                        }
                    })
                } else{
                    FleetsService.getRange(formikVals.item_name, formikVals.brand, formikVals.item_submodel).then((response) => {
                        if(response.success){
                            setItemRange(response.data)
                        }
                    })
                }
            } else{
                FleetsService.getSubModel(formikVals.item_name, formikVals.brand).then((response) => {
                    if(response.success){
                        setItemSubmodels(response.data)
                    }
                })
            }
        }
        if(fleetItem){
            //if(fleetItem.fleet.caliberateImages) setCalibrateImages([fleetItem.fleet.caliberateImages])
            //if(fleetItem.fleet.itemImages) setSerialImages([fleetItem.fleet.itemImages])
            if(formikVals.item_name === '') formik.values.item_name = fleetItem.masterItem.equipment
            if(formikVals.brand === '') formik.values.brand = fleetItem.masterItem.brand
            if(formikVals.item_submodel === '') formik.values.item_submodel = fleetItem.masterItem.brandSubModel
            if(formikVals.range === '') formik.values.range = fleetItem.masterItem.itemRange
            //if(formikVals.item_type === '') formik.values.item_type = fleetItem.masterItem.itemType
            if(formikVals.serial_no === '') formik.values.serial_no = fleetItem.fleet.serialNo
            //if(formikVals.item_image === '') formik.values.item_image = [fleetItem.fleet.itemImages]
            //if(formikVals.calibration_image === '') formik.values.calibration_image = [fleetItem.fleet.caliberateImages]
            if(formikVals.is_item_calibrated === '') formik.values.is_item_calibrated = fleetItem.fleet.caliberated ? 'Yes' : 'No'
            if(formikVals.fleet_description === '') formik.values.fleet_description = fleetItem.fleet.specification
            const inputDate = new Date(fleetItem.fleet.caliberatedAt);
            if(formikVals.caliberatedOn === '' && inputDate && inputDate.toString() !== 'Invalid Date') {
                formik.values.caliberatedOn = inputDate
                setSelectedDate(inputDate)
            }
            if(formikVals.manufacturingYear === '') formik.values.manufacturingYear = fleetItem.fleet.manufacturingYear
            if(formikVals.is_owned === '') formik.values.is_owned = fleetItem.fleet.owned ? 'Owned' : fleetItem.fleet.rented ? 'Rented' : ''
            setItemMasterId(fleetItem.fleet.masterItemId)
            setItemFleetId(fleetItem.fleet.fleetId)
        }
    }, [formik.values, fleetItem])
    
    const handleFileUpload = async (event, imgType) => {
        setSerialImgErr('')
        setCalibrateImgErr('')
        const targetEle = event.target
        const files = targetEle.files
        const formdata = new FormData()
        let maxFiles = 1
        if(imgType === 'FLEET_EQUIPMENT_IMAGE'){
            maxFiles = serialImages ? 1 - serialImages.length : 1
        } else if(imgType === 'FLEET_CALIBERATE_IMAGE'){
            maxFiles = calibrateImages ? 1 - calibrateImages.length : 1
        }
        const fileSize = 5*1024*1024
        const fileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
        if(files.length > maxFiles){
            if(imgType === 'FLEET_EQUIPMENT_IMAGE') setSerialImgErr('Only 1 image is allowed')
            if(imgType === 'FLEET_CALIBERATE_IMAGE') setCalibrateImgErr('Only 1 image is allowed')
            targetEle.value = ''
            return false
        }
        for(let i = 0; i < files.length; i++){
            const file = files[i]
            if(file.size > fileSize){
                if(imgType === 'FLEET_EQUIPMENT_IMAGE') setSerialImgErr(`Files size of ${file.name} is more then 1MB. Please upload smaller size file.`)
                if(imgType === 'FLEET_CALIBERATE_IMAGE') setCalibrateImgErr(`Files size of ${file.name} is more then 1MB. Please upload smaller size file.`)
            } else if (!fileTypes.includes(file.type)) {
                if(imgType === 'FLEET_EQUIPMENT_IMAGE') setSerialImgErr(`Only JPEG, PNG, and PDF files are allowed. Please check format of ${file.name}`)
                if(imgType === 'FLEET_CALIBERATE_IMAGE') setCalibrateImgErr(`Only JPEG, PNG, and PDF files are allowed. Please check format of ${file.name}`)
            } else{
                formdata.append('file', file)
            }
        }
        if(formdata.has('file') && fleetId){
            if(imgType === 'FLEET_EQUIPMENT_IMAGE') setSerialFileLoader(true)
            if(imgType === 'FLEET_CALIBERATE_IMAGE') setCalFileLoader(true)
            FleetsService.fleetUploadFile(fleetId, imgType, formdata).then((response) => {
                if(response.success){
                    if(imgType === 'FLEET_EQUIPMENT_IMAGE') {
                        setSerialImages([...serialImages, ...response.data])
                        formik.values.item_image.push(...response.data.map((item) => item.doc.documentId))
                    }
                    if(imgType === 'FLEET_CALIBERATE_IMAGE') {
                        setCalibrateImages([...calibrateImages, ...response.data])
                        formik.values.calibration_image.push(...response.data.map((item) => item.doc.documentId))
                    }
                    for (var key of formdata.keys()) {
                        formdata.delete(key)
                    }
                    targetEle.value = ''
                    setFleetSuccess('File upload successful')
                } else if(!response.success){
                    setFleetError(response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                    setFleetError(response.data.message)
                    return false
                } else if(response.code === "ERR_NETWORK") {
                    setFleetError(response.message)
                }
                setCalFileLoader(false)
                setSerialFileLoader(false)
            })
        }
    }
    const deleteImage = (docId, fleetId, imgType) => {
        if(imgType === 'FLEET_EQUIPMENT_IMAGE') setSerialFileLoader(true)
        if(imgType === 'FLEET_CALIBERATE_IMAGE') setCalFileLoader(true)
        setFleetError('')
        setFleetSuccess('')
        let deletedDocId = docId;
        FleetsService.deleteFleetFiles(docId, fleetId, imgType).then((response) => {
            if(response.success){
                if(imgType === 'FLEET_EQUIPMENT_IMAGE') {
                    const updatedDocs = serialImages.filter((item) => item.doc.documentId !== deletedDocId)
                    setSerialImages(updatedDocs)
                    formik.setFieldValue('item_image', updatedDocs.map((item) => item.doc.documentId))
                }
                if(imgType === 'FLEET_CALIBERATE_IMAGE') {
                    const updatedDocs = calibrateImages.filter((item) => item.doc.documentId !== deletedDocId)
                    setCalibrateImages(updatedDocs)
                    formik.setFieldValue('calibration_image', updatedDocs.map((item) => item.doc.documentId))
                }
                setFleetSuccess(response.data)
            } else if(!response.success){
                setFleetError(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setFleetError(response.data.message)
                return false
            } else if(response.code === "ERR_NETWORK") {
                setFleetError(response.message)
            }
            setCalFileLoader(false)
            setSerialFileLoader(false)
        })
    }
    const handleSubModelSelect = (option) => {
        formik.setFieldValue("item_submodel", option)
        //setFleetMasterAlertToggle(false)
    }
    const handleRangeSelect = (option) => {
        formik.setFieldValue("range", option)
        //setFleetMasterAlertToggle(false)
    }
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal fleet-modal'>
        <Modal.Header>
            <Modal.Title>{fleetItem && !fleetMasterToggle ? 'Update' : 'Add'} New {fleetMasterToggle ? 'Item' : 'Fleet'}</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        {fleetMasterAlertToggle ? <div className='strip red-strip alert-strip text-center d-flex justify-content-center flex-wrap'><p className='mb-0'>This item is not in our database. Would you like to request to add it?</p><div className='action-buttons'><strong onClick={openFleetMasterForm} style={{cursor: 'pointer'}} className='btn btn-light btn-red mt-1 me-1'>Yes</strong> <strong className='btn btn-light btn-red mt-1' onClick={() => setFleetMasterAlertToggle(false)} style={{cursor: 'pointer'}}>No</strong></div></div> : null}
        {fleetMasterToggle && <p className='strip mb-0 pb-0'><span className='link' onClick={closeFleetMasterForm}>&lt; Go back to add fleet</span></p>}
        <Modal.Body>
            {!fleetMasterToggle && <div className='form'>
                <form onSubmit={formik.handleSubmit}>
                    <SearchDropdown name="item_name" id="ItemNameControl"
                        onBlur={formik.handleBlur}
                        icon="noicon"
                        autoPopulate={true}
                        defaultValue={formik.values.item_name}
                        value={formik.values.item_name}
                        data={itemNames ? itemNames : []}
                        onSelectItem={(option) => formik.setFieldValue("item_name", option)}
                        label="Equipment Name" searcherror={(formik.touched.item_name && formik.errors.item_name) ? formik.errors.item_name : null} />
                    <SearchDropdown name="brand" id="brandControl"
                        onBlur={formik.handleBlur}
                        icon="noicon"
                        autoPopulate={true}
                        defaultValue={formik.values.brand}
                        value={formik.values.brand}
                        data={brands ? brands : []}
                        onSelectItem={(option) => formik.setFieldValue("brand", option)}
                        label="Brand" searcherror={(formik.touched.brand && formik.errors.brand) ? formik.errors.brand : null} />
                    <SearchDropdown name="item_submodel" id="ItemSubmodelControl"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        icon="noicon"
                        autoPopulate={true}
                        defaultValue={formik.values.item_submodel}
                        value={formik.values.item_submodel}
                        data={itemSubmodels ? itemSubmodels : []}
                        onSelectItem={(option) => handleSubModelSelect(option)}
                        label="Brand Submodel" searcherror={(formik.touched.item_submodel && formik.errors.item_submodel) ? formik.errors.item_submodel : null} />
                    <div className="input-wrapper mat-input">
                        <SelectDropdown
                            options={itemRange ? itemRange : []}
                            labelText='Equipment Range'
                            value={formik.values.range}
                            onChange={(option) => handleRangeSelect(option)}
                        />
                        {(formik.touched.range && formik.errors.range) ? <div className='error'>{formik.errors.range}</div> : null}
                    </div>
                    {/*<SearchDropdown name="item_type" id="ItemTypeControl"
                        onBlur={formik.handleBlur}
                        icon="noicon"
                        autoPopulate={true}
                        defaultValue={formik.values.item_type}
                        value={formik.values.item_type}
                        data={itemtype ? itemtype : []}
                        onSelectItem={(option) => formik.setFieldValue("item_type", option)}
                        label="Equipment Type" searcherror={(formik.touched.item_type && formik.errors.item_type) ? formik.errors.item_type : null} />*/}
                    <div className="input-wrapper mat-input">
                        <input type="text" name="serial_no" className="form-control" id="serialNoControl" placeholder=' ' autoComplete='off'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.serial_no}
                        />
                        <label htmlFor="serialNoControl" className="form-label">Serial Number</label>
                        {(formik.touched.serial_no && formik.errors.serial_no) ? <div className='error'>{formik.errors.serial_no}</div> : null}
                    </div>
                    <div className="input-wrapper icon-input w-420">
                        <div className='files-wrapper'>
                            <div className={`file-input-wrapper ${serialImages.length>0 ? 'files-added' : ''}`}>
                                <input type="file" name="item_image" className="form-control file-control"
                                id="ItemImageControl" multiple onBlur={formik.handleBlur}
                                onChange={(e) => handleFileUpload(e, 'FLEET_EQUIPMENT_IMAGE')}
                                />
                                <div className='file-upload-overlay'>
                                    <label>Upload Item Image</label>
                                    <span className='upload-icon'><span className='icon icon-upload'></span></span>
                                </div>
                            </div>
                            {serialImages && serialImages.map((item, key) => item && <div className='file-item' key={key}>
                                <div className='delete-icon' onClick={() => deleteImage(item.doc.documentId, fleetId, 'FLEET_EQUIPMENT_IMAGE')}><span className='icon icon-delete'></span></div>
                                <div className='image'><img src={item.doc.documentPath} alt={item.doc.documentPath.split('/')[item.doc.documentPath.split('/').length - 1]} /></div>
                                <p>{item.doc.documentPath.split('/')[item.doc.documentPath.split('/').length - 1]}</p></div>)}
                        </div>
                        <p className='help-text' style={{marginBottom: '10px'}}><span className='icon icon-info-dark'></span> File types allowed are JPEG, PNG, and PDF</p>
                        {serialFileLoader && <div className="loader loader-small mt-1"></div>}
                        {(formik.touched.item_image && formik.errors.item_image) || serialImgErr ? <div className='error'>{formik.errors.item_image || serialImgErr}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <SelectDropdown
                            options={['Yes', 'No']}
                            labelText='Is Item Calibrated ?'
                            value={formik.values.is_item_calibrated}
                            onChange={(option) => formik.setFieldValue("is_item_calibrated", option)}
                        />
                        {(formik.touched.is_item_calibrated && formik.errors.is_item_calibrated) ? <div className='error'>{formik.errors.is_item_calibrated}</div> : null}
                    </div>
                    {formik.values.is_item_calibrated === 'Yes' &&
                    <><div className="input-wrapper icon-input">
                        <div className='files-wrapper'>
                            <div className={`file-input-wrapper ${calibrateImages.length>0 ? 'files-added' : ''}`}>
                                    <input type="file" name="calibration_image" className="form-control file-control" id="CertificateControl" multiple
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => handleFileUpload(e, 'FLEET_CALIBERATE_IMAGE')}
                                />
                                <div className='file-upload-overlay'>
                                    <label>Upload Calibration Certificate</label>
                                    <span className='upload-icon'><span className='icon icon-upload'></span></span>
                                </div>
                            </div>
                            {calibrateImages && calibrateImages.map((item, key) => item && <div className='file-item' key={key}>
                                <div className='delete-icon' onClick={() => deleteImage(item.doc.documentId, fleetId, 'FLEET_CALIBERATE_IMAGE')}><span className='icon icon-delete'></span></div>
                                <div className='image'><img src={item.doc.documentPath} alt={item.doc.documentPath.split('/')[item.doc.documentPath.split('/').length - 1]} /></div>
                                <p>{item.doc.documentPath.split('/')[item.doc.documentPath.split('/').length - 1]}</p></div>)}
                        </div>
                        <p className='help-text' style={{marginBottom: '10px'}}><span className='icon icon-info-dark'></span> File types allowed are JPEG, PNG, and PDF</p>
                        {(formik.touched.calibration_image && formik.errors.calibration_image) || calibrateImgErr ? <div className='error'>{formik.errors.calibration_image || calibrateImgErr}</div> : null}
                        {calFileLoader && <div className="loader loader-small mt-1"></div>}
                        {(formik.touched.calibration_image && formik.errors.calibration_image) ? <div className='error'>{formik.errors.calibration_image}</div> : null}
                    </div>
                    
                    <div className={`input-wrapper mat-input icon-input w-420 ${selectedDate ? 'date-selected' : ''}`}>
                        <DatePicker selected={selectedDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} 
                        name="caliberatedOn" id='caliberatedOnControl' autoComplete='off'
                        onChange={handleDateChange}
                        onBlur={formik.handleBlur}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        value={formik.values.caliberatedOn}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="caliberatedOnControl" className="form-label">Calibrated On</label>
                        <p className='help-text'>You can enter date manually as well.</p>
                        {(formik.touched.caliberatedOn && formik.errors.caliberatedOn) ? <div className='error'>{formik.errors.caliberatedOn}</div> : null}
                    </div>
                    </>}
                    
                    <div className="input-wrapper mat-input">
                        <SelectDropdown
                            options={['Rented', 'Owned']}
                            labelText='Is Item owned by you?'
                            value={formik.values.is_owned}
                            onChange={(option) => formik.setFieldValue("is_owned", option)}
                        />
                        {(formik.touched.is_owned && formik.errors.is_owned) ? <div className='error'>{formik.errors.is_owned}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <SelectDropdown
                            options={years}
                            labelText='Manufacturing Year'
                            value={formik.values.manufacturingYear}
                            onChange={(option) => formik.setFieldValue("manufacturingYear", option)}
                        />
                        {(formik.touched.manufacturingYear && formik.errors.manufacturingYear) ? <div className='error'>{formik.errors.manufacturingYear}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input w-420">
                        <textarea name="fleet_description" className={`form-control ${formik.values.fleet_description !== '' ? 'textarea-filled' : ''}`} maxLength={2000} id="fleetDescriptionControl" rows="3" style={{'height': '140px'}}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} value={formik.values.fleet_description}></textarea>
                        <label htmlFor="fleetDescriptionControl" className="form-label">Enter Item Specifications (Optional)</label>
                        {(formik.touched.fleet_description && formik.errors.fleet_description) ? <div className='error'>{formik.errors.fleet_description}</div> : null}
                    </div>
                    <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled btn-loader' : ''}`}>{fleetItem ? 'Update' : 'Add'} Fleet {isLoading && <div className='loader loader-medium'></div>}</button>
                </form>
            </div>}
            {fleetMasterToggle && <AddMasterItem itemAdded={itemAdded} hidemasterItemForm={hidemasterItemForm} brandsList={brands} equipmentsList={itemNames}
            //equipmentTypeList={itemtype}
            />}
        </Modal.Body>
    </Modal>
    {fleetSuccess && <MToast color="green" duration={3500} icon="icon-check-green-rev">{fleetSuccess}</MToast>}
    {fleetError && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{fleetError}</MToast>}
    </>
  )
}

export default AddFleetModal