import { apiData } from "./api";
import { authServices } from "./auth.services";

const createJob = async (jobData) => {
    let Jobdata = {}
    const inputDate = new Date(jobData.last_bid_date);
    const year = inputDate.getUTCFullYear();
    const month = (`0${inputDate.getUTCMonth() + 1}`).slice(-2);
    const day = (`0${inputDate.getUTCDate()}`).slice(-2);
    const hour = (`0${inputDate.getUTCHours()}`).slice(-2);
    const minute = (`0${inputDate.getUTCMinutes()}`).slice(-2);
    const second = (`0${inputDate.getUTCSeconds()}`).slice(-2);

    const convertedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    Jobdata.jobTitle = jobData.job_title
    Jobdata.category = jobData.category
    Jobdata.equipmentType = jobData.equipment_type
    //Jobdata.equipmentName = jobData.equipment_name
    Jobdata.software = jobData.software
    Jobdata.personRequired = jobData.person_required === 'Yes' ? true : false
    Jobdata.location = jobData.location
    Jobdata.startDate = jobData.start_date
    Jobdata.endDate = jobData.end_date
    Jobdata.docUrls = jobData.doc_urls
    Jobdata.description = jobData.description
    Jobdata.bidType = jobData.bid_type === "Fixed budget" ? "FIX_BUDGET" : "REQUEST_QUOTE"
    Jobdata.budgetType = jobData.budget_type === "Per Day" ? "PERDAY" : jobData.budget_type === "Per Project" ? "PERPROJECT" : 'Quantity'
    Jobdata.bidInclusion = jobData.bid_inclusion
    Jobdata.bidAmount = jobData.bid_amount
    Jobdata.lastBidDate = convertedDate
    Jobdata.bidCloserDate = jobData.bidCloserDate
    Jobdata.siteContactName = jobData.site_contact_name
    Jobdata.siteContactMobile = jobData.site_contact_mobile
    Jobdata.siteContactEmail = jobData.site_contact_email
    Jobdata.JobId = localStorage.getItem('JobId')
    Jobdata.paymentOption = jobData.commissionTier
    Jobdata.brand = jobData.brand_name
    Jobdata.multiActivity = true
    Jobdata.sacCode = jobData.saccode
    if(jobData.minimum_assured_quantity) {
        Jobdata.assuredQuantity = jobData.minimum_assured_quantity
    }
    if(jobData.minimum_assured_days) {
        Jobdata.assuredDays = jobData.minimum_assured_days
    }
    Jobdata.jobActivity = [...jobData.schedules]
    /*JobData.JobId = localStorage.getItem('JobId')
    JobData.doc_urls = jobData.doc_urls
    JobData.bid_type = JobData.bid_type === "Fixed budget" ? "FIX_BUDGET" : "REQUEST_QUOTE"
    JobData.budget_type = JobData.budget_type === "Per Day" ? "PERDAY" : "PERPROJECT"
    JobData.person_required = JobData.person_required === 'Yes' ? true : false
    JobData.last_bid_date = convertedDate*/
    const token = await authServices.getToken();
    return await apiData.api_job.post(`/create`, Jobdata, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

const getJobId = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_job.get(`/get-jobid`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response){
                localStorage.setItem('JobId', response.data)
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}
const uploadJobFiles = async (files, JobId) => {
    try {
        if(JobId){
            const token = await authServices.getToken();
            return await apiData.api_internal.post(`/file/uploade?contentId=${JobId}`, files, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
            }).then((response) => {
                if(response && response.data){
                    return response.data
                }
            });
        }
    } catch (error) {
      return error;
    }
}
const deleteJobFiles = async (docId, jobId) => {
    try {
        if(jobId){
            const token = await authServices.getToken();
            return await apiData.api_internal.get(`/file/delete-s3?docId=${docId}&contentId=${jobId}&docType=JOB`, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
            }).then((response) => {
                if(response && response.data){
                    return response.data
                }
            });
        }
    } catch (error) {
      return error;
    }
}

const getCategoryJobs = async (jobstatus) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_job.post(`/get-category-jobs`, {"status": jobstatus}, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}
const getBidRejectedJobs = async (jobStatus) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_job.post(`/get-vendor-jobs-bid-status`, {"status": jobStatus}, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getJobsByStatus = async (status) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_job.post(`/get-job-by-status`, {"status": status}, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getJobById = async (JobId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_job.get(`get-job-by-id?jobId=${JobId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getVendorById = async (vendorId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_vendor.get(`get-vendor?searchKey=vendorId&searchValue=${vendorId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getVendorSearchList = async (searchKey, searchValue) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_vendor.get(`get-vendor?searchKey=${searchKey}&searchValue=${searchValue}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getVendorInfo = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_vendor.get(`get-vendor`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getCustomerTierInfo = async () => {
    try {
        const token = await authServices.getToken();
        const user = await authServices.getUser()
        const tierName = user.tier
        return await apiData.api_config.get(`get-customer-tier-list?itemName=${tierName}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

export const jobsService = {
    createJob, getJobId, uploadJobFiles, deleteJobFiles, getCategoryJobs, getBidRejectedJobs,
    getJobsByStatus, getJobById, getVendorById, getVendorSearchList, getVendorInfo, getCustomerTierInfo
}