import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { authServices } from '../services/auth.services'
import Profile from '../images/profile.svg';

function Sidebar({user, userImage, handleMenuToggle}) {
  //const [user, setUser] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dropdowns, setDropdowns] = useState({
    dropdown1: false,
    dropdown2: false
  });
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/admin/vendor-report' || location.pathname === '/admin/onboard-report' || location.pathname === '/admin/job-report') {
      setDropdowns((prevDropdowns) => ({
        ...Object.keys(prevDropdowns).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        dropdown2: true,
      }))
    }
    if (location.pathname === '/admin/manage-users-status' || location.pathname === '/admin/manage-users-tier') {
      setDropdowns((prevDropdowns) => ({
        ...Object.keys(prevDropdowns).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        dropdown1: true,
      }))
    }
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const navigate = useNavigate()
  const toggleDropdown = (dropdownName) => {
    setDropdowns((prevDropdowns) => ({
      ...Object.keys(prevDropdowns).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [dropdownName]: !prevDropdowns[dropdownName],
    }));
  };
  const logout = () =>{
      authServices.logout().then(response => navigate('/login'))
  }
  return (
    <div>
      {windowWidth < 768 && <div className='user-dropdown'>
        <span>{user && user.username}</span>
        <div className='user-profile'>
            <img src={userImage ? userImage : Profile} alt='User Name' />
        </div>
      </div>}
      <ul>
        {user && user.roles.includes("ROLE_CUSTOMER") ?
          <>
            <li><NavLink to='/customer/dashboard' exact="true" activeclassname="active"><span className='icon icon-dashboard'></span> Dashboard</NavLink></li>
            <li><NavLink to='/customer/my-profile' onClick={() => handleMenuToggle(false)}  activeclassname="active"><span className='icon icon-profile'></span> Profile</NavLink></li>
            <li><NavLink to='/customer/post-a-job' activeclassname="active"><span className='icon icon-suitcase-plus'></span> Post a Job</NavLink></li>
            <li><NavLink to='/customer/my-jobs' activeclassname="active"><span className='icon icon-suitcase'></span> My Jobs</NavLink></li>
            <li><NavLink to='/customer/reports' activeclassname="active"><span className='icon icon-report'></span> Reports</NavLink></li>
            {/*<li><NavLink to='/customer/find-vendor' activeclassname="active"><span className='icon icon-search'></span> Search Vendors</NavLink></li>*/}
          </>
        : user && user.roles.includes("ROLE_VENDOR") ? <>
          <li><NavLink to='/vendor/dashboard' exact="true" activeclassname="active"><span className='icon icon-dashboard'></span> Dashboard</NavLink></li>
            <li><NavLink to='/vendor/my-profile' onClick={() => handleMenuToggle(false)}  activeclassname="active"><span className='icon icon-profile'></span> Profile</NavLink></li>
            <li><NavLink to='/vendor/reports' activeclassname="active"><span className='icon icon-report'></span> Reports</NavLink></li>
            <li><NavLink to='/vendor/my-jobs' activeclassname="active"><span className='icon icon-suitcase'></span> My Jobs</NavLink></li>
            <li><NavLink to='/vendor/my-profile/categories' onClick={() => handleMenuToggle(false)} activeclassname="active"><span className='icon icon-list'></span> Categories</NavLink></li>
            <li><NavLink to='/vendor/my-profile/fleets' onClick={() => handleMenuToggle(false)}  activeclassname="active"><span className='icon icon-fleet'></span> Fleet</NavLink></li>
            {/*<li><NavLink to='/vendor/subscription-and-payment' activeclassname="active"><span className='icon icon-card'></span> Subscription & Payment</NavLink></li>
            <li><NavLink to='/vendor/find-vendor' activeclassname="active"><span className='icon icon-search'></span> Search Vendors</NavLink></li>*/}
        </> :  user && user.roles.includes("ROLE_ADMIN") ?
          <><li><NavLink to='/admin/dashboard' activeclassname="active"><span className='icon icon-dashboard'></span> Dynamic Dashboard</NavLink></li>
          <li><NavLink to='/admin/master-items' activeclassname="active"><span className='icon icon-master'></span> Master Items</NavLink></li>
          <li><NavLink to='/admin/add-distinct-items' activeclassname="active"><span className='icon icon-search'></span> Distinct Items</NavLink></li>
          {/*<li><NavLink to='/admin/invoice-status' activeclassname="active"><span className='icon icon-suitcase'></span> Invoices</NavLink></li>
          <li><NavLink to='/admin/po-status' activeclassname="active"><span className='icon icon-suitcase'></span> PO Status</NavLink></li>*/}
          <li className='dropmenu'>
          <div className='menu-link' onClick={() => toggleDropdown('dropdown2')}><span className='icon icon-report'></span> User Reports</div>
          {dropdowns.dropdown2 && <ul>
              {/*<li>
                <NavLink to='/admin/vendor-report' activeclassname="active">Vendor Count by Job</NavLink>
          </li>*/}
              <li>
                <NavLink to='/admin/onboard-report' activeclassname="active">Users Report</NavLink>
              </li>
              <li>
                <NavLink to='/admin/job-report' activeclassname="active">Job Report</NavLink>
              </li>
            </ul>}
          </li>
          <li><NavLink to='/admin/tier-commission' activeclassname="active"><span className='icon icon-badge'></span> Tier Commission</NavLink></li>
          <li><NavLink to='/admin/manage-subscription' activeclassname="active"><span className='icon icon-subscription'></span> Manage Subscription</NavLink></li>
          {/*<li className='dropmenu'>
          <div className='menu-link' onClick={() => toggleDropdown('dropdown1')}><span className='icon icon-profile'></span> Manage Users</div>
          {dropdowns.dropdown1 && <ul>
              <li>
                <NavLink to='/admin/manage-users-status' activeclassname="active">Users Status</NavLink>
              </li>
              <li>
                <NavLink to='/admin/manage-users-tier' activeclassname="active">Users Tier</NavLink>
              </li>
            </ul>}
          </li>*/}
          </>
        : null}
        <li><div activeclassname="active" onClick={logout} className='menu-link'><span className='icon icon-logout'></span> Logout</div></li>
      </ul>
    </div>
  )
}

export default Sidebar