import React, { useEffect, useState } from 'react'
import { jobsService } from '../../../services/jobs.service'

function VendorTerms({vendorId, jobValue, budgetType}) {
    const [vendorData, setVendorData] = useState(null)
    useEffect(() => {
        jobsService.getVendorById(vendorId).then((response) => {
            setVendorData(response.data[0])
        })
    }, [vendorId])
  return (
    <div className='modal-section vendor-terms-section'>
        {vendorData && <>
            <h4 className='modal-section-title'>Vendor Bid Details</h4>
            <div className="vendor-info">
                <div className="company">{vendorData.companyName}</div>
                <div className="vendor-meta">
                    <span className="rating"><span className="icon icon-star"></span> {vendorData.rating}</span>
                    <span>{vendorData.location}</span>
                </div>
            </div>
            <ul className='bid-info'>
                {jobValue && <li className='w-100p'>
                    <span>Job Value</span>
                    <div>₹{jobValue}/{budgetType === "PERPROJECT" ? 'Per Project' : 'Per Day'}</div>
                </li>}
                <li>
                    <span>Email</span>
                    <div>{vendorData.email}</div>
                </li>
                <li>
                    <span>Mobile</span>
                    <div>{vendorData.mobile}</div>
                </li>
            </ul>
        </>}
    </div>
  )
}

export default VendorTerms