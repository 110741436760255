import React from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper';
/*import { Link } from 'react-router-dom';
import pdf1 from '../../images/dashboards-MPASS1.pdf'
import pdf2 from '../../images/Dashboards-MPASS2.pdf'
import pdf3 from '../../images/Dashboards-MPASS3.pdf'
import pdf4 from '../../images/Leads-MPASS.pdf'*/
import icon1 from '../../images/icon-1.svg'
import icon2 from '../../images/icon-2.svg'
import icon3 from '../../images/icon-3.svg'
import icon4 from '../../images/icon-4.svg'


function ReportsVendor() {
  return (
    <DashboardWrapper>
      <div className='page-content ps-0 pe-0 myjobs'>
        <div className='page-header'>
          <h2>Reports</h2>
        </div>
        <div className='reports'>
            <div className='link' target='_blank'><img src={icon1} alt='PO Report' style={{width: '25px', height: '25px'}} /> Purchase Order reports</div>
            <div className='link' target='_blank'><img src={icon2} alt='Invoice Report' style={{width: '25px', height: '25px'}} /> Invoice Reports</div>
            <div className='link' target='_blank'><img src={icon3} alt='Job by category' style={{width: '25px', height: '25px'}} /> Job By Category</div>
            <div className='link' target='_blank'><img src={icon4} alt='Job by status' style={{width: '25px', height: '25px'}} /> Job by Status</div>
              {/*<Link className='btn btn-secondary btn-medium' to={pdf1} target='_blank'>Report 1 <span className='icon icon-pdf-white'></span></Link>
            <Link className='btn btn-secondary btn-medium' to={pdf2} target='_blank'>Report 2 <span className='icon icon-pdf-white'></span></Link>
            <Link className='btn btn-secondary btn-medium' to={pdf3} target='_blank'>Report 3 <span className='icon icon-pdf-white'></span></Link>
            <Link className='btn btn-secondary btn-medium' to={pdf4} target='_blank'>Report 4 <span className='icon icon-pdf-white'></span></Link>*/}
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default ReportsVendor