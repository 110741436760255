import React, { useState } from "react";
import RowDropdown from "../../shared/UI/RowDropdown";
import { adminServices } from "../../services/admin.service";
import SelectDropdown from "../../shared/UI/SelectDropdown";
import MToast from "../../shared/UI/MToast";

function CompanyTableRow({ userData, tierList, tableUpdated, sno }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedTier, setSelectedTier] = useState(null);
    const [updatedTier, setUpdatedTier] = useState('');
    const [openForm, setOpenForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const updateTier = (customerId, tier) => {
        setIsLoading(true)
        adminServices.updateCustomerTier(customerId, tier).then((response) => {
            if (response.success) {
                setSuccessMessage(response.data);
                setOpenForm(false)
                setUpdatedTier(tier)
            } else if (response.error) {
                setErrorMessage(response.error);
            }
            if (response.code === "ERR_BAD_REQUEST") {
                setErrorMessage('Bad Request. Please try again later.');
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message);
            }
            setIsLoading(false)
        });
    };
    const handleRCCheck = (customerId, allowRC) => {
        adminServices.allowRCJobs(customerId, allowRC).then((response) => {
            if (response.success) {
                setSuccessMessage(response.data)
                if(tableUpdated) tableUpdated(true)
            } else if (response.error) {
                setErrorMessage(response.error);
            }
            if (response.code === "ERR_BAD_REQUEST") {
                setErrorMessage('Bad Request. Please try again later.');
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message);
            }
            setTimeout(() => {
                setIsLoading(false)
                setErrorMessage('')
                setSuccessMessage('')
            }, 3000);
        });
    }

    return (
        <>
            {userData && <div className="table-row">
                <div className="serial">{sno+1}</div>
                <div>{userData.companyName}</div>
                <div>{userData.companyGstNo}</div>
                <div>
                    {!openForm && <span className="tier-text">{updatedTier ? updatedTier : userData.tier}</span>}
                    {openForm && <div className="form mb-0 inline-form">
                        <div className="input-wrapper mat-input w-auto" style={{minWidth: '120px'}}>
                            <SelectDropdown
                                className="select-small"
                                options={
                                    tierList.length > 0 ? tierList.map((item) => item.level) : []
                                }
                                labelText='Select Tier'
                                value={selectedTier ? selectedTier : userData.tier}
                                onChange={(option) =>
                                    setSelectedTier(option)
                                }
                            />
                        </div>
                        <button className={`btn btn-primary ${isLoading ? 'disabled' : ''}`} onClick={() => updateTier(userData.customerId, selectedTier)}>Save{isLoading && <span className='loader loader-small ms-1'></span>}</button>
                    </div>}
                </div>
                {userData.customerId ? <div className={`${userData.tier && userData.tier.toLowerCase() !== 'platinum' ? "rc-tooltip" : ''}`}>
                    <label className="rc-switch">
                        <input type="checkbox" name="rccheck" onChange={() => handleRCCheck(userData.customerId, !userData.multiactivityAllowed)} checked={userData.multiactivityAllowed} />
                        <span className="slider"></span>
                    </label>
                    {userData.tier && userData.tier.toLowerCase() !== 'platinum' ? <div className='tooltip'>Allowing RC Jobs will automatically convert user into PLATINUM.</div> : null}
                </div> : <div></div>}
                <div className="d-flex action">
                    <RowDropdown>
                        <li
                            onClick={() =>
                                setOpenForm(!openForm)
                            }
                        >
                            Edit Tier
                        </li>
                    </RowDropdown>
                </div>
            </div>}
            {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
            {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
        </>
    );
}

export default CompanyTableRow;
