import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { UserService } from '../../../services/user.service'
import { useFormik } from 'formik'
import { externalServices } from '../../../services/external.services'
import LocationDropdown from '../LocationDropdown'
import MToast from '../MToast'
import SelectDropdown from '../SelectDropdown'

function UserUpdateModal({showModal, viewModal, userInfo, profileUpdateSuccess}) {
    const [show, setShow] = useState(false)
    const [profileImg, setProfileImg] = useState('')
    const [profileImgErr, setProfileImgErr] = useState(null)
    const [imageSuccess, setImageSuccess] = useState(null)
    const [imageError, setImageError] = useState(null)
    /*const [formError, setFormError] = useState('');
    const [gstError, setgstError] = useState('');
    const [gstChange, setgstChange] = useState('');
    const [companyError, setcompanyError] = useState('');
    const [loader, setLoader] = useState(false);*/
    const [imageLoader, setImageLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        if(userInfo && userInfo.profileImage) setProfileImg(userInfo.profileImage)
    }, [viewModal, userInfo])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    /*let getCompanyName = async (gstNo) => {
        setFormError('')
        if(!formik.errors.companygst_no && gstNo !== gstChange){
            setLoader(true)
            await externalServices.getCompanyNameByGST(gstNo).then((response) => {
                if(response.success){
                    formik.setFieldValue('companyname', response.data)
                    setgstError('')
                    setcompanyError('')
                } else{
                    formik.setFieldValue('companyname', '')
                    setgstError('Please provide correct GST number')
                    setcompanyError('Company name as per the GST Number')
                    if(response.code === "ERR_BAD_REQUEST"){
                        setFormError(response.message + '- Please try again later')
                      } else if(response && response.code === "ERR_NETWORK"){
                        setFormError(response.message + '- Please check internet or try again later')
                      }  else if(response && response.code){
                        setFormError(response.response.data.error)
                      }
                }
                setgstChange(gstNo)
                setLoader(false)
            }).catch((error) => {
                setLoader(false)
            })
        } else if(gstNo !== gstChange && gstNo.length < 15){
            formik.setFieldValue('companyname', '')
        }
    }*/
    const updateUserInfo = (values) => {
        setIsLoading(true)
        const category = userInfo && userInfo.category ? userInfo.category : []
        const updatedInfo = {...userInfo, ...values, category}
        UserService.updateVendor(updatedInfo).then((response) => {
            if(response.success){
                if(profileUpdateSuccess) profileUpdateSuccess(true, 'Profile updated successfully.')
                handleHide()
            } else if(!response.success){
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.data.message)
            } else if(response.code === "ERR_NETWORK") {
                if(profileUpdateSuccess) profileUpdateSuccess(false, response.message)
            }
            setIsLoading(false)
        })
    }
    const validate = values => {
        const errors = {};
        /*if (values.companygst_no === '') {
            formik.errors.companygst_no = 'Enter your company GST Number';
        }
        if((values.companygst_no).length !== 15){
            errors.companygst_no = 'Please provide valid GST number'
        }
        if (values.companyname === '') {
            errors.companyname = 'Company name as per the GST Number';
        }*/
        return errors;
    };
    const formik = useFormik({
        initialValues: { vendorId: userInfo.vendorId, location: userInfo.location || '', profile_image: userInfo.profileImage || '', phone_no: userInfo.mobile || '', email_address: userInfo.email || '', employeeCountUpto: userInfo.employeeCountUpto === 0 ? '' : userInfo.employeeCountUpto === 5 ? '0-5' : userInfo.employeeCountUpto === 10 ? '5-10' : userInfo.employeeCountUpto > 10 ? 'More than 10' : '' || '', multiOffices: userInfo.multiOffices ? 'Multiple Locations' : 'Single Location', profile_description: userInfo.about || '', companyAddress: userInfo.companyAddress || '', state: userInfo.state || '', city: userInfo.city || '', pin: userInfo.pin || '' },
        validate,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            updateUserInfo(values)
        },
    });
    const handleFileUpload = (event, uploadFor) => {
        setProfileImgErr('')
        const targetEle = event.target
        const files = targetEle.files
        const formdata = new FormData()
        let maxFiles = 1
        const fileSize = 1*1024*1024
        const fileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        if(files.length > maxFiles){
            setProfileImgErr('Only one image is allowed to upload')
            targetEle.value = ''
            return false
        }
        for(let i = 0; i < files.length; i++){
            const file = files[i]
            if(file.size > fileSize){
                setProfileImgErr(`Files size of ${file.name} is more then 1MB. Please upload smaller size file.`)
            } else if (!fileTypes.includes(file.type)) {
                setProfileImgErr(`Only JPEG, PNG, and PDF files are allowed. Please check format of ${file.name}`)
            } else{
                formdata.append('file', file)
            }
        }
        if(formdata.has('file')){
            setImageLoader(true)
            externalServices.ProfilePicUpload(uploadFor, formdata).then((response) => {
                if(response.success){
                    if(response.success){
                        setProfileImg(response.data[0].doc.documentPath)
                        formik.setFieldValue('profile_image', response.data[0].doc.documentId)
                        for (var key of formdata.keys()) {
                            formdata.delete(key)
                        }
                        targetEle.value = ''
                        setImageSuccess('File upload successful')
                    } else if(!response.success){
                        setImageError(response.error)
                    }
                    if(response.code === 'ERR_BAD_REQUEST'){
                        setImageError(response.data.message)
                        return false
                    } else if(response.code === "ERR_NETWORK") {
                        setImageError(response.message)
                    }
                    setImageLoader(false)
                }
            })
        }
    }
  return (
    <Modal show={show} onHide={handleHide} className='mpass-modal fixed-footer'>
        <Modal.Header>
            <Modal.Title>Update Info</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        {userInfo && 
        <Modal.Body>
            <div className='form'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="companygst_no" className="form-control" id="gstControl" placeholder=" "
                        onBlur={formik.handleBlur}
                        value={userInfo.companyGstNo}
                        //onKeyUp={() => getCompanyName(formik.values.companygst_no)}
                        maxLength={15}
                        readOnly={true}
                        />
                        <label htmlFor="gstControl" className="form-label">Company GST Number</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="companyname" className="form-control" id="companyControl" placeholder=" "
                        value={userInfo.companyName}
                        readOnly
                        />
                        <label htmlFor="companyControl" className="form-label d-flex">Company Name</label>
                    </div>
                    <LocationDropdown name="location" id="locationControl"
                        onBlur={formik.handleBlur}
                        isFull={true}
                        onSelectItem={(option) => formik.setFieldValue("location", option)}
                        value={formik.values.location}
                        label="Location" searcherror={(formik.touched.location && formik.errors.location) ? formik.errors.location : null} />
                    <div className="input-wrapper mat-input">
                        <input type="text" name="state" className="form-control" id="stateControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                        />
                        <label htmlFor="stateControl" className="form-label d-flex">State</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="city" className="form-control" id="cityControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                        />
                        <label htmlFor="cityControl" className="form-label d-flex">City</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="companyAddress" className="form-control" id="addressControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyAddress}
                        />
                        <label htmlFor="addressControl" className="form-label d-flex">Address</label>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="pin" className="form-control" id="pinControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={6}
                        value={formik.values.pin ? (isNaN(formik.values.pin) ? Math.abs(formik.values.pin.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(formik.values.pin)) : ''}
                        />
                        <label htmlFor="pinControl" className="form-label d-flex">Pin Code</label>
                    </div>
                    <div className="input-wrapper icon-input">
                        <div className='files-wrapper'>
                            <div className={`file-input-wrapper ${profileImg && profileImg.length>0 ? 'files-added' : ''}`}>
                                <input type="file" name="profile_image" className="form-control file-control"
                                id="ProfileControl" multiple onBlur={formik.handleBlur}
                                onChange={(e) => handleFileUpload(e, 'COMPANY_PROFILE_IMG')}
                                />
                                <div className='file-upload-overlay'>
                                    <label>Upload Profile Image</label>
                                    <span className='upload-icon'><span className='icon icon-upload'></span></span>
                                </div>
                            </div>
                            {profileImg && <div className='file-item'>
                                <div className='delete-icon'><span className='icon icon-delete'></span></div>
                                <div className='image'><img src={profileImg} alt={profileImg.split('/')[profileImg.split('/').length - 1]} /></div>
                                <p>{profileImg.split('/')[profileImg.split('/').length - 1]}</p></div>}
                            {(formik.touched.profile_image && formik.errors.profile_image) || profileImgErr ? <div className='error'>{formik.errors.profile_image || profileImgErr}</div> : null}
                        </div>
                        {imageLoader && <div className="loader loader-small mt-1"></div>}
                        <p className='help-text' style={{marginBottom: '10px'}}><span className='icon icon-info-dark'></span> File types allowed are JPEG, PNG, and PDF</p>
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="text" name="phone_no" className="form-control" id="phoneControl" placeholder=" " maxLength={10}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone_no}
                        />
                        <label htmlFor="phoneControl" className="form-label">Phone Number</label>
                        {(formik.touched.phone_no && formik.errors.phone_no) ? <div className='error'>{formik.errors.phone_no}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <input type="email" name="email_address" className="form-control" id="emailControl" placeholder=" "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email_address}
                        />
                        <label htmlFor="emailControl" className="form-label">Email ID</label>
                        {(formik.touched.email_address && formik.errors.email_address) ? <div className='error'>{formik.errors.email_address}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <SelectDropdown
                            options={['Single Location', 'Multiple Locations']}
                            labelText='Select no. of Office locations'
                            value={formik.values.multiOffices}
                            onChange={(option) => formik.setFieldValue('multiOffices', option)}
                        />
                        {(formik.touched.multiOffices && formik.errors.multiOffices) ? <div className='error'>{formik.errors.multiOffices}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <SelectDropdown
                            options={['0-5', '5-10', 'More than 10']}
                            labelText='Select no. of employees'
                            value={formik.values.employeeCountUpto}
                            onChange={(option) => formik.setFieldValue('employeeCountUpto', option)}
                        />
                        {(formik.touched.employeeCountUpto && formik.errors.employeeCountUpto) ? <div className='error'>{formik.errors.employeeCountUpto}</div> : null}
                    </div>
                    <div className="input-wrapper mat-input">
                        <textarea name="profile_description" className={`form-control ${formik.values.profile_description !== '' ? 'textarea-filled' : ''}`} id="profileDescriptionControl" rows="3" style={{'height': '140px'}}
                        onChange={formik.handleChange}
                        maxLength={2000}
                        onBlur={formik.handleBlur} value={formik.values.profile_description}></textarea>
                        <label htmlFor="profileDescriptionControl" className="form-label">Enter Profile Description</label>
                        {(formik.touched.profile_description && formik.errors.profile_description) ? <div className='error'>{formik.errors.profile_description}</div> : null}
                    </div>
                    <Modal.Footer>
                        <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`}>Update Info{isLoading && <div className='loader loader-medium'></div>}</button>
                    </Modal.Footer>
                    {/*formError !== '' ? <div className='error'>{formError}</div> : null*/}
                </form>
            </div>
            {imageSuccess && <MToast color="green" duration={3500} icon="icon-check-green-rev">{imageSuccess}</MToast>}
            {imageError && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{imageError}</MToast>}
        </Modal.Body>}
    </Modal>
  )
}

export default UserUpdateModal