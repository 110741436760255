import React from "react";
import RowDropdown from "../../shared/UI/RowDropdown";
import { adminServices } from "../../services/admin.service";
import MpaasPagination from "../../shared/UI/Pagination";

function UsersTable({ usersData, usersType, userUpdated, totalPage, pageChangeHandler }) {
    const activateUser = (userType, userId) => {
        adminServices.activateUser(userType, userId).then((response) => {
            if (response.success) {
              userUpdated(true, response.data)
            } else if (!response.success) {
              userUpdated(false, response.error);
            } 
            if (response.code === "ERR_BAD_REQUEST") {
              userUpdated(false, response.data.message);
                return false;
            } else if (response.code === "ERR_NETWORK") {
              userUpdated(false, response.message);
            }
        });
    };
    const suspendUser = (userType, userId) => {
        adminServices.suspendUser(userType, userId).then((response) => {
          if (response.success) {
            userUpdated(true, response.data)
          } else if (!response.success) {
            userUpdated(false, response.error);
          }
          if (response.code === "ERR_BAD_REQUEST") {
            userUpdated(false, 'Bad Request');
              return false;
          } else if (response.code === "ERR_NETWORK") {
            userUpdated(false, response.message);
          }
        });
    };
    
    return (
        <>
            {totalPage > 1 ? <MpaasPagination totalPage={totalPage} onPageChange={pageChangeHandler} /> : null}
            <div className="table hover-table user-table">
                {usersData && usersData.n > 0 ? (
                    <div className="table-body">
                        <div className="table-header">
                            <div className="serial">S. No.</div>
                            <div>Name</div>
                            <div>Email</div>
                            <div>Phone Number</div>
                            <div>User Type</div>
                            <div className="action">Action</div>
                        </div>
                        {usersData.data.map((item, index) => (
                            <div className="table-row" key={index}>
                                <div className="serial">{index+1}</div>
                                <div>{item.personName}</div>
                                <div>{item.email}</div>
                                <div>{item.mobile_number}</div>
                                <div>{item.userType}</div>
                                <div className="d-flex action">
                                    <RowDropdown>
                                        {usersType === "active" ? (
                                            <li
                                                onClick={() =>
                                                    suspendUser(
                                                        item.userType,
                                                        item.userTypeId
                                                    )
                                                }
                                            >
                                                Suspend
                                            </li>
                                        ) : usersType === "suspended" ? (
                                            <li
                                                onClick={() =>
                                                    activateUser(
                                                        item.userType,
                                                        item.userTypeId
                                                    )
                                                }
                                            >
                                                Activate
                                            </li>
                                        ) : null}
                                    </RowDropdown>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="table-body">
                        <div className="table-row">No users available.</div>
                    </div>
                )}
            </div>
        </>
    );
}

export default UsersTable;
