import BannerBg from '../../images/side-banner.jpg'
import '../../scss/signup.scss'
import '../../scss/form.scss'
import '../../scss/stepper.scss'
import { Link } from 'react-router-dom';
import { Fade } from "react-awesome-reveal";
import UserRegister from './UserRegister'
import { useState } from 'react'
import ExternalWrapper from '../../shared/ExternalWrapper'

function SignUp() {
    const [steps, getSteps] = useState(1)
    const [user, setuser] = useState('customer')
    const getStep = (step) => {
        getSteps(step)
    }
    const userType = (userType) => {
        setuser(userType)
    }
  return (
    <ExternalWrapper
        title="Discover India's Top Measurement, Design and 3D Printing service providers"
        bgImg={BannerBg}>
        <Fade delay={1500} triggerOnce>
            <div className="login-menu text-end">
                <span className='login-menu-text'>Already have an Account? </span><Link to='/login' className='btn btn-secondary'>Login</Link>
            </div>
        </Fade>
        <Fade delay={1800} triggerOnce>
            <div className='heading-wrapper'>
                <h2>Create an Account</h2>
                <div className='steps'>Step {steps} of {user === 'customer' ? '2' : '3'}</div>
            </div>
            <UserRegister getStep={getStep} userType={userType} />
        </Fade>
    </ExternalWrapper>
  )
}

export default SignUp