import React, { useState } from 'react'

function FullScreenBox({children, rerenderChart}) {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
        if(rerenderChart) rerenderChart()
    }
    
  return (
    <div className={isFullScreen ? "fullscreen-card card-wrapper" : "card-wrapper"}>
        <div className="card graph-card">
            <div className='fullscreen-icons' onClick={toggleFullScreen}>
                <div className='tooltip-wrapper'>
                    {!isFullScreen && <span className='icon icon-fullscreen'></span>}
                    {isFullScreen && <span className='icon icon-fullscreenrev'></span>}
                    <div className='tooltip'>{isFullScreen ? 'zoom-out' : 'zoom-in'}</div>
                </div>
            </div>
            {children}
        </div>
    </div>
  )
}

export default FullScreenBox