import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../shared/DashboardWrapper'
import '../scss/form.scss';
import { useFormik } from 'formik';
import SelectDropdown from '../shared/UI/SelectDropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchDropdown from '../shared/UI/SearchDropdown';
import LocationDropdown from '../shared/UI/LocationDropdown';
import { externalServices } from '../services/external.services';
import ReviewModal from '../shared/UI/Modals/ReviewModal';
import { jobsService } from '../services/jobs.service';
import CategoryModal from '../shared/UI/Modals/CategoriesModal';
import MToast from '../shared/UI/MToast';
import { useNavigate } from 'react-router-dom';
import { FleetsService } from '../services/fleets.service';
import PDFIcon from '../images/icons/pdf-icon-dark.svg';
import stepIcon from '../images/icons/step-icon.svg';
import igesIcon from '../images/icons/iges-icon.svg';
import wordIcon from '../images/icons/word-icon.svg';
import xlsIcon from '../images/icons/xls-icon.svg';
import { saveAs } from "file-saver";
import axios from 'axios';
import { authServices } from '../services/auth.services';


function PostJob() {
    const personNeedOptions = ["Yes", "No"];
    const bidTypeOptions = ["Fixed budget", "Request for Quote"];
    const amountTypeOptions = ["Per Hour", "Per Shift", "Per Project", "Per Unit Quantity"];
    const amountTypeOptionsSchedule = ["Per Shift", "Per Hour", "Per Unit Quantity"];
    const bidInclusionOptions = ["Travelling", "Accomodation", "Shipping", "Others", "None"];
    const defaultSACList = [{sacCode: '997314', sacInfo: 'Leasing or rental services concerning office machinery and equipment (except computers) with or without operator'}, {sacCode: '997316', sacInfo: 'Leasing or rental services concerning telecommunications equipment with or without operator'}, {sacCode: '997319', sacInfo: 'Leasing or rental services concerning other machinery and equipments with or without operator'}, {sacCode: '998399', sacInfo: 'Other professional. technical and business services n.e.c. '}, {sacCode: '', sacInfo: 'Select Other'}]
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedLastBidDate, setSelectedLastBidDate] = useState(null);
    const [selectedbidCloserDate, setSelectedbidCloserDate] = useState(null);
    const [allcategories, setAllCategories] = useState(null);
    const [allEquipmentType, setAllEquipmentType] = useState(null);
    const [allBrandsName, setAllBrandsName] = useState(null);
    const [allSoftwares, setAllSoftwares] = useState(null);
    const [assignedTiers, setAssignedTiers] = useState(null);
    //const [sacCodeList, setsacCodeList] = useState([]);
    const [TermHelpText, setTermHelpText] = useState(null);
    const [pickerOpen, setPickerOpen] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [viewModal, SetViewModal] = useState(false)
    const [jobData, setJobData] = useState(null)
    const [jobid, setJobId] = useState(null)
    const [jobFiles, setjobFiles] = useState([])
    const [fileError, setFileError] = useState('')
    const [fileLoader, setFileLoader] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)
    const [secondaryErrorMsg, setSecondaryErrorMsg] = useState(null)
    const [scheduleError, setScheduleError] = useState(null)
    const [viewCategoriesModal, SetViewCategoriesModal] = useState(false)
    const [user, setUser] = useState(null)
    const [customSAC, setCustomSAC] = useState(null)
    const navigate = useNavigate()

    const getCategories = async () => {
        await externalServices.getCategories().then((response) => {
            if(response.data){
                let categories = response.data
                setAllCategories(categories)
            } else if(response.error){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg('Bad Request')
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
        })
    }
    const showCategoryModal = (show) => {
        SetViewCategoriesModal(show)
    }
    const getEquipmentsType = async () => {
        await FleetsService.getDistinctAttribute('ITEMTYPE').then((response) => {
            if(response.data){
                let equipmentTypes = response.data
                setAllEquipmentType(equipmentTypes)
            } else if(response.error){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg('Bad Request')
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
        })
    }
    /*const getEquipmentsName = async () => {
        await FleetsService.getDistinctAttribute('EQUIPMENT').then((response) => {
            if(response){
                setAllEquipmentName(response.data)
            }
        })
    }*/
    const getBrandsName = async () => {
        await FleetsService.getDistinctAttribute('BRAND').then((response) => {
            if(response.success){
                setAllBrandsName(response.data)
            } else if(response.error){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg('Bad Request')
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
        })
    }
    const getSoftwares = async () => {
        await externalServices.getSoftwares().then((response) => {
            if(response.data){
                let softwares = response.data
                setAllSoftwares(softwares)
            } else if(response.error){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg('Bad Request')
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
        })
    }
    const getAssignedTiers = async () => {
        await jobsService.getCustomerTierInfo().then((response) => {
            if(response.data){
                setAssignedTiers(response.data)
            } else if(response.error){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg('Bad Request')
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
        })
    }
    useEffect(() => {
        getCategories()
        getEquipmentsType()
        //getEquipmentsName()
        getBrandsName()
        getSoftwares()
        getAssignedTiers()
        authServices.getUser().then((response) => {
            setUser(response)
        })
    }, [])

    const showModal = (show) => {
        SetViewModal(show)
    }

    const dateHandler = (date, compareDate) => {
        let updatedDate;
        if(date <= compareDate){
            updatedDate = compareDate
        } else {
            updatedDate = date
        }
        updatedDate.setMinutes(0);
        updatedDate.setSeconds(0);
        updatedDate.setMilliseconds(0);
        const roundedHour = date <= compareDate ? updatedDate.getHours() + 1 : updatedDate.getHours();
        updatedDate.setHours(roundedHour);
        return updatedDate
    } 
    
    const handleDateChange = (date) => {
        let updatedDate = dateHandler(date, new Date())
        setSelectedDate(updatedDate);
        formik.setFieldValue('start_date', updatedDate)
    };
    const handleEndDateChange = (date) => {
        let updatedDate = dateHandler(date, selectedDate)
        setSelectedEndDate(updatedDate);
        formik.setFieldValue('end_date', updatedDate)
    };
    const handleLastBidDateChange = (date) => {
        setSelectedLastBidDate(date);
        formik.setFieldValue('last_bid_date', date)
    };
    const handlebidCloserDateChange = (date) => {
        setSelectedbidCloserDate(date);
        formik.setFieldValue('bidCloserDate', date)
    };
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };

    const onDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const onDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };
    const validate = values => {
        const errors = {};
        if (values.job_title === '') {
            errors.job_title = 'Job Title is required';
        }
        if (values.category === '') {
            errors.category = 'Category field is required';
        }
        if (values.equipment_type === '') {
            errors.equipment_type = 'Equipment Type field is required';
        }
        /*if (values.equipment_name === '') {
            errors.equipment_name = 'Equipment Name field is required';
        }
        if (values.software === '') {
            errors.software = 'Software field is required';
        }*/
        if (values.person_required === '') {
            errors.person_required = 'Need Person field is required';
        }
        if (values.location === '') {
            errors.location = 'Location field is required';
        }
        if (values.saccode === '') {
            errors.saccode = 'SAC Code field is required';
        }
        if (values.commissionTier === '') {
            errors.commissionTier = 'Commission Tier is required';
        }
        if (values.start_date === '') {
            errors.start_date = 'Please select start date';
        }
        if (values.start_date < new Date()) {
            errors.start_date = 'Start date can not be less then current date time.';
        }
        if (values.end_date === '') {
            errors.end_date = 'Please select end date';
        }
        if (values.end_date <= values.start_date) {
            errors.end_date = 'End date can not be less then start date.';
        }
        if (values.description === '') {
            errors.description = 'Job Description is required';
        }
        if (values.description.length > 2000) {
            errors.description = 'Maximum 2000 character are allowed';
        }
        if (values.bid_type === '') {
            errors.bid_type = 'Please select bid type';
        }
        if (values.budget_type === '') {
            errors.budget_type = 'Please select amount type';
        }
        if (values.bid_inclusion === '' || values.bid_inclusion.length === 0) {
            errors.bid_inclusion = 'Bid Inclusion is required';
        }
        if ((values.bid_type).toLowerCase() === 'fixed budget' && (values.bid_amount === '' || values.bid_amount === null || values.bid_amount === undefined)) {
            errors.bid_amount = 'Bid Amount is required';
        }
        if(values.bid_amount !== '' && values.bid_amount<1){
            errors.bid_amount = "Bid amount can't be less than 1"
        }
        if (values.last_bid_date === '') {
            errors.last_bid_date = 'Please select last bid date';
        }
        if (values.last_bid_date >= values.start_date) {
            errors.last_bid_date = 'Last bid date can not exceed job start date.';
        }
        if (values.last_bid_date < new Date()) {
            errors.last_bid_date = 'Last bid date can not be less then current date time.';
        }
        if (values.bidCloserDate === '') {
            errors.bidCloserDate = 'Please select job award date';
        }
        if (values.bidCloserDate <= values.last_bid_date) {
            errors.bidCloserDate = 'Job award date can not be less then last bid date.';
        }
        if (values.bidCloserDate >= values.start_date) {
            errors.bidCloserDate = 'Job award date can not exceed job start date.';
        }
        if(values.bidCloserDate !== "" && values.start_date !== ""){
            if((values.start_date - values.bidCloserDate)/(1000*60*60) <= 12){
                setSecondaryErrorMsg('Since the time gap for mobilisation is less than 12 hours, you may not get sufficient number of bids for the job or the vendor may be delayed in reaching your location.')
            } else{
                setSecondaryErrorMsg('')
            }
        }
        if (values.site_contact_name === '') {
            errors.site_contact_name = 'Contact name is required';
        }
        if (values.site_contact_mobile === '') {
            errors.site_contact_mobile = 'Contact phone number is required';
        }
        if ((values.site_contact_mobile).length !== 10) {
            errors.site_contact_mobile = 'Phone number must be 10 digits';
        }
        if (values.site_contact_email === '') {
            errors.site_contact_email = 'Contact email address is required';
        }
        if (values.saccode === '') {
            errors.saccode = 'SAC Code is required';
        }
        if (values.isScheduled && values.schedules.length===0) {
            errors.schedules = 'At least one schedule need to be added in RC jobs';
        }
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.site_contact_email)){
            errors.site_contact_email = 'Email address is not valid'
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            job_title: '', category: '', equipment_type: '',
            brand_name: [], software: '', person_required: '',
            location: '', commissionTier: '', start_date: '', end_date: '', doc_urls: [],
            description: '', bid_type: '', budget_type: '', bid_inclusion: [], bid_amount: '',
            last_bid_date: '', bidCloserDate: '', site_contact_name: '', site_contact_mobile: '', site_contact_email: '', isScheduled: false, schedules: [], saccode: '',
            //minimum_assured_quantity: '', minimum_assured_days: '',
            required_quantity: ''
        },
        validate,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: values => {
            showModal(true)
            setJobData(values)
          //alert(JSON.stringify(values, null, 2));
        },
    });
    const createJobId = () => {
        let JobId = localStorage.getItem('JobId')
        if(!JobId){
            jobsService.getJobId().then(() => {
                JobId = localStorage.getItem('JobId')
            })
        }
        return JobId
    }
    const handleFileUpload = (event) => {
        setFileError('')
        setErrorMsg('')
        setSuccessMsg('')
        const targetEle = event.target
        const files = targetEle.files
        const formdata = new FormData()
        let maxFiles = jobFiles ? 5 - jobFiles.length : 5
        const fileSize = 5*1024*1024
        const fileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msexcel', 'application/msword', 'application/iges', 'application/STEP'];
        const fileExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'xls', 'xlsx', 'doc', 'docx', 'iges', 'step'];
        if(files.length > maxFiles){
            setFileError('Maximum 5 images are allowed')
            targetEle.value = ''
            return false
        }
        for(let i = 0; i < files.length; i++){
            const file = files[i]
            const fileExt = file.name.split('.')[file.name.split('.').length - 1]
            if(file.size > fileSize){
                setFileError(`Files size of ${file.name} is more then 5MB. Please upload smaller size file.`)
            } else if (!fileTypes.includes(file.type) && !fileExtensions.includes(fileExt.toLowerCase())) {
                setFileError(`Only JPEG, PNG, XLSX, WORD, IGES, STEP and PDF files are allowed. Please check format of ${file.name}`)
            } else{
                formdata.append('file', file)
            }
        }
        if(formdata.has('file')){
            setFileLoader(true)
            const jobId = createJobId()
            setJobId(jobId)
            jobsService.uploadJobFiles(formdata, jobId).then((response) => {
                if(response && response !== undefined){
                    if(response.success){
                        setjobFiles([...jobFiles, ...response.data])
                        formik.setFieldValue('doc_urls', [...jobFiles.map((item) => item.doc.documentId), ...response.data.map((item) => item.doc.documentId)])
                        for (var key of formdata.keys()) {
                            formdata.delete(key)
                        }
                        targetEle.value = ''
                        setSuccessMsg('File upload successful')
                    } else if(!response.success){
                        setErrorMsg(response.error)
                    }
                    if(response.code === 'ERR_BAD_REQUEST'){
                        setErrorMsg(response.data.message)
                        return false
                    } else if(response.code === "ERR_NETWORK") {
                        setErrorMsg(response.message)
                    }
                }
                setFileLoader(false)
            })
        }
    }
    const deleteImage = (docId, jobId) => {
        setFileLoader(true)
        setErrorMsg('')
        setSuccessMsg('')
        let deletedDocId = docId;
        jobsService.deleteJobFiles(docId, jobId).then((response) => {
            if(response.success){
                const updatedDocs = jobFiles.filter((item) => item.doc.documentId !== deletedDocId)
                setjobFiles(updatedDocs)
                formik.setFieldValue('doc_urls', updatedDocs.map((item) => item.doc.documentId))
                setSuccessMsg(response.data)
            } else if(!response.success){
                setErrorMsg(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMsg(response.data.message)
                return false
            } else if(response.code === "ERR_NETWORK") {
                setErrorMsg(response.message)
            }
            setFileLoader(false)
        })
    }
    const jobPosted = (posted, message) => {
        setSuccessMsg('')
        if(posted){
            formik.resetForm()
            setjobFiles([])
            setSuccessMsg(message)
            setTimeout(() => {
                navigate('/customer/my-jobs')
            }, 3000);
        }
    }
    const handleTermChange = (option) => {
        const optionVal = assignedTiers.filter((item) => {return item.message === option})
        formik.setFieldValue("commissionTier", optionVal[0].level)
        setTermHelpText(option)
    }
    const downloadFile = async (url) => {
        try {
          const response = await axios.get(`https://cors.23byt.es/${url}`, { responseType: "blob" });
          const contentDisposition = response.headers["content-disposition"];
          const fileName = contentDisposition
            ? contentDisposition.split("filename=")[1]
            : url.split('/')[url.split('/').length - 1];
          saveAs(new Blob([response.data]), fileName);
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    }
    const handleAddField = () => {
        setScheduleError('')
        const last_entry = formik.values.schedules[formik.values.schedules.length - 1]
        if((last_entry && last_entry.startDate && last_entry.endDate) || formik.values.schedules.length === 0){
            if(formik.values.budget_type === 'Per Unit Quantity'){
                formik.setValues({
                ...formik.values,
                schedules: [...formik.values.schedules, { startDate: '', endDate: '', quantity: '' }],
                });
            } else{
                formik.setValues({
                ...formik.values,
                schedules: [...formik.values.schedules, { startDate: '', endDate: '' }],
                });
            }
        } else{
            setScheduleError('Please enter start and end schedule dates.')
        }
    }
    const handleRemoveField = (indexToRemove) => {
        formik.setValues({
          ...formik.values,
          schedules: formik.values.schedules.filter((_, index) => index !== indexToRemove),
        });
    }
    const handleEmailChange = (e) => {
        const trimmedValue = e.target.value.trim();
        formik.handleChange(e);
        formik.setFieldValue('site_contact_email', trimmedValue);
    }
  return (
    <>
    <DashboardWrapper>
        <div className='page-content'>
            <h2>Post a Job</h2>
            <div className='form'>
                <form onSubmit={formik.handleSubmit}>
                    <h3>Job Details</h3>
                    <p>To check the complete list of categories available, click <span className='link' onClick={() => showCategoryModal(true)}>here</span></p>
                    {user && user.multiActivity && <div className="input-wrapper mat-input icon-input">
                        <input type='checkbox' className='form-check-input' value={formik.values.isScheduled}  name="isScheduled" id='isScheduled' checked={formik.values.isScheduled} onChange={() => formik.setFieldValue('isScheduled', !formik.values.isScheduled)} />
                        <label htmlFor="isScheduled" style={{marginLeft: '10px'}}>Is this rate contract job with schedule?</label>
                            {(formik.touched.isScheduled && formik.errors.isScheduled) ? <div className='error'>{formik.errors.isScheduled}</div> : null}
                        <p className='help-text' style={{fontSize: '14px'}}><span className='icon icon-info-dark'></span> Rate contract jobs are long term and may have breaks.</p>
                        <p className='help-text' style={{fontSize: '14px'}}><span className='icon icon-info-dark'></span> An RC job must be at least 60 days long. The difference between start date and end date must be more than or equals to 60 days.</p>
                    </div>}
                    <div className='d-flex justify-content-between flex-wrap w-948'>
                        <div className="input-wrapper mat-input w-420">
                            <input type="text" name="job_title" className="form-control" id="jobControl" placeholder=' ' autoComplete='off'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.job_title}
                            />
                            <label htmlFor="jobControl" className="form-label">Job Title*</label>
                            {(formik.touched.job_title && formik.errors.job_title) ? <div className='error'>{formik.errors.job_title}</div> : null}
                        </div>
                        <SearchDropdown name="category" id="categoryControl"
                            columns="2"
                            autoPopulate={true}
                            onBlur={formik.handleBlur}
                            onSelectItem={(option) => formik.setFieldValue("category", option)}
                            data={allcategories && allcategories}
                            value={formik.values.category}
                            label="Category of Job*" searcherror={(formik.touched.category && formik.errors.category) ? formik.errors.category : null} />
                        <SearchDropdown name="equipment_type" id="equipmentControl"
                            onBlur={formik.handleBlur}
                            onSelectItem={(option) => formik.setFieldValue("equipment_type", option)}
                            data={allEquipmentType}
                            value={formik.values.equipment_type}
                            label="Equipment Type*" searcherror={(formik.touched.equipment_type && formik.errors.equipment_type) ? formik.errors.equipment_type : null} />
                        <div className="input-wrapper icon-input mat-input w-420">
                            <SelectDropdown
                                name="default_sac" id="defSacControl"
                                options={defaultSACList}
                                labelText='Select a SAC Code'
                                sacSelect={true}
                                value={customSAC ? (customSAC.sacCode === '' ? customSAC.sacInfo : customSAC.sacCode ? customSAC.sacCode : '') : ''}
                                onChange={(option) => {
                                    setCustomSAC(option)
                                    option.sacCode !== '' ? formik.setFieldValue("saccode", option.sacCode) : formik.setFieldValue("saccode", '')
                                    if(option.sacInfo === 'Select Other') {
                                        setTimeout(() => {
                                            document.getElementById('saccodeControl').focus()
                                        }, 500);
                                    }
                                }}
                            />
                            {!customSAC && formik.touched.saccode && formik.errors.saccode ? <div className='error'>{formik.errors.saccode}</div> : null}
                        </div>
                        <div className="input-wrapper icon-input mat-input w-420">
                            <SelectDropdown
                                name="brand_name" id="brandNameControl"
                                options={allBrandsName}
                                labelText='Brand Name'
                                multiselect={true}
                                value={formik.values.brand_name.length>0 ? ((formik.values.brand_name).map((item, index) => `${item}${index !== (formik.values.brand_name.length - 1)? ', ' : ''}`)) : ''}
                                onChange={(option) => formik.setFieldValue("brand_name", option)}
                            />
                            {(formik.touched.brand_name && formik.errors.brand_name) ? <div className='error'>{formik.errors.brand_name}</div> : null}
                        </div>
                        {customSAC && customSAC.sacInfo === 'Select Other' && <SearchDropdown name="saccode" id="saccodeControl"
                            onSelectItem={(option) => formik.setFieldValue("saccode", option)}
                            value={formik.values.saccode ? (isNaN(formik.values.saccode) ? Math.abs(formik.values.saccode.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(formik.values.saccode)) : ''}
                            maxLength={6}
                            sacSelect={true}
                            label="Enter SAC Code" searcherror={(formik.touched.saccode && formik.errors.saccode) ? formik.errors.saccode : null} />}
                        <SearchDropdown name="software" id="softwareControl"
                            onBlur={formik.handleBlur}
                            onSelectItem={(option) => formik.setFieldValue("software", option)}
                            data={allSoftwares}
                            value={formik.values.software}
                            label="Software" searcherror={(formik.touched.software && formik.errors.software) ? formik.errors.software : null} />
                        <div className="input-wrapper mat-input w-420">
                            <SelectDropdown
                                options={personNeedOptions}
                                labelText='Do you need a person do this job ?*'
                                value={formik.values.person_required}
                                onChange={(option) => formik.setFieldValue("person_required", option)}
                            />
                            {(formik.touched.person_required && formik.errors.person_required) ? <div className='error'>{formik.errors.person_required}</div> : null}
                        </div>
                        <LocationDropdown name="location" id="locationControl"
                            onBlur={formik.handleBlur}
                            onSelectItem={(option) => formik.setFieldValue("location", option)}
                            value={formik.values.location}
                            label="Location*" searcherror={(formik.touched.location && formik.errors.location) ? formik.errors.location : null} />
                        <div className="input-wrapper mat-input w-420">
                            <SelectDropdown
                                options={assignedTiers ? assignedTiers.map((item) => {return item.message}) : []}
                                labelText='Select Payment Terms'
                                value={TermHelpText ? TermHelpText : ''}
                                onChange={(option) => handleTermChange(option)}
                            />
                            {/*TermHelpText && <p className='help-text mb-0'>{TermHelpText}</p>*/}
                            {(formik.touched.commissionTier && formik.errors.commissionTier) ? <div className='error'>{formik.errors.commissionTier}</div> : null}
                        </div>
                        <div className={`input-wrapper mat-input icon-input timeenabled w-420 ${selectedDate ? 'date-selected' : ''}`}>
                            <DatePicker selected={selectedDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} 
                            name="start_date" id='jobStartControl' autoComplete='off'
                            minDate={new Date()}
                            onFocus={(e) => e.target.readOnly = true}
                            minTime={((selectedDate && selectedDate.toDateString() === (new Date()).toDateString()) || !selectedDate) ? new Date() : new Date().setHours(0, 0)}
                            maxTime={new Date().setHours(23, 59)}
                            onChange={handleDateChange}
                            onBlur={formik.handleBlur}
                            showTimeSelect
                            timeIntervals={60}
                            dateFormat="EEE, dd MMM, yyyy h:mm aa"
                            value={formik.values.start_date}
                            onCalendarOpen={onPickerOpen}
                            onCalendarClose={onPickerClose} />
                            <span className='icon icon-calendar'></span>
                            <label htmlFor="jobStartControl" className="form-label">Job Start Date*</label>
                            {(formik.touched.start_date && formik.errors.start_date) ? <div className='error'>{formik.errors.start_date}</div> : null}
                        </div>
                        <div className={`input-wrapper mat-input icon-input w-420 timeenabled ${selectedEndDate ? 'date-selected' : ''}`}>
                            <DatePicker selected={selectedEndDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="end_date" id='endDateControl' autoComplete='off'
                            minDate={formik.values.isScheduled ? new Date((new Date(formik.values.start_date)).setMonth(new Date(formik.values.start_date).getMonth() + 2)) : formik.values.start_date}
                            onChange={handleEndDateChange}
                            disabled={formik.values.start_date === ''}
                            onBlur={formik.handleBlur}
                            onFocus={(e) => e.target.readOnly = true}
                            minTime={(selectedDate && ((selectedEndDate && selectedDate.toDateString() === selectedEndDate.toDateString()) || !selectedEndDate)) ? new Date().setHours(selectedDate.getHours() + 1, 0) : new Date().setHours(0, 0)}
                            maxTime={new Date().setHours(23, 59)}
                            showTimeSelect
                            timeIntervals={60}
                            dateFormat="EEE, dd MMM, yyyy h:mm aa"
                            value={formik.values.end_date}
                            onCalendarOpen={onPickerOpen}
                            onCalendarClose={onPickerClose} />
                            <span className='icon icon-calendar'></span>
                            <label htmlFor="endDateControl" className="form-label">Job End Date*</label>
                            {(formik.touched.end_date && formik.errors.end_date) ? <div className='error'>{formik.errors.end_date}</div> : null}
                            <p className='help-text'><span className='icon icon-info-dark'></span> In respect to select end date, start date need to be selected.</p>
                        </div>
                        <div className="input-wrapper icon-input w-420">
                            <label htmlFor="docUrlsControl" className="form-label d-flex"><strong>Upload Documents</strong> (up to 5 attachements) {fileLoader && <div className="loader loader-small ms-1"></div>}</label>
                            <p className='help-text' style={{marginBottom: '10px'}}><span className='icon icon-info-dark'></span> File types allowed are JPEG, PNG, XLSX, WORD, IGES, STEP and PDF</p>
                            <div className='files-wrapper'>
                               <div className={`file-input-wrapper ${isDragging ? 'is-dragging' : ''} ${jobFiles.length>0 ? 'files-added' : ''}`}
                                        onDragEnter={onDragEnter}
                                        onDragOver={onDragEnter}
                                        onDragLeave={onDragLeave}
                                        onDrop={onDrop}>
                                        <input type="file" name="doc_urls" className="form-control file-control" id="docUrlsControl" multiple
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => handleFileUpload(e)}
                                    />
                                    <div className='file-upload-overlay'>
                                        <label>Upload</label>
                                        <span className='upload-icon'><span className='icon icon-upload'></span></span>
                                    </div>
                                </div>
                                {jobFiles && jobFiles.map((item, key) => item && <div className='file-item' key={key}>
                                    <div className='delete-icon'
                                    onClick={() => deleteImage(item.doc.documentId, jobid)}>
                                        <span className='icon icon-delete'></span>
                                    </div>
                                    <div className='image' onClick={() => downloadFile(item.doc.documentPath)}>
                                        <img src={
                                            item.doc.documentPath.split('.')[item.doc.documentPath.split('.').length - 1] === 'pdf' ? PDFIcon
                                            : item.doc.documentPath.split('.')[item.doc.documentPath.split('.').length - 1] === 'STEP' ? stepIcon
                                            : item.doc.documentPath.split('.')[item.doc.documentPath.split('.').length - 1] === 'iges' ? igesIcon
                                            : (item.doc.documentPath.split('.')[item.doc.documentPath.split('.').length - 1] === 'docx' || item.doc.documentPath.split('.')[item.doc.documentPath.split('.').length - 1] === 'doc') ? wordIcon
                                            : (item.doc.documentPath.split('.')[item.doc.documentPath.split('.').length - 1] === 'xls' || item.doc.documentPath.split('.')[item.doc.documentPath.split('.').length - 1] === 'xlsx') ? xlsIcon
                                            : item.doc.documentPath}
                                        alt={item.doc.documentPath.split('/')[item.doc.documentPath.split('/').length - 1]}
                                        />
                                    </div>
                                    <p onClick={() => downloadFile(item.doc.documentPath)}>{item.doc.documentPath.split('/')[item.doc.documentPath.split('/').length - 1]}</p></div>)}
                                {(formik.touched.doc_urls && formik.errors.doc_urls) || fileError ? <div className='error'>{formik.errors.doc_urls || fileError}</div> : null}
                            </div>
                        </div>
                        <div className="input-wrapper mat-input w-420">
                            <textarea name="description" className={`form-control ${formik.values.description !== '' ? 'textarea-filled' : ''}`} id="projectDescriptionControl" rows="3" style={{'height': '140px'}}
                            onChange={formik.handleChange}
                            maxLength={2000}
                            onBlur={formik.handleBlur} value={formik.values.description}></textarea>
                            <label htmlFor="projectDescriptionControl" className="form-label">Project Description*</label>
                            {(formik.touched.description && formik.errors.description) ? <div className='error'>{formik.errors.description}</div> : null}
                        </div>
                    </div>
                    <h3>Bid Details</h3>
                    <div className='d-flex justify-content-between flex-wrap w-948'>
                        <div className="input-wrapper mat-input w-420">
                            <SelectDropdown
                                options={bidTypeOptions}
                                labelText='Bid Type*'
                                value={formik.values.bid_type}
                                onChange={(option) => formik.setFieldValue("bid_type", option)}
                            />
                            {(formik.touched.bid_type && formik.errors.bid_type) ? <div className='error'>{formik.errors.bid_type}</div> : null}
                        </div>
                        <div className="input-wrapper mat-input w-420">
                            <SelectDropdown
                                options={formik.values.isScheduled ? amountTypeOptionsSchedule : amountTypeOptions}
                                labelText='Budget Type*'
                                value={formik.values.budget_type}
                                onChange={(option) => formik.setFieldValue("budget_type", option)}
                            />
                            {(formik.touched.budget_type && formik.errors.budget_type) ? <div className='error'>{formik.errors.budget_type}</div> : null}
                        </div>
                        {formik.values.bid_type === "Fixed budget" ? <div className="input-wrapper mat-input w-420">
                            <input type="text" name="bid_amount" className="form-control" id="bidAmountControl" placeholder=' '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bid_amount ? (isNaN(formik.values.bid_amount) ? Math.abs(formik.values.bid_amount.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(parseInt(formik.values.bid_amount))) : ''}
                            maxLength={10}
                            />
                            <label htmlFor="bidAmountControl" className="form-label">Enter Amount (₹)*</label>
                            {(formik.touched.bid_amount && formik.errors.bid_amount) ? <div className='error'>{formik.errors.bid_amount}</div> : null}
                        </div> : null}
                        
                        {/*formik.values.isScheduled && formik.values.budget_type === "Per Day" ? <div className="input-wrapper mat-input w-420">
                            <input type="text" name="minimum_assured_days" className="form-control" id="minAssuredDaysControl" placeholder=' '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={!isNaN(formik.values.minimum_assured_days) ? Math.round(formik.values.minimum_assured_days): formik.values.minimum_assured_days.split('').filter((char) => {return !isNaN(char)}).join('')}
                            />
                            <label htmlFor="minAssuredDaysControl" className="form-label">Enter Minimum Assured Days</label>
                            {(formik.touched.minimum_assured_days && formik.errors.minimum_assured_days) ? <div className='error'>{formik.errors.minimum_assured_days}</div> : null}
                        </div> : null}
                        
                        {formik.values.isScheduled && formik.values.budget_type === "Per Unit Quantity" ? <div className="input-wrapper mat-input w-420">
                            <input type="text" name="minimum_assured_quantity" className="form-control" id="minAssuredQuantityControl" placeholder=' '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={!isNaN(formik.values.minimum_assured_quantity) ? Math.round(formik.values.minimum_assured_quantity): formik.values.minimum_assured_quantity.split('').filter((char) => {return !isNaN(char)}).join('')}
                            />
                            <label htmlFor="minAssuredQuantityControl" className="form-label">Enter Minimum Assured Quantity</label>
                            {(formik.touched.minimum_assured_quantity && formik.errors.minimum_assured_quantity) ? <div className='error'>{formik.errors.minimum_assured_quantity}</div> : null}
                        </div> : null*/}
                        
                        {!formik.values.isScheduled && formik.values.budget_type === "Per Unit Quantity" ? <div className="input-wrapper mat-input w-420">
                            <input type="text" name="required_quantity" className="form-control" id="reqQuantityControl" placeholder=' '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.required_quantity ? (isNaN(formik.values.required_quantity) ? Math.abs(formik.values.required_quantity.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(formik.values.required_quantity)) : ''}
                            maxLength={10}
                            />
                            <label htmlFor="reqQuantityControl" className="form-label">Enter Required Quantity</label>
                            {(formik.touched.required_quantity && formik.errors.required_quantity) ? <div className='error'>{formik.errors.required_quantity}</div> : null}
                        </div> : null}
                        <div className="input-wrapper icon-input mat-input w-420">
                            <SelectDropdown
                                options={bidInclusionOptions}
                                labelText='Bid Inclusion*'
                                multiselect={true}
                                value={formik.values.bid_inclusion.length>0 ? ((formik.values.bid_inclusion).map((item, index) => `${item}${index !== (formik.values.bid_inclusion.length - 1)? ', ' : ''}`)) : ''}
                                onChange={(option) => formik.setFieldValue("bid_inclusion", option)}
                            />
                            {(formik.touched.bid_inclusion && formik.errors.bid_inclusion) ? <div className='error'>{formik.errors.bid_inclusion}</div> : null}
                            <p className='help-text'><span className='icon icon-info-dark'></span> Bid inclusions are overhead costs you expect the vendor to include in their quotation.</p>
                        </div>
                        <div className={`input-wrapper mat-input icon-input w-420 timeenabled ${selectedLastBidDate ? 'date-selected' : ''}`}>
                            <DatePicker selected={selectedLastBidDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="last_bid_date" id='LastBidDateControl' autoComplete='off'
                            minDate={new Date()}
                            maxDate={formik.values.start_date}
                            minTime={((selectedLastBidDate && new Date(selectedLastBidDate).toDateString() === (new Date()).toDateString()) || !selectedLastBidDate) ? new Date().setHours(new Date().getHours() + 1, 0) : new Date().setHours(0, 0)}
                            maxTime={(selectedDate && ((selectedLastBidDate && selectedDate.toDateString() === selectedLastBidDate.toDateString()) || !selectedLastBidDate)) ? new Date().setHours(selectedDate.getHours(), 0) : new Date().setHours(23, 59)}
                            onChange={handleLastBidDateChange}
                            onBlur={formik.handleBlur}
                            onFocus={(e) => e.target.readOnly = true}
                            showTimeSelect
                            timeIntervals={60}
                            dateFormat="EEE, dd MMM, yyyy h:mm aa"
                            value={formik.values.last_bid_date}
                            disabled={formik.values.start_date === '' && formik.values.end_date === ''}
                            onCalendarOpen={onPickerOpen}
                            onCalendarClose={onPickerClose} />
                            <span className='icon icon-calendar'></span>
                            <label htmlFor="LastBidDateControl" className="form-label">Last Bid Date*</label>
                            {(formik.touched.last_bid_date && formik.errors.last_bid_date) ? <div className='error'>{formik.errors.last_bid_date}</div> : null}
                            <p className='help-text'><span className='icon icon-info-dark'></span> In respect to select last bid date, end date need to be selected.</p>
                        </div>
                        <div className={`input-wrapper mat-input icon-input w-420 timeenabled ${selectedbidCloserDate ? 'date-selected' : ''}`}>
                            <DatePicker selected={selectedbidCloserDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="bidCloserDate" id='bidCloserDateControl' autoComplete='off'
                            minDate={formik.values.last_bid_date}
                            maxDate={formik.values.start_date}
                            minTime={(selectedLastBidDate && selectedbidCloserDate && selectedLastBidDate.toDateString() === selectedbidCloserDate.toDateString()) || (selectedLastBidDate && !selectedbidCloserDate) ? new Date().setHours(selectedLastBidDate.getHours() + 1, 0) : new Date().setHours(0, 0)}
                            maxTime={(selectedDate && selectedbidCloserDate && selectedDate.toDateString() === selectedbidCloserDate.toDateString()) ? new Date().setHours(selectedDate.getHours(), 0) : new Date().setHours(23, 59)}
                            onChange={handlebidCloserDateChange}
                            onBlur={formik.handleBlur}
                            showTimeSelect
                            onFocus={(e) => e.target.readOnly = true}
                            timeIntervals={60}
                            dateFormat="EEE, dd MMM, yyyy h:mm aa"
                            value={formik.values.bidCloserDate}
                            disabled={formik.values.start_date === '' || formik.values.last_bid_date === ''}
                            onCalendarOpen={onPickerOpen}
                            onCalendarClose={onPickerClose} />
                            <span className='icon icon-calendar'></span>
                            <label htmlFor="bidCloserDateControl" className="form-label">Job Award Date*</label>
                            {(formik.touched.bidCloserDate && formik.errors.bidCloserDate) ? <div className='error'>{formik.errors.bidCloserDate}</div> : null}
                            <p className='help-text'><span className='icon icon-info-dark'></span> By this date a vendor must be selected and Job awarded to process the PO.</p>
                            {secondaryErrorMsg && <div className='error'>{secondaryErrorMsg}</div>}
                        </div>
                    </div>
                    {formik.values.isScheduled && <>
                        <h3>Schedule</h3>
                        <p className='help-text mt-0 mb-3'><span className='icon icon-info-dark'></span> You must add at least one activity to post this job. You can add more activities once a vendor has accepted the job</p>
                        {formik.values.schedules.map((field, index) => (
                            <div key={index} className='d-flex justify-content-start align-items-center schedule-row flex-wrap w-948'>
                                <div className={`input-wrapper mat-input mb-0 icon-input timeenabled ${field.startDate ? 'date-selected' : ''}`}>
                                    <DatePicker selected={field.startDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}
                                    id={`jobStartControl-${index}`} autoComplete='off'
                                    minDate={formik.values.start_date}
                                    maxDate={formik.values.schedules.length===1 ? formik.values.start_date : formik.values.end_date}
                                    minTime={(selectedDate && selectedDate.toDateString() === new Date(field.startDate).toDateString()) ? new Date().setHours(selectedDate.getHours(), 0) : new Date().setHours(0, 0)}
                                    maxTime={(selectedEndDate && selectedEndDate.toDateString() === new Date(field.startDate).toDateString()) ? new Date().setHours(selectedEndDate.getHours(), 0) : new Date().setHours(23, 59)}
                                    onFocus={(e) => e.target.readOnly = true}
                                    onChange={(date) => formik.setFieldValue(`schedules[${index}].startDate`, date)}
                                    onBlur={formik.handleBlur}
                                    showTimeSelect
                                    timeIntervals={60}
                                    dateFormat="EEE, dd MMM, yyyy h:mm aa"
                                    value={field.startDate}
                                    onCalendarOpen={onPickerOpen}
                                    onCalendarClose={onPickerClose} />
                                    <span className='icon icon-calendar'></span>
                                    <label htmlFor={`jobStartControl-${index}`} className="form-label">Schedule Start Date*</label>
                                </div>
                                <div className={`input-wrapper mat-input mb-0 icon-input timeenabled ${field.endDate ? 'date-selected' : ''}`}>
                                    <DatePicker selected={field.endDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}
                                    id={`jobEndControl-${index}`} autoComplete='off'
                                    minDate={field.startDate}
                                    maxDate={formik.values.end_date}
                                    minTime={(field.startDate && field.startDate.toDateString() === new Date(field.endDateDate).toDateString()) ? new Date().setHours(field.startDate.getHours(), 0) : new Date().setHours(0, 0)}
                                    maxTime={(selectedEndDate && selectedEndDate.toDateString() === new Date(field.startDate).toDateString()) ? new Date().setHours(selectedEndDate.getHours(), 0) : new Date().setHours(23, 59)}
                                    onFocus={(e) => e.target.readOnly = true}
                                    onChange={(date) => formik.setFieldValue(`schedules[${index}].endDate`, date)}
                                    onBlur={formik.handleBlur}
                                    showTimeSelect
                                    timeIntervals={60}
                                    dateFormat="EEE, dd MMM, yyyy h:mm aa"
                                    value={field.endDate}
                                    disabled={formik.values.schedules[index].startDate === ''}
                                    onCalendarOpen={onPickerOpen}
                                    onCalendarClose={onPickerClose} />
                                    <span className='icon icon-calendar'></span>
                                    <label htmlFor={`jobEndControl-${index}`} className="form-label">Schedule End Date*</label>
                                </div>
                                {formik.values.budget_type === 'Per Unit Quantity' && <div className={`input-wrapper mat-input mb-0 w-110`}>
                                    <input type="text" name="quantity" className="form-control" id={`quantityControl-${index}`} placeholder=' '
                                    onChange={(e) => formik.setFieldValue(`schedules[${index}].quantity`, e.target.value)}
                                    onBlur={formik.handleBlur}
                                    value={field.quantity}
                                    />
                                    <label htmlFor={`quantityControl-${index}`} className="form-label">Quantity*</label>
                                </div>}
                            <button type="button" className='remove-btn' onClick={() => handleRemoveField(index)}><span className='icon icon-cross-white'></span></button>
                            </div>
                        ))}
                        <div className='w-100 input-wrapper'>
                            <button type='button' className='btn btn-secondary' onClick={handleAddField}>Add Activity</button>
                        </div>
                        {scheduleError || (formik.touched.schedules && formik.errors.schedules)  ? <div className='error'>{scheduleError || formik.errors.schedules}</div> : null}
                    </>}
                    <h3>Contact Details</h3>
                    <div className='d-flex justify-content-between flex-wrap w-948'>
                        <div className="input-wrapper mat-input w-420">
                            <input type="text" name="site_contact_name" className="form-control" id="contactNameControl" placeholder=' '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.site_contact_name}
                            />
                            <label htmlFor="contactNameControl" className="form-label">Site Contact Name*</label>
                            {(formik.touched.site_contact_name && formik.errors.site_contact_name) ? <div className='error'>{formik.errors.site_contact_name}</div> : null}
                        </div>
                        <div className="input-wrapper mat-input w-420">
                            <input type="text" name="site_contact_mobile" className="form-control" id="phoneControl" placeholder=' '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.site_contact_mobile ? (isNaN(formik.values.site_contact_mobile) ? Math.abs(formik.values.site_contact_mobile.split('').filter((char) => {return !isNaN(char)}).join('')) :  Math.abs(formik.values.site_contact_mobile)) : ''}
                            maxLength={10}
                            />
                            <label htmlFor="phoneControl" className="form-label">Site Contact Mobile*</label>
                            {(formik.touched.site_contact_mobile && formik.errors.site_contact_mobile) ? <div className='error'>{formik.errors.site_contact_mobile}</div> : null}
                        </div>
                        <div className="input-wrapper mat-input w-420">
                            <input type="text" name="site_contact_email" className="form-control" id="contactEmailControl" placeholder=' '
                            onChange={handleEmailChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.site_contact_email}
                            />
                            <label htmlFor="contactEmailControl" className="form-label">Site Contact Email*</label>
                            {(formik.touched.site_contact_email && formik.errors.site_contact_email) ? <div className='error'>{formik.errors.site_contact_email}</div> : null}
                        </div>
                    </div>
                    <button type="submit" className='btn btn-primary btn-medium'>Review Job</button>
                </form>
            </div>
        </div>
        {jobData && <ReviewModal showModal={showModal} viewModal={viewModal} jobData={jobData} jobPosted={jobPosted} jobFiles={jobFiles} tierMessage={TermHelpText} />}
        {viewCategoriesModal && allcategories && <CategoryModal showModal={showCategoryModal} viewModal={viewCategoriesModal} viewMode={true} />}
    </DashboardWrapper>
        {successMsg && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMsg}</MToast>}
        {errorMsg && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMsg}</MToast>}
    </>
  )
}

export default PostJob
