import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adminServices } from '../../services/admin.service';
import SelectDropdown from '../../shared/UI/SelectDropdown';
import AdminReportsTable from './AdminReportsTable';
import MToast from '../../shared/UI/MToast';
import MpaasPagination from '../../shared/UI/Pagination';

function AdminReportsVendor() {
    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [pickerOpen, setPickerOpen] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    //const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState('')
    const [isSticky, setSticky] = useState(false);
    let itemsCountList = [10, 25, 50, 100]
    //const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
    useEffect(() => {
        const handleScroll = () => {
          setSticky(window.scrollY > 100);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    const pageChangeHandler = (pageNumber) => {
        getVendorReports(selectedStartDate, selectedEndDate, pageNumber, itemsPerPage)
    }

    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    }
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    }
    const formatDate = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    const getVendorReports = (startdate, enddate, currentpage, itemsperPage) => {
        setIsLoading(true)
        const formatedSD = formatDate(startdate)
        const formatedED = formatDate(enddate)
        setCurrentPage(currentpage)
        adminServices.getVendorJobReport(formatedSD, formatedED, currentpage, itemsperPage, '-1').then((response) => {
            if(response.data){
                setTotalPage(Math.ceil(response.total / itemsPerPage))
                setItems(response.data)
                //setTotalItemsCount(response.total)
            } else if(!response.success){
              setErrorMessage(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
              setErrorMessage('Bad Request')
            } else if(response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
            setIsLoading(false)
          setTimeout(() => {
              setErrorMessage('')
          }, 3500);
        })
    }
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <h2>Vendor Count by Job</h2>
            <div className={`distinct-row ${isSticky ? 'sticked-row' : ''}`}>
                <div className='form'>
                   <div className={`input-wrapper mat-input icon-input ${selectedStartDate ? 'date-selected' : ''}`}>
                        <DatePicker selected={selectedStartDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="bidCloserDate" id='bidCloserDateControl' autoComplete='off'
                        onChange={handleStartDateChange}
                        dateFormat="yyyy-MM-dd"
                        value={selectedStartDate}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="LastBidDateControl" className="form-label">Start Date*</label>
                    </div>
                    <div className={`input-wrapper mat-input icon-input ${selectedEndDate ? 'date-selected' : ''}`}>
                        <DatePicker selected={selectedEndDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="bidCloserDate" id='bidCloserDateControl' autoComplete='off'
                        onChange={handleEndDateChange}
                        dateFormat="yyyy-MM-dd"
                        value={selectedEndDate}
                        disabled={selectedStartDate === ''}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="LastBidDateControl" className="form-label">End Date*</label>
                    </div>
                    <div className="input-wrapper mat-input mb-0" style={{minWidth: '150px'}}>
                        <SelectDropdown
                            options={itemsCountList}
                            labelText='Items per page'
                            value={itemsPerPage}
                            onChange={(option) => setItemsPerPage(option)}
                        />
                    </div>
                    <button type="submit" className={`btn btn-primary btn-medium ${selectedEndDate && selectedStartDate && !isLoading ? '' : 'disabled'}`} onClick={() => getVendorReports(selectedStartDate, selectedEndDate, 1, itemsPerPage)}>Search {isLoading && <span className='loader loader-small ms-1'></span>}</button>
                </div>
            </div>
            {totalPage > 1 ? <MpaasPagination totalPage={totalPage} onPageChange={pageChangeHandler} currentPage={currentPage} /> : null}

            <AdminReportsTable items={items} reportType="vendor_job" currentPageNo={currentPage} itemsPerPage={itemsPerPage} />

            {totalPage > 1 ? <MpaasPagination totalPage={totalPage} onPageChange={pageChangeHandler} currentPage={currentPage} /> : null}
        </div>
        {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </DashboardWrapper>
  )
}

export default AdminReportsVendor