import { apiData } from "./api";
import { authServices } from "./auth.services";

let createReview = async (reviewData) => {
    let ReviewData = {...reviewData}
    const token = await authServices.getToken();
    return await apiData.api_review.post(`/create`, ReviewData, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

const getReview = async (vendorId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_review.get(`/get-reviews?vendorId=${vendorId}&size=10&page=1&order=1`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

export const reviewService = {
    createReview, getReview
}