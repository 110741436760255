import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import MToast from '../../shared/UI/MToast';
import SelectDropdown from '../../shared/UI/SelectDropdown'
import { adminServices } from '../../services/admin.service'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { jobsService } from '../../services/jobs.service';

function EditPOModal({showModal, viewModal, poData, isEdited}) {
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    //const [jobData, setJobData] = useState(null)
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState('');
    const [pickerOpen, setPickerOpen] = useState(null);
    const statusList = ['PENDING', 'APPROVED', 'CANCELLED']
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        /*if(poData && poData.jobId){
            jobsService.getJobById(poData.jobId).then((response) => {
                if(response.success){
                    setJobData(response.data)
                } else if(!response.success){
                    setErrorMessage(response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                    setErrorMessage('Bad Request')
                } else if(response.code === "ERR_NETWORK") {
                    setErrorMessage(response.message)
                }
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 3500);
                setIsLoading(false)
            })
        }*/
    }, [viewModal])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const validate1 = values => {
        const errors = {}
        return errors;
    };
    const validate = values => {
        const errors = {}
        if(values.cusPoNum && !values.customerPoDate){
            errors.customerPoDate = 'Customer PO Date is required to update Customer PO Number'
        }
        if(values.customerPoDate && !values.cusPoNum){
            errors.cusPoNum = 'Customer PO Number is required to update Customer PO Date'
        }
        return errors;
    };
    
    const formik = useFormik({
        initialValues: { cusPoNum: poData.customerPoNumber || '', customerPoDate: new Date(poData.customerPoDate) || '', status: poData.status || '' },
        validate,
        onSubmit: (values, { setSubmitting }) => {
            updatePO(values)
            setSubmitting(false);
        },
    });
    
    const formik1 = useFormik({
        initialValues: { commission: poData.commission || '', PoAmt: poData.PoAmt || '', status: poData.status || '' },
        validate1,
        onSubmit: (values, { setSubmitting }) => {
            updateVendorPO(values)
            setSubmitting(false);
        },
    });

    const updatePO = (values) => {
        setIsLoading(true)
        adminServices.updatePurchasedOrders(poData.jobId, poData.poNumber, values.status, '', values.cusPoNum, values.customerPoDate).then((response) => {
            if(response.success){
                setSuccessMessage(response.data)
                if(isEdited) isEdited(true)
            } else if(!response.success){
                setErrorMessage(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage('Bad Request')
            } else if(response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
            setTimeout(() => {
                setErrorMessage('')
                setSuccessMessage('')
            }, 3500);
            setIsLoading(false)
        })
    }

    const updateVendorPO = (values) => {
        setIsLoading(true)
        adminServices.updatePurchasedOrders(poData.jobId, poData.poNumber, values.status).then((response) => {
            if(response.success){
                setSuccessMessage(response.data)
                if(isEdited) isEdited(true)
            } else if(!response.success){
                setErrorMessage(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage('Bad Request')
            } else if(response.code === "ERR_NETWORK") {
                setErrorMessage(response.message)
            }
            setTimeout(() => {
                setErrorMessage('')
                setSuccessMessage('')
            }, 3500);
            setIsLoading(false)
            setIsLoading(false)
        })
    }
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
        formik.setFieldValue('customerPoDate', date)
    };

    useEffect(() => {
        if(poData && poData.customerPoDate) {
            setSelectedDate(new Date(poData.customerPoDate))
        }
    }, [poData])
  return (
    <>
    <Modal show={show} onHide={handleHide} className='mpass-modal'>
        <Modal.Header>
            <Modal.Title>Update PO</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <div className='invoice-bid-info-wrapper'>
                <ul className='bid-info invoice-bid-info'>
                    <li>
                        <span>Job Id</span>
                        <div>{poData.jobId}</div>
                    </li>
                    {poData.destination.toLowerCase() === 'mpaas' && poData.customerCompanyName && <li>
                        <span>Customer Name</span>
                        <div>{poData.customerCompanyName} (From)</div>
                    </li>}
                    <li>
                        <span>MPAAS&reg;</span>
                        <div>{poData.destination.toLowerCase() === 'mpaas' ? <>{poData.destination}&reg; (To)</> : <>{poData.source}&reg; (From)</>}</div>
                    </li>
                    {poData.source.toLowerCase() === 'mpaas' && poData.vendorCompanyName && <li>
                        <span>Vendor Name</span>
                        <div>{poData.vendorCompanyName} (To)</div>
                    </li>}
                    <li>
                        <span>PO Date</span>
                        <div>{poData.createdAt}</div>
                    </li>
                    <li>
                        <span>PO Number</span>
                        <div>{poData.poNumber}</div>
                    </li>
                    {poData.destination.toLowerCase() === 'mpaas' && poData.customerPoNumber &&<li>
                        <span>Customer PO Number</span>
                        <div>{poData.customerPoNumber}</div>
                    </li>}
                    {/*poData.destination.toLowerCase() === 'mpaas' &&<li>
                        <span>Customer PO Amount</span>
                        <div>{poData.customerPoAmount ? poData.customerPoAmount : '-'}</div>
                    </li>*/}
                    {poData.destination.toLowerCase() === 'mpaas' &&<li>
                        <span>Status Date</span>
                        <div>{poData.updatedAt}</div>
                    </li>}
                </ul>
                <ul className='bid-info invoice-bid-info'>
                <li>
                        <span>Job Value</span>
                        <div>{Math.round(poData.amount)}</div>
                    </li>
                    {poData.source.toLowerCase() === 'mpaas' && <li>
                        <span>Fixed Commission (-5%)</span>
                        <div>{Math.round(poData.fixComission)}</div>
                    </li>}
                    <li>
                        <span>GST (18%)</span>
                        <div>{Math.round(poData.tax)}</div>
                    </li>
                    <li>
                        <span>Net PO Amount</span>
                        <div>{Math.round(poData.grandTotalPoAmount)}</div>
                    </li>
                    {/*poData.source.toLowerCase() === 'mpaas' &&<><li>
                        <span>Fix Commission (5%)</span>
                        <div>{Math.round(poData.fixComission)}</div>
                    </li><li>
                        <span>Extra Commission (3%)</span>
                        <div>{Math.round(poData.extraComission)}</div>
                    </li></>*/}
                </ul>
            </div>
            {poData.destination.toLowerCase() === 'mpaas' ? <form onSubmit={formik.handleSubmit} className='split-form'>
                <div className="input-wrapper mat-input">
                    <input type="text" name="cusPoNum" className="form-control" id="cusPoNumControl" placeholder=' '
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cusPoNum}
                    />
                    <label htmlFor="cusPoNumControl" className="form-label">Customer PO Number</label>
                    {(formik.touched.cusPoNum && formik.errors.cusPoNum) ? <div className='error'>{formik.errors.cusPoNum}</div> : null}
                </div>
                {/*<div className="input-wrapper mat-input">
                    <input type="text" name="cusPoAmt" className="form-control" id="cusPoAmtControl" placeholder=' '
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cusPoAmt}
                    />
                    <label htmlFor="cusPoAmtControl" className="form-label">Customer PO Amount</label>
                    {(formik.touched.cusPoAmt && formik.errors.cusPoAmt) ? <div className='error'>{formik.errors.cusPoAmt}</div> : null}
                </div>*/}
                <div className={`input-wrapper mat-input icon-input ${selectedDate ? 'date-selected' : ''}`}>
                    <DatePicker selected={selectedDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} 
                        name="customerPoDate" id='customerPoDateControl' autoComplete='off'
                        onChange={handleDateChange}
                        onBlur={formik.handleBlur}
                        dateFormat="dd/MM/yyyy"
                        value={formik.values.customerPoDate}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="customerPoDateControl" className="form-label">Customer PO Date</label>
                        {(formik.touched.customerPoDate && formik.errors.customerPoDate) ? <div className='error'>{formik.errors.customerPoDate}</div> : null}
                </div>
                <div className="input-wrapper mat-input">
                    <SelectDropdown
                        options={statusList ? statusList : []}
                        labelText='Status'
                        value={formik.values.status ? formik.values.status : ''}
                        onChange={(option) => formik.setFieldValue('status', option)}
                    />
                    {(formik.touched.status && formik.errors.status) ? <div className='error'>{formik.errors.status}</div> : null}
                </div>
                <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`}>Update PO {isLoading && <div className='loader loader-medium'></div>}</button>
            </form> : <form onSubmit={formik1.handleSubmit} className='split-form'>
            <div className="input-wrapper mat-input">
                <SelectDropdown
                    options={statusList ? statusList : []}
                    labelText='Status'
                    value={formik1.values.status ? formik1.values.status : ''}
                    onChange={(option) => formik1.setFieldValue('status', option)}
                />
                {(formik1.touched.status && formik1.errors.status) ? <div className='error'>{formik1.errors.status}</div> : null}
            </div>
            <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`}>Update PO {isLoading && <div className='loader loader-medium'></div>}</button>
        </form>}
        </Modal.Body>
    </Modal>
    {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
    {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </>
  )
}

export default EditPOModal