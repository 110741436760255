import { apiData } from "./api";
import { authServices } from "./auth.services"

const updateVendor = async (vendorInfo) => {
    let updatedVendorInfo = {}
    if(vendorInfo.email_address !== '' && vendorInfo.email_address !== undefined) updatedVendorInfo['email'] = vendorInfo['email_address'];
    if(vendorInfo.location !== '' && vendorInfo.location !== undefined) updatedVendorInfo['location'] = vendorInfo['location'];
    if(vendorInfo.phone_no !== '' && vendorInfo.phone_no !== undefined) updatedVendorInfo['mobile'] = vendorInfo['phone_no'];
    if(vendorInfo.profile_image !== '' && vendorInfo.profile_image !== undefined) updatedVendorInfo['profileImage'] = vendorInfo['profile_image'];
    if(vendorInfo.profile_description !== '' && vendorInfo.profile_description !== undefined) updatedVendorInfo['about'] = vendorInfo['profile_description'];
    if(vendorInfo.category !== '' && vendorInfo.category !== undefined) updatedVendorInfo['category'] = vendorInfo['category'];
    if(vendorInfo.city !== '' && vendorInfo.city !== undefined) updatedVendorInfo['city'] = vendorInfo['city'];
    if(vendorInfo.state !== '' && vendorInfo.state !== undefined) updatedVendorInfo['state'] = vendorInfo['state'];
    if(vendorInfo.pin !== '' && vendorInfo.pin !== undefined) updatedVendorInfo['pin'] = vendorInfo['pin'];
    if(vendorInfo.companyAddress !== '' && vendorInfo.companyAddress !== undefined) updatedVendorInfo['companyAddress'] = vendorInfo['companyAddress'];
    if(vendorInfo.accountNumber !== '' && vendorInfo.accountNumber !== undefined) updatedVendorInfo['accountNumber'] = vendorInfo['accountNumber'];
    if(vendorInfo.ifsc !== '' && vendorInfo.ifsc !== undefined) updatedVendorInfo['ifsc'] = vendorInfo['ifsc'];
    if(vendorInfo.accountName !== '' && vendorInfo.accountName !== undefined) updatedVendorInfo['accountName'] = vendorInfo['accountName'];
    if(vendorInfo.accountType !== '' && vendorInfo.accountType !== undefined) updatedVendorInfo['accountType'] = vendorInfo['accountType'];
    if(vendorInfo.multiOffices !== '' && vendorInfo.multiOffices !== undefined) updatedVendorInfo['multiOffices'] =  vendorInfo.multiOffices === 'Multiple Locations' ? true : false
    if(vendorInfo.employeeCountUpto !== '' && vendorInfo.employeeCountUpto !== undefined) updatedVendorInfo['employeeCountUpto'] = vendorInfo.employeeCountUpto === '0-5' ? 5 : vendorInfo.employeeCountUpto === '5-10' ? 10 : vendorInfo.employeeCountUpto === 'More than 10' ? 11 : 0
    try {
        const token = await authServices.getToken();
        return await apiData.api_vendor.post(`/update`, updatedVendorInfo, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const updateCustomer = async (customerInfo) => {
    let updatedCustomerInfo = {}
    if(customerInfo.customerId !== '' && customerInfo.customerId !== undefined) updatedCustomerInfo['customerId'] = customerInfo['customerId'];
    if(customerInfo.email_address !== '' && customerInfo.email_address !== undefined) updatedCustomerInfo['email'] = customerInfo['email_address'];
    if(customerInfo.phone_no !== '' && customerInfo.phone_no !== undefined) updatedCustomerInfo['mobile'] = customerInfo['phone_no'];
    if(customerInfo.city !== '' && customerInfo.city !== undefined) updatedCustomerInfo['city'] = customerInfo['city'];
    if(customerInfo.state !== '' && customerInfo.state !== undefined) updatedCustomerInfo['state'] = customerInfo['state'];
    if(customerInfo.pin !== '' && customerInfo.pin !== undefined) updatedCustomerInfo['pin'] = customerInfo['pin'];
    if(customerInfo.companyAddress !== '' && customerInfo.companyAddress !== undefined) updatedCustomerInfo['companyAddress'] = customerInfo['companyAddress'];
    try {
        const token = await authServices.getToken();
        return await apiData.api_customer.post(`/update`, updatedCustomerInfo, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getCustomerInfo = async (customerId) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_customer.get(`/get?customerId=${customerId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getCustomerStats = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_customer.get(`/stats`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const getVendorStats = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_vendor.get(`/stats`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

export const UserService = {updateVendor, updateCustomer, getCustomerInfo, getCustomerStats, getVendorStats}