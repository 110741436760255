import React, { useEffect, useState } from "react";
//import RowDropdown from "../../shared/UI/RowDropdown";
//import SelectDropdown from "../../shared/UI/SelectDropdown";
import CompanyTableRow from "./CompanyTableRow";
import { adminServices } from "../../services/admin.service";
import MToast from "../../shared/UI/MToast";

function CompanyTable({ usersData, tableUpdated }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [tierList, setTierList] = useState([]);
    useEffect(() => {
        getTierList();
    }, []);
    const getTierList = () => {
        adminServices.getTierList().then((response) => {
            if (response.success) {
                setTierList(response.data);
            } else if (!response.success) {
                setErrorMessage(response.error);
            }
            if (response.code === "ERR_BAD_REQUEST") {
                setErrorMessage('Bad Request. Please try again later.');
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message);
            }
        });
    };
    return (
        <div className="table hover-table user-tier-table">
            {usersData && usersData.length > 0 ? (
                <div className="table-body">
                    <div className="table-header">
                        <div className="serial">S. No.</div>
                        <div>Company Name</div>
                        <div>Company GST</div>
                        <div>Tier</div>
                        <div>Allow RC Jobs</div>
                        <div className="action">Action</div>
                    </div>
                    {usersData.map((item, index) => (
                        <CompanyTableRow key={index} userData={item} sno={index} tierList={tierList} tableUpdated={tableUpdated} />
                    ))}
                </div>
            ) : (
                <div className="table-body">
                    <div className="table-row">No users available.</div>
                </div>
            )}
            {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
        </div>
    );
}

export default CompanyTable;
