import React, { useEffect, useRef, useState } from 'react'
import { externalServices } from '../../services/external.services';

function LocationDropdown({label, searcherror, onSelectItem, isFull, ...rest}) {
    //const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [AllCities, setAllCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    //const [searching, setSearching] = useState(true);
    const dropDown = useRef(null)

    useEffect(() => {
      getCities('')
      const onClickOutside = (event) => {
        if(dropDown.current && !dropDown.current.contains(event.target)){
          setIsOpen(false)
        }
      }
      document.addEventListener("mousedown", onClickOutside)
      return () => {
        document.removeEventListener("mousedown", onClickOutside)
      }
    }, [dropDown]);
  
    const handleInputChange = async (event) => {
      const value = event.target.value;
      //setSearchTerm(value);
      onSelectItem(value)
      //await getCities(value);
      if (value) {
        const filteredCitiesArr = AllCities.filter((city) => city.name.toLowerCase().includes(value.toLowerCase()))
        setFilteredCities(filteredCitiesArr)
        setIsOpen(true);
      } else {
        setFilteredCities([]);
      }
    };
  
    const getCities = async (cityStr) => {
      //if(searching){
        //setSearching(false)
        await externalServices.getCities(cityStr).then((response) => {
            if(response.data){
                let cities = response.data
                setAllCities(cities)
                //setSearching(true)
            }
        })
    //}
    }
    const handleOptionClick = (item) => {
        //setSearchTerm(item);
        setIsOpen(false)
        onSelectItem(item);
    };
  
    return (
        <div className={`input-wrapper mat-input icon-input ${isFull ? 'w-100' : 'w-420'}`} ref={dropDown}>
            <input type="text" className="form-control" {...rest} placeholder={rest.placeholder || ' '} value={rest.value} onChange={handleInputChange} autoComplete='off' />
            <label htmlFor={rest.id} className="form-label">{label}</label>
            {searcherror !== '' ? <div className='error'>{searcherror}</div> : null}
            <span className={`icon icon-search`}></span>
            {isOpen ? <ul className='search-options'>
            {filteredCities && filteredCities.map((result) => (
                <li key={result.id} onClick={() => handleOptionClick(result.name + ', ' + result.state)}>
                  {result.name + ', ' + result.state}
                </li>
            ))}
            </ul>: null}
      </div>
    );
}

export default LocationDropdown