import React, { useEffect, useState } from 'react'
import AdminReportsRow from './AdminReportsRow';
import { Accordion } from 'react-bootstrap';
import '../../scss/accordion.scss'
import { adminServices } from '../../services/admin.service';
import SelectDropdown from '../../shared/UI/SelectDropdown';
import MToast from '../../shared/UI/MToast';

function AdminReportsTable({items, reportType, userType, currentPageNo, itemsPerPage, tableUpdated}) {
    const [activeIndex, setActiveIndex] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedTier, setSelectedTier] = useState(null);
    const [updatedTier, setUpdatedTier] = useState('');
    const [openForm, setOpenForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [tierList, setTierList] = useState([]);
    useEffect(() => {
        getTierList();
    }, []);
    const getTierList = () => {
        adminServices.getTierList().then((response) => {
            if (response.success) {
                setTierList(response.data);
            } else if (!response.success) {
                setErrorMessage(response.error);
            }
            if (response.code === "ERR_BAD_REQUEST") {
                setErrorMessage('Bad Request. Please try again later.');
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message);
            }
        });
    };
    const updateTier = (customerId, tier) => {
        setIsLoading(true)
        adminServices.updateCustomerTier(customerId, tier).then((response) => {
            if (response.success) {
                if(tier !== 'PLATINUM'){
                    handleRCCheck(customerId, false)
                }
                setSuccessMessage(response.data);
                setOpenForm(false)
                setUpdatedTier(tier)
            } else if (response.error) {
                setErrorMessage(response.error);
            }
            if (response.code === "ERR_BAD_REQUEST") {
                setErrorMessage('Bad Request. Please try again later.');
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message);
            }
            setIsLoading(false)
        });
    }
    
    const handleRCCheck = (customerId, allowRC) => {
        adminServices.allowRCJobs(customerId, allowRC).then((response) => {
            if (response.success) {
                setSuccessMessage(response.data)
                if(tableUpdated) tableUpdated(true)
            } else if (response.error) {
                setErrorMessage(response.error);
            }
            if (response.code === "ERR_BAD_REQUEST") {
                setErrorMessage('Bad Request. Please try again later.');
            } else if (response.code === "ERR_NETWORK") {
                setErrorMessage(response.message);
            }
            setTimeout(() => {
                setIsLoading(false)
                setErrorMessage('')
                setSuccessMessage('')
            }, 3000);
        });
    }

    const handleAccordionSelect = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };
    useEffect(() => {
        if(currentPageNo) setActiveIndex(null);
      }, [currentPageNo])

    const activateUser = (userType, userId) => {
        adminServices.activateUser(userType, userId).then((response) => {
            if (response.success) {
              tableUpdated(true, response.data)
            } else if (!response.success) {
              tableUpdated(false, response.error);
            } 
            if (response.code === "ERR_BAD_REQUEST") {
              tableUpdated(false, response.data.message);
                return false;
            } else if (response.code === "ERR_NETWORK") {
              tableUpdated(false, response.message);
            }
        });
    };
    const suspendUser = (userType, userId) => {
        adminServices.suspendUser(userType, userId).then((response) => {
          if (response.success) {
            tableUpdated(true, response.data)
          } else if (!response.success) {
            tableUpdated(false, response.error);
          }
          if (response.code === "ERR_BAD_REQUEST") {
            tableUpdated(false, 'Bad Request');
              return false;
          } else if (response.code === "ERR_NETWORK") {
            tableUpdated(false, response.message);
          }
        });
    };
    const handleActiveCheck = (userId, status) => {
        const usertp = userType === 'Customers' ? 'CUSTOMER' : 'VENDOR'
        if(status){
            suspendUser(usertp, userId)
        } else{
            activateUser(usertp, userId)
        }
    }
  return (
    <>
        {items && Object.keys(items).length > 0 ? <Accordion defaultActiveKey="0" activeKey={activeIndex} className='mpass-accordion' onSelect={handleAccordionSelect}>
                    {userType !== 'Admin' && items ? items.map((item, index) => (
                        <Accordion.Item eventKey={index} key={index} className={activeIndex === index ? 'active' : ''}>
                            <Accordion.Header>{((currentPageNo - 1) * itemsPerPage) + index+1}. {item.companyName} - {item.companyGstNo} {item.mobile && <small> (Primary Contact: {item.mobile})</small>}</Accordion.Header>
                            <Accordion.Body>
                                <div className='company-actions d-flex align-items-center flex-wrap'>
                                    {userType === 'Customers' && <>
                                        <div className='tier-box'>
                                            <h4 className='mb-0'>Tier: {updatedTier ? updatedTier : item.tier} <span className='icon icon-edit' onClick={() => setOpenForm(!openForm)}></span></h4>
                                            {openForm && <div className="form mb-0 inline-form">
                                                <div className="input-wrapper mat-input w-auto" style={{minWidth: '120px'}}>
                                                    <SelectDropdown
                                                        className="select-small"
                                                        options={
                                                            tierList.length > 0 ? tierList.map((item) => item.level) : []
                                                        }
                                                        labelText='Select Tier'
                                                        value={selectedTier ? selectedTier : item.tier}
                                                        onChange={(option) =>
                                                            setSelectedTier(option)
                                                        }
                                                    />
                                                </div>
                                                <button className={`btn btn-primary ${isLoading ? 'disabled' : ''}`} onClick={() => updateTier(item.customerId, selectedTier)}>Save{isLoading && <span className='loader loader-small ms-1'></span>}</button>
                                            </div>}
                                        </div>
                                        <div className='rc-box d-flex align-items-center me-2 ms-md-auto'>
                                            <p className='help-text mt-0 me-1'>Allow RC</p>
                                            <div className='allow-rc'>
                                                {item.customerId ? <div className={`${item.tier && item.tier.toLowerCase() !== 'platinum' ? "rc-tooltip" : ''}`}>
                                                <label className="rc-switch">
                                                    <input type="checkbox" name="rccheck" onChange={() => handleRCCheck(item.customerId, !item.multiactivityAllowed)} checked={item.multiactivityAllowed} />
                                                    <span className="slider"></span>
                                                </label>
                                                {item.tier && item.tier.toLowerCase() !== 'platinum' ? <div className='tooltip tooltip-left'>Allowing RC Jobs will automatically convert user into PLATINUM.</div> : null}
                                                </div> : null}
                                            </div>
                                        </div>
                                    </>}
                                    {userType === 'Customers' || userType === 'Vendors' ? <div className='rc-box d-flex align-items-center'>
                                        <p className='help-text mt-0 me-1'>{item.active ? 'Suspend' : 'Activate'} Users</p>
                                        <div className='allow-rc'>
                                            {item.customerId || item.vendorId ? <div>
                                            <label className="rc-switch danger-switch">
                                                {userType === 'Customers' ? <input type="checkbox" name="activatecheck" onChange={() => handleActiveCheck(item.customerId, item.active)} checked={!item.active} /> : <input type="checkbox" name="activatecheck" onChange={() => handleActiveCheck(item.vendorId, item.active)} checked={!item.active} />}
                                                <span className="slider"></span>
                                            </label>
                                            </div> : null}
                                        </div>
                                    </div> : null}
                                </div>
                                <div className={`table hover-table mb-0 mobile-responsive ${reportType === 'onboard' ? 'ob-reports-table' : 'ven-reports-table'}`}>
                                    <div className='table-body'>
                                        <div className='table-header'>
                                            <div className='serial'>S. No.</div>
                                            <div>Name</div>
                                            <div>Role</div>
                                            <div>Joining Date</div>
                                            <div>Phone Number</div>
                                            <div>Email</div>
                                            <div>Status</div>
                                            <div className='action'></div>
                                        </div>
                                        {item.user && item.user.map((user, userindex) => (<AdminReportsRow item={user} key={userindex} reportType={reportType} sno={userindex} tableUpdated={tableUpdated} />))}
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )) : userType === 'Admin' && items && items[0].admin && items[0].admin.length>0 ? items.map((item, index) => (
                        <Accordion.Item eventKey={index} key={index} className={activeIndex === index ? 'active' : ''}>
                            <Accordion.Header>MPAAS&reg;</Accordion.Header>
                            <Accordion.Body>
                            <div className={`table hover-table mb-0 ob-reports-table`}>
                                <div className='table-body'>
                                    <div className='table-header'>
                                        <div className='serial'>S. No.</div>
                                        <div>Name</div>
                                        <div>Role</div>
                                        <div>Joining Date</div>
                                        <div>Phone Number</div>
                                        <div>Email</div>
                                    </div>
                                    {item['admin'] && item['admin'].map((user, userindex) => (<AdminReportsRow item={user} key={userindex} reportType={reportType} sno={userindex} />))}
                                </div>
                            </div>
                            </Accordion.Body>
                        </Accordion.Item>)) : <div className='table-body'>No data available.</div>}
                </Accordion> : null}
            {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
            {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
    </>
  )
}

export default AdminReportsTable