import React from 'react'

function ProfileCard({vendorDetail}) {
  return (
    <>{vendorDetail && <div className='review-card'>
        <div className="user-info align-items-start">
            <div className="user-thumb"></div>
            <div className="user-content mb-0">
                <h2>{vendorDetail.companyName}</h2>
                <div className="location">GST No: <strong>{vendorDetail.companyGstNo}</strong></div>
                <div className="location">{vendorDetail.location}</div>
                <div className="rating-review">
                    <div className="rating">{vendorDetail.rating} <span className="icon icon-star"></span></div>
                    <div>{vendorDetail.reviewCount} Reviews</div>
                </div>
            </div>
        </div>
    </div>}</>
  )
}

export default ProfileCard