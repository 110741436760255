import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import UsersTable from './UsersTable'
import { Tab, Tabs } from 'react-bootstrap'
import { adminServices } from '../../services/admin.service'
import MToast from '../../shared/UI/MToast'

function ManageUsersPage() {
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [gstNo, setGstNo] = useState('')
    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [suspendedUsersData, setSuspendedUsersData] = useState([])
    const [activeUsersData, setActiveUsersData] = useState([])
    /*const [selectedSuspendedUsers, setSelectedSuspendedUsers] = useState([])
    const [selectedactiveUsers, setSelectedActiveUsers] = useState([])*/
    useEffect(() => {
        getUsersByGST(true, '', 1, 10)
        getUsersByGST(false, '', 1, 10)
    }, [])
    const getUsersByGST = (status, gstno, pageNo, pageSize) => {
        setIsLoading(true)
        setIsFetching(true)
        adminServices.listUserByGST(status, gstno, pageNo, pageSize).then((response) => {
            if(response){
                if(status){
                    setActiveUsersData(response)
                } else{
                    setSuspendedUsersData(response)
                }
                setIsLoading(false)
                setIsFetching(false)
            }
        })
    }
    const handleUserSearch = (e) => {
        e.preventDefault()
        if(gstNo.length===15){
            getUsersByGST(true, gstNo)
            getUsersByGST(false, gstNo)
        } else{
            setErrorMessage('GST no. must be 15 characters')
        }
    }
    const userUpdated = (isUpdated, message) => {
        if(isUpdated){
            setSuccessMessage(message)
            getUsersByGST(true, '')
            getUsersByGST(false, '')
        } else{
            setErrorMessage(message)
        }
        setTimeout(() => {
            setErrorMessage('')
            setSuccessMessage('')
        }, 3500);
    }
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <h2>Users</h2>
            <h3>Find Users</h3>
            <div className='form inline-form'>
                <div className="input-wrapper mat-input w-420">
                    <input type="text" name="companygst" className="form-control" id="companygstControl" placeholder=' ' onChange={(e) => setGstNo(e.target.value)} />
                    <label htmlFor="userControl" className="form-label">Enter GST Number</label>
                </div>
                <button type="button" className={`btn btn-primary btn-medium ${gstNo && isLoading ? 'disabled' : ''}`} onClick={(e) => handleUserSearch(e)}>Search{gstNo && isLoading && <span className='loader loader-small ms-1'></span>}</button>
            </div>
            {(suspendedUsersData.n>0 || activeUsersData.n>0) ? <Tabs className="jobs-tab users-tabs" defaultActiveKey="active">
                <Tab eventKey="active" title="Active">
                    <UsersTable usersData={activeUsersData} usersType="active" userUpdated={userUpdated} />
                </Tab>
                <Tab eventKey="suspended" title="Suspended">
                    <UsersTable usersData={suspendedUsersData} usersType="suspended" userUpdated={userUpdated} />
                </Tab>
            </Tabs> : isFetching ? <div>Loading Data...</div> : <div>No users available</div>}
            {/*<div className='split-box'>
                <div className='left'>
                    <h4>Active</h4>
                    <ul>
                        {activeUsers.map((user) => <li onClick={(e) => selectActiveUsers(e, user)}>
                            {user}
                        </li>)}
                    </ul>
                </div>
                <div className='center-nav'>
                    <span className='icon icon-chevron-right-double' onClick={updateAllSuspendedUser}></span>
                    <span className='icon icon-chevron-left-double' onClick={updateAllActiveUsers}></span>
                    <span className='icon icon-chevron-right' onClick={updateSuspendedUser}></span>
                    <span className='icon icon-chevron-left' onClick={updateActiveUsers}></span>
                </div>
                <div className='right'>
                    <h4>Suspended</h4>
                    <ul>
                        {suspendedUsers.map((user) => <li onClick={(e) => selectSuspendedUsers(e, user)}>
                            {user}
                        </li>)}
                    </ul>
                </div>
                <div className='full-width w-100 d-flex justify-content-center'>
                    <button className='btn btn-primary btn-medium'>Update</button>
                </div>
            </div>*/}
        </div>
        {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
        {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </DashboardWrapper>
  )
}

export default ManageUsersPage