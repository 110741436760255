import { apiv2 } from "./api-v2";
import { authServices } from "./auth.services";

const createBid = async (bidData) => {
    const token = await authServices.getToken();
    return await apiv2.api_bid.post(`/create`, bidData, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

const updateBid = async (bidData) => {
    const token = await authServices.getToken();
    return await apiv2.api_bid.post(`/update`, bidData, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

const getJobBids = async (jobId) => {
    const token = await authServices.getToken();
    return await apiv2.api_bid.get(`/bids?size=1000&page=1&order=1&jobid=${jobId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

export const bidServicev2 = {
    createBid,
    updateBid,
    getJobBids
}