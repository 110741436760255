import axios from "axios";

const api_ob = axios.create({
    baseURL: process.env.REACT_APP_API_URL_OB,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_internal = axios.create({
    baseURL: process.env.REACT_APP_API_URL_INTERNAL,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_auth = axios.create({
    baseURL: process.env.REACT_APP_API_URL_AUTH,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_bid = axios.create({
    baseURL: process.env.REACT_APP_API_URL_BID,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_job = axios.create({
    baseURL: process.env.REACT_APP_API_URL_JOB,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_vendor = axios.create({
    baseURL: process.env.REACT_APP_API_URL_VENDOR,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_review = axios.create({
    baseURL: process.env.REACT_APP_API_URL_REVIEW,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_fleet = axios.create({
    baseURL: process.env.REACT_APP_API_URL_FLEET,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_item = axios.create({
    baseURL: process.env.REACT_APP_API_URL_ITEM,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_file = axios.create({
    baseURL: process.env.REACT_APP_API_URL_file,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_config = axios.create({
    baseURL: process.env.REACT_APP_API_URL_CONFIG,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_po = axios.create({
    baseURL: process.env.REACT_APP_API_URL_PO,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_invoice = axios.create({
    baseURL: process.env.REACT_APP_API_URL_INVOICE,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_admin = axios.create({
    baseURL: process.env.REACT_APP_API_URL_ADMIN,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_payment = axios.create({
    baseURL: process.env.REACT_APP_API_URL_PAYMENT,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_report = axios.create({
    baseURL: process.env.REACT_APP_API_URL_REPORT,
    headers: {
      'Content-Type': 'application/json',
    },
});
const api_customer = axios.create({
    baseURL: process.env.REACT_APP_API_URL_CUSTOMER,
    headers: {
      'Content-Type': 'application/json',
    },
});

export const apiData = {api_ob, api_internal, api_auth, api_bid, api_job, api_vendor, api_review, api_fleet, api_item, api_file, api_config, api_po, api_invoice, api_admin, api_payment, api_report, api_customer}