import React, { useEffect, useState } from 'react'
import MToast from '../MToast'
import { Link } from 'react-router-dom'
import ConfirmModal from '../Modals/ConfirmationModal'
import { paymentService } from '../../../services/payment.service'
import { jobsServicev2 } from '../../../services/job.service-v2'

//function BidTable({bids, statusChanged, updatedBidStatus, isBidApproved, isBlured}) {
function BidTable({bids, isBidApproved, isBlured, bidsUpdated, jobData}) {
  const [bidData, setBidData] = useState(null)
  const [bidSuccess, setBidSuccess] = useState(null)
  const [bidError, setBidError] = useState(null)
  const [viewConfirmModal, setViewConfirmModal] = useState(false)
  const [bidId, setBidId] = useState('')
  const [eventType, setEventType] = useState(null)
  //const [bidStatus, setBidStatus] = useState('CREATE')
  const [bidLoader, setBidLoader] = useState(false)
  const [paymentData, setPaymentData] = useState(null)
  //const buttonRef = useRef()
  useEffect(() => {
    if(bids) setBidData(bids)
    //if(updatedBidStatus) setBidStatus(updatedBidStatus)
    //if(statusChanged) statusChanged(updatedBidStatus)
  }, [bids])
  const approveBid = (bidId) => {
    setBidLoader(true)
    //buttonRef.current.classList.add('disabled')
    if(jobData){ 
      if(jobData.paymentOption.toLowerCase() === 'silver') {
        if(new Date(jobData.lastBidDate) < new Date()){
          const initData = {
              purpose: "JOB",
              jobId: jobData.jobId,
              bidId: bidId
          }
          paymentService.initJobPayment(initData).then((response) => {
            if(response.success){
              localStorage.setItem('jobPaymentInfo', JSON.stringify({...initData, transactionId: response.data.transactionId}))
              showConfirmModal(false, '', '')
              setPaymentData(response.data)
              showConfirmModal(true, 'payment', '')
              setBidLoader(false)
            } else if(!response.success){
              setBidError(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
              setBidError(response.data.message)
            } else if(response.code === "ERR_NETWORK") {
                setBidError(response.message)
            }
            setTimeout(() => {
              setBidLoader(false)
              setBidError('')
              setBidSuccess('')
            }, 3500)
          })
        } else{
          setBidError('You can not pay & approve job before last bid date.')
        }
        setTimeout(() => {
          setBidLoader(false)
          setBidError('')
        }, 3500);
      } else {
        jobsServicev2.approveJobBid(bidId).then((response) => {
          if(response.success){
            /*setTimeout(() => {
              setBidStatus('APPROVED')
            }, 2500);*/
            if(bidsUpdated) bidsUpdated()
            showConfirmModal(false, '', '')
            setBidSuccess('Bid approved successfully')
            if(isBidApproved) isBidApproved(true)
          } else if(!response.success){
            setBidError(response.error)
          }
          if(response.code === 'ERR_BAD_REQUEST'){
            setBidError(response.data.message)
              return false
          } else if(response.code === "ERR_NETWORK") {
              setBidError(response.message)
          }
          setTimeout(() => {
            setBidLoader(false)
            setBidError('')
            setBidSuccess('')
          }, 3500);
          //buttonRef.current.classList.remove('disabled')
        })
      }
    }
  }

  const paymentConfirm = () => {
    setBidLoader(true)
    if(paymentData){
      window.location.href = paymentData.pg_url
    }
  }

  /*const rejectBid = (bidId) => {
    setBidLoader(true)
    //buttonRef.current.classList.add('disabled')
    JobActionService.rejectJobBid(bidId).then((response) => {
      if(response.success){
        showConfirmModal(false, '', '')
        if(bidsUpdated) bidsUpdated()
        //setBidStatus('REJECTED')
        setBidSuccess('Bid Rejected Succesfully')
      } else if(!response.success){
        setBidError(response.error)
      }
      if(response.code === 'ERR_BAD_REQUEST'){
        setBidError(response.data.message)
      } else if(response.code === "ERR_NETWORK") {
          setBidError(response.message)
      }
      setTimeout(() => {
        setBidLoader(false)
        setBidError('')
        setBidSuccess('')
      }, 3500);
      //buttonRef.current.classList.remove('disabled')
    })
  }*/
  
  const showConfirmModal = (show, eventType, bidId) => {
    setBidId(bidId)
    setEventType(eventType)
    setViewConfirmModal(show)
  }
  /*const revokeBid = (bidId) => {
    setBidSuccess('')
    setBidError('')
    buttonRef.current.classList.add('disabled')
    JobActionService.revokeJobBid(bidId).then((response) => {
      if(response.success){
        setBidStatus('CREATE')
        setBidSuccess('Bid Revoked Succesfully')
      } else if(!response.success){
        setBidError(response.error)
      }
      if(response.code === 'ERR_BAD_REQUEST'){
        setBidError(response.data.message)
          return false
      } else if(response.code === "ERR_NETWORK") {
          setBidError(response.message)
      }
      buttonRef.current.classList.remove('disabled')
    })
  }*/
  return (
    <>
    <div className='table hover-table bid-table'>
        <div className='table-header'>
            <div>Vendor Name & Location</div>
            <div>Bid Amount</div>
            {jobData.status !== 'EXPIRED' && <div>Action</div>}
        </div>
        <div className='table-body'>
          {bidData && bidData.data.map((item, index) =>
            <div className='table-row' key={index}>
                <div className='vendor-info'>
                  <div className='company'><Link to={`/profile/${item.vendor.vendorId}`} className='revert-link' target='_blank'>{item.vendor.companyName}</Link></div>
                  <div className='vendor-meta'>
                    <span className='rating'><span className='icon icon-star'></span> {item.vendor.rating}</span>
                    <span>{item.vendor.location}</span>
                  </div>
                </div>
                <div className={isBlured ? 'blurred' : ''}>₹{item.bid.quotation}</div>
                {jobData.status !== 'EXPIRED' && <div className='d-flex'>
                  {/*bidStatus === "APPROVED" ? <button className='btn btn-light btn-success mr-8px'>Accepted <span className='icon icon-green-white-check'></span></button> :
                  <>{bidStatus === "CREATE" && <><button className='btn btn-light btn-blue mr-8px' ref={buttonRef} onClick={() => approveBid(item.bid.bidId)}>Accept</button>
                  <button className='btn btn-light btn-red' ref={buttonRef} onClick={() => rejectBid(item.bid.bidId)}>Reject</button></>}
                  {bidStatus === "REJECTED" && <button className='btn btn-light btn-blue' ref={buttonRef} onClick={() => revokeBid(item.bid.bidId)}>Revoke</button>}</>*/}
                  <button className='btn btn-light btn-blue mr-8px' onClick={() => showConfirmModal(true, 'accept', item.bid.bidId)}>Accept</button>
                </div>}
                {item.bid && item.bid.bidCatlogs && item.bid.bidCatlogs.length > 0 && <div className='vendor-meta full-width pt-0'>
                  {item.bid.bidCatlogs[0].fleet.masterItem.brand + ': ' +item.bid.bidCatlogs[0].fleet.masterItem.equipment} -  
                  {item.bid.bidCatlogs.length>0 && item.bid.bidCatlogs[0].fleet && <div className='d-inline align-items-center'>Fleet Rating &nbsp;<span className="rating small-rating d-inline-block"><span className="icon icon-star ms-0"></span> {item.bid.bidCatlogs[0].fleet.rating}</span></div>}
                </div>}
                {/*<div className='vendor-meta full-width pt-0 d-flex align-items-center'>Fleet Rating &nbsp;<span className="rating small-rating"><span className="icon icon-star ms-0"></span> {item.bid.bidCatlogs[0].fleet.rating}</span></div>*/}
            </div>
          )}
        </div>
        {bidSuccess && <MToast color="green" duration={3500} icon="icon-check-green-rev">{bidSuccess}</MToast>}
        {bidError && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{bidError}</MToast>}
    </div>
    {viewConfirmModal && bidId ? <>
      {eventType && eventType === 'accept' ?
        <ConfirmModal showModal={showConfirmModal} viewModal={viewConfirmModal} handleConfirm={() => approveBid(bidId)} isLoading={bidLoader} confirmMessage="Do u really want to accept?" confirmTitle="Accept Bid" /> : null }
        {/*<ConfirmModal showModal={showConfirmModal} viewModal={viewConfirmModal} handleConfirm={() => rejectBid(bidId)} isLoading={bidLoader} confirmMessage="Do u really want to reject?" confirmTitle="Reject Bid" />*/}
        </> : null}
        {viewConfirmModal && eventType && eventType === 'payment' ?
          <ConfirmModal showModal={showConfirmModal} viewModal={viewConfirmModal} handleConfirm={() => paymentConfirm()} isLoading={bidLoader} confirmMessage="As 100% Advance payment selected, you need to pay before the job starts. Do u want to proceed with given payment info?" confirmTitle="Confirm Payment">
            {paymentData ? <ul className='bid-info w-half mt-0 mb-0'>
              <li>
                <span>Amount</span>
                <div>{paymentData.amount}</div>
              </li>
              <li>
                <span>Tax</span>
                <div>{paymentData.tax}</div>
              </li>
              <li>
                <span>Total</span>
                <div>{paymentData.grand_total}</div>
              </li>
            </ul> : null}
          </ConfirmModal> : null}
    </>
  )
}

export default BidTable