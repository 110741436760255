import { apiv2 } from "./api-v2";
import { authServices } from "./auth.services";


const createJob = async (jobData) => {
    let Jobdata = {}
    const inputDate = new Date(jobData.last_bid_date);
    const year = inputDate.getUTCFullYear();
    const month = (`0${inputDate.getUTCMonth() + 1}`).slice(-2);
    const day = (`0${inputDate.getUTCDate()}`).slice(-2);
    const hour = (`0${inputDate.getUTCHours()}`).slice(-2);
    const minute = (`0${inputDate.getUTCMinutes()}`).slice(-2);
    const second = (`0${inputDate.getUTCSeconds()}`).slice(-2);

    const convertedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    Jobdata.jobTitle = jobData.job_title
    Jobdata.category = jobData.category
    Jobdata.equipmentType = jobData.equipment_type
    //Jobdata.equipmentName = jobData.equipment_name
    Jobdata.software = jobData.software
    Jobdata.personRequired = jobData.person_required === 'Yes' ? true : false
    Jobdata.location = jobData.location
    Jobdata.startDate = jobData.start_date
    Jobdata.endDate = jobData.end_date
    Jobdata.docUrls = jobData.doc_urls
    Jobdata.description = jobData.description
    Jobdata.bidType = jobData.bid_type === "Fixed budget" ? "FIX_BUDGET" : "REQUEST_QUOTE"
    Jobdata.budgetType = jobData.budget_type === "Per Shift" ? "PERSHIFT" : jobData.budget_type === "Per Project" ? "PERPROJECT" : jobData.budget_type === "Per Unit Quantity" ? "QUANTITY" : jobData.budget_type === "Per Hour" ? "HOURLY" : ''
    Jobdata.bidInclusion = jobData.bid_inclusion
    Jobdata.bidAmount = jobData.bid_amount
    Jobdata.lastBidDate = convertedDate
    Jobdata.bidCloserDate = jobData.bidCloserDate
    Jobdata.siteContactName = jobData.site_contact_name
    Jobdata.siteContactMobile = jobData.site_contact_mobile
    Jobdata.siteContactEmail = jobData.site_contact_email
    Jobdata.JobId = localStorage.getItem('JobId')
    Jobdata.paymentOption = jobData.commissionTier
    Jobdata.brand = jobData.brand_name
    Jobdata.multiActivity = jobData.isScheduled ? true : false
    Jobdata.sacCode = jobData.saccode
    if(jobData.minimum_assured_quantity) {
        Jobdata.assuredQuantity = jobData.minimum_assured_quantity
    }
    if(jobData.minimum_assured_days) {
        Jobdata.assuredDays = jobData.minimum_assured_days
    }
    if(jobData.isScheduled) {
        Jobdata.jobActivity = [...jobData.schedules]
    } else{
        const activity = {startDate: jobData.start_date, endDate: jobData.end_date}
        if(jobData.required_quantity) activity.quantity = jobData.required_quantity
        Jobdata.jobActivity =  [activity]
    }
    /*JobData.JobId = localStorage.getItem('JobId')
    JobData.doc_urls = jobData.doc_urls
    JobData.bid_type = JobData.bid_type === "Fixed budget" ? "FIX_BUDGET" : "REQUEST_QUOTE"
    JobData.budget_type = JobData.budget_type === "Per Day" ? "PERDAY" : "PERPROJECT"
    JobData.person_required = JobData.person_required === 'Yes' ? true : false
    JobData.last_bid_date = convertedDate*/
    const token = await authServices.getToken();
    return await apiv2.api_job.post(`/create`, Jobdata, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

const getJobsByStatus = async (status, order, pageNo, size) => {
    try {
        const token = await authServices.getToken();
        return await apiv2.api_job.get(`/js?status=${status}&order=${order}&page=${pageNo}&size=${size}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getJobsCounts = async () => {
    try {
        const token = await authServices.getToken();
        return await apiv2.api_job.get(`/jc`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getJobById = async (jobId) => {
    try {
        const token = await authServices.getToken();
        return await apiv2.api_job.get(`/jid?jobId=${jobId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const addActivity = async (activitiesData) => {
    const token = await authServices.getToken();
    return await apiv2.api_job.post(`/add-activity`, activitiesData, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

const approveJobBid = async (bidId) => {
    try {
        const token = await authServices.getToken();
        return await apiv2.api_bid.get(`approve?bidId=${bidId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const editJobEndDate = async (date, jobId) => {
    const token = await authServices.getToken();
    let editedDate = new Date(date).toISOString()
    //editedDate.setHours(editedDate.getHours() + 5)
    //editedDate.setMinutes(editedDate.getMinutes() + 30)
    //editedDate = new Date(editedDate).toISOString()
    //return await apiv2.api_job.post(`/edit`, {jobId: jobId, endDate: date}, {
    return await apiv2.api_job.get(`/edit?jobId=${jobId}&endDate=${editedDate}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const jobEndDateAction = async (actionType, id) => {
    const token = await authServices.getToken();
    return await apiv2.api_job.get(`/atbr/action?workflowId=${id}&action=${actionType}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const generateStartJobOtp = async (serialNo, jobId, activityId) => {
    try {
        const token = await authServices.getToken();
        return await apiv2.api_job.get(`startotp?jobId=${jobId}&activityId=${activityId}&serialNo=${serialNo}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const generateEndJobOtp = async (budgetType, value, jobId, activityId) => {
    try {
        const token = await authServices.getToken();
        const string = budgetType === 'PERSHIFT' || budgetType === 'PERDAY' ? `shift=${value}` : budgetType === 'QUANTITY' ? `qnt=${value}` : `hour=${value}&min=${0}`
        return await apiv2.api_job.get(`endotp?${string}&jobId=${jobId}&activityId=${activityId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const verifyJobStartOtp = async (otp, jobid, activityId) => {
    const token = await authServices.getToken();
    return await apiv2.api_job.get(`/start?jobId=${jobid}&activityId=${activityId}&otp=${otp}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        if(response){
            return response.data
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const verifyJobEndOtp = async (otp, jobid, activityId) => {
    const token = await authServices.getToken();
    return await apiv2.api_job.get(`/end?jobId=${jobid}&activityId=${activityId}&otp=${otp}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        if(response){
            return response.data
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

export const jobsServicev2 = {
    createJob,
    getJobsByStatus,
    getJobsCounts,
    getJobById,
    addActivity,
    approveJobBid,
    editJobEndDate,
    jobEndDateAction,
    generateStartJobOtp,
    generateEndJobOtp,
    verifyJobStartOtp,
    verifyJobEndOtp
}