import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/logo.svg';
import Profile from '../images/profile.svg';
//import { authServices } from '../services/auth.services'

function Header({userImage, handlemenuToggle, user, hideCross}) {
    //const [user, setUser] = useState(null)
    const [isVisible, setIsVisible] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        hideCross ? setIsVisible(false) : setIsVisible(true)
    }, [hideCross])

    const handleMenuToggle = () => {
        handlemenuToggle(!isVisible)
        setIsVisible(!isVisible)
    }

    return (
        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container-fluid">
                <span className={`icon ${isVisible ? 'icon-cross' : 'icon-hamburger'} toggle-menu`} onClick={handleMenuToggle}></span>
                <Link className="navbar-brand"
                to={user && user.roles.includes("ROLE_CUSTOMER") ? '/customer/dashboard' : '/vendor/my-jobs'}>
                    <img src={Logo} alt="MPAAS&reg;" />
                </Link>
                {windowWidth >= 768 && <div className="ms-auto d-flex align-items-center">
                    {/*<div className='search-form'>
                        <span className='icon icon-search'></span>
                        <input className="form-control search mb-0" type="search" placeholder="Search" aria-label="Search" />
                    </div>
                    <div className='notification-dropdown'>
                        <div className='notification'>
                            <div className='icon icon-notification'></div>
                            <span className='notification-badge badge rounded-pill badge-red'>10</span>
                        </div>
                    </div>*/}
                    <div className='user-dropdown'>
                        <span>{user && user.username}</span>
                        <div className='user-profile'>
                            <img src={userImage ? userImage : Profile} alt='User Name' />
                        </div>
                    </div>
                    {/*<div onClick={logout} style={{fontSize: '14px', cursor: 'pointer', marginLeft: '8px'}}>Logout</div>*/}
                </div>}
            </div>
        </nav>
    )
}

export default Header