import React, { useState } from 'react'
import ReviewDetailsModal from '../Modals/ReviewDetailsModal'

function ReviewCard({reviewDetail}) {
  const [viewModal, setViewModal] = useState(false)
  const showModal = (show) => {
    if(show){
      setViewModal(show)
    } else{
      setViewModal(false)
    }
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDateString = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: true
    });
    return formattedDateString
  }
  return (
    <>{reviewDetail && <div className='review-card' onClick={() => showModal(true)} style={{cursor: 'pointer'}}>
        <h4>{reviewDetail.jobTitle}</h4>
        <div className='ratings'>
            <div className='rating-stars'>
                {[...Array(5)].map((item, index) => 
                  <span className={`icon icon-star${index >= reviewDetail.review.rating ?'-gray' : ''}`} key={index}></span>
                )}
            </div>
            <div className='rating-text'>{reviewDetail.review.rating}</div>
        </div>
        <div className='dates'>{`${formatDate(reviewDetail.jobStartDate)} - ${formatDate(reviewDetail.jobEndDate)}`}</div>
        <p>{reviewDetail.review.comment}</p>
        <div className='name'>{reviewDetail.review.name}</div>
    </div>}
    {viewModal ? <ReviewDetailsModal reviewDetail={reviewDetail} showModal={showModal} viewModal={viewModal} /> : null}
    </>
  )
}

export default ReviewCard