import { apiData } from "./api";
import { authServices } from "./auth.services"

const getFleets = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_fleet.get(`get-all-fleets`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getFleetId = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_fleet.get(`get-fleetid`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const createFleet = async (masterId, fleetData) => {
    let fleetdata = {
        "masterItemId": masterId,
        "serialNo": fleetData.serial_no,
        "itemImages": fleetData.item_image[0],
        "specification": fleetData.fleet_description,
        "caliberated": fleetData.is_item_calibrated === 'Yes' ? true : false,
        "caliberateImages" : fleetData.calibration_image[0],
        "manufacturingYear" : fleetData.manufacturingYear,
        "caliberatedAt" : fleetData.is_item_calibrated === 'Yes' ? fleetData.caliberatedOn : '',
        "owned": fleetData.is_owned === "Owned" ? true : false,
        "rented": fleetData.is_owned === "Rented" ? true : false
    }
    try {
        const token = await authServices.getToken();
        return await apiData.api_fleet.post(`/create`, fleetdata, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const updateFleet = async (fleetId, masterId, fleetData) => {
    let fleetdata = {
        "masterItemId": masterId,
        "fleetId": fleetId,
        "serialNo": fleetData.serial_no,
        "specification": fleetData.fleet_description,
        "caliberated": fleetData.is_item_calibrated === 'Yes' ? true : false,
        "manufacturingYear" : fleetData.manufacturingYear,
        "caliberatedAt" : fleetData.is_item_calibrated === 'Yes' ? fleetData.caliberatedOn : '',
        "owned": fleetData.is_owned === "Owned" ? true : false,
        "rented": fleetData.is_owned === "Rented" ? true : false
    }
    if(fleetData.calibration_image[0]) fleetdata.caliberateImages = fleetData.calibration_image[0]
    if(fleetData.item_image[0]) fleetdata.itemImages = fleetData.item_image[0]
    try {
        const token = await authServices.getToken();
        return await apiData.api_fleet.post(`/update`, fleetdata, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const deleteFleet = async (fleetId) => {
    try{
        const token = await authServices.getToken();
        return await apiData.api_fleet.get(`delete?fleetId=${fleetId}`,
        {headers: { Authorization: `Bearer ${token}` }})
        .then((response) => {
            return response.data
        })
    } catch(err){
        return err
    }
}

const fleetUploadFile = async (fleetId, uploadFor, formdata) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_fleet.post(`/file/uploade?fleetId=${fleetId}&contentName=${uploadFor}`, formdata, {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const deleteFleetFiles = async (docId, fleetId, fileFor) => {
    try {
        if(fleetId){
            const token = await authServices.getToken();
            return await apiData.api_internal.get(`/file/delete-s3?docId=${docId}&contentId=${fleetId}&docType=${fileFor}`, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
            }).then((response) => {
                if(response && response.data){
                    return response.data
                }
            });
        }
    } catch (error) {
      return error;
    }
}

const createMasterItem = async (MasterItemData) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.post(`/create`, MasterItemData, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const updateMasterItem = async (MasterItemData) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.post(`/update`, MasterItemData, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getPreloadItems = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.get('/get-preload-items', {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}
const getSubModel = async (equipment, brand) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.get(`/get-fleet-brand-sub-model?equipment=${equipment}&brand=${brand}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getRange = async (equipment, brand, subModel) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.get(`/get-fleet-item-range?equipment=${equipment}&brand=${brand}&brandSubModel=${subModel}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getMasterId = async (equipment, brand, subModel, range) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.get(`/get-fleet-master-item-id?equipment=${equipment}&brand=${brand}&brandSubModel=${subModel}&itemRange=${range}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getSerialNumberList = async () => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_fleet.get(`/get-equipment-serial`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

const getDistinctAttribute = async (attrType) => {
    try {
        const token = await authServices.getToken();
        return await apiData.api_item.get(`/get-distinct-attribute?attribute=${attrType}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
      return error;
    }
}

export const FleetsService = {getFleets, getFleetId, createFleet, updateFleet, deleteFleet,
    fleetUploadFile, deleteFleetFiles, createMasterItem, updateMasterItem, getPreloadItems, getSubModel, getRange, getMasterId, getSerialNumberList, getDistinctAttribute} 