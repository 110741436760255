import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SelectDropdown from '../../shared/UI/SelectDropdown'
import { adminServices } from '../../services/admin.service'
import MToast from '../../shared/UI/MToast'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditInvoiceModal({showModal, viewModal, InvoiceData, invoiceUpdated}) {
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState('');
    const [pickerOpen, setPickerOpen] = useState(null);
    const statusList = ['PENDING', 'APPROVED', 'PAYMENT_RECEIVED', 'REJECTED', 'CANCELLED', 'WAIVED']
    useEffect(() => {
        if(viewModal) setShow(viewModal)
        if(InvoiceData && InvoiceData.vendorInvoiceDate) setSelectedDate(new Date(InvoiceData.vendorInvoiceDate))
    }, [viewModal, InvoiceData])
    const handleHide = () => {
        setShow(false)
        if(showModal) showModal(false)
    }
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
        formik.setFieldValue('vendorInvoiceDate', date)
    };
    const validate = values => {
        const errors = {}
        if(values.vendorInvoiceNumber && !values.vendorInvoiceDate){
            errors.vendorInvoiceDate = 'Vendor Invoice Date is required to update Vendor Invoice Number'
        }
        if(values.vendorInvoiceDate && values.vendorInvoiceDate.toString() !== 'Invalid Date' && !values.vendorInvoiceNumber){
            errors.vendorInvoiceNumber = 'Vendor Invoice Number is required to update Vendor Invoice Date'
        }
        return errors;
    };
    
    const formik = useFormik({
        initialValues: { vendorInvoiceNumber: InvoiceData.vendorInvoiceNumber || '', vendorInvoiceDate: new Date(InvoiceData.vendorInvoiceDate) || '', status: InvoiceData.status || '' },
        validate,
        onSubmit: (values, { setSubmitting }) => {
            updateInvoice(values)
            setSubmitting(false);
        },
    });

    const updateInvoice = (values) => {
        setIsLoading(true)
        adminServices.updateInvoice(InvoiceData.jobId, InvoiceData.invoiceNumber, values).then((response) => {
            if(response.success){
                setSuccessMessage(response.data)
                if(invoiceUpdated) invoiceUpdated(true)
            } else if(!response.success){
                setErrorMessage(response.error)
            }
            if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage('Bad Request')
            } else if(response.code === "ERR_NETWORK") {
                setErrorMessage(response.message);
            }
            setTimeout(() => {
                setErrorMessage('')
                setSuccessMessage('')
            }, 3500);
            setIsLoading(false)
        })
    }
  return (
    <Modal show={show} onHide={handleHide} className='mpass-modal'>
        <Modal.Header>
            <Modal.Title>Update Invoice</Modal.Title>
            <span className='close-modal icon icon-cross-white' onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <div className='invoice-bid-info-wrapper'>
                <ul className='bid-info invoice-bid-info'>
                    <li>
                        <span>Job Id</span>
                        <div>{InvoiceData.jobId}</div>
                    </li>
                    {InvoiceData.vendorCompanyName && <li>
                        <span>Vendor Name</span>
                        <div>{InvoiceData.vendorCompanyName} (From)</div>
                    </li>}
                    <li>
                        <span>MPAAS&reg;</span>
                        <div>{InvoiceData.source.toLowerCase() === 'mpaas' ? <>{InvoiceData.source}&reg; (From)</> : <>{InvoiceData.destination}&reg; (To)</>}</div>
                    </li>
                    {InvoiceData.customerCompanyName && <li>
                        <span>Customer Name</span>
                        <div>{InvoiceData.customerCompanyName} (To)</div>
                    </li>}
                    <li>
                        <span>Invoice Date</span>
                        <div>{InvoiceData.createdAt}</div>
                    </li>
                    <li>
                        <span>Invoice Number</span>
                        <div>{InvoiceData.invoiceNumber}</div>
                    </li>
                    {InvoiceData.destination.toLowerCase() === 'mpaas' && InvoiceData.vendorInvoiceNumber && <li>
                        <span>Vendor Invoice Number</span>
                        <div>{InvoiceData.vendorInvoiceNumber}</div>
                    </li>}
                </ul>
                <ul className='bid-info invoice-bid-info'>
                    <li>
                        <span>Job Value</span>
                        <div>{Math.round(InvoiceData.amount)}</div>
                    </li>
                    {InvoiceData.fixComission ? <li>
                        <span>Fixed Commission (-5%)</span>
                        <div>{Math.round(InvoiceData.fixComission)}</div>
                    </li> : null}
                    {InvoiceData.extraComission ? <li>
                        <span>Extra Commission (-3%)</span>
                        <div>{Math.round(InvoiceData.extraComission)}</div>
                    </li> : null}
                    <li>
                        <span>GST (18%)</span>
                        <div>{Math.round(InvoiceData.tax)}</div>
                    </li>
                    <li>
                        <span>Net Invoice Amount</span>
                        <div>{Math.round(InvoiceData.grandTotalInvoiceAmount)}</div>
                    </li>
                    {/*InvoiceData.source.toLowerCase() === 'mpaas' && <li>
                        <span>Vendor Invoice Amount</span>
                        <div>{InvoiceData.vendorInvoiceAmount}</div>
                    </li>*/}
                </ul>
            </div>
            <form onSubmit={formik.handleSubmit} className='split-form'>
                {InvoiceData && InvoiceData.vendorCompanyName && <>
                <div className="input-wrapper mat-input">
                    <input type="text" name="vendorInvoiceNumber" className="form-control" id="vendorInvoiceNumberControl" placeholder=' '
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vendorInvoiceNumber}
                    />
                    <label htmlFor="vendorInvoiceNumberControl" className="form-label">Vendor Invoice Number</label>
                    {(formik.touched.vendorInvoiceNumber && formik.errors.vendorInvoiceNumber) ? <div className='error'>{formik.errors.vendorInvoiceNumber}</div> : null}
                </div>
                {/*<div className="input-wrapper mat-input">
                    <input type="text" name="vendorInvoiceAmount" className="form-control" id="vendorInvoiceAmountControl" placeholder=' '
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vendorInvoiceAmount}
                    />
                    <label htmlFor="vendorInvoiceAmountControl" className="form-label">Vendor Invoice Amount</label>
                    {(formik.touched.vendorInvoiceAmount && formik.errors.vendorInvoiceAmount) ? <div className='error'>{formik.errors.vendorInvoiceAmount}</div> : null}
                </div>*/}
                <div className={`input-wrapper mat-input icon-input ${selectedDate ? 'date-selected' : ''}`}>
                    <DatePicker selected={selectedDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} 
                        name="vendorInvoiceDate" id='vendorInvoiceDateControl' autoComplete='off'
                        onChange={handleDateChange}
                        onBlur={formik.handleBlur}
                        dateFormat="dd/MM/yyyy"
                        value={formik.values.vendorInvoiceDate}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="vendorInvoiceDateControl" className="form-label">Vendor Invoice Date</label>
                        {(formik.touched.vendorInvoiceDate && formik.errors.vendorInvoiceDate) ? <div className='error'>{formik.errors.vendorInvoiceDate}</div> : null}
                </div></>}
                <div className="input-wrapper mat-input">
                    <SelectDropdown
                        options={statusList ? statusList : []}
                        labelText='Status'
                        value={formik.values.status ? formik.values.status : ''}
                        onChange={(option) => formik.setFieldValue('status', option)}
                    />
                    {(formik.touched.status && formik.errors.status) ? <div className='error'>{formik.errors.status}</div> : null}
                </div>
                <button type='submit' className={`btn btn-primary btn-medium ${isLoading ? 'disabled' : ''}`}>Update Invoice {isLoading && <div className='loader loader-medium'></div>}</button>
            </form>
            {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
        {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
        </Modal.Body>
    </Modal>
  )
}

export default EditInvoiceModal