import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import DashboardWrapper from '../../shared/DashboardWrapper'
import { adminServices } from '../../services/admin.service'
import MToast from '../../shared/UI/MToast'
import JobDetailsReportsTable from './JobDetailsReportsTable'
import SelectDropdown from '../../shared/UI/SelectDropdown';
import JobDetailReportModal from '../../shared/UI/Modals/JobDetailReportModal';
import { useLocation } from 'react-router';
import MpaasPagination from '../../shared/UI/Pagination';

function JobDetailsReport() {
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [pickerOpen, setPickerOpen] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const [jobId, setJobId] = useState([])
  const [isLoading, setIsLoading] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [jobList, setJobList] = useState([]);
  const [isSticky, setSticky] = useState(false);
  const [viewModal, SetViewModal] = useState(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get('from');
  const to = queryParams.get('to');
  const jbtype = queryParams.get('type');
  let itemsCountList = [10, 25, 50, 100]
  const [itemsPerPage, setItemsPerPage] = useState(itemsCountList[0])
  let jobTypeList = ['All', 'Created', 'Confirmed', 'Completed', 'Expired']
  const [jobType, setJobType] = useState(jobTypeList[0]);
  const [isVisible, setIsVisible] = useState(true)
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 768);
          //(window.innerWidth < 768) ? setIsVisible(false) : setIsVisible(true)
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuToggle = () => {
      setIsVisible(!isVisible)
  }
  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollHeight > window.innerHeight + 150) {
        setSticky(window.scrollY > 100);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const showModal = (show) => {
    SetViewModal(show)
    if (!show) setJobId('')
  }
  const passJobId = (jobId) => {
    setJobId(jobId)
    showModal(true)
  }
  const pageChangeHandler = (pageNumber) => {
    getJobReportList(false, selectedStartDate, selectedEndDate, pageNumber, itemsPerPage, jobType)
  }
  const onPickerOpen = () => {
    setPickerOpen(true);
  };
  const onPickerClose = () => {
    setPickerOpen(false);
  };
  const formatDate = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const handleStartDateChange = (date) => {
    if(from) queryParams.set('from', formatDate(date))
    window.history.replaceState({}, '', `${location.pathname}?${queryParams.toString()}`)
    setSelectedStartDate(date);
  }
  const handleEndDateChange = (date) => {
    if(to) queryParams.set('to', formatDate(date))
    window.history.replaceState({}, '', `${location.pathname}?${queryParams.toString()}`)
    setSelectedEndDate(date);
  }
  const getJobReportList = useCallback((loader, startdate, enddate, currentpage, itemsperPage, jobtype) => {
    if(loader) setIsLoading(true)
    const formatedSD = formatDate(startdate)
    const formatedED = formatDate(enddate)
    setCurrentPage(currentpage)
    adminServices.getJobReportList(formatedSD, formatedED, currentpage, itemsperPage, jobtype).then((response) => {
      if (response.data) {
        setTotalPage(Math.ceil(response.total / itemsPerPage))
        setJobList(response.data)
      } else if (!response.success) {
        setErrorMessage(response.error)
      }
      if (response.code === 'ERR_BAD_REQUEST') {
        setErrorMessage('Bad Request')
      } else if (response.code === "ERR_NETWORK") {
        setErrorMessage(response.message)
      }
      setIsLoading(false)
      setTimeout(() => {
        setErrorMessage('')
      }, 3500);
    })
  }, [itemsPerPage])
  useEffect(() => {
    if(jbtype) setJobType(jbtype)
    if (from && to) {
      getJobReportList(false, from, to, 1, itemsPerPage, jbtype)
      setSelectedStartDate(new Date(from))
      setSelectedEndDate(new Date(to))
    }
  }, [from, to, itemsPerPage, getJobReportList, jbtype])

  return (
    <DashboardWrapper>
      <div className='page-content'>
        <h2 className='mb-0-md'>Job Details</h2>

        <div className={`distinct-row ${isSticky ? 'sticked-row' : ''}`}>
          {isSmallScreen && <div className='form-filter-toggle d-flex align-items-center' onClick={handleMenuToggle}>{isVisible ? 'Hide' : 'Show'} Filter <i className='icon icon-filter-green'></i></div>}
          {isVisible && <div className='form'>
            <div className={`input-wrapper mat-input icon-input ${selectedStartDate ? 'date-selected' : ''}`}>
              <DatePicker selected={selectedStartDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} name="bidCloserDate" id='startDateControl' autoComplete='off'
                onChange={handleStartDateChange}
                dateFormat="yyyy-MM-dd"
                value={selectedStartDate}
                onCalendarOpen={onPickerOpen}
                onCalendarClose={onPickerClose} />
              <span className='icon icon-calendar'></span>
              <label htmlFor="startDateControl" className="form-label">Start Date*</label>
            </div>
            <div className={`input-wrapper mat-input icon-input ${selectedEndDate ? 'date-selected' : ''}`}>
              <DatePicker selected={selectedEndDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`} name="bidCloserDate" id='endDateControl' autoComplete='off'
                onChange={handleEndDateChange}
                dateFormat="yyyy-MM-dd"
                value={selectedEndDate}
                disabled={selectedStartDate === ''}
                onCalendarOpen={onPickerOpen}
                onCalendarClose={onPickerClose} />
              <span className='icon icon-calendar'></span>
              <label htmlFor="endDateControl" className="form-label">End Date*</label>
            </div>
            <div className="input-wrapper mat-input mb-0" style={{minWidth: '150px'}}>
                <SelectDropdown
                    options={jobTypeList}
                    labelText='Job Status'
                    value={jobType}
                    onChange={(option) => {
                        setJobType(option)
                    }}
                />
            </div>
            <div className="input-wrapper mat-input mb-0" style={{ minWidth: '150px' }}>
              <SelectDropdown
                options={itemsCountList}
                labelText='Items per page'
                value={itemsPerPage}
                onChange={(option) => setItemsPerPage(option)}
              />
            </div>
            <button type="submit" className={`btn btn-primary btn-medium ${selectedEndDate && selectedStartDate && !isLoading ? '' : 'disabled'}`} onClick={() => getJobReportList(true, selectedStartDate, selectedEndDate, 1, itemsPerPage, jobType)}>Search {isLoading && <span className='loader loader-small ms-1'></span>}</button>
          </div>}
        </div>

        {totalPage > 1 ? <MpaasPagination totalPage={totalPage} onPageChange={pageChangeHandler} currentPage={currentPage} /> : null}

        <JobDetailsReportsTable items={jobList} passJobId={passJobId} currentPageNo={currentPage} itemsPerPage={itemsPerPage} />

        {totalPage > 1 ? <MpaasPagination totalPage={totalPage} onPageChange={pageChangeHandler} currentPage={currentPage} /> : null}

      </div>
      {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
      {jobId && viewModal && <JobDetailReportModal showModal={showModal} viewModal={viewModal} jobId={jobId} />}
    </DashboardWrapper>
  )
}

export default JobDetailsReport