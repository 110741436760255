import React, { useState } from 'react'
//import RowDropdown from '../../shared/UI/RowDropdown'
import EditInvoiceModal from './EditInvoiceModal'

function InvoiceStatusRow({InvoiceData, invoiceUpdated}) {
    const [viewModal, SetViewModal] = useState(false)
//const [vendorInvoice, setVendorInvoice] = useState('')
//const [customerInvoice, setCustomerInvoice] = useState('')
    const showModal = (show) => {
        SetViewModal(show)
    }
    /*useEffect(() => {
        if(InvoiceData){
            if(InvoiceData.source === 'MPAAS'){
                setVendorInvoice(InvoiceData)
            } else{
                setCustomerInvoice(InvoiceData)
            }
        }
    }, [InvoiceData])*/
  return (
    <>
    <div className='table-row' onClick={() => showModal(true)}>
        {InvoiceData && <>
        <div className='link'>{InvoiceData.jobId}</div>
        {/*<div>{customerInvoice.source}</div>
        <div className='status'>{customerInvoice.status}</div>
        <div className="d-flex action">
            <RowDropdown>
                <li onClick={() => showModal(true, 'customer')}>
                    Edit Customer Invoice
                </li>
            </RowDropdown>
        </div></>}
        {vendorInvoice && InvoiceType === 'vendor' && <>
        <div className='id'>{vendorInvoice.jobId}</div>
        <div>{vendorInvoice.destination}</div>
        <div className='status'>{vendorInvoice.status}</div>
        <div className="d-flex action">
            <RowDropdown>
                <li onClick={() => showModal(true)}>
                    Edit Invoice
                </li>
            </RowDropdown>
        </div>*/}</>}
    </div>
    {viewModal && <EditInvoiceModal showModal={showModal} viewModal={viewModal} InvoiceData={InvoiceData} invoiceUpdated={invoiceUpdated} />}
    </>
  )
}

export default InvoiceStatusRow