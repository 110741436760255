import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { jobsService } from '../services/jobs.service'
import { authServices } from '../services/auth.services'
import { Fade, Reveal } from "react-awesome-reveal";
import { keyframes } from '@emotion/react'

function DashboardWrapper({children, disableCard}) {
  const [userImage, setUserImage] = useState('')
  const [user, setUser] = useState(null)
  const [showMenu, setShowMenu] = useState(false)
  const [hideCross, setHideCross] = useState(true)
  const customAnimation = keyframes`
  0% {
    left: 0;
    width: 0;
  }
  5% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
  }
  51% {
    left: auto;
    right: 0;
  }
  100% {
    left: auto;
    right: 0;
    width: 0;
  }
  `;
  useEffect(() => {
    jobsService.getVendorInfo().then((response) => {
        if(response.success && response.data.length > 0){
          setUserImage(response.data[0].profileImage)
        }
    })
  }, [])

  useEffect(() => {
    authServices.getUser().then((response) => {
        setUser(response)
    })
}, [])

  const handleMenuToggle = (isVisible) => {
    setShowMenu(isVisible)
    isVisible ? setHideCross(false) : setHideCross(true)
  }

  return (
    <>
      <Fade duration={1400} delay={1200} triggerOnce>
        <div className='main'>
          <header>
            <Header userImage={userImage ? userImage : null} user={user} handlemenuToggle={handleMenuToggle} hideCross={hideCross} />
          </header>
          <div className={ disableCard ? 'page-wrapper' : 'page-wrapper card-wrapper' }>
              <aside className={`page-sidebar ${showMenu ? 'open' : ''}`}>
                  <Sidebar user={user} userImage={userImage ? userImage : null} handleMenuToggle={handleMenuToggle} />
              </aside>
              <>{children}</>
          </div>
        </div>
      </Fade>
      <Reveal className="page-overlay" keyframes={customAnimation}>&nbsp;</Reveal>
    </>
  )
}

export default DashboardWrapper