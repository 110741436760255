import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ManageSubscriptionPage() {
    //const [successMessage, setSuccessMessage] = useState(null)
    //const [errorMessage, setErrorMessage] = useState(null)
    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [pickerOpen, setPickerOpen] = useState(null);
    useEffect(() => {
    }, [])
    const onPickerOpen = () => {
        setPickerOpen(true);
    };
    const onPickerClose = () => {
        setPickerOpen(false);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    }
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    }
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <h2>Manage Subscription</h2>
            <div className='distinct-row'>
                <h3>Free Trial Period</h3>
                <div className='form'>
                    <div className={`input-wrapper mat-input icon-input w-420 ${selectedStartDate ? 'date-selected' : ''}`}>
                        <DatePicker selected={selectedStartDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="bidCloserDate" id='bidCloserDateControl' autoComplete='off'
                        onChange={handleStartDateChange}
                        dateFormat="EEE, dd MMM, yyyy"
                        value={selectedStartDate}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="LastBidDateControl" className="form-label">Start Date*</label>
                    </div>
                    <div className={`input-wrapper mat-input icon-input w-420 ${selectedEndDate ? 'date-selected' : ''}`}>
                        <DatePicker selected={selectedEndDate} className={`form-control ${pickerOpen ? 'open-picker' : ''}`}  name="bidCloserDate" id='bidCloserDateControl' autoComplete='off'
                        onChange={handleEndDateChange}
                        dateFormat="EEE, dd MMM, yyyy"
                        value={selectedEndDate}
                        disabled={selectedStartDate === ''}
                        onCalendarOpen={onPickerOpen}
                        onCalendarClose={onPickerClose} />
                        <span className='icon icon-calendar'></span>
                        <label htmlFor="LastBidDateControl" className="form-label">End Date*</label>
                    </div>
                    <button type="submit" className={`btn btn-primary btn-medium`}>Create</button>
                </div>
            </div>
        </div>
    </DashboardWrapper>
  )
}

export default ManageSubscriptionPage