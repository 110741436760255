import { apiData } from "./api";

let registerUser = async (userInfo) => {
    let UserData = {
        username: userInfo.email_address.toLowerCase(),
        password: userInfo.password,
        email: userInfo.email_address.toLowerCase(),
        role: [userInfo.joining_as],
        company_name: userInfo.companyname,
        mobile_number: userInfo.phone_no,
        user_type: (userInfo.joining_as).toUpperCase(),
        companygst_no: userInfo.companygst_no,
        person_name: userInfo.person_name
    }
    if(userInfo.address) UserData.address = userInfo.address
    if(userInfo.city) UserData.city = userInfo.city
    if(userInfo.state) UserData.state = userInfo.state
    if(userInfo.pin) UserData.pin = userInfo.pin
    if(userInfo.registrationDate) UserData.registrationDate = userInfo.registrationDate
    if(userInfo.joining_as === "vendor"){
        UserData.category = userInfo.categories
        //UserData.subscription_type = userInfo.subscription_type
        //UserData.registrationDate = userInfo.registrationDate
        UserData.multiOffices = userInfo.multiOffices === 'Multiple Locations' ? true : false
        UserData.employeeCountUpto = userInfo.employeeCountUpto === '0-5' ? 5 : userInfo.employeeCountUpto === '5-10' ? 10 : userInfo.employeeCountUpto === 'More than 10' ? 11 : 0
    }
    return await apiData.api_auth.post(`/signup`, UserData).then((response) => {
        return response
    }).catch(function (error) {
        if (error) {
            return error
        }
      });
}

let signIn = async (credentials) => {
    let userCred = {
        username: credentials.email.toLowerCase(),
        password: credentials.password
    }
    return await apiData.api_auth.post(`/signin`, userCred, {withCredentials: true}).then((response) => {
        if (response.data.username) {
            localStorage.setItem("userData", JSON.stringify(response.data));
        }
        return response.data;
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const resetPasswordOtp = async (email) => {
    return await apiData.api_ob.get(`/get-reset-psw-otp?username=${email}`).then((response) => {
        if (response && response.data) {
            return response.data;
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const resetPassword = async (credentials) => {
    let userCred = {
        username: credentials.email_address,
        password: credentials.password,
        otp: credentials.otp1 + '' + credentials.otp2 + '' + credentials.otp3 + '' + credentials.otp4 + '' + credentials.otp5 + '' + credentials.otp6
    }
    return await apiData.api_auth.post(`/reset-password`, userCred).then((response) => {
        if (response.data) {
            return response.data;
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getUser = async () => {
    const userInfo = await localStorage.getItem('userData');
    if(userInfo){
        return JSON.parse(userInfo)
    }
}

const getToken = async () => {
    const user = await getUser();
    if (user && user['jwt']) {
        const decodedToken = JSON.parse(window.atob(user['jwt'].split('.')[1]));
        if (decodedToken.exp * 1000 > Date.now()) {
            return user['jwt']
        }
    } else {
        localStorage.getItem('userData') && localStorage.removeItem('userData')
    }
};

const getOtp = async () => {
    try {
        const token = await getToken()
        return await apiData.api_ob.get('/gen-otp?otp_mode=MOBILE', {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            if(response && response.data){
                return response.data
            }
        });
    } catch (error) {
        return error;
    }
}

const resendOtp = async () => {
    try {
        const token = await getToken();
        return await apiData.api_ob.get('/gen-otp?otp_mode=MOBILE', {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            return response.data
        });
    } catch (error) {
        return error;
    }
}

const verifyOtp = async (otp, mode, jobid) => {
    const token = await getToken();
    let apiStr = (mode && jobid) ? `otp=${otp}&otp_mode=${mode}&jobId=${jobid}` : `otp=${otp}`
    return await apiData.api_ob.get(`/verify-otp?${apiStr}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        if(response){
            return response
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const logout = () => {
    return apiData.api_auth.post("/signout").then((response) => {
        if(response.data.message){
            localStorage.removeItem("userData");
            localStorage.removeItem("otpSent");
            return response
        }
    });
};

export const authServices = {
    registerUser, signIn, resetPasswordOtp, resetPassword, getToken, getUser, getOtp, resendOtp, verifyOtp, logout
}
