import React, { useState } from 'react'
import DashboardWrapper from '../../shared/DashboardWrapper'
import { Tab, Tabs } from 'react-bootstrap'
import ItemsTable from './ItemsTable'
import AddItemModal from './AddItemModal'
import MToast from '../../shared/UI/MToast'

function MasterItemPage() {
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [viewModal, SetViewModal] = useState(false)
    const [itemData, setItemData] = useState(false)
    const [ updateTable, setUpdateTable] = useState(false)
    const showModal = (show) => {
        SetViewModal(show)
        if(!show) setItemData('')
    }
    const itemAdded = (added, message) => {
        if(added){
          setSuccessMessage(message)
          showModal(false)
          setUpdateTable(true)
          setItemData('')
        } else{
          setErrorMessage(message)
        }
        setTimeout(() => {
          setSuccessMessage('')
          setErrorMessage('')
        }, 3500);
    }
    const showUpdateItemModal = (item) => {
      setItemData(item)
      showModal(true)
    }
  return (
    <DashboardWrapper>
        <div className='page-content'>
            <div className='page-header d-flex justijy-content-between'>
                <h2>Master Items</h2>
                <button className='btn btn-primary btn-auto' onClick={() => showModal(true)}>Add Item</button>
            </div>
            <Tabs className="jobs-tab profile-tabs" defaultActiveKey="pending">
                <Tab eventKey="pending" title="Pending Approval">
                    <ItemsTable status="PENDING" showUpdateItemModal={showUpdateItemModal} updateTable={updateTable} />
                </Tab>
                <Tab eventKey="approved" title="Approved">
                    <ItemsTable status="APPROVED" showUpdateItemModal={showUpdateItemModal} updateTable={updateTable} />
                </Tab>
                <Tab eventKey="rejected" title="Rejected">
                    <ItemsTable status="REJECTED" showUpdateItemModal={showUpdateItemModal} updateTable={updateTable} />
                </Tab>
            </Tabs>
            {viewModal && <AddItemModal showModal={showModal} viewModal={viewModal} itemAdded={itemAdded} itemData={itemData} />}
        </div>
        {successMessage && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMessage}</MToast>}
        {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
    </DashboardWrapper>
  )
}

export default MasterItemPage