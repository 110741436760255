import React, { useState } from 'react'
//import RowDropdown from '../../shared/UI/RowDropdown'
import EditPOModal from './EditPOModal'

function POStatusTableRow({POData, isEdited}) {
    const [viewModal, SetViewModal] = useState(false)
    const showModal = (show) => {
        SetViewModal(show)
    }
  return (
    <>
    <div className='table-row' onClick={() => showModal(true)}>
        <div className='link'>{POData.jobId}</div>
        {/*<div className="d-flex action">
            <RowDropdown>
                <li onClick={() => showModal(true)}>
                    Edit PO
                </li>
            </RowDropdown>
  </div>*/}
        {/*{vendorPO && poType === 'vendor' && <>
        <div className='id'>{vendorPO.jobId}</div>
        <div>{vendorPO.destination}</div>
        <div>{vendorPO.updatedAt}</div>
        <div className='id'>{vendorPO.poNumber}</div>
        <div>{vendorPO.amount}</div>
        <div>{vendorPO.amount}</div>
        <div className='status'>{vendorPO.status}</div>
        <div className="d-flex action">
            <RowDropdown>
                <li onClick={() => showModal(true, 'vendor')}>
                    Edit Vendor PO
                </li>
            </RowDropdown>
        </div></>}*/}
    </div>
    {viewModal && <EditPOModal showModal={showModal} viewModal={viewModal} poData={POData} isEdited={isEdited} />}
    </>
  )
}

export default POStatusTableRow