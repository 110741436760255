import React, { useEffect, useRef } from 'react'

function RowDropdown({children, className}) {
    const dropDown = useRef(null)
    useEffect(() => {
        const onClickOutside = (event) => {
            if(dropDown.current && !dropDown.current.contains(event.target)){
                dropDown.current.classList.remove('open')
            }
        }
        document.addEventListener("mousedown", onClickOutside);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
        };
    }, [])
    const handleToggle = () => {
        if(dropDown.current && dropDown.current.classList.contains('open')) {
            dropDown.current.classList.remove('open')
        } else {
            dropDown.current.classList.add('open')
        }
    }
  return (
    <div className='toggle-dropdown mx-auto' ref={dropDown}>
        <span className='icon icon-more-vrt' onClick={handleToggle}></span>
        <ul className={`select-options ${className !== undefined ? className : ''}`}>
            {children}
        </ul>
    </div>
  )
}

export default RowDropdown