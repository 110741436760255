import React, {useState, useEffect, useCallback} from 'react'
import MToast from '../../shared/UI/MToast';
import { adminServices } from '../../services/admin.service';
import SelectDropdown from '../../shared/UI/SelectDropdown';
import POStatusTableRow from './POStatusRow';

function POStatusTable({status}) {
    //const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(15)
    const [jobId, setJobId] = useState('')
    const [isLoading, setIsLoading] = useState('')
    let itemsCountList = [15, 20, 50, 100]
    const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
    const getPendingPO = useCallback((status) => {
      adminServices.getPurchasedOrders(status, itemsPerPage, currentPage).then((response) => {
          if(response.data){
            /*const resultObject = [];
            for(const key in response.data){
                resultObject.push(...response.data[key])
            }
            setItems(resultObject)*/
              setItems(response.data)
              setTotalItemsCount(response.total)
          } else if(!response.success){
            setErrorMessage(response.error)
          }
          if(response.code === 'ERR_BAD_REQUEST'){
            setErrorMessage('Bad Request')
          } else if(response.code === "ERR_NETWORK") {
              setErrorMessage(response.message)
          }
          
        setTimeout(() => {
            setErrorMessage('')
        }, 3500);
      })
    }, [currentPage, itemsPerPage])
    useEffect(() => {
      getPendingPO(status);
      }, [status, currentPage, itemsPerPage, getPendingPO]);
    /*const approveItem = (itemId) => {
        adminServices.approveMasterItem(itemId).then((response) => {
            if(response.success){
                setSuccessMessage(response.data)
                getPendingPO(status)
            } else if(!response.success){
                setErrorMessage(response.error)
              }
              if(response.code === 'ERR_BAD_REQUEST'){
                setErrorMessage(response.data.message)
                  return false
              } else if(response.code === "ERR_NETWORK") {
                  setErrorMessage(response.message)
              }
        })
    }
    const rejectItem = (itemId) => {
        alert('Yee rejected')
    }*/
    const goToPage = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
          setCurrentPage(pageNumber);
        }
    };
    const getPageRange = () => {
        const range = [];
        const maxDisplayedPages = 10;
        const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);
        let startPage = currentPage - halfDisplayedPages;
        let endPage = currentPage + halfDisplayedPages;
    
        if (startPage <= 0) {
          startPage = 1;
          endPage = Math.min(maxDisplayedPages, totalPages);
        }
    
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(1, endPage - maxDisplayedPages + 1);
        }
    
        for (let i = startPage; i <= endPage; i++) {
          range.push(i);
        }
    
        return range;
    }
    const handlePOSearch = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if(jobId !== ''){
            adminServices.searchPurchasedOrders(jobId).then((response) => {
                if(response.data){
                    setItems(response.data)
                    setIsLoading(false)
                }
            })
        }
    }
    const clearSearch = () => {
        setJobId('')
        getPendingPO(status)
    }
    const isEdited = (value) => {
        if(value){
            getPendingPO(status)
        }
    }
  return (
    <>
        <div className='form inline-form align-items-center'>
            <div className="input-wrapper mat-input w-420">
                <input type="text" name="companygst" className="form-control" id="companygstControl" placeholder=' ' autoComplete='off' value={jobId} onChange={(e) => setJobId(e.target.value)} />
                <label htmlFor="userControl" className="form-label">Enter Job Id</label>
            </div>
            <button type="button" className={`btn btn-primary btn-medium ${jobId && isLoading ? 'disabled' : ''}`} onClick={(e) => handlePOSearch(e)}>Search{jobId && isLoading && <span className='loader loader-small ms-1'></span>}</button>
            {jobId && <div className='link' onClick={clearSearch} style={{marginLeft: '10px'}}>Clear Search</div>}
        </div>
        {items && Object.keys(items).length > 0 ? <div className='table hover-table po-table'>
            <div className='table-body'>
                <div className='table-header'>
                    <div>Job ID</div>
                    {/*{poType === 'customer' && <><div>Customer Name</div>
                    <div className='status'>Status</div></>}
                    {poType === 'vendor' && <><div>Vendor Name</div>
                    <div>PO Date</div>
                    <div>PO No.</div>
                    <div>Commission (%)</div>
                    <div>PO Amount</div>
                    <div className='status'>Status</div></>}
                    <div className='action'>Action</div>*/}
                </div>
                {items && items.map((item, index) => (
                    <POStatusTableRow POData={item} key={index} isEdited={isEdited} />
                ))}
            </div>
            {errorMessage && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMessage}</MToast>}
        </div> : <div className='table-body'>No data available.</div>}
        {totalPages > 1 ? <div className='table-pagination'>
            <ul className="pagination mb-0">
                <li className="page-item"><button className="page-link prev" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button></li>
                {getPageRange().map((pageNumber) => (
                <li key={pageNumber} className={currentPage === pageNumber ? 'page-item active' : 'page-item'}>
                    <button onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber} className="page-link">
                    {pageNumber}
                    </button>
                </li>
                ))}
                <li className="page-item"><button className="page-link next" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button></li>
            </ul>
            <div className="input-wrapper w-110 ms-auto mb-0">
                <label htmlFor="ItemsPerPage" className="form-label">Items Per Page</label>
                <SelectDropdown
                    options={itemsCountList}
                    className='select-small open-top'
                    disableMat={true}
                    labelText='Items per page'
                    value={itemsPerPage}
                    onChange={(option) => setItemsPerPage(option)}
                />
            </div>
        </div> : null}
    </>
  )
}

export default POStatusTable