import React, { useEffect, useState } from 'react'
import '../../scss/toasts.scss'

function MToast({icon, color, children, duration = 3000}) {
  const [showToast, setShowToast] = useState(true);
  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowToast(false);
    }, duration);
    return () => {
      clearTimeout(hideTimeout);
    };
  }, [duration]);
  const hideToast = () => {
    setShowToast(false);
  };
  return (
    <>
      {showToast && (
        <div className={`toast toast-${color} ${showToast ? '' : 'hide'}`}>
          <span className={`icon ${icon}`}></span>
          {children}<span className='icon icon-cross-white close' onClick={hideToast}></span>
        </div>
      )}
    </>
  )
}

export default MToast