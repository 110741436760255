import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik';
import MToast from '../../shared/UI/MToast';
import { adminServices } from '../../services/admin.service';

function RejectModal({viewRejectModal, showRejectModal, itemId, itemRejected}) {
    const [show, setShow] = useState(false)
    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(viewRejectModal) setShow(viewRejectModal)
    }, [viewRejectModal])
    const handleHide = () => {
        setShow(false)
        if(showRejectModal) showRejectModal(false)
    }
    const rejectItem = (values) => {
        if(itemId){
            setIsLoading(true)
            adminServices.rejectMasterItem({masterItemId: itemId, remarks: values.remarks}).then((response) => {
                if(response.success){
                    setSuccessMsg(response.data)
                    setTimeout(() => {
                        handleHide()
                        if(itemRejected) itemRejected(true)
                    }, 2800);
                } else if(!response.success){
                    setErrorMsg(response.error)
                }
                if(response.code === 'ERR_BAD_REQUEST'){
                    setErrorMsg(response.data.message)
                } else if(response.code === "ERR_NETWORK") {
                    setErrorMsg(response.message)
                }
                setIsLoading(false)
            })
        } else{
            setErrorMsg('Unable to get Item Id')
        }
        setTimeout(() => {
            setErrorMsg('')
            setSuccessMsg('')
        }, 3000);
    }
    const validate = values => {
        const errors = {};
        if (values.remarks === '') {
            errors.remarks = 'Please provide a reason to modify item';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            remarks: ""
        },
        validate,
        validateOnMount: true,
        onSubmit: values => {
            rejectItem(values)
        },
    });
  return (
    <Modal show={show} onHide={handleHide} className='mpass-modal center-modal modal-medium'>
        <Modal.Header>
            <Modal.Title>Add Remarks</Modal.Title>
            <span className='close-modal icon icon-cross' style={{width: '25px', height: '25px'}} onClick={handleHide}></span>
        </Modal.Header>
        <Modal.Body>
            <div className='modal-section'>
                <form onSubmit={formik.handleSubmit}>
                    <div className='rating-form form'>
                        <div className="input-wrapper">
                            <textarea name="remarks" className={`form-control ${formik.values.remarks !== '' ? 'textarea-filled' : ''}`} rows="3"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} value={formik.values.remarks} id="remarks" style={{'height': '60px'}} placeholder='Please tell us the reason to reject'></textarea>
                        </div>
                        {(formik.touched.remarks && formik.errors.remarks) ? <div className='error'>{formik.errors.remarks}</div> : null}
                        <button className={`btn btn-primary btn-auto ${isLoading ? 'disabled' : ''}`} type='submit'>Reject Item{isLoading && <div className='loader loader-medium'></div>}</button>
                    </div>
                </form>
            </div>
            {successMsg && <MToast color="green" duration={3500} icon="icon-check-green-rev">{successMsg}</MToast>}
            {errorMsg && <MToast color="red" duration={3500} icon="icon-cross-red-rev">{errorMsg}</MToast>}
        </Modal.Body>
    </Modal>
  )
}

export default RejectModal